var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("section", { staticClass: "choose-us text-center" }, [
      _c("div", { staticClass: "container" }, [
        _c("h2", { staticClass: "h1" }, [
          _vm._v(_vm._s(_vm.$t("choose.title1")) + " "),
          _c("span", [_vm._v(_vm._s(_vm.$t("choose.title2")))])
        ]),
        _vm._v(" "),
        _c("br"),
        _vm._v(" "),
        _c("br"),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-md-3 col-sm-12" }, [
            _c("div", { staticClass: "holder" }, [
              _c("img", {
                directives: [
                  {
                    name: "lazy",
                    rawName: "v-lazy",
                    value: "./images/Alf/alf/choose/Group 1.png",
                    expression: "'./images/Alf/alf/choose/Group 1.png'"
                  }
                ],
                staticClass: "img-fluid bg-transparent m-auto",
                attrs: { alt: "image " }
              })
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "\n                        " +
                  _vm._s(_vm.$t("choose.secFourTitle")) +
                  "\n                    "
              )
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "text-dimied" }, [
              _vm._v(_vm._s(_vm.$t("choose.secFourPara")))
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-md-3 col-sm-12" }, [
            _c("div", { staticClass: "holder" }, [
              _c("img", {
                directives: [
                  {
                    name: "lazy",
                    rawName: "v-lazy",
                    value: "./images/Alf/alf/choose/Group 2.png",
                    expression: "'./images/Alf/alf/choose/Group 2.png'"
                  }
                ],
                staticClass: "img-fluid bg-transparent m-auto",
                attrs: { alt: "image" }
              })
            ]),
            _vm._v(" "),
            _c("p", [_vm._v(_vm._s(_vm.$t("choose.secThreeTitle")))]),
            _vm._v(" "),
            _c("span", { staticClass: "text-dimied" }, [
              _vm._v(_vm._s(_vm.$t("choose.secThreePara")))
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-md-3 col-sm-12" }, [
            _c("div", { staticClass: "holder" }, [
              _c("img", {
                directives: [
                  {
                    name: "lazy",
                    rawName: "v-lazy",
                    value: "./images/Alf/alf/choose/Group 33.png",
                    expression: "'./images/Alf/alf/choose/Group 33.png'"
                  }
                ],
                staticClass: "img-fluid bg-transparent m-auto",
                attrs: { alt: "image" }
              })
            ]),
            _vm._v(" "),
            _c("p", [_vm._v(_vm._s(_vm.$t("choose.secTwoTitle")))]),
            _vm._v(" "),
            _c("span", { staticClass: "text-dimied" }, [
              _vm._v(_vm._s(_vm.$t("choose.secTwoPara")))
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-md-3 col-sm-12" }, [
            _c("div", { staticClass: "holder" }, [
              _c("img", {
                directives: [
                  {
                    name: "lazy",
                    rawName: "v-lazy",
                    value: "./images/Alf/alf/choose/Group 4.png",
                    expression: "'./images/Alf/alf/choose/Group 4.png'"
                  }
                ],
                staticClass: "img-fluid bg-transparent m-auto",
                attrs: { alt: "image" }
              })
            ]),
            _vm._v(" "),
            _c("p", [_vm._v(_vm._s(_vm.$t("choose.secOneTitle")))]),
            _vm._v(" "),
            _c("span", { staticClass: "text-dimied" }, [
              _vm._v(_vm._s(_vm.$t("choose.secOnePara")))
            ])
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }