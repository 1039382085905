var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.product
    ? _c(
        "div",
        {
          staticClass:
            "product-details product-details-centered product-details-separator"
        },
        [
          _c("div", { staticClass: "container" }, [
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-md-6" },
                [
                  _c("h1", { staticClass: "product-title" }, [
                    _vm._v(_vm._s(_vm.product.name))
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "ratings-container" }, [
                    _c("div", { staticClass: "ratings" }, [
                      _c("div", {
                        staticClass: "ratings-val",
                        style: { width: _vm.product.ratings * 20 + "%" }
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "tooltip-text" }, [
                        _vm._v(_vm._s(_vm.product.ratings.toFixed(2)))
                      ])
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "ratings-text mt-0" }, [
                      _vm._v("( " + _vm._s(_vm.product.review) + " Reviews )")
                    ])
                  ]),
                  _vm._v(" "),
                  _vm.product.stock == 0
                    ? _c(
                        "div",
                        { key: "outPrice", staticClass: "product-price" },
                        [
                          _c("span", { staticClass: "out-price" }, [
                            _vm._v("$" + _vm._s(_vm.product.price.toFixed(2)))
                          ])
                        ]
                      )
                    : [
                        _vm.minPrice == _vm.maxPrice
                          ? _c("div", { staticClass: "product-price" }, [
                              _vm._v("$" + _vm._s(_vm.minPrice.toFixed(2)))
                            ])
                          : [
                              _vm.product.variants.length == 0
                                ? _c("div", { staticClass: "product-price" }, [
                                    _c("span", { staticClass: "new-price" }, [
                                      _vm._v(
                                        "$" + _vm._s(_vm.minPrice.toFixed(2))
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c("span", { staticClass: "old-price" }, [
                                      _vm._v(
                                        "$" + _vm._s(_vm.maxPrice.toFixed(2))
                                      )
                                    ])
                                  ])
                                : _c("div", { staticClass: "product-price" }, [
                                    _vm._v(
                                      "$" +
                                        _vm._s(_vm.minPrice.toFixed(2)) +
                                        " - $" +
                                        _vm._s(_vm.maxPrice.toFixed(2))
                                    )
                                  ])
                            ]
                      ],
                  _vm._v(" "),
                  _c("div", { staticClass: "product-content" }, [
                    _c("p", [_vm._v(_vm._s(_vm.product.short_desc))])
                  ]),
                  _vm._v(" "),
                  _vm.product.variants.length > 0
                    ? [
                        _c(
                          "div",
                          {
                            staticClass: "details-filter-row details-row-size"
                          },
                          [
                            _c("label", [_vm._v("Color:")]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "product-nav product-nav-dots" },
                              _vm._l(_vm.colorArray, function(item, index) {
                                return _c("a", {
                                  key: index,
                                  class: {
                                    active:
                                      item.color == _vm.selectedVariant.color,
                                    disabled: item.disabled
                                  },
                                  style: { "background-color": item.color },
                                  attrs: { href: "#" },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      return _vm.selectColor(item)
                                    }
                                  }
                                })
                              }),
                              0
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "details-filter-row details-row-size"
                          },
                          [
                            _c("label", { attrs: { for: "size" } }, [
                              _vm._v("Size:")
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "select-custom" }, [
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.selectedVariant.size,
                                      expression: "selectedVariant.size"
                                    }
                                  ],
                                  staticClass: "form-control",
                                  attrs: { name: "size", id: "size" },
                                  on: {
                                    change: [
                                      function($event) {
                                        var $$selectedVal = Array.prototype.filter
                                          .call($event.target.options, function(
                                            o
                                          ) {
                                            return o.selected
                                          })
                                          .map(function(o) {
                                            var val =
                                              "_value" in o ? o._value : o.value
                                            return val
                                          })
                                        _vm.$set(
                                          _vm.selectedVariant,
                                          "size",
                                          $event.target.multiple
                                            ? $$selectedVal
                                            : $$selectedVal[0]
                                        )
                                      },
                                      _vm.selectSize
                                    ]
                                  }
                                },
                                [
                                  _c("option", { attrs: { value: "null" } }, [
                                    _vm._v("Select a size")
                                  ]),
                                  _vm._v(" "),
                                  _vm._l(_vm.sizeArray, function(item, index) {
                                    return _c(
                                      "option",
                                      {
                                        key: index,
                                        domProps: { value: item.size }
                                      },
                                      [_vm._v(_vm._s(item.size))]
                                    )
                                  })
                                ],
                                2
                              )
                            ]),
                            _vm._v(" "),
                            _vm._m(0),
                            _vm._v(" "),
                            _vm.showClear
                              ? _c(
                                  "a",
                                  {
                                    attrs: { href: "#" },
                                    on: {
                                      click: function($event) {
                                        $event.preventDefault()
                                        return _vm.clearSelection.apply(
                                          null,
                                          arguments
                                        )
                                      }
                                    }
                                  },
                                  [_vm._v("clear")]
                                )
                              : _vm._e()
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "vue-slide-toggle",
                          { attrs: { open: _vm.showVariationPrice } },
                          [
                            _c("div", { staticClass: "product-price" }, [
                              _vm._v(
                                "$" +
                                  _vm._s(
                                    _vm.selectedVariant.price
                                      ? _vm.selectedVariant.price.toFixed(2)
                                      : 0
                                  )
                              )
                            ])
                          ]
                        )
                      ]
                    : _vm._e()
                ],
                2
              ),
              _vm._v(" "),
              _c("div", { staticClass: "col-md-6" }, [
                _c("div", { staticClass: "product-details-action mb-1" }, [
                  _c(
                    "div",
                    { staticClass: "details-action-col" },
                    [
                      _c("quantity-input", {
                        staticClass: "mr-3 mr-sm-4",
                        attrs: { product: _vm.product },
                        on: { "change-qty": _vm.changeQty }
                      }),
                      _vm._v(" "),
                      _c(
                        "a",
                        {
                          staticClass: "btn-product btn-cart ml-sm-2",
                          class: {
                            "btn-disabled":
                              !_vm.canAddToCart(_vm.product, _vm.qty) ||
                              (_vm.product.variants.length > 0 &&
                                !_vm.showVariationPrice)
                          },
                          attrs: { href: "#" },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.addCart.apply(null, arguments)
                            }
                          }
                        },
                        [
                          _c("span", [
                            _vm._v(_vm._s(_vm.$t("product.addToCart")))
                          ])
                        ]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "details-action-wrapper" },
                    [
                      !_vm.isInWishlist(_vm.product)
                        ? _c(
                            "a",
                            {
                              key: "notInWishlist",
                              staticClass: "btn-product btn-wishlist",
                              attrs: { href: "#", title: "Wishlist" },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.addToWishlist({
                                    product: _vm.product
                                  })
                                }
                              }
                            },
                            [
                              _c("span", [
                                _vm._v(_vm._s(_vm.$t("product.addToWishlist")))
                              ])
                            ]
                          )
                        : _c(
                            "nuxt-link",
                            {
                              key: "inWishlist",
                              staticClass:
                                "btn-product btn-wishlist added-to-wishlist",
                              attrs: { to: "/shop/wishlist", title: "Wishlist" }
                            },
                            [_c("span", [_vm._v("Go to Wishlist")])]
                          )
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "product-details-footer details-footer-col" },
                  [
                    _c(
                      "div",
                      { staticClass: "product-cat" },
                      [
                        _c("span", [_vm._v("Category:")]),
                        _vm._v(" "),
                        _vm._l(_vm.product.category, function(cat, index) {
                          return _c(
                            "span",
                            { key: index },
                            [
                              _c(
                                "nuxt-link",
                                {
                                  attrs: {
                                    to: {
                                      path: "/shop/sidebar/list",
                                      query: { category: cat.slug }
                                    }
                                  }
                                },
                                [_vm._v(_vm._s(cat.name))]
                              ),
                              _vm._v(
                                "\n                            " +
                                  _vm._s(
                                    index < _vm.product.category.length - 1
                                      ? ","
                                      : ""
                                  ) +
                                  "\n                        "
                              )
                            ],
                            1
                          )
                        })
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _vm._m(1)
                  ]
                )
              ])
            ])
          ])
        ]
      )
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "a",
      { staticClass: "size-guide mr-4", attrs: { href: "javascript:;" } },
      [
        _c("i", { staticClass: "icon-th-list" }),
        _vm._v("size guide\n                        ")
      ]
    )
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "social-icons social-icons-sm" }, [
      _c("span", { staticClass: "social-label" }, [_vm._v("Share:")]),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass: "social-icon",
          attrs: { href: "javascript:;", title: "Facebook", target: "_blank" }
        },
        [_c("i", { staticClass: "icon-facebook-f" })]
      ),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass: "social-icon",
          attrs: { href: "javascript:;", title: "Twitter", target: "_blank" }
        },
        [_c("i", { staticClass: "icon-twitter" })]
      ),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass: "social-icon",
          attrs: { href: "javascript:;", title: "Instagram", target: "_blank" }
        },
        [_c("i", { staticClass: "icon-instagram" })]
      ),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass: "social-icon",
          attrs: { href: "javascript:;", title: "Pinterest", target: "_blank" }
        },
        [_c("i", { staticClass: "icon-pinterest" })]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }