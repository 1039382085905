var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("h2", { staticClass: "title text-center mb-4" }, [
      _vm._v("منتجات تم شرائها")
    ]),
    _vm._v(" "),
    _vm.products.length == 0
      ? _c("div", [
          _c("div", { staticClass: "swiper-carousel carousel-with-shadow" }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "swiper",
                    rawName: "v-swiper:swiper2",
                    value: _vm.carouselSetting,
                    expression: "carouselSetting",
                    arg: "swiper2"
                  }
                ]
              },
              [
                _c(
                  "div",
                  { staticClass: "swiper-wrapper" },
                  _vm._l([1, 2, 3, 4], function(item) {
                    return _c(
                      "div",
                      { key: item, staticClass: "swiper-slide" },
                      [_c("div", { staticClass: "skel-pro" })]
                    )
                  }),
                  0
                )
              ]
            )
          ])
        ])
      : _c(
          "div",
          { staticClass: "swiper-carousel carousel-with-shadow swiper-1" },
          [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "swiper",
                    rawName: "v-swiper:swiper1",
                    value: _vm.carouselSetting,
                    expression: "carouselSetting",
                    arg: "swiper1"
                  }
                ]
              },
              [
                _c(
                  "div",
                  { staticClass: "swiper-wrapper" },
                  _vm._l(_vm.products, function(product, index) {
                    return _c(
                      "div",
                      { key: index, staticClass: "swiper-slide" },
                      [
                        _c(
                          "nuxt-link",
                          {
                            staticClass: "brand",
                            attrs: {
                              to: _vm.localePath(
                                `/product/default/${product.id}`
                              )
                            }
                          },
                          [
                            _c("img", {
                              directives: [
                                {
                                  name: "lazy",
                                  rawName: "v-lazy",
                                  value: product.main_image,
                                  expression: "product.main_image"
                                }
                              ],
                              staticClass: "bg-transparent",
                              attrs: {
                                alt: "Molla Logo",
                                height: "27",
                                width: "200"
                              }
                            }),
                            _vm._v(" "),
                            _c("p", { staticClass: "text-dark h6 mt-2" }, [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(product.name) +
                                  "\n                            "
                              )
                            ]),
                            _vm._v(" "),
                            product.discount_int !== null
                              ? _c("div", { staticClass: "row" }, [
                                  _c(
                                    "div",
                                    { staticClass: "col-md-6 col-sm-12" },
                                    [
                                      _c("p", { staticClass: "text-danger" }, [
                                        _c("del", [
                                          _vm._v(_vm._s(product.price))
                                        ])
                                      ])
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "col-md-6 col-sm-12" },
                                    [
                                      _c("p", { staticClass: "text-primary" }, [
                                        _vm._v(
                                          "\n                                        " +
                                            _vm._s(
                                              product.productPrice_after_discount
                                            ) +
                                            " "
                                        ),
                                        _c("span", [_vm._v("جنية")])
                                      ])
                                    ]
                                  )
                                ])
                              : _c("div", { staticClass: "row" }, [
                                  _c(
                                    "div",
                                    { staticClass: "col-md-12 col-sm-12" },
                                    [
                                      _c("p", { staticClass: "text-primary" }, [
                                        _vm._v(
                                          "\n                                        " +
                                            _vm._s(
                                              product.productPrice_after_discount
                                            ) +
                                            " "
                                        ),
                                        _c("span", [_vm._v("جنية")])
                                      ])
                                    ]
                                  )
                                ])
                          ]
                        )
                      ],
                      1
                    )
                  }),
                  0
                )
              ]
            ),
            _vm._v(" "),
            _vm._m(0)
          ]
        )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "swiper-nav" }, [
      _c("div", { staticClass: "swiper-prev" }, [
        _c("i", { staticClass: "icon-angle-left" })
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "swiper-next" }, [
        _c("i", { staticClass: "icon-angle-right" })
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }