var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      !_vm.loading
        ? [
            _c(
              "div",
              { staticClass: "col-9 m-auto skeleton-body skel-shop-products" },
              [
                _vm._l(1, function(item) {
                  return _c("div", {
                    key: item,
                    staticClass: "skel-pro skel-pro-list"
                  })
                })
              ],
              2
            )
          ]
        : _c(
            "div",
            {
              directives: [
                {
                  name: "lazy",
                  rawName: "v-lazy:background-image",
                  value: "./images/home/honeycomb-with-jar (4).png",
                  expression: "'./images/home/honeycomb-with-jar (4).png'",
                  arg: "background-image"
                }
              ],
              staticClass: "newArrival",
              attrs: { id: "recommendations" }
            },
            [
              _c("div", { staticClass: "img" }, [
                _c("img", {
                  directives: [
                    {
                      name: "lazy",
                      rawName: "v-lazy",
                      value: "./images/home/Asset 2@2x.png",
                      expression: "'./images/home/Asset 2@2x.png'"
                    }
                  ]
                })
              ]),
              _vm._v(" "),
              _vm.newArrival.length > 0
                ? _c(
                    "section",
                    {
                      staticClass:
                        "baby-producst recommendations text-center fourth-carousel"
                    },
                    [
                      _c("div", { staticClass: "wrapper-with-margin" }, [
                        _c("div", { staticClass: "container" }, [
                          _c(
                            "div",
                            { staticClass: "row align-items-center mb-5" },
                            [
                              _vm._m(0),
                              _vm._v(" "),
                              _c("hr"),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "col-6 show-all" },
                                [
                                  _c(
                                    "nuxt-link",
                                    {
                                      staticClass:
                                        "btn btn-primary btn-round p-4",
                                      attrs: {
                                        to: _vm.localePath("/shop/sidebar/list")
                                      }
                                    },
                                    [_c("span", [_vm._v("عرض الكل")])]
                                  )
                                ],
                                1
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "swiper-carousel new-arrival swiper-2 pb-5 mt-5"
                            },
                            [
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "swiper",
                                      rawName: "v-swiper:swiper9",
                                      value: _vm.carouselSetting9,
                                      expression: "carouselSetting9",
                                      arg: "swiper9"
                                    }
                                  ]
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "swiper-wrapper" },
                                    _vm._l(_vm.newArrival, function(myNew) {
                                      return _c(
                                        "div",
                                        {
                                          key: myNew.id,
                                          staticClass: "swiper-slide"
                                        },
                                        [
                                          _vm._m(1, true),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "card-holder" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "card text-center"
                                                },
                                                [
                                                  myNew.discount_int !== null
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "card-info text-white"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                                Sale!\n                                            "
                                                          )
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "image-box"
                                                    },
                                                    [
                                                      _c(
                                                        "nuxt-link",
                                                        {
                                                          attrs: {
                                                            to: _vm.localePath(
                                                              `/product/default/${myNew.id}`
                                                            )
                                                          }
                                                        },
                                                        [
                                                          myNew.product_main_image !==
                                                          ""
                                                            ? _c("img", {
                                                                directives: [
                                                                  {
                                                                    name:
                                                                      "lazy",
                                                                    rawName:
                                                                      "v-lazy",
                                                                    value:
                                                                      myNew.product_main_image,
                                                                    expression:
                                                                      "\n                                                            myNew.product_main_image\n                                                        "
                                                                  }
                                                                ],
                                                                staticClass:
                                                                  "bg-transparent",
                                                                attrs: {
                                                                  alt:
                                                                    "Alf Logo",
                                                                  height: "27"
                                                                }
                                                              })
                                                            : _c("img", {
                                                                directives: [
                                                                  {
                                                                    name:
                                                                      "lazy",
                                                                    rawName:
                                                                      "v-lazy",
                                                                    value:
                                                                      "~/static/images/Alf/alf/recomm/sv-row10301-dockatot-deluxe-pod-pristine-white-1605564557.png",
                                                                    expression:
                                                                      "\n                                                            '~/static/images/Alf/alf/recomm/sv-row10301-dockatot-deluxe-pod-pristine-white-1605564557.png'\n                                                        "
                                                                  }
                                                                ],
                                                                staticClass:
                                                                  "bg-transparent",
                                                                attrs: {
                                                                  alt:
                                                                    "Alf Logo",
                                                                  height: "27"
                                                                }
                                                              })
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "card-content"
                                                    },
                                                    [
                                                      _c(
                                                        "h2",
                                                        {
                                                          staticClass:
                                                            "card-title"
                                                        },
                                                        [
                                                          _c(
                                                            "nuxt-link",
                                                            {
                                                              attrs: {
                                                                to: _vm.localePath(
                                                                  `/product/default/${myNew.id}`
                                                                )
                                                              }
                                                            },
                                                            [
                                                              _c("p", [
                                                                _vm._v(
                                                                  "\n                                                            " +
                                                                    _vm._s(
                                                                      myNew.name
                                                                    ) +
                                                                    "\n                                                        "
                                                                )
                                                              ])
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      ),
                                                      _vm._v(" "),
                                                      myNew.reviews.length > 0
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "rating"
                                                            },
                                                            [
                                                              _c("i", {
                                                                staticClass:
                                                                  "fas fa-star"
                                                              }),
                                                              _vm._v(
                                                                "\n                                                    (" +
                                                                  _vm._s(
                                                                    myNew
                                                                      .reviews[0]
                                                                      .rate
                                                                  ) +
                                                                  "\n                                                    rate)\n                                                    "
                                                              )
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      myNew.discount_int !==
                                                      null
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "price-box text-center"
                                                            },
                                                            [
                                                              _c(
                                                                "p",
                                                                {
                                                                  staticClass:
                                                                    "price"
                                                                },
                                                                [
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticClass:
                                                                        "text-primary"
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                                                            " +
                                                                          _vm._s(
                                                                            myNew.productPrice_after_discount
                                                                          ) +
                                                                          "\n                                                        "
                                                                      )
                                                                    ]
                                                                  )
                                                                ]
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "p",
                                                                {
                                                                  staticClass:
                                                                    "old-price"
                                                                },
                                                                [
                                                                  _c("del", [
                                                                    _vm._v(
                                                                      "\n                                                            " +
                                                                        _vm._s(
                                                                          myNew.price
                                                                        ) +
                                                                        "\n                                                        "
                                                                    )
                                                                  ])
                                                                ]
                                                              )
                                                            ]
                                                          )
                                                        : _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "price-box text-center"
                                                            },
                                                            [
                                                              _c(
                                                                "p",
                                                                {
                                                                  staticClass:
                                                                    "price"
                                                                },
                                                                [
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticClass:
                                                                        "text-primary"
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                                                           " +
                                                                          _vm._s(
                                                                            myNew.price
                                                                          ) +
                                                                          "\n                                                        "
                                                                      )
                                                                    ]
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass: "actions"
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass: "row"
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "col"
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "img-holder d-flex justify-content-center align-items-center",
                                                                      class: {
                                                                        "btn-disabled": !_vm.canAddToCart(
                                                                          myNew,
                                                                          1
                                                                        )
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "button",
                                                                        {
                                                                          on: {
                                                                            click: function(
                                                                              $event
                                                                            ) {
                                                                              $event.preventDefault()
                                                                              return _vm.addToCart(
                                                                                {
                                                                                  product: myNew
                                                                                }
                                                                              )
                                                                            }
                                                                          }
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "i",
                                                                            {
                                                                              staticClass:
                                                                                "fas fa-cart-plus fa-2x"
                                                                            }
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  )
                                                                ]
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "col"
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "img-holder d-flex justify-content-center align-items-center"
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "wishlist-outline d-flex justify-content-start"
                                                                        },
                                                                        [
                                                                          _vm.isInWishlist(
                                                                            myNew
                                                                          )
                                                                            ? _c(
                                                                                "nuxt-link",
                                                                                {
                                                                                  key:
                                                                                    "inWishlist",
                                                                                  staticClass:
                                                                                    "btn-product",
                                                                                  attrs: {
                                                                                    to: _vm.localePath(
                                                                                      "/shop/wishlist"
                                                                                    )
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "i",
                                                                                    {
                                                                                      staticClass:
                                                                                        "fas fa-heart"
                                                                                    }
                                                                                  )
                                                                                ]
                                                                              )
                                                                            : _c(
                                                                                "a",
                                                                                {
                                                                                  key:
                                                                                    "notInWishlist",
                                                                                  staticClass:
                                                                                    "btn-product",
                                                                                  on: {
                                                                                    click: function(
                                                                                      $event
                                                                                    ) {
                                                                                      $event.preventDefault()
                                                                                      ;({
                                                                                        product: myNew
                                                                                      })
                                                                                    }
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "i",
                                                                                    {
                                                                                      staticClass:
                                                                                        "far fa-heart fa-2x"
                                                                                    }
                                                                                  )
                                                                                ]
                                                                              )
                                                                        ],
                                                                        1
                                                                      )
                                                                    ]
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    }),
                                    0
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _vm._m(2)
                            ]
                          )
                        ])
                      ])
                    ]
                  )
                : _vm._e()
            ]
          )
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-6" }, [
      _c("h1", { staticClass: "text-right text-gray m-3 mb-2" }, [
        _vm._v(
          "\n                                احدث العروض\n                            "
        )
      ])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "rating card-info" }, [
      _c("i", { staticClass: "fas fa-star" }),
      _vm._v(" "),
      _c("span", [_vm._v("4.5")])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "swiper-nav" }, [
      _c("div", { staticClass: "swiper-prev" }, [
        _c("i", { staticClass: "icon-angle-left" })
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "swiper-next" }, [
        _c("i", { staticClass: "icon-angle-right" })
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }