var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isLoggedIn
    ? _c(
        "div",
        { staticClass: "wishlist" },
        [
          _c(
            "nuxt-link",
            {
              attrs: {
                to: _vm.localePath("/shop/wishlist"),
                title: _vm.$t("payment.wishlist")
              }
            },
            [
              _c("div", { staticClass: "icon" }, [
                _c("img", {
                  directives: [
                    {
                      name: "lazy",
                      rawName: "v-lazy",
                      value: "./images/Alf/whishlist.png",
                      expression: "'./images/Alf/whishlist.png'"
                    }
                  ],
                  staticClass: "bg-transparent",
                  attrs: { alt: "Molla Logo", width: "100%", height: "100%" }
                }),
                _vm._v(" "),
                _c("span", { staticClass: "wishlist-count badge" }, [
                  _vm._v(_vm._s(_vm.myWishLength))
                ])
              ])
            ]
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }