<template>
    <div class="sidebar sidebar-blog">
        <div class="widget widget-search">
            <h3 class="widget-title"> {{ $t('PopularArticles.name') }}</h3>
            <div
                class="row mb-2"
                v-for="popularArticle in popularArticles"
                :key="popularArticle.id"
            >
                <nuxt-link :to="`/blog/single/default/${popularArticle.id}`">
                    <div class="row">
                        <div class="col-6">
                            <div class="sidebar-blog__img">
                                <img v-lazy="popularArticle.image" />
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="sidebar-blog__desc">
                                <p class="" style="font-size:10px">
                                    {{ popularArticle.short_description }}
                                </p>
                            </div>
                            <div class="row">
                                <div class="col-8">
                                    <div class="sider-blog__date">
                                        <p style="font-size:10px">
                                            <i class="far fa-calendar-alt"></i>
                                            {{ popularArticle.time }}
                                        </p>
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div class="sider-blog__review">
                                        <p style="font-size:12px">
                                            <i class="far fa-eye"></i>
                                            {{ popularArticle.views }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </nuxt-link>
            </div>
        </div>
        <hr />

        <div class="widget">
            <h3 class="widget-title">{{ $t('randomArticles.name')}}</h3>

            <section class="blog">
                <div class="">
                    <div
                        class="row"
                        v-for="randomArticle in randomArticles"
                        :key="randomArticle.id"
                    >
                        <nuxt-link
                            :to="`/blog/single/default/${randomArticle.id}`"
                        >
                            <div
                                class="d-flex justify-content-start"
                                v-if="randomArticle.image"
                            >
                                <img
                                    v-lazy="randomArticle.image"
                                    alt="blog cover"
                                    class="img-fluid bg-transparent"
                                />
                            </div>
                            <div class="">
                                <div class="data-holder py-4">
                                    <h6>
                                        {{ randomArticle.title }}
                                    </h6>
                                    <p style="font-size: 11px;">
                                        {{ randomArticle.short_description }}
                                    </p>
                                    <ul
                                        class="
                                            list-unstyled
                                            d-flex
                                            justify-content-around
                                        "
                                        style="margin-top: 10px;"
                                    >
                                        <li style="font-size: 10px;" class="d-flex align-items-center">
                                            <span
                                                ><i class="icon-calendar"></i
                                            ></span>
                                            <span>
                                                {{
                                                    randomArticle.created_at
                                                }}</span
                                            >
                                        </li>
                                        <li class="d-flex align-items-center">
                                            <span
                                                ><i class="icon-eye"></i
                                            ></span>
                                            <span>
                                                {{ randomArticle.views }}</span
                                            >
                                        </li>
                                    </ul>

                                    <br />
                                </div>
                            </div>
                        </nuxt-link>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>
<script>
import axios from 'axios';
import Repository, { baseUrl } from '~/repositories/repository.js';

export default {
    data: function() {
        return {
            category: this.$route.query.category,
            id: this.$route.params.category,
            popularArticles: [],
            randomArticles: [],
            categories: [],
            keyWords:[]
        };
    },

    created(){
this.getBlogs()
    },
    methods:{
         getBlogs: async function() {
            this.type = this.$route.params.type;

            this.loaded = false;

            let params = {
                ...this.$route.query,
                orderBy: this.orderBy,
                per_page: this.perPage,
                demo: this.currentDemo
            };

            if (this.$route.query.category) {
                params = { ...params, category: this.$route.query.category };
            }

            await Repository.post(`${baseUrl}/blog/all`, {
                // params: params,
            })
                .then(resp => {
                    this.popularArticles = resp.data.articles.data.slice(0, 5);
                console.log('this.popularArticles', this.popularArticles)
                })
                .catch(error => ({ error: JSON.stringify(error) }));
        },
    },
    mounted() {
        axios
            .get(`${baseUrl}/blog/random_blogs`, {
                headers: {
                    language:
                        this.$i18n.locale == 'en' ? 'LANGUAGE2' : 'LANGUAGE1'
                }
            })
            .then(resp => {
                this.randomArticles = resp.data.articles.slice(0, 13);
                console.log('randomArticles', this.randomArticles);
            }).catch(error => ({ error: JSON.stringify(error) }));;
        axios
            .get(`${baseUrl}/blog/blog_categories?id=${this.id}`,{
                headers: {
                    language:
                        this.$i18n.locale == 'en' ? 'LANGUAGE2' : 'LANGUAGE1'
                }
            })
            .then(resp => {
                this.categories = resp.data.categories;
                console.log('categories', this.categories);
            }).catch(error => ({ error: JSON.stringify(error) }));;
                axios
            .get(`${baseUrl}/blog/random_keywords`,{
            })
            .then(resp => {
                this.keyWords = resp.data.keywords;
                console.log('categories', this.categories);
            }).catch(error => ({ error: JSON.stringify(error) }));;
    }
};
</script>

<style lang="scss" scoped>
.readButtton {
    color: #ff4141;
    font-weight: 600;
}
hr {
    height: 2px;
}
.sidebar-blog {
    h6 {
        word-break: break-word;
    }
    .sidebar-blog__desc {
        p {
            font-size: 15px;
            font-weight: 400;
            word-break: break-word;
        }
    }
    .sider-blog__date {
        font-size: 9px;
        i {
            font-size: 14px;
        }
    }
    .sider-blog__review {
        font-size: 13px;
        i {
            font-size: 15px;
        }
    }
}
.Categories__links {
    flex-wrap: wrap;
    justify-content: space-around;
    a {
        display: inline-block;
        padding-right: 20px;
        padding-left: 20px;
        text-align: center;
        background: #e8e8e8;
        border-radius: 6px;
        color: #9f9f9f;
        font-weight: 400;
    }
}
.data-holder {
    p {
        word-break: break-all;
    }
}
</style>
