<template>
    <div>
        <div class="services py-5">
            <div class="container">
                <div class="row">
                    <div class="col-2">
                        <h4>Services</h4>
                    </div>
                    <div class="col-10">
                        <div
                            class="
                                accordion accordion-plus
                                product-details-accordion
                                mb-2
                            "
                        >
                            <div class="card card-box card-sm">
                                <div class="card-header">
                                    <h2 class="card-title">
                                        <a
                                            class="toggle-button bg-transparent"
                                            :class="{
                                                expanded: toggleState[0],
                                                collapsed: !toggleState[0],
                                            }"
                                            @click.prevent="changeToggle(0)"
                                            
                                            >Lab & Blood Draw Services</a
                                        >
                                    </h2>
                                </div>

                                <vue-slide-toggle :open="toggleState[0]">
                                    <div class="card-body">
                                        <div class="product-desc-content">
                                            <p>
                                                Lorem ipsum dolor sit amet,
                                                consectetuer adipiscing elit.
                                                Donec odio. Quisque volutpat
                                                mattis eros. Nullam malesuada
                                                erat ut turpis. Suspendisse urna
                                                viverra non, semper suscipit.
                                            </p>
                                            <ul>
                                                <li>
                                                    Nunc nec porttitor turpis.
                                                    In eu risus enim. In vitae
                                                    mollis elit.
                                                </li>
                                                <li>
                                                    Vivamus finibus vel mauris
                                                    ut vehicula.
                                                </li>
                                                <li>
                                                    Nullam a magna porttitor,
                                                    dictum risus nec, faucibus
                                                    sapien.
                                                </li>
                                            </ul>

                                            <p>
                                                Lorem ipsum dolor sit amet,
                                                consectetuer adipiscing elit.
                                                Donec odio. Quisque volutpat
                                                mattis eros. Nullam malesuada
                                                erat ut turpis. Suspendisse urna
                                                viverra non, semper suscipit,
                                                posuere a, pede. Donec nec justo
                                                eget felis facilisis fermentum.
                                                Aliquam porttitor mauris sit
                                                amet orci. Aenean dignissim
                                                pellentesque felis. Phasellus
                                                ultrices nulla quis nibh.
                                                Quisque a lectus. Donec
                                                consectetuer ligula vulputate
                                                sem tristique cursus.
                                            </p>
                                        </div>
                                    </div>
                                </vue-slide-toggle>
                            </div>

                            <div class="card card-box card-sm">
                                <div class="card-header">
                                    <h2 class="card-title">
                                        <a
                                            class="toggle-button bg-transparent"
                                            :class="{
                                                expanded: toggleState[1],
                                                collapsed: !toggleState[1],
                                            }"
                                            @click.prevent="changeToggle(1)"
                                            
                                            >Sleep Medicine Services</a
                                        >
                                    </h2>
                                </div>

                                <vue-slide-toggle :open="toggleState[1]">
                                    <div class="card-body">
                                        <div class="product-desc-content">
                                            <p class="mb-2">
                                                Lorem ipsum dolor sit amet,
                                                consectetuer adipiscing elit.
                                                Donec odio. Quisque volutpat
                                                mattis eros. Nullam malesuada
                                                erat ut turpis.
                                            </p>

                                            <h3>Fabric & care</h3>
                                            <ul>
                                                <li>Faux suede fabric</li>
                                                <li>
                                                    Gold tone metal hoop
                                                    handles.
                                                </li>
                                                <li>RI branding</li>
                                                <li>
                                                    Snake print trim interior
                                                </li>
                                                <li>
                                                    Adjustable cross body strap
                                                </li>
                                                <li>
                                                    Height: 31cm; Width: 32cm;
                                                    Depth: 12cm; Handle Drop:
                                                    61cm
                                                </li>
                                            </ul>

                                            <h3>Size</h3>
                                            <p>one size</p>
                                        </div>
                                    </div>
                                </vue-slide-toggle>
                            </div>

                            <div class="card card-box card-sm">
                                <div class="card-header">
                                    <h2 class="card-title">
                                        <a
                                            class="toggle-button bg-transparent"
                                            :class="{
                                                expanded: toggleState[2],
                                                collapsed: !toggleState[2],
                                            }"
                                            @click.prevent="changeToggle(2)"
                                            
                                            >Surgical Services</a
                                        >
                                    </h2>
                                </div>

                                <vue-slide-toggle :open="toggleState[2]">
                                    <div class="card-body">
                                        <div class="product-desc-content">
                                            <p>
                                                We deliver to over 100 countries
                                                around the world. For full
                                                details of the delivery options
                                                we offer, please view our
                                                <a 
                                                    >Delivery information</a
                                                >
                                                <br />We hope you’ll love every
                                                purchase, but if you ever need
                                                to return an item you can do so
                                                within a month of receipt. For
                                                full details of how to make a
                                                return, please view our
                                                <a 
                                                    >Returns information</a
                                                >
                                            </p>
                                        </div>
                                    </div>
                                </vue-slide-toggle>
                            </div>

                            <!-- <div class="card card-box card-sm">
                                    <div class="card-header">
                                        <h2 class="card-title">
                                            <a
                                                class="toggle-button bg-transparent"
                                                :class="{
                                                    expanded: toggleState[3],
                                                    collapsed: !toggleState[3],
                                                }"
                                                @click.prevent="changeToggle(3)"
                                                
                                                >Reviews(2)</a
                                            >
                                        </h2>
                                    </div>

                                    <vue-slide-toggle :open="toggleState[3]">
                                        <div class="card-body">
                                            <div class="reviews">
                                                <div class="review">
                                                    <div class="row no-gutters">
                                                        <div class="col-auto">
                                                            <h4>
                                                                <a 
                                                                    >Samanta
                                                                    J.</a
                                                                >
                                                            </h4>
                                                            <div
                                                                class="
                                                                    ratings-container
                                                                "
                                                            >
                                                                <div
                                                                    class="
                                                                        ratings
                                                                    "
                                                                >
                                                                    <div
                                                                        class="
                                                                            ratings-val
                                                                        "
                                                                        style="
                                                                            width: 80%;
                                                                        "
                                                                    ></div>
                                                                    <span
                                                                        class="
                                                                            tooltip-text
                                                                        "
                                                                        >4</span
                                                                    >
                                                                </div>
                                                            </div>

                                                            <span
                                                                class="
                                                                    review-date
                                                                "
                                                                >6 days
                                                                ago</span
                                                            >
                                                        </div>

                                                        <div class="col">
                                                            <h4>
                                                                Good, perfect
                                                                size
                                                            </h4>

                                                            <div
                                                                class="
                                                                    review-content
                                                                "
                                                            >
                                                                <p>
                                                                    Lorem ipsum
                                                                    dolor sit
                                                                    amet,
                                                                    consectetur
                                                                    adipisicing
                                                                    elit.
                                                                    Ducimus cum
                                                                    dolores
                                                                    assumenda
                                                                    asperiores
                                                                    facilis
                                                                    porro
                                                                    reprehenderit
                                                                    animi culpa
                                                                    atque
                                                                    blanditiis
                                                                    commodi
                                                                    perspiciatis
                                                                    doloremque,
                                                                    possimus,
                                                                    explicabo,
                                                                    autem fugit
                                                                    beatae quae
                                                                    voluptas!
                                                                </p>
                                                            </div>

                                                            <div
                                                                class="
                                                                    review-action
                                                                "
                                                            >
                                                                <a >
                                                                    <i
                                                                        class="
                                                                            icon-thumbs-up
                                                                        "
                                                                    ></i
                                                                    >Helpful (2)
                                                                </a>
                                                                <a >
                                                                    <i
                                                                        class="
                                                                            icon-thumbs-down
                                                                        "
                                                                    ></i
                                                                    >Unhelpful
                                                                    (0)
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="review border-0">
                                                    <div class="row no-gutters">
                                                        <div class="col-auto">
                                                            <h4>
                                                                <a 
                                                                    >John Doe</a
                                                                >
                                                            </h4>
                                                            <div
                                                                class="
                                                                    ratings-container
                                                                "
                                                            >
                                                                <div
                                                                    class="
                                                                        ratings
                                                                    "
                                                                >
                                                                    <div
                                                                        class="
                                                                            ratings-val
                                                                        "
                                                                        style="
                                                                            width: 100%;
                                                                        "
                                                                    ></div>
                                                                    <span
                                                                        class="
                                                                            tooltip-text
                                                                        "
                                                                        >5</span
                                                                    >
                                                                </div>
                                                            </div>

                                                            <span
                                                                class="
                                                                    review-date
                                                                "
                                                                >5 days
                                                                ago</span
                                                            >
                                                        </div>

                                                        <div class="col">
                                                            <h4>Very good</h4>

                                                            <div
                                                                class="
                                                                    review-content
                                                                "
                                                            >
                                                                <p>
                                                                    Sed,
                                                                    molestias,
                                                                    tempore? Ex
                                                                    dolor esse
                                                                    iure hic
                                                                    veniam
                                                                    laborum
                                                                    blanditiis
                                                                    laudantium
                                                                    iste amet.
                                                                    Cum non
                                                                    voluptate
                                                                    eos enim, ab
                                                                    cumque nam,
                                                                    modi, quas
                                                                    iure illum
                                                                    repellendus,
                                                                    blanditiis
                                                                    perspiciatis
                                                                    beatae!
                                                                </p>
                                                            </div>

                                                            <div
                                                                class="
                                                                    review-action
                                                                "
                                                            >
                                                                <a >
                                                                    <i
                                                                        class="
                                                                            icon-thumbs-up
                                                                        "
                                                                    ></i
                                                                    >Helpful (0)
                                                                </a>
                                                                <a >
                                                                    <i
                                                                        class="
                                                                            icon-thumbs-down
                                                                        "
                                                                    ></i
                                                                    >Unhelpful
                                                                    (0)
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </vue-slide-toggle>
                                </div> -->

                            <div class="card card-box card-sm">
                                <div class="card-header">
                                    <h2 class="card-title">
                                        <a
                                            class="toggle-button bg-transparent"
                                            :class="{
                                                expanded: toggleState[3],
                                                collapsed: !toggleState[3],
                                            }"
                                            @click.prevent="changeToggle(3)"
                                            
                                            >Imaging & X-Ray Services</a
                                        >
                                    </h2>
                                </div>

                                <vue-slide-toggle :open="toggleState[3]">
                                    <div class="card-body">
                                        <div class="product-desc-content">
                                            <p>
                                                We deliver to over 100 countries
                                                around the world. For full
                                                details of the delivery options
                                                we offer, please view our
                                                <a 
                                                    >Delivery information</a
                                                >
                                                <br />We hope you’ll love every
                                                purchase, but if you ever need
                                                to return an item you can do so
                                                within a month of receipt. For
                                                full details of how to make a
                                                return, please view our
                                                <a 
                                                    >Returns information</a
                                                >
                                            </p>
                                        </div>
                                    </div>
                                </vue-slide-toggle>
                            </div>
                            <div class="card card-box card-sm">
                                <div class="card-header">
                                    <h2 class="card-title">
                                        <a
                                            class="toggle-button bg-transparent"
                                            :class="{
                                                expanded: toggleState[4],
                                                collapsed: !toggleState[4],
                                            }"
                                            @click.prevent="changeToggle(4)"
                                            
                                            >Infusion Clinic Services</a
                                        >
                                    </h2>
                                </div>

                                <vue-slide-toggle :open="toggleState[4]">
                                    <div class="card-body">
                                        <div class="product-desc-content">
                                            <p>
                                                We deliver to over 100 countries
                                                around the world. For full
                                                details of the delivery options
                                                we offer, please view our
                                                <a 
                                                    >Delivery information</a
                                                >
                                                <br />We hope you’ll love every
                                                purchase, but if you ever need
                                                to return an item you can do so
                                                within a month of receipt. For
                                                full details of how to make a
                                                return, please view our
                                                <a 
                                                    >Returns information</a
                                                >
                                            </p>
                                        </div>
                                    </div>
                                </vue-slide-toggle>
                            </div>
                            <div class="card card-box card-sm">
                                <div class="card-header">
                                    <h2 class="card-title">
                                        <a
                                            class="toggle-button bg-transparent"
                                            :class="{
                                                expanded: toggleState[5],
                                                collapsed: !toggleState[5],
                                            }"
                                            @click.prevent="changeToggle(5)"
                                            
                                            >24-Hour Emergency Care</a
                                        >
                                    </h2>
                                </div>

                                <vue-slide-toggle :open="toggleState[5]">
                                    <div class="card-body">
                                        <div class="product-desc-content">
                                            <p>
                                                We deliver to over 100 countries
                                                around the world. For full
                                                details of the delivery options
                                                we offer, please view our
                                                <a 
                                                    >Delivery information</a
                                                >
                                                <br />We hope you’ll love every
                                                purchase, but if you ever need
                                                to return an item you can do so
                                                within a month of receipt. For
                                                full details of how to make a
                                                return, please view our
                                                <a 
                                                    >Returns information</a
                                                >
                                            </p>
                                        </div>
                                    </div>
                                </vue-slide-toggle>
                            </div>
                        </div>
                    </div>
                </div>
                <hr />
            </div>
        </div>
    </div>
</template>

<script>
import { VueSlideToggle } from 'vue-slide-toggle';

export default {
    components: {
        VueSlideToggle,
    },
    data: function () {
        return {
            toggleState: [false, false, false, false, false, false],
        };
    },
    methods: {
        changeToggle: function (index1) {
            this.toggleState = this.toggleState.reduce((acc, cur, index) => {
                if (index == index1) return [...acc, !cur];
                return [...acc, false];
            }, []);
        },
    },
};
</script>

<style lang="scss" scoped>
.featured,
.featured .whitlist-holder {
    box-shadow: none;
    .hospital-name {
        font-size: 25px;
        text-decoration: none;
    }
    .card-holder {
        width: 53%;
    }
}
.hospital-info {
    li {
        margin: 10px 0;
        font-size: 17px;
        i {
            font-size: 20px;
        }
        .colored {
            color: #fd9025;
        }
    }
}
.info {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}
.addToFav {
    padding: 20px 25px;
    background: transparent;
    color: #186aa5;
    border: 2px solid #186aa5;
    border-radius: 5px;
    font-size: 17px;
    transition: all 0.3s ease-in-out;
    i {
        background: #fff;
        transition: all 0.3s ease-in-out;
    }
    &:hover {
        background: #186aa5;
        color: #fff;
        i {
            background: #186aa5;
            color: #fff;
        }
    }
}
.rating-stars {
    i {
        font-size: 30px !important;
    }
}
.anchor {
    text-decoration: underline;
    color: #186aa5;
    margin-bottom: 30px;
    display: block;
    font-size: 17px;
}
.accordion {
    width: 70%;
    margin: auto;
}
.card {
    border: none !important;
    border-bottom: 2px solid #707070 !important;
    a {
        padding-left: 0 !important;
    }
}
</style>