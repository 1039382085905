<template>
    <div>
        <!-- toy store banner  -->
        <template v-if="!loading">
            <div class="col-9 m-auto skeleton-body skel-shop-products">
                <template>
                    <div
                        class="skel-pro skel-pro-list"
                        v-for="item in 1"
                        :key="item"
                    ></div>
                </template>
            </div>
        </template>
        <div class="" v-else>
            <section
                class="toy text-center text-white"
                v-for="banner in banners.fifth"
                :key="banner.id"
            >
                <a :href="banner.button_link" v-if="$i18n.locale == 'en'">
                    <img v-lazy="banner.background_image" />
                </a>
                <a :href="banner.button_link" v-if="$i18n.locale == 'ar'">
                    <img v-lazy="banner.background_image_ar" />
                </a>
                <!-- <h2 class="hug text-white">
                    {{ banner.title }}
                </h2> -->

                <!-- <div class="my-5">
                    <nuxt-link
                        :to="localePath('/shop/sidebar/list')"
                        class="btn toyButton text-white"
                    >
                        <span>
                            {{ banner.button_name }}
                        </span>
                    </nuxt-link>
                </div> -->
            </section>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import axios from 'axios';
import {
    carouselSettingSingle,
    carouselSettingDefault
} from '~/utilities/carousel';
import Repository, { baseUrl } from '~/repositories/repository.js';

export default {
    data: function() {
        return {
            banners: {
                first: [],
                seconnd: [],
                third: [],
                fourth: [],
                fifth: [],
                sixth: [],
                seventh: []
            },
            loading: false
        };
    },
    methods: {
        getData: async function() {
            let banners = `${baseUrl}/banners`;

            // banners
            await Repository.get(banners).then(resp => {
                //banners
                if (resp.data.code == 200) {
                    this.loading = true;
                }
                this.banners = resp.data.banners;
                // console.log('banners', this.banners);
            });
        }
    },
    mounted() {
        this.getData();
    },
    props: ['product']
};
</script>

<style></style>
