var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      !_vm.loading
        ? [
            _c(
              "div",
              { staticClass: "col-9 m-auto skeleton-body skel-shop-products" },
              [
                _vm._l(1, function(item) {
                  return _c("div", {
                    key: item,
                    staticClass: "skel-pro skel-pro-list"
                  })
                })
              ],
              2
            )
          ]
        : _c("div", {}, [
            _vm.fashionBaby.length > 0
              ? _c(
                  "section",
                  {
                    staticClass:
                      "baby-producst baby-show baby-show-carousel text-center"
                  },
                  [
                    _c("div", { staticClass: "wrapper-with-margin" }, [
                      _c("div", { staticClass: "container" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "swiper-carousel brands-slider swiper-2 mb-5 mt-5"
                          },
                          [
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "swiper",
                                    rawName: "v-swiper:swiper5",
                                    value: _vm.carouselSetting5,
                                    expression: "carouselSetting5",
                                    arg: "swiper5"
                                  }
                                ]
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "swiper-wrapper" },
                                  _vm._l(_vm.fashionBaby, function(fashion) {
                                    return _c(
                                      "div",
                                      {
                                        key: fashion.id,
                                        staticClass: "swiper-slide"
                                      },
                                      [
                                        _c(
                                          "a",
                                          {
                                            attrs: { href: fashion.button_link }
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "baby-show-holder"
                                              },
                                              [
                                                _c("img", {
                                                  directives: [
                                                    {
                                                      name: "lazy",
                                                      rawName: "v-lazy",
                                                      value:
                                                        fashion.background_image,
                                                      expression:
                                                        "\n                                                    fashion.background_image\n                                                "
                                                    }
                                                  ],
                                                  staticClass: "bg-transparent",
                                                  attrs: {
                                                    alt: "Alf Logo",
                                                    height: "27"
                                                  }
                                                })
                                              ]
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  }),
                                  0
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _vm._m(0)
                          ]
                        )
                      ])
                    ])
                  ]
                )
              : _vm._e()
          ])
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "swiper-nav" }, [
      _c("div", { staticClass: "swiper-prev" }, [
        _c("i", { staticClass: "icon-angle-left" })
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "swiper-next" }, [
        _c("i", { staticClass: "icon-angle-right" })
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }