var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "header",
    { staticClass: "header header-4 header-intro-clearance bg-white" },
    [
      _c("div", { staticClass: "header-top" }, [
        _c(
          "div",
          { class: _vm.isFullwidth ? "container-fluid" : "container" },
          [
            _c("div", { staticClass: "header-right py-2" }, [
              _c(
                "button",
                {
                  staticClass: "mobile-menu-toggler",
                  on: { click: _vm.openMobileMenu }
                },
                [
                  _c("span", { staticClass: "sr-only" }, [
                    _vm._v("Toggle mobile menu")
                  ]),
                  _vm._v(" "),
                  _c("i", { staticClass: "icon-bars" })
                ]
              ),
              _vm._v(" "),
              _c("ul", { staticClass: "top-menus" }, [
                _c("li", [
                  _c(
                    "ul",
                    {
                      staticClass:
                        "d-flex justify-content-center align-items-center"
                    },
                    [
                      _c("li", { staticClass: "px-4" }),
                      _vm._v(" "),
                      !_vm.isLoggedIn
                        ? _c("li", [
                            _c("a", [
                              _c(
                                "span",
                                {
                                  staticClass: "mr-2 text-dark langChaner",
                                  attrs: { href: "#signin-modal" },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      return _vm.openSignInModal.apply(
                                        null,
                                        arguments
                                      )
                                    }
                                  }
                                },
                                [_vm._v(_vm._s(_vm.$t("login.login")))]
                              ),
                              _vm._v(
                                "\n                                    |\n                                    "
                              ),
                              _c(
                                "span",
                                {
                                  staticClass: "ml-2 text-dark langChaner",
                                  attrs: { href: "#signin-modal " },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      return _vm.openSignUpModal.apply(
                                        null,
                                        arguments
                                      )
                                    }
                                  }
                                },
                                [_vm._v(_vm._s(_vm.$t("login.register")))]
                              )
                            ])
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.isLoggedIn
                        ? _c("li", [
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm.$t("login.welcome")) +
                                  " ,\n                                    "
                              ),
                              _c("b", [_vm._v(_vm._s(_vm.userName))])
                            ])
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.isLoggedIn
                        ? _c("li", [
                            _c("button", { staticClass: "logOut" }, [
                              _c("i", { staticClass: "icon-user" }),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  staticClass: "mr-2 text-dark",
                                  attrs: { href: "#signin-modal" },
                                  on: { click: _vm.logout }
                                },
                                [_vm._v(_vm._s(_vm.$t("login.logout")))]
                              )
                            ])
                          ])
                        : _vm._e()
                    ]
                  )
                ])
              ])
            ])
          ]
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "header-bottom sticky-header" }, [
        _c(
          "div",
          { class: _vm.isFullwidth ? "container-fluid" : "container" },
          [
            _c(
              "div",
              { staticClass: "header-left" },
              [
                _c(
                  "nuxt-link",
                  { staticClass: "logo", attrs: { to: _vm.localePath("/") } },
                  [
                    _c("img", {
                      directives: [
                        {
                          name: "lazy",
                          rawName: "v-lazy",
                          value: "./images/home/Asset 1.png",
                          expression: "'./images/home/Asset 1.png'"
                        }
                      ],
                      staticClass: "bg-transparent",
                      attrs: {
                        alt: "royal-bee Logo",
                        width: "105",
                        height: "27"
                      }
                    })
                  ]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "header-center" }, [
              _c("nav", { staticClass: "main-nav" }, [
                _c("ul", { staticClass: "menu sf-arrows" }, [
                  _c(
                    "li",
                    {
                      staticClass: "megamenu-container",
                      class: { active: _vm.current == "/" }
                    },
                    [
                      _c("nuxt-link", { attrs: { to: _vm.localePath("/") } }, [
                        _vm._v(
                          "\n                                " +
                            _vm._s(_vm.$t("nav.home"))
                        )
                      ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "li",
                    { class: { active: _vm.current == "shop" } },
                    [
                      _c(
                        "nuxt-link",
                        {
                          attrs: {
                            to: _vm.localePath("/shop/sidebar/shopFilter")
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("nav.products")))]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "li",
                    { class: { active: _vm.current == "blog" } },
                    [
                      _c(
                        "nuxt-link",
                        { attrs: { to: _vm.localePath("/blog/classic") } },
                        [_vm._v(_vm._s(_vm.$t("nav.blog")))]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "li",
                    { class: { active: _vm.current == "about" } },
                    [
                      _c(
                        "nuxt-link",
                        { attrs: { to: _vm.localePath("/pages/aboutAlf") } },
                        [_vm._v(_vm._s(_vm.$t("nav.about")))]
                      )
                    ],
                    1
                  )
                ])
              ])
            ]),
            _vm._v(" "),
            _vm._m(0)
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "container-fluid", class: _vm.$i18n.locale }, [
          _c("div", { staticClass: "row w-100" }, [
            _c("div", { staticClass: "col-12 list-contact" }, [
              _c(
                "ul",
                {
                  staticClass:
                    "list-unstyled d-flex justify-content-center align-items-center"
                },
                [
                  _c(
                    "li",
                    [
                      _c(
                        "nuxt-link",
                        { attrs: { to: _vm.localePath("/pages/contact") } },
                        [
                          _vm._v(
                            "\n                                " +
                              _vm._s(_vm.$t("nav.customer")) +
                              "\n                            "
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "li",
                    [
                      _c(
                        "nuxt-link",
                        { attrs: { to: _vm.localePath("/pages/contact-2") } },
                        [
                          _vm._v(
                            "\n                                 " +
                              _vm._s(_vm.$t("nav.partner")) +
                              "\n                            "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ]
              )
            ])
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "header-right social" }, [
      _c("ul", { staticClass: "list-unstyled d-flex" }, [
        _c("li", { staticClass: "facebook" }, [
          _c(
            "a",
            {
              staticClass: "facebook",
              attrs: {
                href: "https://www.facebook.com/alefplanet",
                rel: "noopener",
                target: "_blank"
              }
            },
            [_c("i", { staticClass: "fab fa-facebook-f fa-2x" })]
          )
        ]),
        _vm._v(" "),
        _c("li", { staticClass: "twitter" }, [
          _c(
            "a",
            {
              attrs: {
                href: "https://www.instagram.com/alefplanet/",
                rel: "noopener",
                target: "_blank"
              }
            },
            [_c("span", [_c("i", { staticClass: "fab fa-twitter" })])]
          )
        ]),
        _vm._v(" "),
        _c("li", { staticClass: "youtube" }, [
          _c(
            "a",
            {
              attrs: {
                href:
                  "https://www.youtube.com/channel/UC657uLMEPOf0r-x6Tl8KyRA",
                rel: "noopener",
                target: "_blank"
              }
            },
            [_c("span", [_c("i", { staticClass: "fab fa-youtube fa-2x" })])]
          )
        ]),
        _vm._v(" "),
        _c("li", { staticClass: "instagram" }, [
          _c(
            "a",
            {
              attrs: {
                href: "https://www.instagram.com/alefplanet/",
                rel: "noopener",
                target: "_blank"
              }
            },
            [_c("span", [_c("i", { staticClass: "fab fa-instagram fa-2x" })])]
          )
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }