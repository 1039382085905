var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    _vm._l(_vm.product, function(product) {
      return _c("div", { key: product.id }, [
        product
          ? _c(
              "div",
              { staticClass: "product-details" },
              [
                _c("h1", { staticClass: "product-title mb-4" }, [
                  _vm._v(_vm._s(product.name))
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "ratings-container" }),
                _vm._v(" "),
                product.stock == 0
                  ? _c(
                      "div",
                      {
                        key: "outPrice",
                        staticClass:
                          "product-price justify-content-between col-4"
                      },
                      [
                        _c("span", { staticClass: "out-price" }, [
                          _vm._v(" " + _vm._s(product.price) + " ")
                        ])
                      ]
                    )
                  : _c(
                      "div",
                      {
                        key: "outPrice",
                        staticClass:
                          "product-price mt-2 justify-content-between col-6"
                      },
                      [
                        _c(
                          "span",
                          { staticClass: "out-price text-danger mr-5" },
                          [_vm._v(" " + _vm._s(product.price_after_discount))]
                        ),
                        _vm._v(" "),
                        product.discount_int !== null
                          ? _c("span", { staticClass: "out-price mr-2 ml-3" }, [
                              _c("del", [
                                _vm._v(" " + _vm._s(product.price) + " جنية ")
                              ])
                            ])
                          : _vm._e()
                      ]
                    ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "row" },
                  [
                    _vm._m(0, true),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-md-6" }, [
                      _c("h6", [
                        _c("span", [_vm._v(" " + _vm._s(product.unique_id))])
                      ])
                    ]),
                    _vm._v(" "),
                    _vm._m(1, true),
                    _vm._v(" "),
                    _vm._l(product.attributes, function(x) {
                      return _c(
                        "div",
                        { key: x.id, staticClass: "col-md-6 first-attr" },
                        [
                          _c("h6", [
                            _vm._v(
                              "\n                    " +
                                _vm._s(x.attribute_type_name) +
                                " :"
                            ),
                            _c("span", [_vm._v(" " + _vm._s(x.name))])
                          ])
                        ]
                      )
                    })
                  ],
                  2
                ),
                _vm._v(" "),
                _vm._l(product.anotherAttributes, function(element, index) {
                  return [
                    _c(
                      "nuxt-link",
                      {
                        staticClass:
                          "d-flex anotherAttributes justify-content-between",
                        attrs: {
                          to: _vm.localePath(
                            `/product/default/${element.product_id}`
                          )
                        }
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "row" },
                          [
                            _c("div", { staticClass: "col-md-6" }, [
                              _c("h5", [_vm._v("احجام اخرى")])
                            ]),
                            _vm._v(" "),
                            _vm._l(element.attributes, function(x) {
                              return _c(
                                "div",
                                { key: (index, x), staticClass: "first-attr" },
                                [
                                  _c("p", [
                                    _vm._v(
                                      "\n                            " +
                                        _vm._s(x.attribute_type_name) +
                                        " :"
                                    ),
                                    _c("span", [
                                      _vm._v(
                                        "\n                                " +
                                          _vm._s(x.name)
                                      )
                                    ])
                                  ])
                                ]
                              )
                            })
                          ],
                          2
                        )
                      ]
                    )
                  ]
                }),
                _vm._v(" "),
                _c("div", { staticClass: "product-details-footer" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "wishlist-outline d-flex justify-content-start"
                    },
                    [
                      _vm.isInWishlist(product)
                        ? _c(
                            "nuxt-link",
                            {
                              key: "inWishlist",
                              staticClass:
                                "btn-product btn-wishlist added-to-wishlist",
                              attrs: { to: _vm.localePath("/shop/wishlist") }
                            },
                            [
                              _c("span", [
                                _vm._v(_vm._s(_vm.$t("product.inWishlist")))
                              ])
                            ]
                          )
                        : _c(
                            "a",
                            {
                              key: "notInWishlist",
                              staticClass: "btn-product btn-wishlist",
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.addToWishlist({ product: product })
                                }
                              }
                            },
                            [
                              _c("span", [
                                _vm._v(_vm._s(_vm.$t("product.addToWishlist")))
                              ])
                            ]
                          )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "wishlist-outline" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn-product btn-cart",
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.addToCart({ product: product })
                          }
                        }
                      },
                      [
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("product.addToCart")))
                        ])
                      ]
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "order-date-box" }, [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-md-8 col-sm-12" }, [
                      _vm.$i18n.locale == "en"
                        ? _c("p", [
                            _c("span", { staticClass: "order-now" }, [
                              _vm._v("Order Now ")
                            ]),
                            _vm._v(
                              "and get\n                            it by\n                            "
                            ),
                            _vm.days > 0
                              ? _c("span", { staticClass: "date" }, [
                                  _c("b", [_vm._v(_vm._s(_vm.days) + " days")])
                                ])
                              : _c("span", { staticClass: "date" }, [
                                  _c("b", [_vm._v("today ")])
                                ])
                          ])
                        : _c("p", [
                            _c("span", { staticClass: "order-now" }, [
                              _vm._v("اطلب الان ")
                            ]),
                            _vm._v(
                              "وتحصل\n                            عليه فى\n                            "
                            ),
                            _vm.days > 0
                              ? _c("span", { staticClass: "date" }, [
                                  _c("b", [_vm._v(_vm._s(_vm.days) + " يوم ")])
                                ])
                              : _c("span", { staticClass: "date" }, [
                                  _c("b", [_vm._v("نفس اليوم ")])
                                ])
                          ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-md-4 col-sm-12" }, [
                      _vm.$i18n.locale == "en"
                        ? _c("p", [
                            _vm._v(
                              "\n                            |   to\n                            "
                            ),
                            _vm._m(2, true)
                          ])
                        : _c("p", [
                            _vm._v(
                              "\n                            |   الى\n                            "
                            ),
                            _vm._m(3, true)
                          ])
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "bayment mt-3" }, [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-md-6 col-sm-12" }, [
                      _c("p", [
                        _vm._m(4, true),
                        _vm._v(" "),
                        _c("span", [_vm._v(_vm._s(_vm.$t("payment.payment")))])
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-md-6 col-sm-12" }, [
                      _c("p", [
                        _vm._m(5, true),
                        _vm._v(" "),
                        _vm.$i18n.locale == "en"
                          ? _c("span", [_vm._v("100% Authentic")])
                          : _c("span", [_vm._v("أصلى 100%")])
                      ])
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "returns mt-3" }, [
                  _vm.$i18n.locale == "en"
                    ? _c("h4", [_vm._v("Returns:")])
                    : _c("h4", [_vm._v("المرتجع")]),
                  _vm._v(" "),
                  _vm.$i18n.locale == "en"
                    ? _c("h5", [
                        _vm._v(
                          "\n                    Returns within 7 days of delivery.\n                "
                        )
                      ])
                    : _c("h5", [_vm._v("يتم استرجاع المنتج فى 7 ايام")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "product-details-footer mt-3" }, [
                    _vm.$i18n.locale == "en"
                      ? _c("h5", [
                          _vm._v(
                            "\n                        Purchase this item and earn\n                        "
                          ),
                          _c("span", { staticClass: "points" }, [
                            _vm._v(_vm._s(_vm.points))
                          ])
                        ])
                      : _c("h5", [
                          _vm._v(
                            "\n                        شراء هذا العنصر وكسب\n                        "
                          ),
                          _c("span", { staticClass: "points" }, [
                            _vm._v(_vm._s(_vm.points) + " نقطة ")
                          ])
                        ])
                  ])
                ])
              ],
              2
            )
          : _vm._e()
      ])
    }),
    0
  )
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-md-6" }, [
      _c("h5", [_vm._v("كود المنتج")])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-md-6" }, [
      _c("h5", [_vm._v("مواصفات المنتج")])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "date" }, [_c("b", [_vm._v("Egypt")])])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "date" }, [_c("b", [_vm._v("مصر")])])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "mr-2" }, [
      _c("i", { staticClass: "fas fa-shield-alt" })
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "mr-2" }, [
      _c("i", { staticClass: "fas fa-shield-alt" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }