var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "product-details-tab product-details-extended" },
    [
      _c("tabs", {
        staticClass: "nav-pills justify-content-center",
        attrs: { data: _vm.tabsData }
      }),
      _vm._v(" "),
      _c("div", { staticClass: "tab-content border-bottom-0" }, [
        _c(
          "div",
          { staticClass: "tab-pane fade show active", attrs: { id: "tab1" } },
          [
            _c("div", { staticClass: "product-desc-content" }, [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "lazy",
                      rawName: "v-lazy:background-image",
                      value: "./images/products/single/extended/bg-1.jpg",
                      expression:
                        "'./images/products/single/extended/bg-1.jpg'",
                      arg: "background-image"
                    }
                  ],
                  staticClass: "product-desc-row bg-image"
                },
                [_vm._m(0)]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "lazy",
                      rawName: "v-lazy:background-image",
                      value: "./images/products/single/extended/bg-2.jpg",
                      expression:
                        "'./images/products/single/extended/bg-2.jpg'",
                      arg: "background-image"
                    }
                  ],
                  staticClass: "product-desc-row bg-image text-white"
                },
                [
                  _c("div", { staticClass: "container" }, [
                    _vm._m(1),
                    _vm._v(" "),
                    _c("div", { staticClass: "mb-5" }),
                    _vm._v(" "),
                    _c("img", {
                      directives: [
                        {
                          name: "lazy",
                          rawName: "v-lazy",
                          value: "./images/products/single/extended/sign.png",
                          expression:
                            "'./images/products/single/extended/sign.png'"
                        }
                      ],
                      staticClass: "ml-auto mr-auto bg-transparent",
                      attrs: { alt: "sign" }
                    })
                  ])
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "lazy",
                      rawName: "v-lazy:background-image",
                      value: "./images/products/single/extended/bg-3.jpg",
                      expression:
                        "'./images/products/single/extended/bg-3.jpg'",
                      arg: "background-image"
                    }
                  ],
                  staticClass: "product-desc-row bg-image"
                },
                [_vm._m(2)]
              )
            ])
          ]
        ),
        _vm._v(" "),
        _vm._m(3),
        _vm._v(" "),
        _vm._m(4),
        _vm._v(" "),
        _vm._m(5)
      ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "row justify-content-end" }, [
        _c("div", { staticClass: "col-sm-6 col-lg-4" }, [
          _c("h2", [_vm._v("Product Information")]),
          _vm._v(" "),
          _c("ul", [
            _c("li", [_vm._v("Faux suede fabric upper")]),
            _vm._v(" "),
            _c("li", [_vm._v("Tie strap buckle detail")]),
            _vm._v(" "),
            _c("li", [_vm._v("Block heel")]),
            _vm._v(" "),
            _c("li", [_vm._v("Open toe")]),
            _vm._v(" "),
            _c("li", [_vm._v("Heel Height: 7cm / 2.5 inches")])
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-md-6" }, [
        _c("h2", [_vm._v("Design")]),
        _vm._v(" "),
        _c("p", { staticClass: "mb-2 mb-md-0" }, [
          _vm._v(
            "The perfect choice for the summer months. These wedges are perfect for holidays and home, with the thick cross-over strap design and heel strap with an adjustable buckle fastening. Featuring chunky soles with an espadrille and cork-style wedge."
          )
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-md-6" }, [
        _c("h2", [_vm._v("Fabric & care")]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "As part of our Forever Comfort collection, these wedges have ultimate cushioning with soft padding and flexi soles. Perfect for strolls into the old town on holiday or a casual wander into the village."
          )
        ])
      ])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-lg-5" }, [
          _c("blockquote", [
            _c("p", [
              _vm._v(
                "\n                                        “ Everything is important -\n                                        "
              ),
              _c("br"),
              _vm._v(
                "that success is in the details. ”\n                                    "
              )
            ]),
            _vm._v(" "),
            _c("cite", [_vm._v("– Steve Jobs")])
          ]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "Nullam mollis. Ut justo. Suspendisse potenti. Sed egestas, ante et vulputate volutpat, eros pede semper est, vitae luctus metus libero eu augue."
            )
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "tab-pane fade", attrs: { id: "tab2" } }, [
      _c("div", { staticClass: "product-desc-content" }, [
        _c("div", { staticClass: "container" }, [
          _c("h3", [_vm._v("Information")]),
          _vm._v(" "),
          _c("p", { staticClass: "mb-2" }, [
            _vm._v(
              "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio. Quisque volutpat mattis eros. Nullam malesuada erat ut turpis. Suspendisse urna viverra non, semper suscipit, posuere a, pede. Donec nec justo eget felis facilisis fermentum. Aliquam porttitor mauris sit amet orci."
            )
          ]),
          _vm._v(" "),
          _c("h3", [_vm._v("Fabric & care")]),
          _vm._v(" "),
          _c("ul", [
            _c("li", [_vm._v("Faux suede fabric")]),
            _vm._v(" "),
            _c("li", [_vm._v("Gold tone metal hoop handles.")]),
            _vm._v(" "),
            _c("li", [_vm._v("RI branding")]),
            _vm._v(" "),
            _c("li", [_vm._v("Snake print trim interior")]),
            _vm._v(" "),
            _c("li", [_vm._v("Adjustable cross body strap")]),
            _vm._v(" "),
            _c("li", [
              _vm._v(
                "Height: 31cm; Width: 32cm; Depth: 12cm; Handle Drop: 61cm"
              )
            ])
          ]),
          _vm._v(" "),
          _c("h3", [_vm._v("Size")]),
          _vm._v(" "),
          _c("p", [_vm._v("one size")])
        ])
      ])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "tab-pane fade", attrs: { id: "tab3" } }, [
      _c("div", { staticClass: "product-desc-content" }, [
        _c("div", { staticClass: "container" }, [
          _c("h3", [_vm._v("Delivery & returns")]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "\n                        We deliver to over 100 countries around the world. For full details of the delivery options we offer, please view our\n                        "
            ),
            _c("a", { attrs: { href: "#" } }, [_vm._v("Delivery information")]),
            _vm._v(" "),
            _c("br"),
            _vm._v(
              "We hope you’ll love every purchase, but if you ever need to return an item you can do so within a month of receipt. For full details of how to make a return, please view our\n                        "
            ),
            _c("a", { attrs: { href: "#" } }, [_vm._v("Returns information")])
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "tab-pane fade", attrs: { id: "tab4" } }, [
      _c("div", { staticClass: "reviews" }, [
        _c("div", { staticClass: "container" }, [
          _c("h3", [_vm._v("Reviews (2)")]),
          _vm._v(" "),
          _c("div", { staticClass: "review" }, [
            _c("div", { staticClass: "row no-gutters" }, [
              _c("div", { staticClass: "col-auto" }, [
                _c("h4", [
                  _c("a", { attrs: { href: "#" } }, [_vm._v("Samanta J.")])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "ratings-container" }, [
                  _c("div", { staticClass: "ratings" }, [
                    _c("div", {
                      staticClass: "ratings-val",
                      staticStyle: { width: "80%" }
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "tooltip-text" }, [_vm._v("4")])
                  ])
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "review-date" }, [
                  _vm._v("6 days ago")
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col" }, [
                _c("h4", [_vm._v("Good, perfect size")]),
                _vm._v(" "),
                _c("div", { staticClass: "review-content" }, [
                  _c("p", [
                    _vm._v(
                      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ducimus cum dolores assumenda asperiores facilis porro reprehenderit animi culpa atque blanditiis commodi perspiciatis doloremque, possimus, explicabo, autem fugit beatae quae voluptas!"
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "review-action" }, [
                  _c("a", { attrs: { href: "#" } }, [
                    _c("i", { staticClass: "icon-thumbs-up" }),
                    _vm._v("Helpful (2)\n                                    ")
                  ]),
                  _vm._v(" "),
                  _c("a", { attrs: { href: "#" } }, [
                    _c("i", { staticClass: "icon-thumbs-down" }),
                    _vm._v(
                      "Unhelpful (0)\n                                    "
                    )
                  ])
                ])
              ])
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "review border-0" }, [
            _c("div", { staticClass: "row no-gutters" }, [
              _c("div", { staticClass: "col-auto" }, [
                _c("h4", [
                  _c("a", { attrs: { href: "#" } }, [_vm._v("John Doe")])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "ratings-container" }, [
                  _c("div", { staticClass: "ratings" }, [
                    _c("div", {
                      staticClass: "ratings-val",
                      staticStyle: { width: "100%" }
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "tooltip-text" }, [_vm._v("5")])
                  ])
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "review-date" }, [
                  _vm._v("5 days ago")
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col" }, [
                _c("h4", [_vm._v("Very good")]),
                _vm._v(" "),
                _c("div", { staticClass: "review-content" }, [
                  _c("p", [
                    _vm._v(
                      "Sed, molestias, tempore? Ex dolor esse iure hic veniam laborum blanditiis laudantium iste amet. Cum non voluptate eos enim, ab cumque nam, modi, quas iure illum repellendus, blanditiis perspiciatis beatae!"
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "review-action" }, [
                  _c("a", { attrs: { href: "#" } }, [
                    _c("i", { staticClass: "icon-thumbs-up" }),
                    _vm._v("Helpful (0)\n                                    ")
                  ]),
                  _vm._v(" "),
                  _c("a", { attrs: { href: "#" } }, [
                    _c("i", { staticClass: "icon-thumbs-down" }),
                    _vm._v(
                      "Unhelpful (0)\n                                    "
                    )
                  ])
                ])
              ])
            ])
          ])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }