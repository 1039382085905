<template>
    <div
        class="
            header-search header-search-extended
            mr-0
            header-search-visible
            d-none d-lg-block
        "
    >
        <a class="search-toggle" role="button">
            <i class="icon-search"></i>
        </a>
        <form
            action="#"
            method="get"
            @click.stop="showSearchForm"
            @submit.prevent="submitSearchForm"
        >
            <div class="header-search-wrapper search-wrapper-wide">
                <label for="q" class="sr-only">
                    {{$t('nav.search')}}
                </label>

                <button class="btn" type="submit">
                    <i class="icon-search"></i>
                </button>
                <input
                    type="text"
                    class="form-control"
                    :placeholder="$t('nav.searchField')"
                    required
                    v-model="searchTerm"
                    @input="searchProducts"
                />
            </div>

            <div class="live-search-list">
                <div
                    class="autocomplete-suggestions"
                    v-if="suggestions.length > 0"
                    @click="goProductPage"
                >
                    <nuxt-link
                        :to="localePath(`/product/default/` + product.slug)"
                        class="autocomplete-suggestion"
                        data-index="0"
                        v-for="product in suggestions"
                        :key="product.id"
                    >
                        <img
                            :src="product.image"
                            alt="
								Product
							"
                            width="40"
                            height="40"
                            class="product-image"
                        />
                        <!-- <img
                            :src="`${baseUrl}${product.sm_pictures[0].url}`"
                            alt="
								Product
							"
                            width="40"
                            height="40"
                            class="product-image"
                        /> -->

                        <div
                            class="search-name"
                            v-html="matchEmphasize(product.name)"
                        ></div>
                        <span class="search-price">
                            <div
                                class="product-price mb-0"
                                v-if="product.minPrice == product.maxPrice"
                            >
                                ${{ product.minPrice.toFixed(2) }}
                            </div>
                            <template v-else>
                                <div
                                    class="product-price mb-0"
                                    v-if="product.variants.length == 0"
                                >
                                    <span class="new-price"
                                        >${{
                                            product.minPrice.toFixed(2)
                                        }}</span
                                    >
                                    <span class="old-price"
                                        >${{
                                            product.maxPrice.toFixed(2)
                                        }}</span
                                    >
                                </div>
                                <div class="product-price mb-0" v-else>
                                    ${{ product.minPrice.toFixed(2) }} - ${{
                                        product.maxPrice.toFixed(2)
                                    }}
                                </div>
                            </template>
                        </span>
                    </nuxt-link>
                </div>
            </div>
        </form>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import Repository, { baseUrl } from '~/repositories/repository.js';
import axios from 'axios';
export default {
    data: function () {
        return {
            searchTerm: '',
            suggestions: [],
            timeouts: [],
            baseUrl: baseUrl,
            search: '',
            // filterData: productData
        };
    },
    computed: {
        ...mapGetters('demo', ['currentDemo']),
    },
    mounted: function () {
        document
            .querySelector('body')
            .addEventListener('click', this.closeSearchForm);
    },
    methods: {
        searchProducts: function () {
            if (this.searchTerm.length > 2) {
                var searchTerm = this.searchTerm;
                this.timeouts.map((timeout) => {
                    window.clearTimeout(timeout);
                });
                let token = sessionStorage.getItem('token');
                let data = {
                    // api_token: 'Onetjv7sOxt6EDhov6pB',
                    api_token: token,
                    word: searchTerm,
                };
                this.timeouts.push(
                    setTimeout(() => {
                        // Repository.post(`${baseUrl}/product/search`, {
                        //     params: {
                        //         // searchTerm: searchTerm,
                        //         // demo: this.currentDemo,
                        //         api_ket: token,
                        //         word: searchTerm,
                        //     },
                        //     data: data,
                        // })
                        //     .then((response) => {
                        //         console.log(data);
                        //         console.log('response', response);

                        //         this.suggestions = response.data.reduce(
                        //             (acc, cur) => {
                        //                 let max = 0;
                        //                 let min = 99999;
                        //                 cur.variants.map((item) => {
                        //                     if (min > item.price)
                        //                         min = item.price;
                        //                     if (max < item.price)
                        //                         max = item.price;
                        //                 }, []);

                        //                 if (cur.variants.length == 0) {
                        //                     min = cur.price_after_discount
                        //                         ? cur.price_after_discount
                        //                         : cur.price;
                        //                     max = cur.price;
                        //                 }
                        //                 return [
                        //                     ...acc,
                        //                     {
                        //                         ...cur,
                        //                         minPrice: min,
                        //                         maxPrice: max,
                        //                     },
                        //                 ];
                        //             },
                        //             []
                        //         );
                        //     })
                        //     .catch((error) => {});

                        var config = {
                            method: 'post',
                            url: `${baseUrl}/product/search`,
                            headers: {
                                //  language:this.$i18n.locale =='en' ? 'LANGUAGE2': 'LANGUAGE1'
                            },
                            data: data,
                        };

                        axios(config)
                            .then((resp) => {
                                console.log(resp);
                                console.log('my data', data);
                             
                            })
                            .catch((error) => {
                                console.log(error);
                            });
                    }, 500)
                );
            } else {
                this.timeouts.map((timeout) => {
                    window.clearTimeout(timeout);
                });
                this.suggestions = [];
            }
        },
        matchEmphasize: function (name) {
            var regExp = new RegExp(this.searchTerm, 'i');
            return name.replace(
                regExp,
                (match) => '<strong>' + match + '</strong>'
            );
        },
        goProductPage: function () {
            this.searchTerm = '';
            this.suggestions = [];
        },
        searchToggle: function (e) {
            document.querySelector('.header-search').classList.toggle('show');
            e.stopPropagation();
        },

        showSearchForm: function () {
            document
                .querySelector('.header .header-search')
                .classList.add('show');
        },
        closeSearchForm: function (e) {
            document
                .querySelector('.header .header-search')
                .classList.remove('show');
        },
        submitSearchForm: function (e) {
            this.closeSearchForm();
            this.$router.push({
                // path: '/shop/sidebar/list',
                path:
                    this.$i18n.locale == 'ar'
                        ? `/shop/productSearch`
                        : `/${this.$i18n.locale}/shop/productSearch`,
                query: {
                    searchTerm: this.searchTerm.replace(/\s/g, '-'),
                },
            });
            this.searchTerm = '';
            // this.$router.push({
            // 	path: '/shop/sidebar/3cols',
            // 	query: {
            // 		searchTerm: this.searchTerm
            // 	}
            // });
        },
        //  productSelected: function(item) {
        //     return (
        //         this.$route.query.category &&
        //         this.$route.query.category == item.slug
        //     );
        // },
    },
};
</script>