var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "swiper-carousel swiper-simple", attrs: { id: _vm.id } },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "swiper",
              rawName: "v-swiper:swiper",
              value: _vm.carouselSetting,
              expression: "carouselSetting",
              arg: "swiper"
            }
          ]
        },
        [_c("div", { staticClass: "swiper-wrapper" }, [_vm._t("default")], 2)]
      ),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "swiper-pagination" })
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "swiper-nav" }, [
      _c("div", { staticClass: "swiper-prev" }, [
        _c("i", { staticClass: "icon-angle-left" })
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "swiper-next" }, [
        _c("i", { staticClass: "icon-angle-right" })
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }