import { render, staticRenderFns } from "./home.vue?vue&type=template&id=689630e4&scoped=true&"
import script from "./home.vue?vue&type=script&lang=js&"
export * from "./home.vue?vue&type=script&lang=js&"
import style0 from "./home.vue?vue&type=style&index=0&id=689630e4&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "689630e4",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/html/royalbeeseg.com/Demo-4/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('689630e4')) {
      api.createRecord('689630e4', component.options)
    } else {
      api.reload('689630e4', component.options)
    }
    module.hot.accept("./home.vue?vue&type=template&id=689630e4&scoped=true&", function () {
      api.rerender('689630e4', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "components/alf/home.vue"
export default component.exports