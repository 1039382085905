// Cart
export const ADD_TO_CART = 'ADD_TO_CART';
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART';
export const CLEAR_CART = 'CLEAR_CART';
export const UPDATE_CART = 'UPDATE_CART';
export const SILINT_UPDATE = 'SILINT_UPDATE';
import Repository, { baseUrl } from '~/repositories/repository.js';

import axios from 'axios';
export const state = () => ({
    data: []
});

export const getters = {
    cartList: state => {
        return state.data;
    },
    priceTotal: state => {
        return state.data.reduce((acc, cur) => {
            return acc + cur.sum;
        }, 0);
    },
    qtyTotal: state => {
        return state.data.reduce((acc, cur) => {
            return acc + parseInt(cur.qty, 10);
        }, 0);
    },
    isInCart: state => product => {
        return state.data.find(item => item.id == product.id) ? true : false;
    },
    canAddToCart: state => (product, qty = 1) => {
        var find = state.data.find(item => item.id == product.id);
        if (find) {
            if (product.stock == 0 || product.stock < find.qty + qty)
                return false;
            else return true;
        } else {
            if (product.stock == 0 || product.stock < qty) return false;
            else return true;
        }
    }
};

export const actions = {
    addToCart: async function({ commit, getters }, payload) {
        if (!getters.canAddToCart(payload.product, payload.qty)) {
            this._vm.$vToastify.removeToast();
            this._vm.$vToastify.setSettings({
                withBackdrop: false,
                position: 'top-right',
                errorDuration: 2000
            });
            this._vm.$vToastify.error(
                "Sorry, you can't add that amount to the cart."
            );
            return;
        }

        let token = sessionStorage.getItem('token');
        await Repository.post(`${baseUrl}/order/post`, {
            api_token: token,
            product_id: payload.product.id
                ? payload.product.id
                : payload.product.product_id,
            quantity: payload.qty ? payload.qty : 1
            // quantity: payload.qty
            // quantity:payload.product.quantity
        })
            .then(resp => {
                console.log(resp);
                this._vm.$vToastify.setSettings({
                    withBackdrop: false,
                    position: 'top-right',
                    successDuration: 1500
                });
                if (resp.data.code == 200) {
                    commit(ADD_TO_CART, payload);
                    this._vm.$vToastify.success(resp.data.message);
                    setTimeout(() => {
                        this.$router.go(this.$router.currentRoute);
                    }, 1600);
                } else if (resp.data.code != 200) {
                    this._vm.$vToastify.error(resp.data.message);
                }
            })
            .catch(err => {
                console.log(err);
            });
    },
    removeFromCart: function({ commit }, payload) {
        let token = sessionStorage.getItem('token');
        axios
            .post(`${baseUrl}/order/remove`, {
                api_token: token,
                product_id: payload.product.product_id
            })
            .then(resp => {
                console.log(resp);
                this._vm.$vToastify.setSettings({
                    withBackdrop: false,
                    position: 'top-right',
                    successDuration: 1500
                });
                if (resp.data.code == 200) {
                    commit(REMOVE_FROM_CART, payload);
                    this._vm.$vToastify.success(resp.data.message);
                    setTimeout(() => {
                        this.$router.go(this.$router.currentRoute);
                    }, 1600);
                } else if (resp.data.code !== 200) {
                    this._vm.$vToastify.error(resp.data.message);
                }
            })
            .catch(err => {
                console.log(err);
                this._vm.$vToastify.setSettings({
                    withBackdrop: false,
                    position: 'top-right',
                    successDuration: 1500
                });
                this._vm.$vToastify.fail(err.data.message);
            });
    },
    // reduceCart: function({ commit }, payload) {
    //     console.log('payload', payload);
    //     let token = sessionStorage.getItem('token');
    //     axios
    //         .post(`${baseUrl}/order/post`, {
    //             api_token: token,
    //             product_id: payload.product.product_id,
    //             quantity: 1
    //         })
    //         .then(resp => {
    //             console.log(resp);
    //             commit(REDUCE_CART, payload);
    //             // this.wishlists = resp.data.products;
    //             this._vm.$vToastify.setSettings({
    //                 withBackdrop: false,
    //                 position: 'top-right',
    //                 successDuration: 1500
    //             });
    //             this._vm.$vToastify.success(resp.data.message);
    //         })
    //         .catch(err => {
    //             console.log(err);
    //             this._vm.$vToastify.setSettings({
    //                 withBackdrop: false,
    //                 position: 'top-right',
    //                 successDuration: 1500
    //             });
    //             this._vm.$vToastify.fail(err.data.message);
    //         });
    // },
    updateCart: function({ commit }, payload) {
        commit(UPDATE_CART, payload);
        this._vm.$vToastify.setSettings({
            withBackdrop: false,
            position: 'top-right',
            successDuration: 1500
        });
        this._vm.$vToastify.success('Cart successfully updated');
    },
    silentUpdate: function({ commit }, payload) {
        commit(SILINT_UPDATE, payload);
        this._vm.$vToastify.setSettings({
            withBackdrop: false,
            position: 'top-right',
            successDuration: 1500
        });
        this._vm.$vToastify.success('Cart successfully updated');
    }
};

export const mutations = {
    [ADD_TO_CART](state, payload) {
        var findIndex = state.data.findIndex(
            item => item.id == payload.product.id
        );
        let qty = payload.product.quantity ? payload.qty : 1;
        if (findIndex !== -1 && payload.product.length > 0) {
            findIndex = state.data.findIndex(
                item => item.name == payload.product.name
            );
        }
        // if ( findIndex !== -1 && payload.product.variants.length > 0 ) {
        //     findIndex = state.data.findIndex( item => item.name == payload.product.name );
        // }

        if (findIndex !== -1) {
            state.data = state.data.reduce((acc, product, index) => {
                if (findIndex == index) {
                    acc.push({
                        ...product,
                        qty: payload.product.quantity + qty,
                        sum:
                            (payload.product.discount
                                ? payload.product.discount
                                : payload.product.price) *
                            (payload.product.quantity + qty)
                    });
                } else {
                    acc.push(product);
                }

                return acc;
            }, []);
        } else {
            state.data = [
                ...state.data,
                {
                    ...payload.product,
                    qty: payload.product.quantity,
                    price: payload.product.discount
                        ? payload.product.discount
                        : payload.product.price,
                    sum:
                        qty *
                        (payload.product.discount
                            ? payload.product.discount
                            : payload.product.price)
                }
            ];
        }
    },
    // [REDUCE_CART](state, payload) {
    //     var findIndex = state.data.findIndex(
    //         item => item.id == payload.product.id
    //     );
    //     let qty = payload.product.quantity ? payload.qty : 1;
    //     if (findIndex !== -1 && payload.product.length > 0) {
    //         findIndex = state.data.findIndex(
    //             item => item.name == payload.product.name
    //         );
    //     }
    //     // if ( findIndex !== -1 && payload.product.variants.length > 0 ) {
    //     //     findIndex = state.data.findIndex( item => item.name == payload.product.name );
    //     // }

    //     if (findIndex !== -1) {
    //         state.data = state.data.reduce((acc, product, index) => {
    //             if (findIndex == index) {
    //                 acc.pop({
    //                     ...product,
    //                     qty: payload.product.quantity + qty,
    //                     sum:
    //                         (payload.product.discount
    //                             ? payload.product.discount
    //                             : payload.product.price) *
    //                         (payload.product.quantity + qty)
    //                 });
    //             } else {
    //                 acc.pop(product);
    //             }

    //             return acc;
    //         }, []);
    //     } else {
    //         state.data = [
    //             ...state.data,
    //             {
    //                 ...payload.product,
    //                 qty: payload.product.quantity,
    //                 price: payload.product.discount
    //                     ? payload.product.discount
    //                     : payload.product.price,
    //                 sum:
    //                     qty *
    //                     (payload.product.discount
    //                         ? payload.product.discount
    //                         : payload.product.price)
    //             }
    //         ];
    //     }
    // },

    [REMOVE_FROM_CART](state, payload) {
        state.data = state.data.filter(item => {
            if (item.id !== payload.product.id) return true;
            if (item.name !== payload.product.name) return true;
            return false;
        });
    },

    [UPDATE_CART](state, payload) {
        state.data = payload.cartItems.reduce((acc, cur) => {
            return [
                ...acc,
                {
                    ...cur,
                    sum:
                        (cur.discount ? cur.discount : cur.price) * cur.quantity
                }
            ];
        }, []);
    },
    [SILINT_UPDATE]() {
        this.$router.go(this.$router.currentRoute);
    },
    [CLEAR_CART](state) {
        state.data = [];
    }
};
