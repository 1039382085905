var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isLoggedIn
    ? _c(
        "div",
        { staticClass: "wishlist" },
        [
          _c(
            "nuxt-link",
            {
              attrs: {
                to: _vm.localePath("/pages/account/profile"),
                title: _vm.$t("nav.profile")
              }
            },
            [
              _c("div", { staticClass: "icon" }, [
                _c("img", {
                  directives: [
                    {
                      name: "lazy",
                      rawName: "v-lazy",
                      value: "./images/Alf/profile.png",
                      expression: "'./images/Alf/profile.png'"
                    }
                  ],
                  staticClass: "bg-transparent",
                  attrs: { alt: "Molla Logo", width: "100%", height: "100%" }
                })
              ])
            ]
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }