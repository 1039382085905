var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      !_vm.loading
        ? [
            _c(
              "div",
              { staticClass: "col-9 m-auto skeleton-body skel-shop-products" },
              [
                _vm._l(1, function(item) {
                  return _c("div", {
                    key: item,
                    staticClass: "skel-pro skel-pro-list"
                  })
                })
              ],
              2
            )
          ]
        : _c("div", {}, [
            _vm.thirdListPhotos.length > 0
              ? _c("section", { staticClass: "create-gift" }, [
                  _c("div", { staticClass: "container-fluid" }, [
                    _c(
                      "div",
                      { staticClass: "row" },
                      _vm._l(_vm.thirdListPhotos, function(holder) {
                        return _c(
                          "div",
                          { key: holder.id, staticClass: "col-md-6 col-sm-12" },
                          [
                            _c("div", { staticClass: "holder" }, [
                              _c("img", {
                                directives: [
                                  {
                                    name: "lazy",
                                    rawName: "v-lazy",
                                    value: holder.first_image,
                                    expression: "holder.first_image"
                                  }
                                ],
                                staticClass: "img-fluid bg-transparent",
                                attrs: { alt: "image" }
                              }),
                              _vm._v(" "),
                              _c("p", { staticClass: "text-white" }, [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(holder.title) +
                                    "\n                            "
                                )
                              ])
                            ])
                          ]
                        )
                      }),
                      0
                    )
                  ])
                ])
              : _vm._e()
          ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }