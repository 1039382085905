var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "quickView-container" }, [
    _c("div", { staticClass: "product-list-action" }, [
      _c("div", { staticClass: "product-action2 d-flex" }, [
        _c("div", [
          _c(
            "div",
            {
              staticClass: "card shadow rounded",
              staticStyle: { border: "none" }
            },
            [
              _c("img", {
                directives: [
                  {
                    name: "lazy",
                    rawName: "v-lazy",
                    value: _vm.category.main_image,
                    expression: "category.main_image"
                  }
                ],
                staticClass: "card-img-top p-3",
                attrs: { alt: "Card image cap" }
              }),
              _vm._v(" "),
              _c("div", { staticClass: "card-body p-3" }, [
                _c("h5", { staticClass: "card-title" }, [
                  _vm._v(
                    "\n                            " +
                      _vm._s(_vm.category.subcategory.name) +
                      "\n                        "
                  )
                ]),
                _vm._v(" "),
                _c("br"),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "button",
                    attrs: {
                      "data-toggle": "modal",
                      "data-target": "#addPlanModal"
                    },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.quickView({ category: _vm.category })
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n                            عرض المزيد\n                        "
                    )
                  ]
                )
              ])
            ]
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }