<template>
    <div class="latestCompany">
        <div class="row">
            <test
                class="col-md-4 col-sm-12 mt-2"
                v-for="category in categories"
                :key="category.id"
                :category="category"
            ></test>
        </div>
    </div>
</template>

<script>
import test from './test.vue';
export default {
    props: ['categories'],
    components: {
        test,
    },
};
</script>   

<style>
</style>