<template>
    <div>
        <h2 class="title text-center mb-4">منتجات تم شرائها</h2>

        <div v-if="products.length == 0">
            <div class="swiper-carousel carousel-with-shadow">
                <div v-swiper:swiper2="carouselSetting">
                    <div class="swiper-wrapper">
                        <div
                            class="swiper-slide"
                            v-for="item in [1, 2, 3, 4]"
                            :key="item"
                        >
                            <div class="skel-pro"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="swiper-carousel carousel-with-shadow swiper-1" v-else>
            <div v-swiper:swiper1="carouselSetting">
                <div class="swiper-wrapper">
                    <div
                        class="swiper-slide"
                        v-for="(product, index) in products"
                        :key="index"
                    >
                        <nuxt-link :to="localePath(`/product/default/${product.id}`)" class="brand">
                            <img
                                v-lazy="product.main_image"
                                class="bg-transparent"
                                alt="Molla Logo"
                                height="27"
                                width="200"
                            />
                            <p class="text-dark h6 mt-2">
                                {{ product.name }}
                            </p>

                            <div class="row" v-if="product.discount_int !== null">
                                <div class="col-md-6 col-sm-12">
                                    <p class="text-danger">
                                        <del>{{ product.price }}</del>
                                    </p>
                                </div>
                                <div
                                    class="col-md-6 col-sm-12"
                                >
                                    <p class="text-primary">
                                        {{ product.productPrice_after_discount }} <span>جنية</span>
                                    </p>
                                </div>
                            </div>
<div class="row" v-else>
                                
                                <div
                                    class="col-md-12 col-sm-12"
                                >
                                    <p class="text-primary">
                                        {{ product.productPrice_after_discount }} <span>جنية</span>
                                    </p>
                                </div>
                            </div>
                                                  
                                                    </nuxt-link>
                    </div>
                </div>
            </div>
            <div class="swiper-nav">
                <div class="swiper-prev">
                    <i class="icon-angle-left"></i>
                </div>
                <div class="swiper-next">
                    <i class="icon-angle-right"></i>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
// import ProductNine from '~/components/elements/products/ProductNine';
import { carouselSetting1 } from '~/utilities/carousel';
import axios from 'axios';

export default {
    components: {
        // ProductNine
    },
    // props: {
    //     products: {
    //         type: Array,
    //         default: function() {
    //             return [];
    //         }
    //     }
    // },
    data: function () {
        return {
            carouselSetting: {
                ...carouselSetting1,
                navigation: {
                    nextEl: '.swiper-1 .swiper-next',
                    prevEl: '.swiper-1 .swiper-prev',
                },
                pagination: {
                    el: '.swiper-1 .swiper-dots',
                    clickable: true,
                },
            },
            products: [],
        };
    },
    methods: {
        cheapestProducts() {
            let bestSelling = `https://admin.royalbeeseg.com/public/api/best/selling/products`;
            // axios.post('https://atwdemo.com/commerce/admin/public/api/cheapest/products&num=10')
            // .then(resp =>{
            //     console.log(resp);
            // })
            // .catch(err =>{
            //     console.log(err);
            // });
            axios.post(bestSelling).then((resp) => {
                // bestSelling ==> top trending
                this.products = resp.data.best_seller_products;
                // console.log('bestSelling', resp);
            });
        },
    },
    created() {
        this.cheapestProducts();
    },
};
</script>