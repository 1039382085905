<template lang="html">
    <div :class="$i18n.locale">
        <div class="page-wrapper">
            
            <header-home></header-home>
            <!-- <logout></logout> -->
            <nuxt></nuxt>
            <footer-default></footer-default>
            <button id="scroll-top" ref="scrollTop" title="Back to Top" @click.prevent="scrollTop">
                <i class="icon-arrow-up"></i>
            </button>
        </div>
        <div class="mobile-menu-overlay" @click="hideMobileMenu"></div>
        <mobile-menu></mobile-menu>
    </div>
</template>

<script>
import HeaderHome from '~/components/partial/headers/headerHome';
// import FooterDefault from '~/components/partial/footers/FooterDefault';
import logout from '~/components/alf/autologout.vue';
import { isSafariBrowser, isEdgeBrowser } from '~/utilities/common';

export default {
    components: {
        HeaderHome,
        logout,
        FooterDefault: () =>
            import('~/components/partial/footers/FooterDefault'),
        MobileMenu: () => import('~/components/partial/home/MobileMenu'),
    },
    mounted: function () {
        let scrollTop = this.$refs.scrollTop;
        document.addEventListener(
            'scroll',
            function () {
                if (window.pageYOffset >= 400) {
                    scrollTop.classList.add('show');
                } else {
                    scrollTop.classList.remove('show');
                }
            },
            false
        );
        if (!this.$store.getters.isLoggedIn) {
            localStorage.removeItem('Alf-localStorage');
            sessionStorage.removeItem('token');
        }
        // window.addEventListener('beforeunload', function () {
        //     this.logout();
        // });

        // this.setLang();
    },
    methods: {
        scrollTop: function () {
            if (isSafariBrowser() || isEdgeBrowser()) {
                let pos = window.pageYOffset;
                let timerId = setInterval(() => {
                    if (pos <= 0) clearInterval(timerId);
                    window.scrollBy(0, -120);
                    pos -= 120;
                }, 1);
            } else {
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth',
                });
            }
        },
        hideMobileMenu: function () {
            document.querySelector('body').classList.remove('mmenu-active');
        },
        // setLang() {
        //     if (this.$i18n.locale == 'en') {
        //         sessionStorage.setItem('language', 'LANGUAGE2');
        //     } else if (this.$i18n.locale == 'ar') {
        //         sessionStorage.setItem('language', 'LANGUAGE1');
        //     }
        // },

        isLoggedIn() {
            return this.$store.getters.isLoggedIn;
        },
        logout() {
            this.$store.dispatch('logout');
        },
    },
    created() {
        let myLang = sessionStorage.getItem('language');

        if (myLang !== null) {
            sessionStorage.setItem(
                'language',
                this.$i18n.locale == 'en' ? 'LANGUAGE2' : 'LANGUAGE1'
            );
        } else {
            sessionStorage.setItem(
                'language',
                this.$i18n.locale == 'en' ? 'LANGUAGE2' : 'LANGUAGE1'
            );
        }
    },

    // destroyed() {
    //     this.logout();
    // },
};
</script>
<style lang="scss" scoped>
.en {
    direction: ltr;
    text-align: left;
}
.ar {
    direction: rtl;
    text-align: right;
}
/* Scroll bar stylings */
::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    // background: var(--lightestgrey);
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}
</style>