<template>
    <div>
        <!-- <div class="svgHolder">
            <div :class="`loader ${spinner}`">
                <div class="loader__bar"></div>
                <div class="loader__bar"></div>
                <div class="loader__bar"></div>
                <div class="loader__bar"></div>
                <div class="loader__bar"></div>
                <div class="loader__ball"></div>
            </div>
        </div> -->
        <Allfirst />
        <Allsecond />
        <Allthird />
        <Allfourth />
        <Allfifth />
        
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import axios from 'axios';
import {
    carouselSettingSingle,
    carouselSettingDefault,
} from '~/utilities/carousel';
import Repository, { baseUrl } from '~/repositories/repository.js';
import Allfirst from '~/components/alf/homeComponents/first/index.vue';
import Allsecond from '~/components/alf/homeComponents/second/index.vue';
import Allthird from '~/components/alf/homeComponents/third/index.vue';
import Allfourth from '~/components/alf/homeComponents/fourth/index.vue';
import Allfifth from '~/components/alf/homeComponents/fifth/index.vue';


export default {
    data: function () {
        return {
            carouselSetting2: {
                ...carouselSettingDefault,
                slidesPerView: 6,
                spaceBetween: 30,
                navigation: {
                    nextEl: '.brands-slider .swiper-next',
                    prevEl: '.brands-slider .swiper-prev',
                },
                breakpoints: {
                    992: {
                        slidesPerView: 5,
                    },
                    768: {
                        slidesPerView: 4,
                    },
                    576: {
                        slidesPerView: 3,
                    },
                    480: {
                        slidesPerView: 2,
                    },
                },
            },
            carouselSetting3: {
                ...carouselSettingDefault,
                slidesPerView: 6,
                spaceBetween: 30,
                navigation: {
                    nextEl: '.brands-slider .swiper-next',
                    prevEl: '.brands-slider .swiper-prev',
                },
                breakpoints: {
                    992: {
                        slidesPerView: 5,
                    },
                    768: {
                        slidesPerView: 4,
                    },
                    576: {
                        slidesPerView: 3,
                    },
                    480: {
                        slidesPerView: 2,
                    },
                },
            },
            carouselSetting4: {
                ...carouselSettingDefault,
                slidesPerView: 4,
                spaceBetween: 30,
                navigation: {
                    nextEl: '.brands-slider .swiper-next',
                    prevEl: '.brands-slider .swiper-prev',
                },
                breakpoints: {
                    992: {
                        slidesPerView: 3,
                    },
                    768: {
                        slidesPerView: 2,
                        navigation: true,
                    },
                    576: {
                        slidesPerView: 1,
                        navigation: true,
                    },
                    480: {
                        slidesPerView: 1,
                        navigation: true,
                    },
                },
            },
            carouselSetting5: {
                ...carouselSettingDefault,
                slidesPerView: 4,
                spaceBetween: 30,
                navigation: {
                    nextEl: '.brands-slider .swiper-next',
                    prevEl: '.brands-slider .swiper-prev',
                },
                breakpoints: {
                    992: {
                        slidesPerView: 3,
                    },
                    768: {
                        slidesPerView: 2,
                        navigation: true,
                    },
                    576: {
                        slidesPerView: 1,
                        navigation: true,
                    },
                    480: {
                        slidesPerView: 1,
                        navigation: true,
                    },
                },
            },
            carouselSetting6: {
                ...carouselSettingDefault,
                slidesPerView: 4,
                spaceBetween: 30,
                navigation: {
                    nextEl: '.brands-slider .swiper-next',
                    prevEl: '.brands-slider .swiper-prev',
                },
                breakpoints: {
                    992: {
                        slidesPerView: 3,
                    },
                    768: {
                        slidesPerView: 2,
                        navigation: true,
                    },
                    576: {
                        slidesPerView: 1,
                        navigation: true,
                    },
                    480: {
                        slidesPerView: 1,
                        navigation: true,
                    },
                },
            },
            carouselSetting7: {
                ...carouselSettingDefault,
                slidesPerView: 4,
                spaceBetween: 30,
                navigation: {
                    nextEl: '.brands-slider .swiper-next',
                    prevEl: '.brands-slider .swiper-prev',
                },
                breakpoints: {
                    992: {
                        slidesPerView: 3,
                    },
                    768: {
                        slidesPerView: 2,
                        navigation: true,
                    },
                    576: {
                        slidesPerView: 1,
                        navigation: true,
                    },
                    480: {
                        slidesPerView: 1,
                        navigation: true,
                    },
                },
            },
            carouselSetting8: {
                ...carouselSettingDefault,
                slidesPerView: 6,
                spaceBetween: 30,
                navigation: {
                    nextEl: '.vendors-slider .swiper-next',
                    prevEl: '.vendors-slider .swiper-prev',
                },
                breakpoints: {
                    992: {
                        slidesPerView: 4,
                    },
                    768: {
                        slidesPerView: 2,
                        navigation: true,
                    },
                    576: {
                        slidesPerView: 1,
                        navigation: true,
                    },
                    480: {
                        slidesPerView: 1,
                        navigation: true,
                    },
                },
            },
            carouselSetting9: {
                ...carouselSettingDefault,
                slidesPerView: 4,
                spaceBetween: 30,
                navigation: {
                    nextEl: '.new-arrival .swiper-next',
                    prevEl: '.new-arrival .swiper-prev',
                },
                breakpoints: {
                    992: {
                        slidesPerView: 2,
                    },
                    768: {
                        slidesPerView: 2,
                        navigation: true,
                    },
                    576: {
                        slidesPerView: 1,
                        navigation: true,
                    },
                    480: {
                        slidesPerView: 1,
                        navigation: true,
                    },
                },
            },
            carouselSetting10: {
                ...carouselSettingSingle,
                pagination: {
                    el: '.intro-slider-container .swiper-dots',
                    clickable: true,
                },
                navigation: {
                    nextEl: '.intro-slider-container .swiper-next',
                    prevEl: '.intro-slider-container .swiper-prev',
                },
            },
            carouselSetting11: {
                ...carouselSettingDefault,
                spaceBetween: 0,
                slidesPerView: 1,
                navigation: {
                    nextEl: '.swiper-11 .swiper-next',
                    prevEl: '.swiper-11 .swiper-prev',
                },
                pagination: {
                    el: '.swiper-11 .swiper-dots',
                    clickable: true,
                },
            },
            baseUrl: baseUrl,
            abouts: [],
            banners: {
                first: [],
                seconnd: [],
                third: [],
                fourth: [],
                fifth: [],
                sixth: [],
                seventh: [],
            },
            productId: '',
            firstListCategories: [],
            secondListCategories: [],
            firstListPhotos: [],
            secondListPhotos: [],
            thirdListPhotos: [],
            hotDicounts: [],
            bestSelling: [],
            brands: [],
            newArrival: [],
            fashionBaby: [],
            posts: [],
            postsImage: '',
            postsTime: '',
            postsView: '',
            postsTitle: '',
            postaDeacrease: [],
            spinner: 'd-block',
        };
    },
    methods: {
        ...mapActions('cart', ['addToCart']),
        ...mapActions('wishlist', ['addToWishlist']),
        ...mapActions('compare', ['addToCompare']),
        getData() {
            let sliders = `${this.baseUrl}/sliders`;
            let banners = `${this.baseUrl}/banners`;
            let listCategory = `${this.baseUrl}/list_categories`;
            let listPhotos = `${this.baseUrl}/list_photos`;
            let hotDicounts = `${this.baseUrl}/hot/discount/offers`;
            let bestSelling = `${this.baseUrl}/best/selling/products`;
            let brands = `${this.baseUrl}/vendors_logo`;
            let newArrival = `${this.baseUrl}/latest/products`;
            let popularPosts = `${this.baseUrl}/blog/all`;
            //sliders
            axios
                .get(sliders)
                .then((resp) => {
                    // console.log('abouts', resp);
                    this.abouts = resp.data.about_sliders;
                    this.fashionBaby = resp.data.photos_sliders;
                    // console.log(' this.fashionBaby', this.fashionBaby);
                })
                // .catch((err) => {
                //     console.log(err);
                // });

            // banners
            axios.get(banners).then((resp) => {
                //banners
                this.banners = resp.data.banners;
                // console.log('banners', this.banners);
            });

            //listCategory
            axios.get(listCategory).then((resp) => {
                this.firstListCategories = resp.data.list_products.first;
                this.secondListCategories = resp.data.list_products.second;
                // console.log('listCategories', resp);
                // console.log(this.firstListCategories.length);
            });

            // list photos
            // list photos

            axios.get(listPhotos).then((resp) => {
                this.firstListPhotos = resp.data.list_photos_type1;
                this.secondListPhotos = resp.data.list_photos_type2;
                this.thirdListPhotos = resp.data.list_photos_type3;
                // console.log('listPhotos', resp);
            });

            // hot discounts
            axios.post(hotDicounts).then((resp) => {
                this.hotDicounts = resp.data.offered_products;
                // console.log('hotDicounts', resp);
            });
            // best selling
            axios.post(bestSelling).then((resp) => {
                // bestSelling ==> top trending
                this.bestSelling = resp.data.best_seller_products;
                // console.log('bestSelling', resp);
            });
            // brands
            axios.get(brands).then((resp) => {
                this.brands = resp.data.brands;
                // console.log('brands', resp);
            });
            // new arrival
            axios.post(newArrival).then((resp) => {
                this.newArrival = resp.data.latest_products;
                // console.log('newArrival', resp);
            });
            //blog
            axios.post(popularPosts).then((resp) => {
                // console.log('blog resp', resp);
                this.posts = resp.data.articles.data;
                this.postsImage = this.posts[0].image;
                this.postsTime = this.posts[0].time;
                this.postsView = this.posts[0].views;
                this.postsTitle = this.posts[0].title;
                this.postaDeacrease = this.posts.slice(1, 4);
                // console.log('postaDeacrease', this.postaDeacrease);
                // console.log('this.postaDeacrease',this.postaDeacrease)
            });
        },
    },
    created() {
        this.getData();
    },
    computed: {
        ...mapGetters('cart', ['canAddToCart']),
        ...mapGetters('wishlist', ['isInWishlist']),
        ...mapGetters('compare', ['isInCompare']),
    },
    props: ['product'],
    mounted() {
        setTimeout(() => {
            let svg = document.querySelector('.svgHolder');
            let body = document.querySelector('body');
            if (body.contains(svg)) {
                document.querySelector('.svgHolder').style.transform =
                    'translateX(100%)';
                setTimeout(() => {
                    this.spinner = 'd-none';
                }, 4500);
            }
        }, 3500);
    },
    components: {
        Allfirst,
        Allsecond,
        Allthird,
        Allfourth,
        Allfifth

    },
};
</script>

<style lang="scss" scoped>
.last {
    .swiper-pagination-bullet.swiper-pagination-bullet-active {
        background: #fff !important;
        border-color: #fff !important;
    }
}
// Variables
$bar-color: #fff;
$ball-color: #fff;
$bg-color: #77c1e6;
.svgHolder {
    position: fixed;
    inset: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999999;
    background: $bg-color;
    transition: all 1s ease-in-out;
}

.loader {
    position: relative;
    width: 75px;
    height: 100px;

    &__bar {
        position: absolute;
        bottom: 0;
        width: 10px;
        height: 50%;
        background: $bar-color;
        transform-origin: center bottom;
        box-shadow: 1px 1px 0 rgba(0, 0, 0, 0.2);

        @for $i from 1 through 5 {
            &:nth-child(#{$i}) {
                left: ($i - 1) * 15px;
                transform: scale(1, $i * 0.2);
                animation: barUp#{$i} 4s infinite;
            }
        }
    }

    &__ball {
        position: absolute;
        bottom: 10px;
        left: 0;
        width: 10px;
        height: 10px;
        background: $ball-color;
        border-radius: 50%;
        animation: ball 4s infinite;
    }
}

@keyframes ball {
    0% {
        transform: translate(0, 0);
    }
    5% {
        transform: translate(8px, -14px);
    }
    10% {
        transform: translate(15px, -10px);
    }
    17% {
        transform: translate(23px, -24px);
    }
    20% {
        transform: translate(30px, -20px);
    }
    27% {
        transform: translate(38px, -34px);
    }
    30% {
        transform: translate(45px, -30px);
    }
    37% {
        transform: translate(53px, -44px);
    }
    40% {
        transform: translate(60px, -40px);
    }
    50% {
        transform: translate(60px, 0);
    }
    57% {
        transform: translate(53px, -14px);
    }
    60% {
        transform: translate(45px, -10px);
    }
    67% {
        transform: translate(37px, -24px);
    }
    70% {
        transform: translate(30px, -20px);
    }
    77% {
        transform: translate(22px, -34px);
    }
    80% {
        transform: translate(15px, -30px);
    }
    87% {
        transform: translate(7px, -44px);
    }
    90% {
        transform: translate(0, -40px);
    }
    100% {
        transform: translate(0, 0);
    }
}

@keyframes barUp1 {
    0% {
        transform: scale(1, 0.2);
    }
    40% {
        transform: scale(1, 0.2);
    }
    50% {
        transform: scale(1, 1);
    }
    90% {
        transform: scale(1, 1);
    }
    100% {
        transform: scale(1, 0.2);
    }
}
@keyframes barUp2 {
    0% {
        transform: scale(1, 0.4);
    }
    40% {
        transform: scale(1, 0.4);
    }
    50% {
        transform: scale(1, 0.8);
    }
    90% {
        transform: scale(1, 0.8);
    }
    100% {
        transform: scale(1, 0.4);
    }
}
@keyframes barUp3 {
    0% {
        transform: scale(1, 0.6);
    }
    100% {
        transform: scale(1, 0.6);
    }
}
@keyframes barUp4 {
    0% {
        transform: scale(1, 0.8);
    }
    40% {
        transform: scale(1, 0.8);
    }
    50% {
        transform: scale(1, 0.4);
    }
    90% {
        transform: scale(1, 0.4);
    }
    100% {
        transform: scale(1, 0.8);
    }
}
@keyframes barUp5 {
    0% {
        transform: scale(1, 1);
    }
    40% {
        transform: scale(1, 1);
    }
    50% {
        transform: scale(1, 0.2);
    }
    90% {
        transform: scale(1, 0.2);
    }
    100% {
        transform: scale(1, 1);
    }
}
</style>