var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "product-details-tab" },
    [
      _c("tabs", {
        staticClass: "nav-pills justify-content-start",
        attrs: { data: _vm.tabsData }
      }),
      _vm._v(" "),
      _c("div", { staticClass: "tab-content" }, [
        _c(
          "div",
          { staticClass: "tab-pane fade show active", attrs: { id: "tab1" } },
          [
            _c("div", { staticClass: "product-desc-content" }, [
              _c("h3", [_vm._v("الوصف:")]),
              _vm._v(" "),
              _vm.infoTab.brief
                ? _c("p", { staticClass: "mb-1" }, [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.infoTab.brief) +
                        "\n                "
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "product-desc-info" }, [
                _c("div", { staticClass: "row" }, [
                  _vm._m(0),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-2 info" }, [
                    _vm.price_range
                      ? _c("p", [
                          _vm._v(
                            "\n                                " +
                              _vm._s(_vm.price_range) +
                              "\n                            "
                          )
                        ])
                      : _vm._e()
                  ])
                ]),
                _vm._v(" "),
                _vm.age_stages.length
                  ? _c("div", { staticClass: "row" }, [
                      _vm._m(1),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-2 info" },
                        _vm._l(_vm.age_stages, function(age) {
                          return _c("p", { key: age.id }, [
                            _vm._v(
                              "\n                                " +
                                _vm._s(age.from) +
                                " - " +
                                _vm._s(age.to) +
                                "\n                            "
                            )
                          ])
                        }),
                        0
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  [
                    [
                      _vm.grades.length
                        ? _c("div", { staticClass: "row" }, [
                            _vm._m(2),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "col-2 info" },
                              _vm._l(_vm.grades, function(grade) {
                                return _c("p", { key: grade.id }, [
                                  _vm._v(
                                    "\n                                        " +
                                      _vm._s(grade.name) +
                                      "\n                                    "
                                  )
                                ])
                              }),
                              0
                            )
                          ])
                        : _vm._e()
                    ]
                  ],
                  2
                ),
                _vm._v(" "),
                _vm.curriculums.length
                  ? _c("div", { staticClass: "row" }, [
                      _vm._m(3),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-2 info" },
                        _vm._l(_vm.curriculums, function(curriculum) {
                          return _c("p", { key: curriculum.id }, [
                            _vm._v(
                              "\n                                " +
                                _vm._s(curriculum.name) +
                                "\n                            "
                            )
                          ])
                        }),
                        0
                      )
                    ])
                  : _vm._e()
              ])
            ])
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "tab-pane fade review", attrs: { id: "tab2" } },
          [
            _c("div", { staticClass: "product-desc-content" }, [
              _vm.comments.length
                ? _c(
                    "div",
                    { staticClass: "comment-list" },
                    _vm._l(_vm.comments, function(comment) {
                      return _c(
                        "div",
                        { key: comment.id, staticClass: "col-6" },
                        [
                          _c("div", { staticClass: "user d-flex" }, [
                            _vm._m(4, true),
                            _vm._v(" "),
                            _c("div", { staticClass: "desc" }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex align-center desc__content"
                                },
                                [
                                  _c("h5", [_vm._v(_vm._s(comment.name))]),
                                  _vm._v(" "),
                                  _c("p", { staticClass: "date" })
                                ]
                              ),
                              _vm._v(" "),
                              _c("p", { staticClass: "comment" }, [
                                _vm._v(
                                  "\n                                    " +
                                    _vm._s(comment.comment) +
                                    "\n                                "
                                )
                              ])
                            ])
                          ]),
                          _vm._v(" "),
                          _vm._m(5, true)
                        ]
                      )
                    }),
                    0
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "comment-list left-padding" }, [
                _c(
                  "div",
                  {
                    staticClass: "single-comment justify-content-between d-flex"
                  },
                  [
                    _c("div", { staticClass: "user d-flex comment-replay" }, [
                      _c("div", { staticClass: "desc" }, [
                        _c("div", { staticClass: "media my-5" }, [
                          _vm._m(6),
                          _vm._v(" "),
                          _c("div", { staticClass: "media-body" }, [
                            _c("div", { staticClass: "row" }, [
                              _vm._m(7),
                              _vm._v(" "),
                              _c("div", { staticClass: "col-4" }, [
                                !_vm.commentField
                                  ? _c("span", { staticClass: "mx-5" }, [
                                      _c(
                                        "button",
                                        {
                                          staticClass: "btn",
                                          on: {
                                            click: function($event) {
                                              _vm.commentField = !_vm.commentField
                                            }
                                          }
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "fa fa-reply"
                                          }),
                                          _vm._v(
                                            "\n                                                        reply\n                                                    "
                                          )
                                        ]
                                      )
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.commentField
                                  ? _c("span", { staticClass: "mx-5" }, [
                                      _c(
                                        "button",
                                        {
                                          staticClass: "btn",
                                          on: {
                                            click: function($event) {
                                              _vm.commentField = !_vm.commentField
                                            }
                                          }
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "far fa-times-circle"
                                          }),
                                          _vm._v(
                                            "\n                                                        close\n                                                    "
                                          )
                                        ]
                                      )
                                    ])
                                  : _vm._e()
                              ]),
                              _vm._v(" "),
                              _vm.commentField
                                ? _c(
                                    "form",
                                    { staticClass: "replyArea w-100" },
                                    [
                                      _c("textarea", {
                                        staticClass: "form-control my-5",
                                        attrs: {
                                          required: "",
                                          rows: "5",
                                          placeholder: "Write your reply",
                                          spellcheck: "false",
                                          cols: "10"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _vm._m(8)
                                    ]
                                  )
                                : _vm._e()
                            ])
                          ])
                        ])
                      ])
                    ])
                  ]
                )
              ])
            ]),
            _vm._v(" "),
            _vm._m(9)
          ]
        )
      ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-2" }, [
      _c("p", [_vm._v("متوسط السعر :")])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-2" }, [
      _c("p", [_vm._v("Age Stages :")])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-2" }, [_c("p", [_vm._v("Grade :")])])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-2" }, [
      _c("p", [_vm._v("Curriculum :")])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "thumb" }, [
      _c("i", {
        staticClass: "fa fa-user",
        staticStyle: { "font-size": "30px", width: "30px" }
      })
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "reply-btn" }, [
      _c("a", { staticClass: "btn-reply text-uppercase" }, [_vm._v("reply")])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "pr-5" }, [
      _c("img", {
        staticClass: "rounded-circle",
        attrs: {
          alt: "Bootstrap Media Another Preview",
          src: "https://i.imgur.com/nAcoHRf.jpg",
          width: "70"
        }
      })
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-8" }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-12 d-flex" }, [
          _c("h5", [
            _vm._v(
              "\n                                                            Emilly Blunt\n                                                        "
            )
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "date" }, [
            _vm._v(
              "\n                                                            December 4, 2017\n                                                            at 3:12 pm\n                                                        "
            )
          ])
        ])
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "comment" }, [
        _vm._v(
          "\n                                                    Never say goodbye till\n                                                    the end comes!\n                                                "
        )
      ])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "d-flex justify-content-end" }, [
      _c("input", {
        staticClass: "btn btn-primary py-3 px-5 submit-comment",
        attrs: { type: "submit", value: "Submit" }
      })
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "form" }, [
      _c("form", { attrs: { action: "#" } }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "offset-lg-2 col-lg-8 col-md-8 col-sm-10" },
            [
              _c("textarea", {
                staticClass: "form-control",
                attrs: { rows: "5", placeholder: "Comment", required: "" }
              })
            ]
          )
        ]),
        _vm._v(" "),
        _c("br"),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "form-group",
            staticStyle: { "text-align": "center" }
          },
          [
            _c("input", {
              staticClass: "btn btn-primary py-3 px-5 submit-comment",
              attrs: { type: "submit", value: "Submit" }
            })
          ]
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }