var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.product
    ? _c(
        "div",
        { staticClass: "product-details" },
        [
          _c("h1", { staticClass: "product-title" }, [
            _vm._v(_vm._s(_vm.product.name))
          ]),
          _vm._v(" "),
          _vm.product.stock == 0
            ? _c("div", { key: "outPrice", staticClass: "product-price" }, [
                _c("span", { staticClass: "out-price" }, [
                  _vm._v("$" + _vm._s(_vm.product.price))
                ])
              ])
            : [
                _vm.product.price_after_discount == _vm.product.price
                  ? _c("div", { staticClass: "product-price" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.product.price_after_discount) +
                          "\n        "
                      )
                    ])
                  : [
                      _vm.product.length == 0
                        ? _c("div", { staticClass: "product-price" }, [
                            _c("span", { staticClass: "new-price" }, [
                              _vm._v("$" + _vm._s(_vm.product.price))
                            ]),
                            _vm._v(" "),
                            _c("span", { staticClass: "old-price" }, [
                              _vm._v(_vm._s(_vm.product.price_after_discount))
                            ])
                          ])
                        : _c("div", { staticClass: "product-price" }, [
                            _c("div", { staticClass: "product-price mt-2" }, [
                              _c(
                                "span",
                                { staticClass: "out-price text-danger mr-5" },
                                [
                                  _vm._v(
                                    "\n                        " +
                                      _vm._s(_vm.product.price_after_discount) +
                                      "\n                    "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c("span", { staticClass: "out-price" }, [
                                _c("del", [
                                  _vm._v(" " + _vm._s(_vm.product.price) + " ")
                                ])
                              ])
                            ])
                          ])
                    ]
              ],
          _vm._v(" "),
          _vm.product.description
            ? _c("div", { staticClass: "product-content" }, [
                _c("p", {
                  domProps: { innerHTML: _vm._s(_vm.product.description) }
                })
              ])
            : _c("div", { staticClass: "product-content" }, [
                _c("p", [_vm._v("no description till now")])
              ]),
          _vm._v(" "),
          _vm.product.length > 0
            ? [
                _c(
                  "div",
                  { staticClass: "details-filter-row details-row-size" },
                  [
                    _c("label", [_vm._v("Color:")]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "product-nav product-nav-dots" },
                      _vm._l(_vm.colorArray, function(item, index) {
                        return _c("a", {
                          key: index,
                          class: {
                            active: item.color == _vm.selectedVariant.color,
                            disabled: item.disabled
                          },
                          style: { "background-color": item.color },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.selectColor(item)
                            }
                          }
                        })
                      }),
                      0
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "details-filter-row details-row-size" },
                  [
                    _c("label", { attrs: { for: "size" } }, [_vm._v("Size:")]),
                    _vm._v(" "),
                    _c("div", { staticClass: "select-custom" }, [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.selectedVariant.size,
                              expression: "selectedVariant.size"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: { name: "size", id: "size" },
                          on: {
                            change: [
                              function($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function(o) {
                                    return o.selected
                                  })
                                  .map(function(o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  _vm.selectedVariant,
                                  "size",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              },
                              _vm.selectSize
                            ]
                          }
                        },
                        [
                          _c("option", { attrs: { value: "null" } }, [
                            _vm._v("Select a size")
                          ]),
                          _vm._v(" "),
                          _vm._l(_vm.sizeArray, function(item, index) {
                            return _c(
                              "option",
                              { key: index, domProps: { value: item.size } },
                              [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(item.size) +
                                    "\n                    "
                                )
                              ]
                            )
                          })
                        ],
                        2
                      )
                    ]),
                    _vm._v(" "),
                    _vm._m(0),
                    _vm._v(" "),
                    _vm.showClear
                      ? _c(
                          "a",
                          {
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.clearSelection.apply(null, arguments)
                              }
                            }
                          },
                          [_vm._v("clear")]
                        )
                      : _vm._e()
                  ]
                ),
                _vm._v(" "),
                _c(
                  "vue-slide-toggle",
                  { attrs: { open: _vm.showVariationPrice } },
                  [
                    _c("div", { staticClass: "product-price" }, [
                      _vm._v(
                        "\n                $" +
                          _vm._s(
                            _vm.selectedVariant.price
                              ? _vm.selectedVariant.price.toFixed(2)
                              : 0
                          ) +
                          "\n            "
                      )
                    ])
                  ]
                )
              ]
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "details-filter-row details-row-size" },
            [
              _c("label", { attrs: { for: "qty" } }, [_vm._v("Qty:")]),
              _vm._v(" "),
              _c("quantity-input", {
                attrs: { product: _vm.product },
                on: { "change-qty": _vm.changeQty }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "product-details-action" }, [
            _c(
              "a",
              {
                staticClass: "btn-product btn-cart",
                class: {
                  "btn-disabled":
                    !_vm.canAddToCart(_vm.product, _vm.qty) ||
                    (_vm.product.length > 0 && !_vm.showVariationPrice)
                },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.addCart(0)
                  }
                }
              },
              [_c("span", [_vm._v(_vm._s(_vm.$t("product.addToCart")))])]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "details-action-wrapper" },
              [
                !_vm.isInWishlist(_vm.product)
                  ? _c(
                      "a",
                      {
                        key: "notInWishlist",
                        staticClass: "btn-product btn-wishlist",
                        attrs: { title: "Wishlist" },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.addToWishlist({ product: _vm.product })
                          }
                        }
                      },
                      [
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("product.addToWishlist")))
                        ])
                      ]
                    )
                  : _c(
                      "nuxt-link",
                      {
                        key: "inWishlist",
                        staticClass:
                          "btn-product btn-wishlist added-to-wishlist",
                        attrs: {
                          to: _vm.localePath("/shop/wishlist"),
                          title: "Wishlist"
                        }
                      },
                      [_c("span", [_vm._v("Go to Wishlist")])]
                    )
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "product-details-footer" }, [
            _c(
              "div",
              { staticClass: "product-cat w-100 text-truncate" },
              [
                _c("span", [_vm._v("Category:")]),
                _vm._v(" "),
                _vm._l(_vm.product.category, function(cat, index) {
                  return _c(
                    "span",
                    { key: index },
                    [
                      _c(
                        "nuxt-link",
                        {
                          attrs: {
                            to: _vm.localePath({
                              path: "/shop/sidebar/list",
                              query: { category: cat.slug }
                            })
                          }
                        },
                        [_vm._v(_vm._s(cat.name))]
                      ),
                      _vm._v(
                        "\n                " +
                          _vm._s(
                            index < _vm.product.category.length - 1 ? "," : ""
                          ) +
                          "\n            "
                      )
                    ],
                    1
                  )
                })
              ],
              2
            ),
            _vm._v(" "),
            _vm._m(1)
          ]),
          _vm._v(" "),
          _vm.isCartSticy
            ? _c("div", { staticClass: "sticky-bar" }, [
                _c("div", { staticClass: "container" }, [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-6" }, [
                      _c(
                        "figure",
                        { staticClass: "product-media" },
                        [
                          _c(
                            "nuxt-link",
                            {
                              attrs: {
                                to: _vm.localePath(
                                  "/product/default/" + _vm.product.id
                                )
                              }
                            },
                            [
                              _c("img", {
                                directives: [
                                  {
                                    name: "lazy",
                                    rawName: "v-lazy",
                                    value: _vm.product.main_image,
                                    expression: "product.main_image"
                                  }
                                ],
                                attrs: {
                                  alt: "Product",
                                  width: 400,
                                  height: 400
                                }
                              })
                            ]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "h3",
                        { staticClass: "product-title" },
                        [
                          _c(
                            "nuxt-link",
                            {
                              attrs: {
                                to: _vm.localePath(
                                  "/product/default/" + _vm.product.slug
                                )
                              }
                            },
                            [_vm._v(_vm._s(_vm.product.name))]
                          )
                        ],
                        1
                      )
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-6 justify-content-end" },
                      [
                        _vm.product.stock == 0
                          ? _c(
                              "div",
                              { key: "outPrice", staticClass: "product-price" },
                              [
                                _c("span", { staticClass: "out-price" }, [
                                  _vm._v("$" + _vm._s(_vm.product.price))
                                ])
                              ]
                            )
                          : [
                              _vm.product.price_after_discount ==
                              _vm.product.price
                                ? _c("div", { staticClass: "product-price" }, [
                                    _vm._v(
                                      "\n                            $" +
                                        _vm._s(
                                          _vm.product.price_after_discount
                                        ) +
                                        "\n                        "
                                    )
                                  ])
                                : [
                                    _vm.product.length == 0
                                      ? _c(
                                          "div",
                                          { staticClass: "product-price" },
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "new-price" },
                                              [
                                                _vm._v(
                                                  "$" +
                                                    _vm._s(
                                                      _vm.product
                                                        .price_after_discount
                                                    )
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              { staticClass: "old-price" },
                                              [
                                                _vm._v(
                                                  "$" +
                                                    _vm._s(_vm.product.price)
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      : _c(
                                          "div",
                                          { staticClass: "product-price" },
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "new-price" },
                                              [
                                                _vm._v(
                                                  "$" +
                                                    _vm._s(
                                                      _vm.product
                                                        .price_after_discount
                                                    )
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              { staticClass: "old-price" },
                                              [
                                                _vm._v(
                                                  "$" +
                                                    _vm._s(_vm.product.price)
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                  ]
                            ],
                        _vm._v(" "),
                        _c("quantity-input", {
                          attrs: { product: _vm.product },
                          on: { "change-qty": _vm.changeQty2 }
                        }),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "product-details-action" },
                          [
                            _c(
                              "a",
                              {
                                staticClass: "btn-product btn-cart",
                                class: {
                                  "btn-disabled":
                                    !_vm.canAddToCart(_vm.product, _vm.qty) ||
                                    (_vm.product.length > 0 &&
                                      !_vm.showVariationPrice)
                                },
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    return _vm.addCart(1)
                                  }
                                }
                              },
                              [
                                _c("span", [
                                  _vm._v(_vm._s(_vm.$t("product.addToCart")))
                                ])
                              ]
                            ),
                            _vm._v(" "),
                            !_vm.isInWishlist(_vm.product)
                              ? _c(
                                  "a",
                                  {
                                    key: "notInWishlist",
                                    staticClass: "btn-product btn-wishlist",
                                    attrs: { title: "Wishlist" },
                                    on: {
                                      click: function($event) {
                                        $event.preventDefault()
                                        return _vm.addToWishlist({
                                          product: _vm.product
                                        })
                                      }
                                    }
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(_vm.$t("product.addToWishlist"))
                                      )
                                    ])
                                  ]
                                )
                              : _c(
                                  "nuxt-link",
                                  {
                                    key: "inWishlist",
                                    staticClass:
                                      "btn-product btn-wishlist added-to-wishlist",
                                    attrs: {
                                      to: _vm.localePath("/shop/wishlist"),
                                      title: "Wishlist"
                                    }
                                  },
                                  [_c("span", [_vm._v("Go to Wishlist")])]
                                )
                          ],
                          1
                        )
                      ],
                      2
                    )
                  ])
                ])
              ])
            : _vm._e()
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("a", { staticClass: "size-guide mr-4" }, [
      _c("i", { staticClass: "icon-th-list" }),
      _vm._v("size guide\n            ")
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "social-icons social-icons-sm",
        staticStyle: {
          width: "150px",
          display: "flex",
          "justify-content": "space-around",
          "align-items": "center"
        }
      },
      [
        _c("span", { staticClass: "social-label" }, [_vm._v("Share:")]),
        _vm._v(" "),
        _c(
          "a",
          {
            staticClass: "facebook",
            attrs: {
              href: "https://www.facebook.com/RoyalBeesEg",
              rel: "noopener",
              target: "_blank"
            }
          },
          [_c("i", { staticClass: "icon-facebook-f" })]
        ),
        _vm._v(" "),
        _c(
          "a",
          {
            attrs: {
              href: "https://www.instagram.com/royalbeeseg",
              rel: "noopener",
              target: "_blank"
            }
          },
          [_c("i", { staticClass: "fab fa-instagram" })]
        ),
        _vm._v(" "),
        _c(
          "a",
          {
            attrs: {
              href: "https://www.youtube.com/channel/UC86SCyXYV402gABz-IsUR6A",
              rel: "noopener",
              target: "_blank"
            }
          },
          [_c("i", { staticClass: "fab fa-youtube fa-2x" })]
        )
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }