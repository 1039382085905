var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "footer",
      {
        directives: [
          {
            name: "lazy",
            rawName: "v-lazy:background-image",
            value: "./images/home/Group 16883.png",
            expression: "'./images/home/Group 16883.png'",
            arg: "background-image"
          }
        ],
        staticClass: "footer bg-info text-white"
      },
      [
        _c("div", { staticClass: "footer-middle p-0" }, [
          _c(
            "div",
            { class: _vm.isFullwidth ? "container-fluid" : "container" },
            [
              _c("div", { staticClass: "row footer-data align-items-center" }, [
                _c("div", { staticClass: "col-md-5 col-sm-3" }, [
                  _c(
                    "div",
                    { staticClass: "widget widget-about" },
                    [
                      _c(
                        "nuxt-link",
                        {
                          staticClass: "logo",
                          attrs: { to: _vm.localePath("/") }
                        },
                        [
                          _c("img", {
                            directives: [
                              {
                                name: "lazy",
                                rawName: "v-lazy",
                                value: "./images/home/unnamed1.png",
                                expression: "'./images/home/unnamed1.png'"
                              }
                            ],
                            staticClass: "footer-logo bg-transparent",
                            attrs: {
                              alt: "Footer Logo",
                              width: "150",
                              height: "27"
                            }
                          })
                        ]
                      ),
                      _vm._v(" "),
                      _c("p", { staticClass: "text-white" }, [
                        _vm._v(
                          "\n                                شركة مملكة النحل واحدة من الشركات الرائدة فى\n                                مصر و الوطن العربى بخبرة اكثر من ٣٠ سنة\n                            "
                        )
                      ]),
                      _vm._v(" "),
                      _vm._m(0)
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-md-7 col-9", class: _vm.$i18n.locale },
                  [
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-md-6 col-sm-6" }, [
                        _c("div", { staticClass: "widget" }, [
                          _c("h4", { staticClass: "widget-title" }, [
                            _vm._v(
                              "\n                                        الصفحات\n                                    "
                            )
                          ]),
                          _vm._v(" "),
                          _c("ul", { staticClass: "widget-list" }, [
                            _c(
                              "li",
                              { staticClass: "megamenu-container" },
                              [
                                _c(
                                  "nuxt-link",
                                  { attrs: { to: _vm.localePath("/") } },
                                  [
                                    _vm._v(
                                      "\n                                                " +
                                        _vm._s(_vm.$t("nav.home"))
                                    )
                                  ]
                                )
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "li",
                              [
                                _c(
                                  "nuxt-link",
                                  {
                                    attrs: {
                                      to: _vm.localePath("/shop/sidebar/list")
                                    }
                                  },
                                  [_vm._v(_vm._s(_vm.$t("nav.products")))]
                                )
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "li",
                              [
                                _c(
                                  "nuxt-link",
                                  {
                                    attrs: {
                                      to: _vm.localePath("/blog/classic")
                                    }
                                  },
                                  [_vm._v(_vm._s(_vm.$t("nav.blog")))]
                                )
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "li",
                              [
                                _c(
                                  "nuxt-link",
                                  {
                                    attrs: {
                                      to: _vm.localePath("/pages/contact")
                                    }
                                  },
                                  [_vm._v(_vm._s(_vm.$t("nav.contactUs")))]
                                )
                              ],
                              1
                            )
                          ])
                        ])
                      ]),
                      _vm._v(" "),
                      _vm._m(1)
                    ])
                  ]
                )
              ])
            ]
          )
        ]),
        _vm._v(" "),
        _vm.bottomSticky ? _c("div", { staticClass: "mb-10" }) : _vm._e()
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "footer-social social d-flex w-50" }, [
      _c("ul", { staticClass: "list-unstyled d-flex" }, [
        _c("li", { staticClass: "facebook" }, [
          _c(
            "a",
            {
              staticClass: "facebook",
              attrs: {
                href: "https://www.facebook.com/RoyalBeesEg",
                rel: "noopener",
                target: "_blank"
              }
            },
            [_c("i", { staticClass: "fab fa-facebook" })]
          )
        ]),
        _vm._v(" "),
        _c("li", { staticClass: "twitter" }, [
          _c(
            "a",
            {
              attrs: {
                href: "https://www.instagram.com/royalbeeseg",
                rel: "noopener",
                target: "_blank"
              }
            },
            [_c("i", { staticClass: "fab fa-instagram" })]
          )
        ]),
        _vm._v(" "),
        _c("li", { staticClass: "youtube" }, [
          _c(
            "a",
            {
              attrs: {
                href:
                  "https://www.youtube.com/channel/UC86SCyXYV402gABz-IsUR6A",
                rel: "noopener",
                target: "_blank"
              }
            },
            [_c("i", { staticClass: "fab fa-youtube fa-2x" })]
          )
        ])
      ])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-md-5 col-sm-6" }, [
      _c("div", { staticClass: "widget" }, [
        _c("h4", { staticClass: "widget-title" }, [
          _vm._v(
            "\n                                        أتصل بنا\n                                    "
          )
        ]),
        _vm._v(" "),
        _c("ul", { staticClass: "widget-list" }, [
          _c("li", [
            _c(
              "a",
              {
                staticClass: "d-flex mb-3",
                attrs: { href: "mailto:info@royalbeeseg.com", target: "_blank" }
              },
              [
                _c("i", { staticClass: "far fa-envelope ml-2" }),
                _vm._v(" "),
                _c("p", [_vm._v("info@royalbeeseg.com")])
              ]
            )
          ]),
          _vm._v(" "),
          _c(
            "li",
            { staticClass: "d-flex justify-content-start align-items-center" },
            [
              _c(
                "a",
                {
                  staticClass: "d-flex mb-3",
                  attrs: { href: "tel:0238090519", target: "_blank" }
                },
                [
                  _c("i", { staticClass: "fa fa-phone ml-2" }),
                  _vm._v(" "),
                  _c("p", [_vm._v("0238090519‎")])
                ]
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "li",
            { staticClass: "d-flex justify-content-start align-items-center" },
            [
              _c(
                "a",
                {
                  staticClass: "d-flex mb-3",
                  attrs: {
                    href:
                      "http://maps.google.com/maps?saddr=Giza&daddr=Abu+an+Numros",
                    target: "_blank"
                  }
                },
                [
                  _c("i", { staticClass: "fa fa-map-marker ml-2" }),
                  _vm._v(
                    "\n                                               ابو النمرس جيزة"
                  )
                ]
              )
            ]
          )
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }