<template>
    <header class="header header-4 header-intro-clearance bg-white ">
        <div class="header-top bg-white">
            <div :class="isFullwidth ? 'container-fluid' : 'container'">
                <div class="header-right py-1">
                    <ul class="top-menus">
                        <li>
                            <ul
                                class="
                                    d-flex
                                    justify-content-center
                                    align-items-center
                                "
                            >

                                <li v-if="!isLoggedIn">
                                    <a>
                                        <span
                                            class="mr-2 text-dark langChaner"
                                            href="#signin-modal"
                                            @click.prevent="openSignInModal"
                                            >{{ $t('login.login') }}</span
                                        >
                                        |
                                        <span
                                            class="ml-2 text-dark langChaner"
                                            href="#signin-modal "
                                            @click.prevent="openSignUpModal"
                                            >{{ $t('login.register') }}</span
                                        ></a
                                    >
                                </li>
                                <li v-if="isLoggedIn">
                                    <span
                                        >{{ $t('login.welcome') }} ,
                                        <b>{{ userName }}</b></span
                                    >
                                </li>
                                <li v-if="isLoggedIn">
                                    <button>
                                        <i class="icon-user"></i>
                                        <span
                                            class="mr-2 text-dark"
                                            href="#signin-modal"
                                            @click="logout"
                                            >{{ $t('login.logout') }}</span
                                        >
                                    </button>
                                </li>
                            </ul>
                        </li>
                    </ul>
                    <button class="mobile-menu-toggler" @click="openMobileMenu">
                        <span class="sr-only">Toggle mobile menu</span>
                        <i class="icon-bars"></i>
                    </button>
                </div>
            </div>
        </div>

        <sticky-header>
            <div class="header-bottom sticky-header pt-3">
                <div :class="isFullwidth ? 'container-fluid' : 'container'">
                    <div class="header-left">
                        <nuxt-link :to="localePath('/')" class="logo">
                            <img
                                v-lazy="
                                    './images/home/WhatsApp Image 2021-12-08 at 9.57.02 AM.png'
                                "
                                class="bg-transparent"
                                alt="royal-bee Logo"
                                width="105"
                                height="27"
                            />
                        </nuxt-link>
                    </div>
                    <div class="header-center">
                        <main-menu></main-menu>
                    </div>
                    <div class="header-right">
                        <!-- <compare-menu></compare-menu> -->
                        <profile></profile>

                        <wishlist-menu></wishlist-menu>


                        <cart-menu></cart-menu>
                    </div>
                </div>
            </div>
        </sticky-header>
    </header>
</template>

<script>
import CartMenu from '~/components/partial/headers/shared/CartMenu';
import CompareMenu from '~/components/partial/headers/shared/CompareMenu';
import WishlistMenu from '~/components/partial/headers/shared/WishlistMenu';
import profile from '~/components/partial/headers/shared/profile';
import MainMenu from '~/components/partial/headers/shared/MainMenu';
import StickyHeader from '~/components/elements/StickyHeader';
import Repository, { baseUrl } from '~/repositories/repository.js';
import axios from 'axios';
import $ from 'jquery';
export default {
    components: {
        CartMenu,
        CompareMenu,
        WishlistMenu,
        profile,
        MainMenu,
        StickyHeader
    },
    computed: {
        isFullwidth: function() {
            return this.$route.path.includes('fullwidth');
        },
        availableLocales() {
            return this.$i18n.locales.filter(i => i.code !== this.$i18n.locale);
        },
        isLoggedIn() {
            return this.$store.getters.isLoggedIn;
        }
    },
    methods: {
        // isLoggedIn() {
        //     return this.$store.getters.isLoggedIn;
        // },
        openSignInModal: function() {
            this.$modal.show(
                () => import('~/components/elements/modals/SignInModal'),
                {},
                { width: '575', height: 'auto', adaptive: true }
            );
        },
        openSignUpModal: function() {
            this.$modal.show(
                () => import('~/components/elements/modals/SignUpModal'),
                {},
                { width: '575', height: 'auto', adaptive: true }
            );
        },
        openMobileMenu: function() {
            document.querySelector('body').classList.add('mmenu-active');
        },


        logout() {
            this.$store.dispatch('logout');
        },
        setLang(locale) {
            sessionStorage.setItem(
                'language',
                locale == 'en' ? 'LANGUAGE2' : 'LANGUAGE1'
            );
            setTimeout(() => {
                this.$router.go(this.$router.currentRoute);
            }, 1500);
        },
       
        StickyHeader() {
            $(window).scroll(function() {
                var sc = $(this).scrollTop();
                if (sc > 300) {
                    $('.sticky-header').addClass('headerScroll');
                } else {
                    $('.sticky-header').removeClass('headerScroll');
                }
            });
        }
    },
    mounted() {
        let username = sessionStorage.getItem('username');
        this.userName = username;
        let registerName = sessionStorage.getItem('username');
        this.nameAfterRegister = registerName;
        console.log('this.nameAfterRegister', this.nameAfterRegister);

    },
    created() {
        let registerName = sessionStorage.getItem('username');
        this.nameAfterRegister = registerName;
        console.log('this.nameAfterRegister', this.nameAfterRegister);
        this.StickyHeader();
    },
    data() {
        return {
            userName: '',
            nameAfterRegister: ''
        };
    }
};
</script>
<style lang="scss" scoped>
$second-color: #ffe607;
header {
    .list-contact {
        a {
            color: #fff;
            font-family: 'CairoRegular';
            font-size: 18px !important;
            position: relative;
            padding: 0px 10px;
            &.active {
                background: #fdc63f;
            }
        }
    }
}
.headerScroll {
    .header-center {
        margin: 1.5rem 0 0rem;
    }
}
.top-menus {
    margin: 0 !important;
}
.header-center {
    li {
        a {
            color: #fff !important;
        }
    }
}
.header-bottom {
    .logo {
        margin: 0 !important;
    }
}
.header-right.social {
    margin: auto !important;
    .list-unstyled {
        margin: auto !important;
        li {
            a {
                i {
                    color: #fff;
                    font-size: 25px;
                }
            }
        }
    }
}
@media (max-width: 1024px) {
    .header-intro-clearance .header-bottom .menu > li > a {
        font-size: 18px;
    }
    .header-top {
        .header-right {
            display: flex;
            justify-content: space-between !important;
            width: 100% !important;
            flex-direction: row-reverse;
        }
    }
}
@media (max-width: 768px) {
    .header-top {
        .header-right {
            flex-direction: row;
        }
    }
}
@media (max-width: 766.98px) {
    .top-menu {
        display: flex !important;
        flex-direction: column-reverse !important;

        justify-content: center !important;
        align-items: center !important;
    }
}
@media (max-width: 425px) {
    .top-menus {
        .px-4,
        .logOut {
            display: flex;
            align-items: center;
            button {
                font-size: 13px;
                padding: 0 !important;
                a {
                    padding: 0;
                }
            }
            span {
                font-size: 11px;
            }
        }
    }
}
.marked {
    background: $second-color;
}
.text-dark {
    padding: 10px;
}
.categorie-name {
    margin: 0;
    padding: 0;
    // &:last-of-type {
    //     background: $second-color;
    // }

    a {
        padding: 5px 10px;
        text-align: center !important;
        margin: 0 !important;
        line-height: 20px;
        &.active {
            background: $second-color;
        }
    }
}
.lang {
    padding: 10px 15px;
}
@media (max-width: 766.98px) {
    .top-menu {
        display: flex !important;
        flex-direction: column-reverse !important;

        justify-content: center !important;
        align-items: center !important;
    }
}
.langChaner {
    cursor: pointer;
}
</style>
