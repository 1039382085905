var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "latestCompany" }, [
    _c(
      "div",
      { staticClass: "row" },
      _vm._l(_vm.categories, function(category) {
        return _c("test", {
          key: category.id,
          staticClass: "col-md-4 col-sm-12 mt-2",
          attrs: { category: category }
        })
      }),
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }