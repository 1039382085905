var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.product
    ? _c("div", { staticClass: "product-gallery" }, [
        _vm.product[0].images.length > 0
          ? _c(
              "div",
              {
                directives: [
                  {
                    name: "images-loaded",
                    rawName: "v-images-loaded.on",
                    value: _vm.handleIsotope,
                    expression: "handleIsotope",
                    modifiers: { on: true }
                  }
                ]
              },
              [
                _c("figure", { staticClass: "product-main-image" }, [
                  _vm.product[0].new
                    ? _c("span", { staticClass: "product-label label-new" }, [
                        _vm._v("New")
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.product[0].sale_price
                    ? _c("span", { staticClass: "product-label label-sale" }, [
                        _vm._v("Sale")
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.product[0].top
                    ? _c("span", { staticClass: "product-label label-top" }, [
                        _vm._v("Top")
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.product[0].stock === 0
                    ? _c("span", { staticClass: "product-label label-out" }, [
                        _vm._v("Out Of Stock")
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("img", {
                    directives: [
                      {
                        name: "lazy",
                        rawName: "v-lazy",
                        value: _vm.product[0].images[_vm.currentIndex],
                        expression: "product[0].images[currentIndex]"
                      }
                    ],
                    attrs: { id: "product-zoom", alt: "product" }
                  }),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      staticClass: "btn-product-gallery",
                      attrs: { href: "#", id: "btn-product-gallery" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.openLightBox.apply(null, arguments)
                        }
                      }
                    },
                    [_c("i", { staticClass: "icon-arrows" })]
                  )
                ]),
                _vm._v(" "),
                _c(
                  "isotope",
                  {
                    ref: "iso",
                    staticClass:
                      "product-image-gallery product-gallery-masonry",
                    attrs: {
                      id: "product-zoom-gallery",
                      options: _vm.isotopeOptions,
                      list: _vm.product[0].images
                    }
                  },
                  [
                    _vm._l(_vm.product[0].images, function(smPicture, index) {
                      return _c(
                        "a",
                        {
                          key: index,
                          staticClass: "product-gallery-item",
                          class: { active: _vm.currentIndex == index },
                          attrs: { href: "#" },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.changePicture(index)
                            }
                          }
                        },
                        [
                          _c("img", {
                            directives: [
                              {
                                name: "lazy",
                                rawName: "v-lazy",
                                value: _vm.product[0].images[index],
                                expression: "product[0].images[index]"
                              }
                            ],
                            attrs: { alt: "product side image" }
                          })
                        ]
                      )
                    }),
                    _vm._v(" "),
                    _c("a", {
                      key: "grid-sizer",
                      staticClass: "product-gallery-item grid-sizer",
                      attrs: { href: "#" }
                    })
                  ],
                  2
                ),
                _vm._v(" "),
                _c("light-box", {
                  ref: "lightbox",
                  staticClass: "light-box",
                  attrs: {
                    media: _vm.lightBoxMedia,
                    "show-caption": true,
                    "show-light-box": false
                  }
                })
              ],
              1
            )
          : _vm._e()
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }