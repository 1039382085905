<template>
    <div
        class="
            header-search header-search-extended
            mr-0
            header-search-visible
            d-flex
            justify-content-center
            search-blog
        "
    >
        <!-- <a class="search-toggle d-none" role="button">
            <i class="icon-search"></i>
        </a> -->
        <form
            action="#"
            method="get"
            @click.stop="showSearchForm"
            @submit.prevent="submitSearchForm"
        >
            <div class="header-search-wrapper search-wrapper-wide header-search-wrapper2">
                <label for="q" class="sr-only">
                    {{ $t('blog.search') }}
                </label>

                <button class="btn" type="submit">
                    <i class="icon-search"></i>
                </button>
                <input
                    type="text"
                    class="form-control"
                    :placeholder="$t('blog.search')"
                    required
                    v-model="searchPlace"
                    @input="searchPlaces"
                />
                <!-- <button
                    class="btn shopButton round-xs btn-sm text-sm"
                    @click="searchPlaces"
                >
                    <span>Search</span>
                </button> -->
            </div>
        </form>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import Repository, { baseUrl } from '~/repositories/repository.js';
import axios from 'axios';
export default {
    data: function () {
        return {
            searchPlace: '',
            suggestions: [],
            suggestionsLength:0,
            timeouts: [],
            baseUrl: baseUrl,
            search: '',
            // filterData: productData
        };
    },
    computed: {
        ...mapGetters('demo', ['currentDemo']),
    },
    mounted: function () {
        document
            .querySelector('body')
            .addEventListener('click', this.closeSearchForm);
    },
    methods: {
        searchPlaces: function () {
            
            if (this.searchPlace.length > 2) {
                var searchPlace = this.searchPlace;
                this.timeouts.map((timeout) => {
                    window.clearTimeout(timeout);
                });
                // let token = sessionStorage.getItem('token');
                let data = {
                    // api_token: 'Onetjv7sOxt6EDhov6pB',
                    // api_token: token,
                    term: searchPlace,
                };
                this.timeouts.push(
                    setTimeout(() => {

                        var config = {
                            method: 'post',
                            url: `${baseUrl}/blog/search`,
                            headers: {
                                // ...data.getHeaders(),
                            },
                            data: data,
                        };

                        axios(config)
                            .then((resp) => {
                                console.log(resp);
                                console.log('my data', data);
                            })
                            .catch((error) => {
                                console.log(error);
                            });
                    }, 500)
                );
            } else {
                this.timeouts.map((timeout) => {
                    window.clearTimeout(timeout);
                });
                this.suggestions = [];
            }
        },
        matchEmphasize: function (name) {
            var regExp = new RegExp(this.searchPlace, 'i');
            return name.replace(
                regExp,
                (match) => '<strong>' + match + '</strong>'
            );
        },
        goProductPage: function () {
            this.searchPlace = '';
            this.suggestions = [];
        },
        searchToggle: function (e) {
            document.querySelector('.header-search').classList.toggle('show');
            e.stopPropagation();
        },

        showSearchForm: function () {
            document
                .querySelector('.header .header-search')
                .classList.add('show');
        },
        closeSearchForm: function (e) {
            document
                .querySelector('.header .header-search')
                .classList.remove('show');
        },
        submitSearchForm: function (e) {
            this.closeSearchForm();
            this.$router.push({
                // path: '/shop/sidebar/list',
                path: `/blog/blogResult`,
                query: {
                    searchBlog: this.searchPlace.replace(/\s/g, '-'),
                },
            });
            this.searchPlace = '';
            // this.$router.push({
            // 	path: '/shop/sidebar/3cols',
            // 	query: {
            // 		searchPlace: this.searchPlace
            // 	}
            // });
        },
        //  productSelected: function(item) {
        //     return (
        //         this.$route.query.category &&
        //         this.$route.query.category == item.slug
        //     );
        // },
    },
};
</script>
<style lang="scss" scoped>
@media (max-width: 991.98px) {
    .carousel-data .holder {
        padding: 20px 20px 40px 20px;
    }
    .header-search-wrapper2 {
        position: absolute;

        top: -29px;
        margin-top: 0.8rem;

        border-width: 0.4rem;
        left: 0 !important;
        width: 100%;
        display: flex !important;
    }
    .shopButton {
        font-size: 14px;
    }
    .header-search-visible .header-search-wrapper:before{
        display: none;
    }
}
.search-blog{
    max-width: 100%;
}
</style>