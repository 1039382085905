<template>
    <div>
        <template v-if="!loading">
            <div class="col-9 m-auto skeleton-body skel-shop-products">
                <template>
                    <div
                        class="skel-pro skel-pro-list"
                        v-for="item in 1"
                        :key="item"
                    ></div>
                </template>
            </div>
        </template>
        <div class="mt-5" v-else>
            <section
                class="baby-producst text-center"
                v-if="secondListCategories.length > 0"
            >
                <div class="wrapper-with-margin">
                    <div class="container">
                        <div class="row align-items-center mb-5">
                        <div class="col-6">
                            <h1 class="text-right text-gray m-3 mb-2">
                                عسل الزيرو سكروز
                            </h1>
                        </div>
                        <hr>
                        <div class="col-6 show-all">
                            <nuxt-link
                                :to="localePath('/shop/sidebar/list')"
                                class="btn btn-primary btn-round p-4"
                            >
                                <span>عرض الكل</span>
                            </nuxt-link>
                        </div>
                    </div>
                        <div
                            class="
                                swiper-carousel
                                brands-slider
                                swiper-2
                                mb-5
                                mt-5
                            "
                        >
                            <div v-swiper:swiper2="carouselSetting2">
                                <div class="swiper-wrapper">
                                    <div
                                        class="swiper-slide"
                                        v-for="listCategory in secondListCategories"
                                        :key="listCategory.id"
                                    >
                                    <div class="card-holder">
                                        <div class="card text-center">
                                               <div
                                        class="card-info text-white"
                                        v-if="
                                            listCategory.discount_int !== null
                                                
                                        "
                                    >
                                        Sale!
                                    </div>
                                            </div>
                                     
                                        </div>
                                    
                                     <div class="rating card-info">
                                        <i class="fas fa-star"></i>
                                        <span>4.5</span>
                                    </div>
                                        <nuxt-link
                                            :to="
                                                localePath(
                                                    `/product/default/${listCategory.id}`
                                                )
                                            "
                                        >
                                            <img
                                                v-lazy="listCategory.main_image"
                                                class="bg-transparent"
                                                alt="Alf Logo"
                                                height="27"
                                            />
                                            <p class="text-dark">
                                                {{ listCategory.name }}
                                            </p>
                                        </nuxt-link>
                                        
                                        <div class="row text-center" v-if="listCategory.discount_int !== null">
                                            
                                             <div class="col-md-6 col-sm-12">
                                                <p class="text-primary">
                                                    {{
                                                        listCategory.price_after_discount
                                                    }}
                                                    جنية
                                                </p>
                                                
                                            </div>
                                            <div
                                                class="col-md-6 col-sm-12"
                                               
                                            >
                                                <p class="text-primary w-100">
                                                    <del>
                                                        {{
                                                            listCategory.price
                                                        }}</del
                                                    >
                                                </p>
                                            </div>
                                         
                                        </div>
                                        <div class="row text-center" v-else>
                                            
                                            
                                            <div
                                                class="col-md-12 col-sm-12"
                                               
                                            >
                                                <p class="text-primary w-100">
                                                  
                                                        {{
                                                            listCategory.price
                                                        }}
                                                    
                                                </p>
                                            </div>
                                         
                                        </div>
                                         <div class="actions">
                                            <div class="row">
                                                <div class="col">
                                                    <div
                                                        class="
                                                                        img-holder
                                                                        d-flex
                                                                        justify-content-center
                                                                        align-items-center
                                                                    "
                                                        :class="{
                                                            'btn-disabled': !canAddToCart(
                                                                listCategory,
                                                                1
                                                            )
                                                        }"
                                                    >
                                                        <button
                                                            class=""
                                                            @click.prevent="
                                                                addToCart({
                                                                    product: listCategory
                                                                })
                                                            "
                                                        >
                                                            <i
                                                                class="
                                                                                fas
                                                                                fa-cart-plus
                                                                                fa-2x
                                                                            "
                                                            ></i>
                                                        </button>
                                                    </div>
                                                </div>
                                                <div class="col">
                                                    <div
                                                        class="
                                                                        img-holder
                                                                        d-flex
                                                                        justify-content-center
                                                                        align-items-center
                                                                    "
                                                    >
                                                        <div
                                                            class="
                                                                            wishlist-outline
                                                                            d-flex
                                                                            justify-content-start
                                                                        "
                                                        >
                                                            <nuxt-link
                                                                :to="
                                                                    localePath(
                                                                        '/shop/wishlist'
                                                                    )
                                                                "
                                                                class="
                                                                                btn-product
                                                                            "
                                                                v-if="
                                                                    isInWishlist(
                                                                        listCategory
                                                                    )
                                                                "
                                                                key="inWishlist"
                                                            >
                                                                <i
                                                                    class="
                                                                                    fas
                                                                                    fa-heart
                                                                                "
                                                                ></i>
                                                            </nuxt-link>
                                                            <a
                                                                class="
                                                                                btn-product
                                                                            "
                                                                @click.prevent="
                                                                    addToWishlist(
                                                                        {
                                                                            product: listCategory
                                                                        }
                                                                    )
                                                                "
                                                                v-else
                                                                key="notInWishlist"
                                                            >
                                                                <i
                                                                    class="
                                                                                    far
                                                                                    fa-heart
                                                                                    fa-2x
                                                                                "
                                                                ></i>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <!-- <div class="col">
                                                    <div
                                                        class="
                                                                        img-holder
                                                                        share
                                                                    "
                                                    >
                                                        <nuxt-link to="/">
                                                            <i
                                                                class="
                                                                                fas
                                                                                fa-share
                                                                                fa-2x
                                                                            "
                                                            ></i>
                                                        </nuxt-link>
                                                    </div>
                                                </div> -->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="swiper-nav">
                                <div class="swiper-prev">
                                    <i class="icon-angle-left"></i>
                                </div>
                                <div class="swiper-next">
                                    <i class="icon-angle-right"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>


<script>
import { mapGetters, mapActions } from 'vuex';
import axios from 'axios';
import {
    carouselSettingSingle,
    carouselSettingDefault,
} from '~/utilities/carousel';
import Repository, { baseUrl } from '~/repositories/repository.js';

export default {
    data: function () {
        return {
            carouselSetting2: {
                ...carouselSettingDefault,
                slidesPerView: 4,
                spaceBetween: 30,
                navigation: {
                    nextEl: '.brands-slider .swiper-next',
                    prevEl: '.brands-slider .swiper-prev',
                },
                breakpoints: {
                    992: {
                        slidesPerView: 5,
                    },
                    768: {
                        slidesPerView: 3,
                    },
                    576: {
                        slidesPerView: 2,
                    },
                    480: {
                        slidesPerView: 2,
                    },
                },
            },
            firstListCategories: [],
            secondListCategories: [],
            loading: false,
        };
    },
    methods: {
        ...mapActions('cart', ['addToCart']),
        ...mapActions('wishlist', ['addToWishlist']),
        ...mapActions('compare', ['addToCompare']),
        getData: async function () {
            let listCategory = `${baseUrl}/list_categories`;
            console.log(listCategory);

            //listCategory
            await Repository.get(listCategory).then((resp) => {
                if (resp.data.code == 200) {
                    this.loading = true;
                }
                this.firstListCategories = resp.data.list_products.first;
                this.secondListCategories = resp.data.list_products.second;
                console.log(' this.secondListCategories',  this.secondListCategories);
                // console.log(this.firstListCategories.length);
            });
        },
    },
     computed: {
        ...mapGetters('cart', ['canAddToCart']),
        ...mapGetters('wishlist', ['isInWishlist']),
        ...mapGetters('compare', ['isInCompare']),
        fakeArray: function() {
            let temp = [];
            for (let i = 0; i < this.perPage; i++) {
                temp.push(i);
            }
            return temp;
        }
    },
    mounted() {
        this.getData();
    },
    props: ['product'],
};
</script>

<style lang="scss">
hr{
    width: 45%;
    position: absolute;
    left: 19%;
    border-top: 1px solid #9a9999;
}
.text-dark{
        font-size: 16px;
    font-weight: bold;
    margin: 10px 0;
    color:#777 !important
}
.baby-producst .swiper-slide{
        padding: 10px;
    border-radius: 10px;
    border: 1px solid #f6f7f8;
}

@media(max-width:768px) {
    .baby-producst{
        .row{
            margin-bottom: 10px !important;
            h1{
                font-size: 30px;
            }
            .show-all{
                a{
                    padding:10px !important;
                }
            }
        }
        .brands-slider{
            margin-top: 0 !important;
        }
    }
}
@media(max-width: 425px){
    hr{
        display: none;
    }

}
</style>