var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "holder" },
      [
        _vm.firstLoaded ? _c("LazyHydrate", [_c("Allfirst")], 1) : _vm._e(),
        _vm._v(" "),
        _vm.secondLoaded ? _c("LazyHydrate", [_c("Allsecond")], 1) : _vm._e(),
        _vm._v(" "),
        _vm.thirdLoaded ? _c("LazyHydrate", [_c("Allthird")], 1) : _vm._e(),
        _vm._v(" "),
        _vm.fourthLoaded ? _c("LazyHydrate", [_c("Allfourth")], 1) : _vm._e(),
        _vm._v(" "),
        _vm.fifthLoaded ? _c("LazyHydrate", [_c("Allfifth")], 1) : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }