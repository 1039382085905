<template>
    <header class="header header-4 header-intro-clearance bg-white ">
        <div class="header-top bg-white">
            <div :class="isFullwidth ? 'container-fluid' : 'container'">
                <div class="header-right py-2">
                    <!-- <ul class="top-menu">
                        <li>
                            <a href="#">Links</a>
                            <ul>
                                <li>
                                    <div class="header-dropdown">
                                        <a href="#">USD</a>
                                        <div class="header-menu">
                                            <ul>
                                                <li>
                                                    <a href="#">Eur</a>
                                                </li>
                                                <li>
                                                    <a href="#">Usd</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div class="header-dropdown">
                                        <a href="#">English</a>
                                        <div class="header-menu">
                                            <ul>
                                                <li>
                                                    <a href="#">English</a>
                                                </li>
                                                <li>
                                                    <a href="#">French</a>
                                                </li>
                                                <li>
                                                    <a href="#">Spanish</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <a
                                        href="#signin-modal"
                                        @click.prevent="openSignInModal"
                                        >Sign in / Sign up</a
                                    >
                                </li>
                            </ul>
                        </li>
                    </ul> -->
                    <button class="mobile-menu-toggler" @click="openMobileMenu">
                        <span class="sr-only">Toggle mobile menu</span>
                        <i class="icon-bars"></i>
                    </button>
                    <ul class="top-menus">
                        <li>
                            <!-- <a href="">Links</a> -->

                            <ul
                                class="
                                    d-flex
                                    justify-content-center
                                    align-items-center
                                "
                            >
                                <li class="px-4">
                                    <!-- <button
                                        @click.prevent="setLang('ar')"
                                        class="p-2"
                                        :class="{
                                            'text-primary': $i18n.locale == 'ar'
                                        }"
                                    >
                                        <nuxt-link
                                            :to="switchLocalePath('ar')"
                                            class="mx-2 lang"
                                            >العربيه
                                        </nuxt-link>
                                    </button> -->
                                    <!-- <span> | </span>
                                    <button
                                        @click.prevent="setLang('en')"
                                        class="p-2"
                                        :class="{
                                            'text-primary': $i18n.locale == 'en'
                                        }"
                                    >
                                        <nuxt-link
                                            :to="switchLocalePath('en')"
                                            class="mx-2 lang"
                                            >English
                                        </nuxt-link>
                                    </button> -->
                                </li>

                                <li v-if="!isLoggedIn">
                                    <a>
                                        <!-- <i class="icon-user"></i> -->
                                        <span
                                            class="mr-2 text-dark langChaner"
                                            href="#signin-modal"
                                            @click.prevent="openSignInModal"
                                            >{{ $t('login.login') }}</span
                                        >
                                        |
                                        <span
                                            class="ml-2 text-dark langChaner"
                                            href="#signin-modal "
                                            @click.prevent="openSignUpModal"
                                            >{{ $t('login.register') }}</span
                                        ></a
                                    >
                                </li>
                                <li v-if="isLoggedIn">
                                    <span
                                        >{{ $t('login.welcome') }} ,
                                        <b>{{ userName }}</b></span
                                    >
                                </li>
                                <li v-if="isLoggedIn">
                                    <button class="logOut">
                                        <i class="icon-user"></i>
                                        <span
                                            class="mr-2 text-dark"
                                            href="#signin-modal"
                                            @click="logout"
                                            >{{ $t('login.logout') }}</span
                                        >
                                    </button>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="header-bottom sticky-header">
            <div :class="isFullwidth ? 'container-fluid' : 'container'">
                <div class="header-left">
                    <nuxt-link :to="localePath('/')" class="logo">
                        <img
                            v-lazy="'./images/home/Asset 1.png'"
                            class="bg-transparent"
                            alt="royal-bee Logo"
                            width="105"
                            height="27"
                        />
                    </nuxt-link>
                </div>
                <div class="header-center">
                    <nav class="main-nav">
                        <ul class="menu sf-arrows">
                            <li
                                class="megamenu-container"
                                :class="{ active: current == '/' }"
                            >
                                <nuxt-link :to="localePath('/')">
                                    {{ $t('nav.home') }}</nuxt-link
                                >
                            </li>

                            <li :class="{ active: current == 'shop' }">
                                <nuxt-link
                                    :to="localePath('/shop/sidebar/shopFilter')"
                                    >{{ $t('nav.products') }}</nuxt-link
                                >
                            </li>
                            <li :class="{ active: current == 'blog' }">
                                <nuxt-link :to="localePath('/blog/classic')">{{
                                    $t('nav.blog')
                                }}</nuxt-link>
                            </li>
                            <li :class="{ active: current == 'about' }">
                                <nuxt-link
                                    :to="localePath('/pages/aboutAlf')"
                                    >{{ $t('nav.about') }}</nuxt-link
                                >
                            </li>

                            <!-- <li :class="{ active: current == 'places' }">
                                <nuxt-link :to="localePath('/pages/places')">{{
                                    $t('nav.places')
                                }}</nuxt-link>
                            </li> -->

                            <!-- <li :class="{ active: current == 'contact' }">
                                <nuxt-link :to="localePath('/pages/contact')">{{
                                    $t('nav.contactUs')
                                }}</nuxt-link>
                            </li> -->
                        </ul>
                    </nav>
                </div>
                <div class="header-right social">
                    <ul class="list-unstyled d-flex">
                        <li class="facebook">
                            <a
                                href="https://www.facebook.com/alefplanet"
                                rel="noopener"
                                class="facebook"
                                target="_blank"
                            >
                                <i class="fab fa-facebook-f fa-2x"></i>
                            </a>
                        </li>
                        <li class="twitter">
                            <a
                                href="https://www.instagram.com/alefplanet/"
                                rel="noopener"
                                target="_blank"
                            >
                                <span>
                                    <i class="fab fa-twitter"></i>
                                </span>
                            </a>
                        </li>
                        <li class="youtube">
                            <a
                                href="https://www.youtube.com/channel/UC657uLMEPOf0r-x6Tl8KyRA"
                                rel="noopener"
                                target="_blank"
                            >
                                <span>
                                    <i class="fab fa-youtube fa-2x"></i>
                                </span>
                            </a>
                        </li>
                        <li class="instagram">
                            <a
                                href="https://www.instagram.com/alefplanet/"
                                rel="noopener"
                                target="_blank"
                            >
                                <span>
                                    <i
                                        class="
                                                    fab
                                                    fa-instagram fa-2x
                                                "
                                    ></i>
                                </span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </header>
</template>

<script>
import MainMenu from '~/components/partial/headers/shared/MainMenu';
import StickyHeader from '~/components/elements/StickyHeader';
import Repository, { baseUrl } from '~/repositories/repository.js';
import $ from 'jquery';
import axios from 'axios';
export default {
    components: {
        MainMenu,
        StickyHeader
    },
    computed: {
        isFullwidth: function() {
            return this.$route.path.includes('fullwidth');
        },
        availableLocales() {
            return this.$i18n.locales.filter(i => i.code !== this.$i18n.locale);
        },
        isLoggedIn() {
            return this.$store.getters.isLoggedIn;
        },
        current: function() {
            if (this.$route.path.includes('elements')) return 'elements';
            if (this.$route.path.includes('shop')) return 'shop';
            if (this.$route.path.includes('contact')) return 'contact';
            if (this.$route.path.includes('blog')) return 'blog';
            if (this.$route.path.includes('about')) return 'about';
            if (this.$route.path.includes('product')) return 'product';
            if (this.$route.path.includes('places')) return 'places';
            if (this.$route.path.includes('pages')) return 'pages';

            return '/';
        }
    },

    created() {
        // this.isLoggedIn();
    },
    methods: {
        isLoggedIn() {
            return this.$store.getters.isLoggedIn;
        },
        openSignInModal: function() {
            this.$modal.show(
                () => import('~/components/elements/modals/SignInModal'),
                {},
                { width: '575', height: 'auto', adaptive: true }
            );
        },
        openSignUpModal: function() {
            this.$modal.show(
                () => import('~/components/elements/modals/SignUpModal'),
                {},
                { width: '575', height: 'auto', adaptive: true }
            );
        },
        openMobileMenu: function() {
            document.querySelector('body').classList.add('mmenu-active');
        },
        // categoryTree() {
        //     axios
        //         .get(`${baseUrl}/categoryTree`)
        //         .then((resp) => {
        //             // console.log('categories', resp);
        //             this.categories = resp.data.categories;
        //             // console.log('names', this.categories.name);
        //         })
        //         .catch((err) => {
        //             console.log(err);
        //         });
        // },
        categoryTree() {
            axios
                .get(`${baseUrl}/categoryTree`, {
                    headers: {
                        language:
                            this.$i18n.locale == 'en'
                                ? 'LANGUAGE2'
                                : 'LANGUAGE1'
                    }
                })
                .then(resp => {
                    // console.log('categories', resp);
                    this.categories = resp.data.categories;
                    // console.log('names', this.categories.name);
                })
                .catch(err => {
                    console.log(err);
                });
        },

        logout() {
            this.$store.dispatch('logout');
        },
        setLang(locale) {
            sessionStorage.setItem(
                'language',
                locale == 'en' ? 'LANGUAGE2' : 'LANGUAGE1'
            );
            setTimeout(() => {
                this.$router.go(this.$router.currentRoute);
            }, 1500);
        },
        StickyHeader() {
            $(window).scroll(function() {
                var sc = $(this).scrollTop();
                if (sc > 300) {
                    $('.sticky-header').addClass('headerScroll');
                } else {
                    $('.sticky-header').removeClass('headerScroll');
                }
            });
        }
        // storage() {
        //     if(sessionStorage.getItem("token") === null){
        //         this.$store.dispatch('logout');
        //     }
        //     // sessionStorage.token == '';
        //     console.log()
        // }
    },
    mounted() {
        this.categoryTree();
        let username = sessionStorage.getItem('username');
        this.userName = username;
        let registerName = sessionStorage.getItem('username');
        this.nameAfterRegister = registerName;
        console.log('this.nameAfterRegister', this.nameAfterRegister);
        // this.storage();
    },
    created() {
        let registerName = sessionStorage.getItem('username');
        this.nameAfterRegister = registerName;
        console.log('this.nameAfterRegister', this.nameAfterRegister);
        //   this.storage();
        this.StickyHeader();
    },
    data() {
        return {
            categories: [],
            userName: '',
            nameAfterRegister: ''
        };
    }
};
</script>
<style lang="scss" scoped>
$second-color: #ffe607;
header {
    
    .header-center {
        justify-content: center;
        li {
            &.active {
                a {
                    &::before {
                        transform: translateY(-50%) !important;
                    }
                }
            }
            a {
                font-family: 'CairoRegular';
                font-size: 18px !important;
                position: relative;
                &::before {
                    background: #fff;
                    position: absolute;
                    content: '';
                    left: 0;
                    height: 1.5rem;
                    width: 0.1rem;
                    top: 50%;
                    transform: translateY(-50%);
                    background-color: #ccc;
                }
            }
        }
    }
}
.top-menus {
    margin: 0 !important;
}
// .headerScroll {
//     position: fixed !important;
//     width: 100%;
//     z-index: 3;
//     background: #fff;
//     top: 0;
//     left: 0;
//     right: 0;
//     -webkit-box-shadow: 0 3px 16px 0 rgba(0, 0, 0, 0.1);
//     box-shadow: 0 3px 16px 0 rgba(0, 0, 0, 0.1);
//     background: rgba(255, 255, 255, 0.96);
// }
.marked {
    background: $second-color;
}
.text-dark {
    padding: 10px;
}
.categorie-name {
    margin: 0;
    padding: 0;
    // &:last-of-type {
    //     background: $second-color;
    // }

    a {
        padding: 5px 10px;
        text-align: center !important;
        margin: 0 !important;
        line-height: 20px;
        &.active {
            background: $second-color;
        }
    }
}
.lang {
    padding: 10px 15px;
}
.header-center {
    li {
        a {
            color: #fff !important;
        }
    }
}
.header-bottom {
    background: #337de7;
    .logo {
        margin: 0 !important;
    }
}
.header-right.social {
    margin: auto !important;
    .list-unstyled {
        margin: auto !important;
        li {
            a {
                i {
                    color: #fff;
                    font-size: 25px;
                }
            }
        }
    }
}
@media (max-width: 1024px) {
    .header-intro-clearance .header-bottom .menu > li > a {
        font-size: 18px;
    }
    .header-top {
        .header-right {
            display: flex;
            justify-content: space-between !important;
            width: 100% !important;
            flex-direction: row-reverse;
        }
    }
}
@media (max-width: 768px) {
    .header-top {
        .header-right {
            flex-direction: row;
        }
    }
}
@media (max-width: 766.98px) {
    .top-menu {
        display: flex !important;
        flex-direction: column-reverse !important;

        justify-content: center !important;
        align-items: center !important;
    }
}
@media (max-width: 425px) {
    .top-menus {
        .px-4,
        .logOut {
            display: flex;
            align-items: center;
            button {
                font-size: 13px;
                padding: 0 !important;
                a {
                    padding: 0;
                }
            }
            span {
                font-size: 11px;
            }
        }
    }
}
.langChaner {
    cursor: pointer;
}
</style>
