<template>
    <div>
        <h1 class="title main-title mb-4">
            {{$t('shop.relatedProducts')}}
        </h1>

        <div v-if="relates.length == 0">
            <div class="swiper-carousel carousel-with-shadow">
                <div v-swiper:swiper2="carouselSetting">
                    <div class="swiper-wrapper">
                        <div
                            class="swiper-slide"
                            v-for="item in [1, 2, 3, 4]"
                            :key="item"
                        >
                            <div class="skel-pro"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="swiper-carousel carousel-with-shadow swiper-1" v-else>
            <div v-swiper:swiper1="carouselSetting">
                <div class="swiper-wrapper">
                    <div class="swiper-slide" v-for="(relate, index) in relates" :key="index">
                        <product-nine :product="relate"></product-nine>
                    </div>
                </div>
            </div>
            <div class="swiper-nav">
                <div class="swiper-prev">
                    <i class="icon-angle-left"></i>
                </div>
                <div class="swiper-next">
                    <i class="icon-angle-right"></i>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import ProductNine from '~/components/elements/products/ProductNine';
import { carouselSetting1 } from '~/utilities/carousel';
import Repository , {baseUrl} from "~/repositories/repository"
export default {
    components: {
        ProductNine,
    },
    props: {
        products: {
            type: Array,
            default: function () {
                return [];
            },
        },
    },
    data: function () {
        return {
            carouselSetting: {
                ...carouselSetting1,
                navigation: {
                    nextEl: '.swiper-1 .swiper-next',
                    prevEl: '.swiper-1 .swiper-prev',
                },
                pagination: {
                    el: '.swiper-1 .swiper-dots',
                    clickable: true,
                },
            },
            id:this.$route.params.slug,
            relates:[]
        };
    },
    methods:{
        async relatedProducts(){
            let token = sessionStorage.getItem('token')
            await Repository.post(`${baseUrl}/product`,{
                api_token:token,
                id:this.id
            })
            .then(resp =>{
                console.log("related products"  , resp);
                this.relates = resp.data.related_products
            })
            .catch(err =>{
                console.log(err);
            })
        }
    },
    created(){
        this.relatedProducts()
    }
};
</script>

<style lang="scss" scoped>
.main-title{
font-size: 40px;
}
</style>