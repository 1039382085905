var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "header",
    { staticClass: "header header-4 header-intro-clearance bg-white" },
    [
      _c("div", { staticClass: "header-top bg-white" }, [
        _c(
          "div",
          { class: _vm.isFullwidth ? "container-fluid" : "container" },
          [
            _c("div", { staticClass: "header-right py-1" }, [
              _c("ul", { staticClass: "top-menus" }, [
                _c("li", [
                  _c(
                    "ul",
                    {
                      staticClass:
                        "d-flex justify-content-center align-items-center"
                    },
                    [
                      !_vm.isLoggedIn
                        ? _c("li", [
                            _c("a", [
                              _c(
                                "span",
                                {
                                  staticClass: "mr-2 text-dark langChaner",
                                  attrs: { href: "#signin-modal" },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      return _vm.openSignInModal.apply(
                                        null,
                                        arguments
                                      )
                                    }
                                  }
                                },
                                [_vm._v(_vm._s(_vm.$t("login.login")))]
                              ),
                              _vm._v(
                                "\n                                    |\n                                    "
                              ),
                              _c(
                                "span",
                                {
                                  staticClass: "ml-2 text-dark langChaner",
                                  attrs: { href: "#signin-modal " },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      return _vm.openSignUpModal.apply(
                                        null,
                                        arguments
                                      )
                                    }
                                  }
                                },
                                [_vm._v(_vm._s(_vm.$t("login.register")))]
                              )
                            ])
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.isLoggedIn
                        ? _c("li", [
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm.$t("login.welcome")) +
                                  " ,\n                                    "
                              ),
                              _c("b", [_vm._v(_vm._s(_vm.userName))])
                            ])
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.isLoggedIn
                        ? _c("li", [
                            _c("button", [
                              _c("i", { staticClass: "icon-user" }),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  staticClass: "mr-2 text-dark",
                                  attrs: { href: "#signin-modal" },
                                  on: { click: _vm.logout }
                                },
                                [_vm._v(_vm._s(_vm.$t("login.logout")))]
                              )
                            ])
                          ])
                        : _vm._e()
                    ]
                  )
                ])
              ]),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "mobile-menu-toggler",
                  on: { click: _vm.openMobileMenu }
                },
                [
                  _c("span", { staticClass: "sr-only" }, [
                    _vm._v("Toggle mobile menu")
                  ]),
                  _vm._v(" "),
                  _c("i", { staticClass: "icon-bars" })
                ]
              )
            ])
          ]
        )
      ]),
      _vm._v(" "),
      _c("sticky-header", [
        _c("div", { staticClass: "header-bottom sticky-header pt-3" }, [
          _c(
            "div",
            { class: _vm.isFullwidth ? "container-fluid" : "container" },
            [
              _c(
                "div",
                { staticClass: "header-left" },
                [
                  _c(
                    "nuxt-link",
                    { staticClass: "logo", attrs: { to: _vm.localePath("/") } },
                    [
                      _c("img", {
                        directives: [
                          {
                            name: "lazy",
                            rawName: "v-lazy",
                            value:
                              "./images/home/WhatsApp Image 2021-12-08 at 9.57.02 AM.png",
                            expression:
                              "\n                                './images/home/WhatsApp Image 2021-12-08 at 9.57.02 AM.png'\n                            "
                          }
                        ],
                        staticClass: "bg-transparent",
                        attrs: {
                          alt: "royal-bee Logo",
                          width: "105",
                          height: "27"
                        }
                      })
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "header-center" }, [_c("main-menu")], 1),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "header-right" },
                [
                  _c("profile"),
                  _vm._v(" "),
                  _c("wishlist-menu"),
                  _vm._v(" "),
                  _c("cart-menu")
                ],
                1
              )
            ]
          )
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }