var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "header-search header-search-extended mr-0 header-search-visible d-flex justify-content-center search-blog"
    },
    [
      _c(
        "form",
        {
          attrs: { action: "#", method: "get" },
          on: {
            click: function($event) {
              $event.stopPropagation()
              return _vm.showSearchForm.apply(null, arguments)
            },
            submit: function($event) {
              $event.preventDefault()
              return _vm.submitSearchForm.apply(null, arguments)
            }
          }
        },
        [
          _c(
            "div",
            {
              staticClass:
                "header-search-wrapper search-wrapper-wide header-search-wrapper2"
            },
            [
              _c("label", { staticClass: "sr-only", attrs: { for: "q" } }, [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.$t("blog.search")) +
                    "\n            "
                )
              ]),
              _vm._v(" "),
              _vm._m(0),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.searchPlace,
                    expression: "searchPlace"
                  }
                ],
                staticClass: "form-control",
                attrs: {
                  type: "text",
                  placeholder: _vm.$t("blog.search"),
                  required: ""
                },
                domProps: { value: _vm.searchPlace },
                on: {
                  input: [
                    function($event) {
                      if ($event.target.composing) return
                      _vm.searchPlace = $event.target.value
                    },
                    _vm.searchPlaces
                  ]
                }
              })
            ]
          )
        ]
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("button", { staticClass: "btn", attrs: { type: "submit" } }, [
      _c("i", { staticClass: "icon-search" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }