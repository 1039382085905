<template>
    <div>
        <!-- brands  -->
        <template v-if="!loading">
            <div class="col-9 m-auto skeleton-body skel-shop-products">
                <template>
                    <div
                        class="skel-pro skel-pro-list"
                        v-for="item in 1"
                        :key="item"
                    ></div>
                </template>
            </div>
        </template>
        <div class="" v-else>
            <section class="lover-brands text-center" v-if="brands.length > 0">
                <div class="lover-brands-holder">
                    <div class="mb-5">
                        <h2 class="h1 text-gray my-3 Offers">
                            {{ $t('brands.title') }}
                        </h2>
                        <hr class="divider1" />
                    </div>
                    <div class="container">
                        <div
                            class="
                                swiper-carousel
                                vendors-slider
                                swiper-2
                                mb-5
                                mt-5
                            "
                        >
                            <div v-swiper:swiper8="carouselSetting8">
                                <div class="swiper-wrapper">
                                    <div
                                        class="swiper-slide"
                                        v-for="(brand, index) in brands"
                                        :key="index"
                                    >
                                        <nuxt-link
                                            :to="
                                                `/shop/sidebar/advancedBrand?brand=${brand.id}`
                                            "
                                        >
                                            <img
                                                v-if="brand.image"
                                                v-lazy="brand.image"
                                                class="bg-transparent"
                                                alt="Alf Logo"
                                            />
                                            <!-- <img
                                            v-else
                                            v-lazy="
                                                './images/Alf/alf/loved/Group 1277.png'
                                            "
                                            class="bg-transparent"
                                            alt="Alf Logo"
                                        /> -->
                                            <p
                                                class="text-dark"
                                                v-if="brand.name"
                                            >
                                                {{ brand.name }}
                                            </p>
                                        </nuxt-link>
                                    </div>
                                </div>
                            </div>
                            <div class="swiper-nav">
                                <div class="swiper-next">
                                    <i class="icon-angle-right"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import axios from 'axios';
import {
    carouselSettingSingle,
    carouselSettingDefault
} from '~/utilities/carousel';
import Repository, { baseUrl } from '~/repositories/repository.js';
export default {
    data: function() {
        return {
            carouselSetting8: {
                ...carouselSettingDefault,
                slidesPerView: 6,
                spaceBetween: 30,
                navigation: {
                    nextEl: '.vendors-slider .swiper-next',
                    prevEl: '.vendors-slider .swiper-prev'
                },
                breakpoints: {
                    992: {
                        slidesPerView: 4
                    },
                    768: {
                        slidesPerView: 2,
                        navigation: true
                    },
                    576: {
                        slidesPerView: 2,
                        navigation: true
                    },
                    480: {
                        slidesPerView: 2,
                        navigation: true
                    }
                }
            },
            brands: [],
            loading: false
        };
    },
    methods: {
        getData: async function() {
            let brands = `${baseUrl}/vendors_logo`;
            await Repository.get(brands).then(resp => {
                
                if (resp.data.code == 200) {
                    this.loading = true;
                }
                this.brands = resp.data.brands;
                // console.log('brands', resp);
            });
        }
    },
    mounted() {
        this.getData();
    }
};
</script>

<style></style>
