var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      !_vm.loading
        ? [
            _c(
              "div",
              { staticClass: "col-9 m-auto skeleton-body skel-shop-products" },
              [
                _vm._l(1, function(item) {
                  return _c("div", {
                    key: item,
                    staticClass: "skel-pro skel-pro-list"
                  })
                })
              ],
              2
            )
          ]
        : _c("div", {}, [
            _c(
              "section",
              { staticClass: "eid-fashion" },
              _vm._l(_vm.banners.sixth, function(sixth) {
                return _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "lazy",
                        rawName: "v-lazy:background-image",
                        value: sixth.background_image,
                        expression: "sixth.background_image",
                        arg: "background-image"
                      }
                    ],
                    key: sixth.id,
                    staticClass: "eid-fashion-holder"
                  },
                  [
                    _vm.banners.sixth.length > 0
                      ? _c("div", { staticClass: "container" }, [
                          _c("div", { staticClass: "row" }, [
                            _c(
                              "div",
                              { staticClass: "col-md-6 col-sm-12 eid-text" },
                              [
                                _c("h2", { staticClass: "hug text-white" }, [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(sixth.title) +
                                      "\n                            "
                                  )
                                ]),
                                _vm._v(" "),
                                _c(
                                  "p",
                                  { staticClass: "text-break text-white" },
                                  [
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(sixth.description) +
                                        "\n                            "
                                    )
                                  ]
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "col-md-6 col-sm-12" }, [
                              _c("img", {
                                directives: [
                                  {
                                    name: "lazy",
                                    rawName: "v-lazy",
                                    value: sixth.primary_image,
                                    expression: "sixth.primary_image"
                                  }
                                ],
                                staticClass: "bg-transparent",
                                attrs: { alt: "Alf Logo" }
                              })
                            ])
                          ])
                        ])
                      : _vm._e()
                  ]
                )
              }),
              0
            )
          ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }