var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("main", { staticClass: "main" }, [
    _c("nav", { staticClass: "breadcrumb-nav border-0 mb-0" }, [
      _c("div", { staticClass: "container" }, [
        _c("ol", { staticClass: "breadcrumb" }, [
          _c(
            "li",
            { staticClass: "breadcrumb-item" },
            [_c("nuxt-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
            1
          ),
          _vm._v(" "),
          _c("li", { staticClass: "breadcrumb-item active" }, [_vm._v("404")])
        ])
      ])
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "lazy",
            rawName: "v-lazy:background-image",
            value: "./images/backgrounds/error-bg.jpg",
            expression: "'./images/backgrounds/error-bg.jpg'",
            arg: "background-image"
          }
        ],
        staticClass: "error-content text-center"
      },
      [
        _c(
          "div",
          { staticClass: "container" },
          [
            _c("h1", { staticClass: "error-title" }, [_vm._v("Error 404")]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "We are sorry, the page you've requested is not available."
              )
            ]),
            _vm._v(" "),
            _c(
              "nuxt-link",
              {
                staticClass: "btn btn-outline-primary-2 btn-minwidth-lg",
                attrs: { to: "/" }
              },
              [
                _c("span", [_vm._v("BACK TO HOMEPAGE")]),
                _vm._v(" "),
                _c("i", { staticClass: "icon-long-arrow-right" })
              ]
            )
          ],
          1
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }