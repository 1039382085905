var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "count-wrapper" },
    [
      _c("span", { staticClass: "count" }, [
        _vm._v(_vm._s(Math.ceil(_vm.current)))
      ]),
      _vm._v(" "),
      _vm.finished ? _vm._t("default") : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }