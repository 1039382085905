<template>
    <div class="wishlist" v-if="isLoggedIn">
        <nuxt-link :to="localePath('/shop/wishlist')" :title="$t('payment.wishlist')">
            <div class="icon">
                <!-- <i class="icon-heart-o"></i> -->
                <img
                    v-lazy="'./images/Alf/whishlist.png'"
                    class="bg-transparent"
                    alt="Molla Logo"
                    width="100%"
                    height="100%"
                />
                <!-- <img
                    v-lazy="'./images/Alf/whishlist.png'"
                    class="bg-transparent"
                    alt="Molla Logo"
                /> -->
                <!-- <span class="wishlist-count badge">{{ wishlistQty }}</span> -->
                <span class="wishlist-count badge">{{ myWishLength }}</span>
            </div>
            <!-- <p>Wishlist</p> -->
        </nuxt-link>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import axios from 'axios';
import { baseUrl } from '~/repositories/repository.js';
export default {
    computed: {
        ...mapGetters('wishlist', ['wishlistQty']),
         isLoggedIn() {
            return this.$store.getters.isLoggedIn;
        },
    },
    methods: {
        ...mapActions('wishlist', ['removeFromWishlist', 'moveToCart']),
        getWishlist() {
            let token = sessionStorage.getItem('token');
            axios
                .post(`${this.baseUrl}/wish`, {
                    api_token: token,
                })
                .then((resp) => {
                    this.wishlists = resp.data.products;

                    // console.log("myWishLength" , resp);
                    if (resp.data.code == 200) {
                        this.myWishLength = this.wishlists.length;
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        },
    },
    created() {
        this.getWishlist();
    },
    data() {
        return {
            myWishLength: 0,
            wishlists: [],
            baseUrl: baseUrl,
        };
    },
    updated() {
        this.getWishlist();
    },
};
</script>