import axios from 'axios'
export default function ( { $axios, redirect } ) {
    $axios.onError( error => {
        const code = parseInt( error.response && error.response.status );
        if ( code === 400 ) {
            redirect( '/pages/404' );
        }
    } );
    axios.create({
        baseURL: process.env.baseUrl
      })
}

