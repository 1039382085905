var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("article", { staticClass: "entry entry-display" }, [
    _c(
      "figure",
      { staticClass: "entry-media" },
      [
        _c(
          "nuxt-link",
          { attrs: { to: "/blog/single/default/" + _vm.blog.slug } },
          [
            _c("img", {
              directives: [
                {
                  name: "lazy",
                  rawName: "v-lazy",
                  value: `${_vm.baseUrl}${_vm.blog.image[0].url}`,
                  expression: "`${baseUrl}${blog.image[0].url}`"
                }
              ],
              attrs: {
                alt: "blog",
                width: _vm.blog.image[0].width,
                height: _vm.blog.image[0].height
              }
            })
          ]
        )
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "entry-body text-center" }, [
      _c(
        "div",
        { staticClass: "entry-meta" },
        [
          _c(
            "nuxt-link",
            { attrs: { to: "/blog/single/default/" + _vm.blog.slug } },
            [_vm._v(_vm._s(_vm.date))]
          ),
          _vm._v(", \n\t\t\t"),
          _c(
            "nuxt-link",
            { attrs: { to: "/blog/single/default/" + _vm.blog.slug } },
            [_vm._v(_vm._s(_vm.blog.comments) + " Comments")]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "h2",
        { staticClass: "entry-title" },
        [
          _c(
            "nuxt-link",
            { attrs: { to: "/blog/single/default/" + _vm.blog.slug } },
            [_vm._v(_vm._s(_vm.blog.title))]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "entry-content" },
        [
          _c(
            "nuxt-link",
            {
              staticClass: "read-more",
              attrs: { to: "/blog/single/default/" + _vm.blog.slug }
            },
            [_vm._v("Continue Reading")]
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }