var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ul",
    { staticClass: "nav" },
    _vm._l(_vm.tabs, function(tab, index) {
      return _c("li", { key: index, staticClass: "nav-item" }, [
        _c(
          "a",
          {
            staticClass: "nav-link",
            class: { "active bg-white": tab.active },
            attrs: { href: _vm.getUrl(tab) },
            on: {
              click: function($event) {
                $event.preventDefault()
                return _vm.tabClick(tab)
              }
            }
          },
          [_vm._v(_vm._s(tab.title))]
        )
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }