var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "nav",
      { staticClass: "main-nav" },
      [
        _c("ul", { staticClass: "menu sf-arrows" }, [
          _c(
            "li",
            {
              staticClass: "megamenu-container",
              class: { active: _vm.current == "/" }
            },
            [
              _c("nuxt-link", { attrs: { to: _vm.localePath("/") } }, [
                _vm._v("\n                    " + _vm._s(_vm.$t("nav.home")))
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            { class: { active: _vm.current == "shop" } },
            [
              _c(
                "nuxt-link",
                { attrs: { to: _vm.localePath("/shop/sidebar/list") } },
                [_vm._v(_vm._s(_vm.$t("nav.products")))]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            { class: { active: _vm.current == "about" } },
            [
              _c(
                "nuxt-link",
                { attrs: { to: _vm.localePath("/pages/aboutAlf") } },
                [_vm._v(_vm._s(_vm.$t("nav.about")))]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            { class: { active: _vm.current == "blog" } },
            [
              _c(
                "nuxt-link",
                { attrs: { to: _vm.localePath("/blog/classic") } },
                [_vm._v(_vm._s(_vm.$t("nav.blog")))]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            { class: { active: _vm.current == "contact" } },
            [
              _c(
                "nuxt-link",
                { attrs: { to: _vm.localePath("/pages/contact") } },
                [_vm._v(_vm._s(_vm.$t("nav.contactUs")))]
              )
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("header-search")
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }