var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "checkout-discount" }, [
      _c("form", [
        _c(
          "label",
          {
            staticClass: "text-truncate",
            attrs: { for: "checkout-discount-input" }
          },
          [
            _vm._v(
              "\n                " +
                _vm._s(_vm.$t("payment.coupon")) +
                "\n                "
            )
          ]
        ),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.promoCodeInput,
              expression: "promoCodeInput"
            }
          ],
          staticClass: "form-control",
          attrs: {
            type: "text",
            required: "",
            id: "checkout-discount-input",
            placeholder: _vm.$t("payment.couponPlaceHolder")
          },
          domProps: { value: _vm.promoCodeInput },
          on: {
            click: function($event) {
              return _vm.clickCoupon($event)
            },
            input: function($event) {
              if ($event.target.composing) return
              _vm.promoCodeInput = $event.target.value
            }
          }
        }),
        _vm._v(" "),
        _c("div", { staticClass: "input-group-append" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-outline-primary-2",
              attrs: { type: "submit" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.checkPromo.apply(null, arguments)
                }
              }
            },
            [_c("i", { staticClass: "icon-long-arrow-right" })]
          )
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-8 py-3" }, [
          _vm.successPromo
            ? _c("div", { staticClass: "alert aler-success" }, [
                _vm._v(
                  "\n                    " +
                    _vm._s(_vm.promoCodeMessage) +
                    "\n                "
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.errorPromo
            ? _c("div", { staticClass: "alert aler-danger" }, [
                _vm._v(
                  "\n                    " +
                    _vm._s(_vm.promoCodeMessage) +
                    "\n                "
                )
              ])
            : _vm._e()
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }