<template>
    <div>
        <div class="product-details-quantity">
            <div class="input-group input-spinner">
                <div class="input-group-prepend">
                    <button
                        style="min-width: 26px"
                        class="btn btn-decrement btn-spinner"
                        @click.prevent="decrement"
                        type="button"
                    >
                        <i class="icon-minus"></i>
                    </button>
                </div>
                <input
                    type="text"
                    class="form-control text-center"
                    required
                    placeholder
                    v-model="current"
                />

                <div class="input-group-append">
                    <button
                        style="min-width: 26px"
                        class="btn btn-increment btn-spinner"
                        @click.prevent="increment"
                        type="button"
                    >
                        <i class="icon-plus"></i>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import axios from 'axios';
import Repositpry , { baseUrl } from '~/repositories/repository.js';

export default {
    props: ['product'],
    data: function () {
        return {
            current: this.product.quantity ? this.product.quantity : 1,
        };
    },
    watch: {
        product: function () {
            this.current = this.product.quantity ? this.product.quantity : 1;
        },
    },
    methods: {
        ...mapActions('cart', ['removeFromCart']),
        ...mapActions('cart', ['updateCart','silentUpdate']),
        ...mapActions('cart', ['addToCart', 'reduceCart']),
        ...mapActions('wishlist', ['addToWishlist']),
        increment: function () {
            if (
                this.product.total_quantit &&
                this.current >= this.product.total_quantity
            )
                return;
            this.current++;
            this.editQuantity()
            // this.$emit('change-qty', this.current, this.product);
        },

        decrement: function () {
            if (this.current > 1) {
                this.current--;
                // this.$emit('change-qty', this.current, this.product);
            }
            this.editQuantity()
        },
        editQuantity() {
            let token = sessionStorage.getItem('token');
            axios
                .post(
                    `${baseUrl}/quantity/edit`,
                    {
                        api_token: token,
                        id: this.product.order_id,
                        quantity:this.current
                    }
                )
                .then((resp) => {
                    console.log("quntity",resp);
                })
                .catch((err) => {
                    console.log(err);
                });
        },
    },
    computed: {
        ...mapGetters('cart', ['cartList', 'priceTotal', 'qtyTotal']),
        ...mapGetters('cart', ['canAddToCart']),
        ...mapGetters('wishlist', ['isInWishlist']),
        ...mapGetters('compare', ['isInCompare']),
    },
};
</script>

<style>
</style>