<template>
    <div>
        <div class="places-holder text-left">
            <div class="row">
                <div class="col-12">
                    <figure class="product-media">
                        <span
                            class="product-label label-new"
                            v-if="brand.new"
                            >{{ $t('product.new') }}</span
                        >
                        <span
                            class="product-label label-sale"
                            v-if="
                                brand.sale_price ||
                                    brand.price != brand.price_after_discount
                            "
                            >{{ $t('product.sale') }}</span
                        >
                        <span
                            class="product-label label-top"
                            v-if="brand.top"
                            >{{ $t('product.top') }}</span
                        >
                        <span
                            class="product-label label-out"
                            v-if="brand.stock === 0"
                            >{{ $t('product.outOfStock') }}</span
                        >

                        <nuxt-link
                            :to="localePath('/product/default/' + brand.id)"
                        >
                            <img
                                v-lazy="brand.main_image"
                                alt="brand"
                                :width="brand.main_image"
                                :height="brand.main_image"
                                v-if="brand.main_image"
                                class="w-100 h-100"
                            />
                            <img
                                :src="brand.main_image"
                                alt="brand"
                                :width="brand.main_image"
                                :height="brand.main_image"
                                class="brand-image-hover"
                                v-else
                            />
                        </nuxt-link>
                    </figure>
                </div>
                <div
                    class="
                        col-12
                        d-flex
                        justify-content-center
                        align-items-center
                        flex-column
                    "
                >
                    <div class="product-body product-action-inner">
                        <div class="row">
                            <div class="col-md-6 col-sm-12">
                                <div class="product-info1">
                                    <h3 class="product-title">
                                        <nuxt-link
                                            :to="
                                                localePath(
                                                    '/product/default/' +
                                                        brand.id
                                                )
                                            "
                                            >{{ brand.name }}</nuxt-link
                                        >
                                    </h3>
                                </div>
                            </div>

                            <div class="col-md-6 col-sm-12">
                                <div class="product-info2">
                                    <div
                                        class="product-price"
                                        v-if="brand.stock == 0"
                                        key="outPrice"
                                    >
                                        <span class="out-price">{{
                                            $t('product.outOfStock')
                                        }}</span>
                                    </div>

                                    <template v-else>
                                        <div
                                            class="
                                                product-price
                                                d-flex
                                                flex-column
                                                justify-content-start
                                            "
                                        >
                                            <p
                                                class="
                                                    new-price
                                                    lead
                                                    text-primary
                                                    mr-0
                                                "
                                            >
                                                <span class=""
                                                    >{{ $t('product.price') }} :
                                                    {{
                                                        brand.price_after_discount
                                                    }}</span
                                                >
                                            </p>

                                            <p
                                                class="old-price lead"
                                                v-if="
                                                    brand.price !=
                                                        brand.price_after_discount
                                                "
                                            >
                                                <del class="text-danger"
                                                    >{{ $t('product.price') }} :
                                                    {{ brand.price }}</del
                                                >
                                            </p>
                                        </div>
                                    </template>
                                </div>
                            </div>
                            
                            <!-- <div class="product-content" v-else>
                                <p>
                                    {{$t('product.desc')}}
                                </p>
                            </div> -->
                        </div>
                    </div>
                    <div class="product-list-action">
                        <div class="product-action2 d-flex">
                         

                            <!-- <button
                                title="Add to cart"
                                class="btn-product btn-cart"
                                :class="{
                                    'btn-disabled': !canAddToCart(product, 1),
                                }"
                                @click.prevent="addToCart({ product: product })"
                            >
                               
                            </button> -->

                            <!-- <nuxt-link
                                :title="$t('product.inWishlist')"
                                :to="localePath('/shop/wishlist')"
                                class="
                                    btn-product btn-wishlist
                                    added-to-wishlist
                                "
                                v-if="isInWishlist(product)"
                                key="inWishlist"
                            >
                               
                            </nuxt-link> -->
                            <!-- <a
                                :title="$t('product.addToWishlist')"
                                class="btn-product btn-wishlist"
                                @click.prevent="
                                    addToWishlist({ product: product })
                                "
                                v-else
                                key="notInWishlist"
                            >
                             
                            </a> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { baseUrl } from '~/repositories/repository';
import { mapGetters, mapActions } from 'vuex';
import axios from 'axios';
export default {
    props: ['brand'],
    data() {
        return {
            baseUrl: baseUrl,
            maxPrice: 0
        };
    },
    created: function() {
        console.log('my place', this.brand.new);
    }
};
</script>

<style lang="scss" scoped>
.places-price {
    display: block;
}
.places-body {
    width: 100% !important;
}
button,
.btn-places {
    border: none !important;
    padding: 15px;
    box-shadow: 0px 0px 7px 3px #e3e3e3 !important;
    border-radius: 50%;
    line-height: 10px;
    width: 20px;
    height: 20px;
    margin: 0 15px;
}
button:before,
.btn-places:before {
    padding: 0;
    margin: 0;
}
.featured .feature_image {
    height: 280px;
    border-radius: 20px;
}
.myWhitlist {
    background: #fcb932;
    color: #fff;
    padding: 5px;
    border-radius: 5px;
}
</style>
