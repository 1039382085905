var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "products mb-3" },
    [
      _vm.places.length === 0 && _vm.loaded
        ? _c("p", { staticClass: "no-results" }, [
            _vm._v("\n        No products matching your selection.\n    ")
          ])
        : [
            _c(
              "div",
              [
                !_vm.loaded
                  ? _vm._l(_vm.fakeArray, function(item) {
                      return _c("div", {
                        key: item,
                        staticClass: "skel-pro skel-pro-list"
                      })
                    })
                  : [
                      _c(
                        "div",
                        { staticClass: "row" },
                        _vm._l(_vm.places, function(place, index) {
                          return _c("alf-places", {
                            key: index,
                            staticClass: "col-md-6 col-sm-12",
                            attrs: { place: place }
                          })
                        }),
                        1
                      )
                    ]
              ],
              2
            )
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }