<template>
    <div>
        <div class="places-holder text-left">
            <section class="test featured">
                
                <div class="swiper-slide bg-transparent">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-4 col-sm-12 p-2">
                                <div class="card-holder whitlist-holder">
                                    <img
                                        :src="place.main_image"
                                        alt="image"
                                        class="
                                            img-fluid
                                            w-100
                                            bg-transparent
                                            feature_image
                                        "
                                    />
                                    <ul
                                        class="
                                            list-unstyled
                                            featured-card-info
                                            my-4
                                        "
                                    >
                                        <li class="name">
                                            <span v-if="place.rating === '0'">
                                                <i
                                                    class="far fa-star"
                                                    v-for="x in 5"
                                                    :key="x"
                                                ></i>
                                            </span>
                                            <span v-if="place.rating === '1'">
                                                <i
                                                    class="fas fa-star colored"
                                                ></i>
                                                <i
                                                    class="far fa-star"
                                                    v-for="x in 4"
                                                    :key="x"
                                                ></i>
                                            </span>
                                            <span v-if="place.rating === '2'">
                                                <i
                                                    class="fas fa-star colored"
                                                ></i>
                                                <i
                                                    class="fas fa-star colored"
                                                ></i>
                                                <i
                                                    class="far fa-star"
                                                    v-for="x in 3"
                                                    :key="x"
                                                ></i>
                                            </span>
                                            <span v-if="place.rating === '3'">
                                                <i
                                                    class="fas fa-star colored"
                                                ></i>
                                                <i
                                                    class="fas fa-star colored"
                                                ></i>
                                                <i
                                                    class="fas fa-star colored"
                                                ></i>

                                                <i
                                                    class="far fa-star"
                                                    v-for="x in 2"
                                                    :key="x"
                                                ></i>
                                            </span>
                                            <span v-if="place.rating === '3.5'">
                                                <i
                                                    class="fas fa-star colored"
                                                ></i>
                                                <i
                                                    class="fas fa-star colored"
                                                ></i>
                                                <i
                                                    class="fas fa-star colored"
                                                ></i>

                                                <i
                                                    class="
                                                        fas
                                                        fa-star-half-alt
                                                        colored
                                                    "
                                                ></i>
                                                <i
                                                    class="far fa-star"
                                                    v-for="x in 1"
                                                    :key="x"
                                                ></i>
                                            </span>
                                            <span v-if="place.rating === '4'">
                                                <i
                                                    class="fas fa-star colored"
                                                ></i>
                                                <i
                                                    class="fas fa-star colored"
                                                ></i>
                                                <i
                                                    class="fas fa-star colored"
                                                ></i>
                                                <i
                                                    class="fas fa-star colored"
                                                ></i>
                                                <i class="far fa-star"></i>
                                            </span>
                                            <span v-if="place.rating === '5'">
                                                <i
                                                    class="fas fa-star colored"
                                                    v-for="x in 5"
                                                    :key="x"
                                                ></i>
                                            </span>
                                        </li>
                                    </ul>
                                    <div class="hospital-name">
                                        <ul
                                            class="
                                                list-unstyled
                                                d-flex
                                                justify-content-between
                                            "
                                        >
                                            <nuxt-link to="">
                                                <li class="mb-2">
                                                    <nuxt-link
                                                        class="
                                                            hospital-name
                                                            mb-2
                                                            text-dimied
                                                        "
                                                        :to="`/pages/places/${place.id}`"
                                                    >
                                                        {{ place.name }}
                                                    </nuxt-link>
                                                </li>
                                                <li >
                                                    <nuxt-link
                                                        class="hospital-name"
                                                        :to="`/pages/places/${place.id}`"
                                                    >
                                                        {{ place.brief }}
                                                    </nuxt-link>
                                                </li>
                                            </nuxt-link>
                                            <li v-if="place.premium == 1">
                                                <img
                                                    v-lazy="
                                                        './images/Alf/alf/places/premium.png'
                                                    "
                                                    alt=""
                                                    class="bg-transparent"
                                                />
                                            </li>
                                            <li v-if="place.verified == 1">
                                                <div class="check-icon">
                                                    <i class="icon-check"></i>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="d-flex justify-content-between">
                                        <div>
                                            <ul
                                                class="
                                                    list-unstyled
                                                    hospital-info
                                                "
                                            >
                                                <li v-if="place.phone">
                                                    {{ place.phone }}
                                                </li>
                                                <li v-else>no number</li>
                                            </ul>
                                        </div>
                                        <div>
                                            <div class="myWhitlist">
                                                <i
                                                    class="far fa-heart fa-2x"
                                                ></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
import { baseUrl } from '~/repositories/repository';
import { mapGetters, mapActions } from 'vuex';
import axios from 'axios';
export default {
    props: ['place'],
    data() {
        return {
            baseUrl: baseUrl,
            maxPrice: 0,
        };
    },
    // created: function () {
    //     console.log('my place', this.place);
    // },
};
</script>

<style lang="scss" scoped>
.places-price {
    display: block;
}
.places-body {
    width: 100% !important;
}
button,
.btn-places {
    border: none !important;
    padding: 15px;
    box-shadow: 0px 0px 7px 3px #e3e3e3 !important;
    border-radius: 50%;
    line-height: 10px;
    width: 20px;
    height: 20px;
    margin: 0 15px;
}
button:before,
.btn-places:before {
    padding: 0;
    margin: 0;
}
.featured .feature_image {
    height: 280px;
    border-radius: 20px;
}
.myWhitlist {
    background: #fcb932;
    color: #fff;
    padding: 5px;
    border-radius: 5px;
}
</style>