var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "product-details-quantity" }, [
    _c("div", { staticClass: "input-group input-spinner" }, [
      _c("div", { staticClass: "input-group-prepend" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-decrement btn-spinner",
            staticStyle: { "min-width": "26px" },
            attrs: { type: "button" },
            on: { click: _vm.decrement }
          },
          [_c("i", { staticClass: "icon-minus" })]
        )
      ]),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.current,
            expression: "current"
          }
        ],
        staticClass: "form-control text-center",
        attrs: { type: "text", required: "", placeholder: "" },
        domProps: { value: _vm.current },
        on: {
          change: function($event) {
            return _vm.setCurrent($event)
          },
          input: function($event) {
            if ($event.target.composing) return
            _vm.current = $event.target.value
          }
        }
      }),
      _vm._v(" "),
      _c("div", { staticClass: "input-group-append" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-increment btn-spinner",
            staticStyle: { "min-width": "26px" },
            attrs: { type: "button" },
            on: { click: _vm.increment }
          },
          [_c("i", { staticClass: "icon-plus" })]
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }