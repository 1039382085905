var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "products mb-3 content-overlay" }, [
    _vm.products.length === 0 && _vm.loaded
      ? _c("p", { staticClass: "no-results" }, [
          _vm._v("No products matching your selection.")
        ])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "row" },
      [
        !_vm.loaded
          ? _vm._l(_vm.fakeArray, function(item) {
              return _c("div", { key: item, class: _vm.cols }, [
                _c("div", { staticClass: "skel-pro" })
              ])
            })
          : _vm._l(_vm.products, function(product, index) {
              return _c(
                "div",
                { key: index, class: _vm.cols },
                [_c("product-ten", { attrs: { product: product } })],
                1
              )
            })
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }