<template>
    <div class="quickView-container m-4">
        <div class="quickView-content horizontal skeleton-body">
            <div
                class="row skel-pro-single skel-quickview m-0"
                :class="{ loaded: loaded }"
            >
                <div class="col-lg-6 p-0 pr-2">
                    <div class="skel-product-gallery"></div>
                    <div class="product-lg mb-1 position-relative">
                        <div class="swiper-carousel swiper-1">
                            <div
                                v-swiper:swiper1="carouselSetting"
                                v-on:slideChangeTransitionEnd="slideChange"
                            >
                                <div class="swiper-wrapper">
                                    <div class="swiper-slide" v-if="category.main_image">
                                        <img
                                            v-lazy="category.main_image"
                                            alt="product"
                                            :width="category.width"
                                            :height="category.height"
                                        />
                                    </div>
                                    <div class="swiper-slide" v-else>
                                        <img
                                            src="../../../static/images/Alf/unnamed.png"
                                            alt="product"
                                            :width="category.width"
                                            :height="category.height"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="product-sm row px-2">
                        <!-- <div
                            class="col-3 px-2"
                            v-for="(picture, index) in product.sm_pictures"
                            :key="index"
                        >
                            <a
                                class="carousel-dot h-100 d-block"
                                :class="{active: index == currentIndex}"
                                href="#"
                                @click.prevent="changePicture(index)"
                            >
                                <img
                                    v-lazy="picture.main_image"
                                    alt="dot"
                                    :width="picture.width"
                                    :height="picture.height"
                                />
                            </a>
                        </div> -->
                    </div>
                </div>
                <div
                    class="
                        col-lg-6
                        quickview-desc
                        pl-0 pl-lg-4
                        pr-0
                        mt-3 mt-lg-0
                    "
                >
                    <div class="entry-summary row">
                        <div class="col-md-12">
                            <div class="entry-summary1"></div>
                        </div>
                        <div class="col-md-12">
                            <div class="entry-summary2"></div>
                        </div>
                    </div>
                    <div class="product-summary pr-4">
                        name : {{ category.name }}
                    </div>
                    <div class="product-summary pr-4">
                        data : {{ category.updated_at }}
                    </div>
                </div>
            </div>
        </div>
        <button
            title="Close (Esc)"
            type="button"
            class="mfp-close"
            @click="$emit('close')"
        >
            <span>×</span>
        </button>
    </div>
</template>
<script>
export default {
    props: ['category'],
    data() {
        return {
            loaded: true,
        };
    },
};
</script>