<template>
    <div>
        <div class="product-holder text-left">
            <places-list
                :places="places"
                :per-page="perPage"
                :loaded="loaded"
            ></places-list>
            <br />
        </div>
    </div>
</template>

<script>
import PlacesList from '~/components/partial/shop/list/PlacesList';
export default {
    props: ['places'],
    components: {
        PlacesList,
    },
};
</script>

<style lang="scss" scoped>
.product-price {
    display: block;
}
.product-body {
    width: 100% !important;
}
button,
.btn-product {
    border: none !important;
    padding: 15px;
    box-shadow: 0px 0px 7px 3px #e3e3e3 !important;
    border-radius: 50%;
    line-height: 10px;
    width: 20px;
    height: 20px;
    margin: 0 15px;
}
button:before,
.btn-product:before {
    padding: 0;
    margin: 0;
}
</style>