<template>
    <div>
        <!-- our blog  -->
        <template v-if="!loading">
            <div class="col-9 m-auto skeleton-body skel-shop-products">
                <template>
                    <div
                        class="skel-pro skel-pro-list"
                        v-for="item in 1"
                        :key="item"
                    ></div>
                </template>
            </div>
        </template>
        <div class="" v-else>
            <section class="blog">
                <div class="mb-5 text-center">
                    <h2 class="h1 text-gray my-3 Offers">
                        {{ $t('homeBlog.title') }}
                    </h2>
                    <hr class="divider1" />
                </div>
                <div class="container">
                    <div class="row">
                        <div
                            :class="{ 'col-md-12': postaDeacrease.length <= 0 }"
                            class="col-md-6 col-sm-12 main-photo"
                            v-for="post in blogPosts"
                            :key="post.id"
                        >
                            <nuxt-link :to="`/blog/single/default/${post.id}`">
                                <img
                                    v-lazy="post.image"
                                    alt="blog cover"
                                    class="img-fluid bg-transparent m-auto"
                                />
                                <div class="data-holder text-center py-4">
                                    <h4 v-text="postsTitle"></h4>
                                    <ul class="list-unstyled d-flex">
                                        <li v-if="posts.writer">
                                            <span>
                                                {{ posts.writer }}
                                            </span>
                                        </li>
                                        <li class="d-flex">
                                            <span
                                                ><i class="icon-calendar"></i
                                            ></span>
                                            <span v-text="postsTime"></span>
                                        </li>
                                        <li v-if="posts.comments">
                                            <span
                                                ><i class="icon-comments"></i
                                            ></span>
                                            <span>
                                                {{ posts.comments }}
                                            </span>
                                        </li>
                                        <li class="d-flex">
                                            <span
                                                ><i class="icon-eye"></i
                                            ></span>
                                            <span v-text="postsView"></span>
                                        </li>
                                    </ul>
                                    <p class="text-dimied"></p>
                                    <br />
                                </div>
                            </nuxt-link>

                            <div class="button">
                                <nuxt-link
                                    style="font-weight: bold;
    float: right;
    font-size: 20px; color: #ffe607;"
                                    :to="localePath('/blog/classic')"
                                    class="btn read-more"
                                    >{{ $t('buttons.readMore') }}
                                </nuxt-link>
                            </div>
                        </div>
                        <div
                            class="col-md-6 col-sm-12"
                            v-if="postaDeacrease.length > 0"
                        >
                            <div class="blog-slides">
                                <h4>
                                    {{ $t('homeBlog.popularPosts') }}
                                </h4>
                                <span class="devider2"></span>

                                <div
                                    class="media"
                                    v-for="post in postaDeacrease"
                                    :key="post.id"
                                >
                                    <nuxt-link
                                        :to="`/blog/single/default/${post.id}`"
                                    >
                                        <div class="media-body">
                                            <h5 class="mt-0">
                                                {{ post.title }}
                                            </h5>
                                            <ul class="list-unstyled d-flex">
                                                <li v-if="post.writer">
                                                    <span>
                                                        {{ post.writer }}
                                                    </span>
                                                    <!-- <span v-else>Author</span> -->
                                                </li>
                                                <li class="d-flex">
                                                    <span
                                                        ><i
                                                            class="icon-calendar"
                                                        ></i
                                                    ></span>
                                                    <span>{{
                                                        post.created_at
                                                    }}</span>
                                                </li>
                                                <li v-if="post.comments">
                                                    <span
                                                        ><i
                                                            class="icon-comments"
                                                        ></i
                                                    ></span>
                                                    <span>
                                                        {{ post.comments }}
                                                    </span>
                                                </li>
                                                <li class="d-flex">
                                                    <span
                                                        ><i class="icon-eye"></i
                                                    ></span>
                                                    <span>{{
                                                        post.views
                                                    }}</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </nuxt-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
import Repository, { baseUrl } from '~/repositories/repository.js';

export default {
    data: function() {
        return {
            posts: [],
            postsImage: '',
            postsTime: '',
            postsView: '',
            postsTitle: '',
            postaDeacrease: [],
            loading: false,
            blogPosts: []
        };
    },
    methods: {
        getData: async function() {
            let popularPosts = `${baseUrl}/blog/all`;
            //blog
            await Repository.post(popularPosts).then(resp => {
                console.log('blog resp', resp);
                if (resp.data.code == 200) {
                    this.loading = true;
                }
                this.posts = resp.data.articles.data;
                this.blogPosts = resp.data.articles.data.slice(0, 1);
                this.postsImage = this.posts[0].image;
                this.postsTime = this.posts[0].time;
                this.postsView = this.posts[0].views;
                this.postsTitle = this.posts[0].title;
                this.postaDeacrease = this.posts.slice(1, 4);
                // console.log('postaDeacrease', this.postaDeacrease);
                // console.log('this.postaDeacrease',this.postaDeacrease)
            });
        }
    },
    mounted() {
        this.getData();
    }
};
</script>

<style></style>
