var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isLoggedIn
    ? _c(
        "div",
        { staticClass: "dropdown cart-dropdown" },
        [
          _c(
            "nuxt-link",
            {
              staticClass: "dropdown-toggle",
              attrs: { to: _vm.localePath("/shop/cart") }
            },
            [
              _c("div", { staticClass: "icon" }, [
                _c("img", {
                  directives: [
                    {
                      name: "lazy",
                      rawName: "v-lazy",
                      value: "./images/Alf/cart.png",
                      expression: "'./images/Alf/cart.png'"
                    }
                  ],
                  staticClass: "bg-transparent",
                  attrs: { alt: "Molla Logo", width: "100%", height: "100%" }
                }),
                _vm._v(" "),
                _c("span", { staticClass: "cart-count" }, [
                  _vm._v(_vm._s(_vm.cartListLength))
                ])
              ])
            ]
          ),
          _vm._v(" "),
          _vm.cartListLength > 0
            ? _c(
                "div",
                {
                  key: "hasCart",
                  class: {
                    "dropdown-menu dropdown-menu-right":
                      _vm.$i18n.locale == "en",
                    "dropdown-menu dropdown-menu-left": _vm.$i18n.locale == "ar"
                  }
                },
                [
                  _c(
                    "div",
                    { staticClass: "dropdown-cart-products" },
                    _vm._l(_vm.cartList, function(product) {
                      return _c(
                        "div",
                        { key: product.id, staticClass: "product" },
                        [
                          _c("div", { staticClass: "product-cart-details" }, [
                            _c(
                              "h4",
                              { staticClass: "product-title" },
                              [
                                _c(
                                  "nuxt-link",
                                  {
                                    attrs: {
                                      to: _vm.localePath(
                                        `/product/default/` + product.product_id
                                      )
                                    }
                                  },
                                  [_vm._v(_vm._s(product.product_name))]
                                )
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("span", { staticClass: "cart-product-info" }, [
                              _c("div", { staticClass: "row" }, [
                                _c("div", { staticClass: "col-1" }, [
                                  _c(
                                    "span",
                                    { staticClass: "cart-product-qty" },
                                    [_vm._v(_vm._s(product.quantity))]
                                  )
                                ]),
                                _vm._v(" "),
                                _vm._m(0, true),
                                _vm._v(" "),
                                _c("div", { staticClass: "col-8" }, [
                                  _c("span", [
                                    _vm._v(
                                      "\n                                    " +
                                        _vm._s(
                                          product.sale_price
                                            ? product.sale_price
                                            : product.discount
                                        ) +
                                        "\n                                "
                                    )
                                  ])
                                ])
                              ])
                            ])
                          ]),
                          _vm._v(" "),
                          _c(
                            "figure",
                            { staticClass: "product-image-container" },
                            [
                              _c(
                                "nuxt-link",
                                {
                                  staticClass: "product-image",
                                  attrs: {
                                    to: _vm.localePath(
                                      `/product/default/` + product.product_id
                                    )
                                  }
                                },
                                [
                                  product.main_image
                                    ? _c("img", {
                                        directives: [
                                          {
                                            name: "lazy",
                                            rawName: "v-lazy",
                                            value: product.main_image,
                                            expression: "product.main_image"
                                          }
                                        ],
                                        staticClass: "w-100 h-100",
                                        attrs: {
                                          alt: "product",
                                          width: 200,
                                          height: 70
                                        }
                                      })
                                    : _c("img", {
                                        staticClass: "w-100 h-100",
                                        attrs: {
                                          alt: "no product image",
                                          width: 200,
                                          height: 70
                                        }
                                      })
                                ]
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "a",
                            {
                              staticClass: "btn-remove",
                              attrs: { title: "Remove Product" },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.removeFromCart({
                                    product: product
                                  })
                                }
                              }
                            },
                            [_c("i", { staticClass: "icon-close" })]
                          )
                        ]
                      )
                    }),
                    0
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "dropdown-cart-total" }, [
                    _c("span", [
                      _vm._v(_vm._s(_vm.$t("header.total")) + " : ")
                    ]),
                    _vm._v(" "),
                    _vm.cartTotalPrice
                      ? _c("span", { staticClass: "cart-total-price" }, [
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.cartTotalPrice) +
                              "\n             "
                          )
                        ])
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "dropdown-cart-action" },
                    [
                      _c(
                        "nuxt-link",
                        {
                          staticClass: "btn btn-primary",
                          attrs: { to: _vm.localePath("/shop/cart") }
                        },
                        [_vm._v(_vm._s(_vm.$t("payment.viewCart")))]
                      ),
                      _vm._v(" "),
                      _c(
                        "nuxt-link",
                        {
                          staticClass: "btn btn-outline-primary-2",
                          attrs: { to: _vm.localePath("/shop/checkout") }
                        },
                        [
                          _c("span", [
                            _vm._v(_vm._s(_vm.$t("payment.checkout")))
                          ]),
                          _vm._v(" "),
                          _c("i", { staticClass: "icon-long-arrow-right" })
                        ]
                      )
                    ],
                    1
                  )
                ]
              )
            : _c(
                "div",
                {
                  key: "noCart",
                  class: {
                    "dropdown-menu dropdown-menu-right":
                      _vm.$i18n.locale == "en",
                    "dropdown-menu dropdown-menu-left": _vm.$i18n.locale == "ar"
                  }
                },
                [
                  _c("p", { staticClass: "text-center" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.$t("payment.cartNoData")) +
                        "\n        "
                    )
                  ])
                ]
              )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-1" }, [_c("span", [_vm._v("x")])])
  }
]
render._withStripped = true

export { render, staticRenderFns }