var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container mb-5" }, [
    _c("div", { staticClass: "row elements" }, [
      _c(
        "div",
        { staticClass: "col-xl-5col col-lg-4 col-md-3 col-6" },
        [
          _c(
            "nuxt-link",
            {
              staticClass: "element-type",
              attrs: { to: "/elements/accordions" }
            },
            [
              _c("div", { staticClass: "element" }, [
                _c("i", {
                  staticClass: "element-img",
                  staticStyle: {
                    "background-image": "url('./images/elements.png')"
                  }
                }),
                _vm._v(" "),
                _c("i", {
                  staticClass: "element-hover-img",
                  staticStyle: {
                    "background-image": "url('./images/elements.png')"
                  }
                }),
                _vm._v(" "),
                _c("p", [_vm._v("accordions")])
              ])
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-xl-5col col-lg-4 col-md-3 col-6" },
        [
          _c(
            "nuxt-link",
            { staticClass: "element-type", attrs: { to: "/elements/banners" } },
            [
              _c("div", { staticClass: "element" }, [
                _c("i", {
                  staticClass: "element-img",
                  staticStyle: {
                    "background-image": "url('./images/elements.png')"
                  }
                }),
                _vm._v(" "),
                _c("i", {
                  staticClass: "element-hover-img",
                  staticStyle: {
                    "background-image": "url('./images/elements.png')"
                  }
                }),
                _vm._v(" "),
                _c("p", [_vm._v("banners")])
              ])
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-xl-5col col-lg-4 col-md-3 col-6" },
        [
          _c(
            "nuxt-link",
            {
              staticClass: "element-type",
              attrs: { to: "/elements/blog-posts" }
            },
            [
              _c("div", { staticClass: "element" }, [
                _c("i", {
                  staticClass: "element-img",
                  staticStyle: {
                    "background-image": "url('./images/elements.png')"
                  }
                }),
                _vm._v(" "),
                _c("i", {
                  staticClass: "element-hover-img",
                  staticStyle: {
                    "background-image": "url('./images/elements.png')"
                  }
                }),
                _vm._v(" "),
                _c("p", [_vm._v("blog posts")])
              ])
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-xl-5col col-lg-4 col-md-3 col-6" },
        [
          _c(
            "nuxt-link",
            { staticClass: "element-type", attrs: { to: "/elements/buttons" } },
            [
              _c("div", { staticClass: "element" }, [
                _c("i", {
                  staticClass: "element-img",
                  staticStyle: {
                    "background-image": "url('./images/elements.png')"
                  }
                }),
                _vm._v(" "),
                _c("i", {
                  staticClass: "element-hover-img",
                  staticStyle: {
                    "background-image": "url('./images/elements.png')"
                  }
                }),
                _vm._v(" "),
                _c("p", [_vm._v("buttons")])
              ])
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-xl-5col col-lg-4 col-md-3 col-6" },
        [
          _c(
            "nuxt-link",
            { staticClass: "element-type", attrs: { to: "/elements/cta" } },
            [
              _c("div", { staticClass: "element" }, [
                _c("i", {
                  staticClass: "element-img",
                  staticStyle: {
                    "background-image": "url('./images/elements.png')"
                  }
                }),
                _vm._v(" "),
                _c("i", {
                  staticClass: "element-hover-img",
                  staticStyle: {
                    "background-image": "url('./images/elements.png')"
                  }
                }),
                _vm._v(" "),
                _c("p", [_vm._v("call to action")])
              ])
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-xl-5col col-lg-4 col-md-3 col-6" },
        [
          _c(
            "nuxt-link",
            {
              staticClass: "element-type",
              attrs: { to: "/elements/icon-boxes" }
            },
            [
              _c("div", { staticClass: "element" }, [
                _c("i", {
                  staticClass: "element-img",
                  staticStyle: {
                    "background-image": "url('./images/elements.png')"
                  }
                }),
                _vm._v(" "),
                _c("i", {
                  staticClass: "element-hover-img",
                  staticStyle: {
                    "background-image": "url('./images/elements.png')"
                  }
                }),
                _vm._v(" "),
                _c("p", [_vm._v("icon boxes")])
              ])
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-xl-5col col-lg-4 col-md-3 col-6" },
        [
          _c(
            "nuxt-link",
            {
              staticClass: "element-type",
              attrs: { to: "/elements/portfolio" }
            },
            [
              _c("div", { staticClass: "element" }, [
                _c("i", {
                  staticClass: "element-img",
                  staticStyle: {
                    "background-image": "url('./images/elements.png')"
                  }
                }),
                _vm._v(" "),
                _c("i", {
                  staticClass: "element-hover-img",
                  staticStyle: {
                    "background-image": "url('./images/elements.png')"
                  }
                }),
                _vm._v(" "),
                _c("p", [_vm._v("portfolio")])
              ])
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-xl-5col col-lg-4 col-md-3 col-6" },
        [
          _c(
            "nuxt-link",
            {
              staticClass: "element-type",
              attrs: { to: "/elements/categories" }
            },
            [
              _c("div", { staticClass: "element" }, [
                _c("i", {
                  staticClass: "element-img",
                  staticStyle: {
                    "background-image": "url('./images/elements.png')"
                  }
                }),
                _vm._v(" "),
                _c("i", {
                  staticClass: "element-hover-img",
                  staticStyle: {
                    "background-image": "url('./images/elements.png')"
                  }
                }),
                _vm._v(" "),
                _c("p", [_vm._v("product catigories")])
              ])
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-xl-5col col-lg-4 col-md-3 col-6" },
        [
          _c(
            "nuxt-link",
            {
              staticClass: "element-type",
              attrs: { to: "/elements/products" }
            },
            [
              _c("div", { staticClass: "element" }, [
                _c("i", {
                  staticClass: "element-img",
                  staticStyle: {
                    "background-image": "url('./images/elements.png')"
                  }
                }),
                _vm._v(" "),
                _c("i", {
                  staticClass: "element-hover-img",
                  staticStyle: {
                    "background-image": "url('./images/elements.png')"
                  }
                }),
                _vm._v(" "),
                _c("p", [_vm._v("products")])
              ])
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-xl-5col col-lg-4 col-md-3 col-6" },
        [
          _c(
            "nuxt-link",
            { staticClass: "element-type", attrs: { to: "/elements/tabs" } },
            [
              _c("div", { staticClass: "element" }, [
                _c("i", {
                  staticClass: "element-img",
                  staticStyle: {
                    "background-image": "url('./images/elements.png')"
                  }
                }),
                _vm._v(" "),
                _c("i", {
                  staticClass: "element-hover-img",
                  staticStyle: {
                    "background-image": "url('./images/elements.png')"
                  }
                }),
                _vm._v(" "),
                _c("p", [_vm._v("tabs")])
              ])
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-xl-5col col-lg-4 col-md-3 col-6" },
        [
          _c(
            "nuxt-link",
            {
              staticClass: "element-type",
              attrs: { to: "/elements/testimonials" }
            },
            [
              _c("div", { staticClass: "element" }, [
                _c("i", {
                  staticClass: "element-img",
                  staticStyle: {
                    "background-image": "url('./images/elements.png')"
                  }
                }),
                _vm._v(" "),
                _c("i", {
                  staticClass: "element-hover-img",
                  staticStyle: {
                    "background-image": "url('./images/elements.png')"
                  }
                }),
                _vm._v(" "),
                _c("p", [_vm._v("testimonials")])
              ])
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-xl-5col col-lg-4 col-md-3 col-6" },
        [
          _c(
            "nuxt-link",
            { staticClass: "element-type", attrs: { to: "/elements/titles" } },
            [
              _c("div", { staticClass: "element" }, [
                _c("i", {
                  staticClass: "element-img",
                  staticStyle: {
                    "background-image": "url('./images/elements.png')"
                  }
                }),
                _vm._v(" "),
                _c("i", {
                  staticClass: "element-hover-img",
                  staticStyle: {
                    "background-image": "url('./images/elements.png')"
                  }
                }),
                _vm._v(" "),
                _c("p", [_vm._v("titles")])
              ])
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-xl-5col col-lg-4 col-md-3 col-6" },
        [
          _c(
            "nuxt-link",
            {
              staticClass: "element-type",
              attrs: { to: "/elements/typography" }
            },
            [
              _c("div", { staticClass: "element" }, [
                _c("i", {
                  staticClass: "element-img",
                  staticStyle: {
                    "background-image": "url('./images/elements.png')"
                  }
                }),
                _vm._v(" "),
                _c("i", {
                  staticClass: "element-hover-img",
                  staticStyle: {
                    "background-image": "url('./images/elements.png')"
                  }
                }),
                _vm._v(" "),
                _c("p", [_vm._v("typography")])
              ])
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-xl-5col col-lg-4 col-md-3 col-6" },
        [
          _c(
            "nuxt-link",
            {
              staticClass: "element-type",
              attrs: { to: "/elements/video-banners" }
            },
            [
              _c("div", { staticClass: "element" }, [
                _c("i", {
                  staticClass: "element-img",
                  staticStyle: {
                    "background-image": "url('./images/elements.png')"
                  }
                }),
                _vm._v(" "),
                _c("i", {
                  staticClass: "element-hover-img",
                  staticStyle: {
                    "background-image": "url('./images/elements.png')"
                  }
                }),
                _vm._v(" "),
                _c("p", [_vm._v("video")])
              ])
            ]
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }