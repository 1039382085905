import { render, staticRenderFns } from "./searchProducts.vue?vue&type=template&id=9741b2ca&"
import script from "./searchProducts.vue?vue&type=script&lang=js&"
export * from "./searchProducts.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/html/royalbeeseg.com/Demo-4/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('9741b2ca')) {
      api.createRecord('9741b2ca', component.options)
    } else {
      api.reload('9741b2ca', component.options)
    }
    module.hot.accept("./searchProducts.vue?vue&type=template&id=9741b2ca&", function () {
      api.rerender('9741b2ca', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "components/alf/searchProducts.vue"
export default component.exports