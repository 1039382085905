<template>
    <div>
        <div class="checkout-discount">
            <form>
                <label for="checkout-discount-input" class="text-truncate">
                    {{$t('payment.coupon')}}
                    <!-- <span>Click here to enter your code</span> -->
                </label>
                <input
                    type="text"
                    class="form-control"
                    v-model="promoCodeInput"
                    required
                    id="checkout-discount-input"
                    @click="clickCoupon($event)"
                    :placeholder="$t('payment.couponPlaceHolder')"
                />
                <div class="input-group-append">
                    <button
                        class="btn btn-outline-primary-2"
                        type="submit"
                        @click.prevent="checkPromo"
                    >
                        <i class="icon-long-arrow-right"></i>
                    </button>
                </div>
            </form>
            <div class="row">
                <div class="col-8 py-3">
                    <div class="alert aler-success" v-if="successPromo">
                        {{ promoCodeMessage }}
                    </div>
                    <div class="alert aler-danger" v-if="errorPromo">
                        {{ promoCodeMessage }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { VueSlideToggle } from 'vue-slide-toggle';
import PageHeader from '~/components/elements/PageHeader';
import axios from 'axios';
import { baseUrl } from '~/repositories/repository';

export default {
    components: {
        VueSlideToggle,
        PageHeader,
    },
    data: function () {
        return {
            toggleState: [true, false, false, false, false],
            promoCode: '',
            promoCodeInput: '',
            successPromo: false,
            errorPromo: false,
            promoCodeMessage: '',
            baseUrl: baseUrl,
            beforePromo:'',
            afterPromo:'',
        };
    },
    computed: {
        ...mapGetters('cart', ['cartList', 'priceTotal', 'qtyTotal']),
    },
    methods: {
        updateCart: function () {
            this.updateCart(this.cartItems);
        },
        clickCoupon(event) {
            event.currentTarget.parentElement.querySelector('label').style =
                'opacity: 1';
        },
        changeToggle: function (index1) {
            this.toggleState = this.toggleState.reduce((acc, cur, index) => {
                if (index == index1) return [...acc, !cur];
                return [...acc, false];
            }, []);
        },
        submitProcess() {
            this.$router.replace('/shop/complete');
        },
        checkPromo() {
            let token = sessionStorage.getItem('token');
            axios
                .post(`${this.baseUrl}/checkPromo`, {
                    api_token: token,
                    code: this.promoCodeInput,
                })
                .then((resp) => {
                    // console.log(resp);
                    this.promoCode = resp.data.code;
                    this.promoCodeMessage = resp.data.message;
                    this.successPromo = true;
                    this.errorPromo = false;
                    this.beforePromo = resp.data.beforePromo;
                    console.log("this.beforePromo" , this.beforePromo);
                    this.afterPromo = resp.data.total
                })
                // .catch((err) => {
                //     console.log(err);
                //     this.errorPromo = true;
                //     this.successPromo = false;
                // });

            if (this.promoCodeInput === this.promoCode) {
                (this.successPromo = true), (this.errorPromo = false);
            } else {
                (this.successPromo = false), (this.errorPromo = true);
            }
        },
    },
};
</script>
<style lang="scss" scoped>
.checkout-discount label {
    position: relative !important;
}
.checkout-discount label {
    left: 0;
    top: 20px;
    width: 590 px;
    padding-left: 0;
    padding-right: 1.1 rem;
    font-weight: 400;
    transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    opacity: 1;
    transition: all 0.3s;
}
</style>