var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("section", { staticClass: "find-places" }, [
      _c("div", { staticClass: "container-fluid w-100" }, [
        _c("div", { staticClass: "position-relative text-center" }, [
          _c("div", { staticClass: "image col-md-12 mt-5" }, [
            _c("img", {
              directives: [
                {
                  name: "lazy",
                  rawName: "v-lazy",
                  value: "./images/Alf/alf/NewHospital.png",
                  expression: "'./images/Alf/alf/NewHospital.png'"
                }
              ],
              staticClass: "img-fluid bg-transparent w-60",
              attrs: { alt: "find-places" }
            })
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "col-md-4 col-sm-4 col-4 addcompany position-absolute"
            },
            [
              _c("h2", [
                _vm._v(
                  "\n                        " +
                    _vm._s(_vm.$t("findUs.title1")) +
                    "\n                    "
                )
              ]),
              _vm._v(" "),
              _c("h3", [
                _vm._v(
                  "\n                        " +
                    _vm._s(_vm.$t("findUs.title2")) +
                    "\n                    "
                )
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "text-dimied px-4" }, [
                _vm._v(_vm._s(_vm.$t("findUs.desc")))
              ])
            ]
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }