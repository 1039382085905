<template>
    <div class="dropdown cart-dropdown" v-if="isLoggedIn">
        <nuxt-link :to="localePath('/shop/cart')" class="dropdown-toggle">
            <div class="icon">
                <!-- <i class="icon-shopping-cart"></i> -->
                <img
                    v-lazy="'./images/Alf/cart.png'"
                    class="bg-transparent"
                    alt="Molla Logo"
                    width="100%"
                    height="100%"
                />
                <!-- <img
                    v-lazy="'./images/Alf/cart.png'"
                    class="bg-transparent"
                    alt="Molla Logo"
                /> -->
                <span class="cart-count" >{{
                    cartListLength
                }}</span>
                <!-- <span class="cart-count">{{ qtyTotal }}</span> -->
            </div>
            <!-- <p>Cart</p> -->
        </nuxt-link>

        <div
            :class="{
                'dropdown-menu dropdown-menu-right': $i18n.locale == 'en',
                'dropdown-menu dropdown-menu-left': $i18n.locale == 'ar',
            }"
            v-if="cartListLength > 0"
            key="hasCart"
        >
            <div class="dropdown-cart-products">
                <div
                    class="product"
                    v-for="product in cartList"
                    :key="product.id"
                >
                    <div class="product-cart-details">
                        <h4 class="product-title">
                            <nuxt-link
                                :to="
                                    localePath(
                                        `/product/default/` + product.product_id
                                    )
                                "
                                >{{ product.product_name }}</nuxt-link
                            >
                        </h4>

                        <span class="cart-product-info">
                            <div class="row">
                                <div class="col-1">
                                    <span class="cart-product-qty">{{
                                        product.quantity
                                    }}</span>
                                </div>
                                <div class="col-1">
                                    <span>x</span>
                                </div>
                                <div class="col-8">
                                   
                                     <span>
                                        {{
                                            product.sale_price
                                                ? product.sale_price
                                                : product.discount
                                        }}
                                    </span>
                                </div>
                            </div>
                        </span>
                    </div>

                    <figure class="product-image-container">
                        <nuxt-link
                            :to="
                                localePath(
                                    `/product/default/` + product.product_id
                                )
                            "
                            class="product-image"
                        >
                            <img
                                v-if="product.main_image"
                                v-lazy="product.main_image"
                                alt="product"
                                :width="200"
                                :height="70"
                                class="w-100 h-100"
                            />
                            <img
                                v-else
                                alt="no product image"
                                :width="200"
                                :height="70"
                                class="w-100 h-100"
                            />
                        </nuxt-link>
                    </figure>
                    <a
                        class="btn-remove"
                        title="Remove Product"
                        @click.prevent="removeFromCart({ product: product })"
                    >
                        <i class="icon-close"></i>
                    </a>
                </div>
            </div>

            <div class="dropdown-cart-total">
                <span>{{ $t('header.total') }} : </span>

                <span v-if="cartTotalPrice" class="cart-total-price">
                    {{ cartTotalPrice}}
                 </span>
            </div>

            <div class="dropdown-cart-action">
                <nuxt-link
                    :to="localePath('/shop/cart')"
                    class="btn btn-primary"
                    >{{ $t('payment.viewCart') }}</nuxt-link
                >
                <nuxt-link
                    :to="localePath('/shop/checkout')"
                    class="btn btn-outline-primary-2"
                >
                    <span>{{ $t('payment.checkout') }}</span>
                    <i class="icon-long-arrow-right"></i>
                </nuxt-link>
            </div>
        </div>
        <div
            :class="{
                'dropdown-menu dropdown-menu-right': $i18n.locale == 'en',
                'dropdown-menu dropdown-menu-left': $i18n.locale == 'ar',
            }"
            v-else
            key="noCart"
        >
            <p class="text-center">
                {{ $t('payment.cartNoData') }}
            </p>
        </div>
    </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import Repository, { baseUrl } from '~/repositories/repository.js';
import axios from 'axios';
export default {
    data: function () {
        return {
            cartList: [],
            cartListLength: 0,
        };
    },
    computed: {
        ...mapGetters('cart', ['priceTotal', 'qtyTotal']),
         isLoggedIn() {
            return this.$store.getters.isLoggedIn;
        },
    },
    methods: {
        ...mapActions('cart', ['removeFromCart']),
        getCartList: async function () {
            let token = sessionStorage.getItem('token');
            await Repository.post(`${baseUrl}/order`, {
                api_token: token,
            }).then((resp) => {
                if (resp.data.code === 200) {
                    console.log(resp);
                    this.cartTotalPrice = resp.data.total_price;
                    this.cartList = resp.data.products;
                    this.cartListLength = resp.data.products.length;
                }
            })
            .catch((err) => {
                console.log("cartList menu error" , err);
            });
        },
        // formatPrice(value) {
        //    return value.toFixed(2);
        // }
    },
    mounted() {
        this.getCartList();
    },
    props: ['product'],
    watch: {
        $route: function () {
            this.getCartList();
        },
    },
};
</script>