var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ul",
    { staticClass: "pagination" },
    [
      _c("li", { staticClass: "pagination-item" }, [
        _c(
          "button",
          {
            attrs: { type: "button", disabled: _vm.isInFirstPage },
            on: { click: _vm.onClickFirstPage }
          },
          [_vm._v("\n      First\n    ")]
        )
      ]),
      _vm._v(" "),
      _c("li", { staticClass: "pagination-item" }, [
        _c(
          "button",
          {
            attrs: { type: "button", disabled: _vm.isInFirstPage },
            on: { click: _vm.onClickPreviousPage }
          },
          [_vm._v("\n      Previous\n    ")]
        )
      ]),
      _vm._v(" "),
      _vm._v("\n\n  ...\n  "),
      _vm._v(" "),
      _vm._l(_vm.pages, function(page) {
        return _c("li", { key: page.name, staticClass: "pagination-item" }, [
          _c(
            "button",
            {
              class: { active: _vm.isPageActive(page.name) },
              attrs: { type: "button", disabled: page.isDisabled },
              on: {
                click: function($event) {
                  return _vm.onClickPage(page.name)
                }
              }
            },
            [_vm._v("\n      " + _vm._s(page.name) + "\n    ")]
          )
        ])
      }),
      _vm._v(" "),
      _vm._v("\n  ...\n\n  "),
      _vm._v(" "),
      _c("li", { staticClass: "pagination-item" }, [
        _c(
          "button",
          {
            attrs: { type: "button", disabled: _vm.isInLastPage },
            on: { click: _vm.onClickNextPage }
          },
          [_vm._v("\n      Next\n    ")]
        )
      ]),
      _vm._v(" "),
      _c("li", { staticClass: "pagination-item" }, [
        _c(
          "button",
          {
            attrs: { type: "button", disabled: _vm.isInLastPage },
            on: { click: _vm.onClickLastPage }
          },
          [_vm._v("\n      Last\n    ")]
        )
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }