<template>
    <div class="row">
        <div class="product-holder text-left">
            <div class="row">
                <div class="col-12">
                    <figure class="product-media">
                        <span class="product-label label-new" v-if="product.new"
                            >
                            {{$t('product.new')}}
                            </span
                        >
                        <span
                            class="product-label label-sale"
                            v-if="
                                product.sale_price ||
                                product.price != product.price_after_discount
                            "
                            >
                            {{$t('product.sale')}}
                            </span
                        >
                        <span class="product-label label-top" v-if="product.top"
                            >{{$t('product.top')}}</span
                        >
                        <span
                            class="product-label label-out"
                            v-if="product.stock === 0"
                            >{{$t('product.outOfStock')}}</span
                        >

                        <nuxt-link
                            :to="localePath('/product/default/' + product.id)"
                        >
                            <img
                                v-lazy="product.main_image"
                                alt="Product"
                                :width="product.main_image"
                                :height="product.main_image"
                                v-if="product.main_image"
                                class="w-100 h-100"
                            />
                            <img
                                :src="product.main_image"
                                alt="Product"
                                :width="product.main_image"
                                :height="product.main_image"
                                class="product-image-hover"
                                v-else
                            />
                        </nuxt-link>
                    </figure>
                </div>
                <div
                    class="
                        col-12
                        d-flex
                        justify-content-center
                        align-items-center
                        flex-column
                    "
                >
                    <div class="product-body product-action-inner">
                        <div class="row">
                            <div class="col-md-6 col-sm-12">
                                <div class="product-info1">
                                    <h3 class="product-title">
                                        <!-- <nuxt-link
                                :to="'/product/default/' + product.slug"
                                >{{ product.name }}</nuxt-link
                            > -->
                                        <nuxt-link
                                            :to="
                                                localePath(
                                                    '/product/default/' +
                                                        product.id
                                                )
                                            "
                                            >{{ product.name }}</nuxt-link
                                        >
                                    </h3>

                                   

                                    <!-- <div
                            class="product-nav product-nav-dots"
                            v-if="product.length > 0"
                        >
                            <div class="row no-gutters">
                                <a
                                    href="javascript:;"
                                    :style="{
                                        'background-color': item.color,
                                    }"
                                    v-for="(item, index) in product"
                                    :key="index"
                                >
                                    <span class="sr-only">Color name</span>
                                </a>
                            </div>
                        </div> -->
                                </div>
                            </div>

                            <div class="col-md-6 col-sm-12">
                                <div class="product-info2">
                                    <div
                                        class="product-price"
                                        v-if="product.stock == 0"
                                        key="outPrice"
                                    >
                                        <span class="out-price"
                                            >{{$t('product.outOfStock')}}</span
                                        >
                                    </div>

                                    <template v-else>
                                        <div
                                            class="
                                                product-price
                                                d-flex
                                                flex-column
                                                justify-content-start
                                            "
                                        >
                                            <p
                                                class="
                                                    new-price
                                                    lead
                                                    text-primary
                                                    mr-0
                                                "
                                            >
                                                <span class=""
                                                    >{{$t('payment.price')}} :
                                                    {{
                                                        product.price_after_discount
                                                    }}</span
                                                >
                                            </p>

                                            <p
                                                class="old-price lead"
                                                v-if="
                                                    product.price !=
                                                    product.price_after_discount
                                                "
                                            >
                                                <del class="text-danger"
                                                    >Price :
                                                    {{ product.price }}</del
                                                >
                                            </p>
                                        </div>
                                    </template>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="product-list-action">
                        <div class="product-action2 d-flex">
                            <button
                                class="btn-product btn-quickview"
                                title="Quick view"
                                @click.prevent="
                                    quickView({
                                        product: product,
                                    })
                                "
                            >
                                <span>{{$t('product.quickView')}}</span>
                            </button>
                            <!-- <nuxt-link
                                to="/shop/wishlist"
                                class="
                                    btn-product btn-wishlist
                                    added-to-wishlist
                                "
                                v-if="isInWishlist(product)"
                                key="inWishlist"
                            >
                                <span>wishlist</span>
                            </nuxt-link>
                            <a
                                href="javascript:;"
                                class="btn-product btn-wishlist"
                                @click.prevent="
                                    addToWishlist({ product: product })
                                "
                                v-else
                                key="notInWishlist"
                            >
                                <span>wishlist</span>
                            </a> -->
                            <button
                                class="btn-product btn-cart"
                                :class="{
                                    'btn-disabled': !canAddToCart(product, 1),
                                }"
                                @click.prevent="
                                    addToCart({
                                        product: product,
                                    })
                                "
                            >
                                <span>{{$t('product.addToCart')}}</span>
                            </button>

                            <nuxt-link
                                :to="localePath('/shop/wishlist')"
                                class="
                                    btn-product btn-wishlist
                                    added-to-wishlist
                                "
                                v-if="isInWishlist(product)"
                                key="inWishlist"
                            >
                                <span>
                                    {{$t('product.inWishlist')}}
                                </span>
                            </nuxt-link>
                            <a
                                class="btn-product btn-wishlist"
                                @click.prevent="
                                    addToWishlist({
                                        product: product,
                                    })
                                "
                                v-else
                                key="notInWishlist"
                            >
                                <span>{{$t('product.addToWishlist')}}</span>
                            </a>
                        </div>

                        <!-- <nuxt-link
                            :to="'/product/default/' + product.slug"
                            class="btn-product btn-cart btn-select"
                            v-if="product.length > 0"
                        >
                            <span>select options</span>
                        </nuxt-link> -->
                    </div>
                </div>
            </div>
            <br />
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
export default {
    props: ['product'],
    computed: {
        ...mapGetters('cart', ['canAddToCart']),
        ...mapGetters('wishlist', ['isInWishlist']),
        ...mapGetters('compare', ['isInCompare']),
        gridClass: function () {
            if (this.type === 'list' || this.type === '2cols')
                return 'col-md-6 col-sm-12';
            if (this.type === '3cols') return 'col-6 col-md-4 col-lg-4';
            if (this.type === '4cols')
                return 'col-6 col-md-4 col-lg-4 col-xl-3';
            else return 'col-md-6 col-sm-12';
        },
    },
    methods: {
        ...mapActions('cart', ['addToCart']),
        ...mapActions('wishlist', ['addToWishlist']),
        ...mapActions('compare', ['addToCompare']),
        quickView: function () {
            this.$modal.show(
                () => import('~/components/elements/modals/QuickViewModal'),
                {
                    product: this.product,
                },
                { width: '1030', height: 'auto', adaptive: true }
            );
        },
    },
    
};
</script>

<style>
</style>