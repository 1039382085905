export { default as AlfAutologout } from '../../components/alf/autologout.vue'
export { default as AlfHome } from '../../components/alf/home.vue'
export { default as AlfMaster } from '../../components/alf/master.vue'
export { default as AlfOldHome } from '../../components/alf/oldHome.vue'
export { default as AlfPromo } from '../../components/alf/promo.vue'
export { default as AlfSearchProducts } from '../../components/alf/searchProducts.vue'
export { default as AlfUserAddresses } from '../../components/alf/userAddresses.vue'
export { default as ElementsCarousel } from '../../components/elements/Carousel.vue'
export { default as ElementsCartQuantity } from '../../components/elements/CartQuantity.vue'
export { default as ElementsCountDown } from '../../components/elements/CountDown.vue'
export { default as ElementsCountTo } from '../../components/elements/CountTo.vue'
export { default as ElementsPageHeader } from '../../components/elements/PageHeader.vue'
export { default as ElementsPagination } from '../../components/elements/Pagination.vue'
export { default as ElementsParallax } from '../../components/elements/Parallax.vue'
export { default as ElementsQuantityInput } from '../../components/elements/QuantityInput.vue'
export { default as ElementsStickyHeader } from '../../components/elements/StickyHeader.vue'
export { default as ElementsTabs } from '../../components/elements/Tabs.vue'
export { default as AlfGehadCompDetails } from '../../components/alf/gehad/compDetails.vue'
export { default as AlfGehadTest } from '../../components/alf/gehad/test.vue'
export { default as AlfGehadTestComp } from '../../components/alf/gehad/testComp.vue'
export { default as PartialBlogSidebar } from '../../components/partial/blog/BlogSidebar.vue'
export { default as PartialBlogSearch } from '../../components/partial/blog/blogSearch.vue'
export { default as PartialBlogPaginationBlog } from '../../components/partial/blog/paginationBlog.vue'
export { default as PartialElementsElementList } from '../../components/partial/elements/ElementList.vue'
export { default as PartialFootersFooterDefault } from '../../components/partial/footers/FooterDefault.vue'
export { default as PartialHeadersHeaderDefault } from '../../components/partial/headers/HeaderDefault.vue'
export { default as PartialHeadersContactHeader } from '../../components/partial/headers/contactHeader.vue'
export { default as PartialHeadersHeaderBlog } from '../../components/partial/headers/headerBlog.vue'
export { default as PartialHeadersHeaderHome } from '../../components/partial/headers/headerHome.vue'
export { default as PartialHomeDealCollection } from '../../components/partial/home/DealCollection.vue'
export { default as PartialHomeMobileMenu } from '../../components/partial/home/MobileMenu.vue'
export { default as PartialHomeNewCollection } from '../../components/partial/home/NewCollection.vue'
export { default as PartialHomeRecommendCollection } from '../../components/partial/home/RecommendCollection.vue'
export { default as PartialHomeTrendyCollection } from '../../components/partial/home/TrendyCollection.vue'
export { default as PartialPlacesSearch } from '../../components/partial/places/PlacesSearch.vue'
export { default as PartialPlacesInfoTabs } from '../../components/partial/places/infoTabs.vue'
export { default as PartialProductBreadCrumb } from '../../components/partial/product/BreadCrumb.vue'
export { default as ElementsBlogsBlogFour } from '../../components/elements/blogs/BlogFour.vue'
export { default as ElementsBlogsBlogOne } from '../../components/elements/blogs/BlogOne.vue'
export { default as ElementsBlogsBlogThree } from '../../components/elements/blogs/BlogThree.vue'
export { default as ElementsBlogsBlogTwo } from '../../components/elements/blogs/BlogTwo.vue'
export { default as ElementsBrandsAlfBrands } from '../../components/elements/brands/alfBrands.vue'
export { default as ElementsPlacesAlfPlaces } from '../../components/elements/places/AlfPlaces.vue'
export { default as ElementsModalsNewsletterModal } from '../../components/elements/modals/NewsletterModal.vue'
export { default as ElementsModalsQuickViewModal } from '../../components/elements/modals/QuickViewModal.vue'
export { default as ElementsModalsSignInModal } from '../../components/elements/modals/SignInModal.vue'
export { default as ElementsModalsSignUpModal } from '../../components/elements/modals/SignUpModal.vue'
export { default as ElementsModalsVideoModal } from '../../components/elements/modals/VideoModal.vue'
export { default as ElementsModalsBranchModal } from '../../components/elements/modals/branchModal.vue'
export { default as ElementsPortfolioOne } from '../../components/elements/portfolio/PortfolioOne.vue'
export { default as ElementsPortfolioTwo } from '../../components/elements/portfolio/PortfolioTwo.vue'
export { default as ElementsProductsAlfProducts } from '../../components/elements/products/AlfProducts.vue'
export { default as ElementsProductsProductEight } from '../../components/elements/products/ProductEight.vue'
export { default as ElementsProductsProductEleven } from '../../components/elements/products/ProductEleven.vue'
export { default as ElementsProductsProductFive } from '../../components/elements/products/ProductFive.vue'
export { default as ElementsProductsProductFour } from '../../components/elements/products/ProductFour.vue'
export { default as ElementsProductsProductNine } from '../../components/elements/products/ProductNine.vue'
export { default as ElementsProductsProductOne } from '../../components/elements/products/ProductOne.vue'
export { default as ElementsProductsProductSeven } from '../../components/elements/products/ProductSeven.vue'
export { default as ElementsProductsProductSix } from '../../components/elements/products/ProductSix.vue'
export { default as ElementsProductsProductTen } from '../../components/elements/products/ProductTen.vue'
export { default as ElementsProductsProductThree } from '../../components/elements/products/ProductThree.vue'
export { default as ElementsProductsProductTwelve } from '../../components/elements/products/ProductTwelve.vue'
export { default as ElementsProductsProductTwo } from '../../components/elements/products/ProductTwo.vue'
export { default as ElementsProductsSearchPlaces } from '../../components/elements/products/SearchPlaces.vue'
export { default as AlfHomeComponentsFifthFifthfirst } from '../../components/alf/homeComponents/fifth/fifthfirst.vue'
export { default as AlfHomeComponentsFifthFifthsecond } from '../../components/alf/homeComponents/fifth/fifthsecond.vue'
export { default as AlfHomeComponentsFifthFifththird } from '../../components/alf/homeComponents/fifth/fifththird.vue'
export { default as AlfHomeComponentsFifth } from '../../components/alf/homeComponents/fifth/index.vue'
export { default as AlfHomeComponentsFirst } from '../../components/alf/homeComponents/first/first.vue'
export { default as AlfHomeComponentsFirstFourth } from '../../components/alf/homeComponents/first/fourth.vue'
export { default as AlfHomeComponentsFirstSecond } from '../../components/alf/homeComponents/first/second.vue'
export { default as AlfHomeComponentsFirstThird } from '../../components/alf/homeComponents/first/third.vue'
export { default as AlfHomeComponentsFourthFourthfifth } from '../../components/alf/homeComponents/fourth/fourthfifth.vue'
export { default as AlfHomeComponentsFourthFourthfirst } from '../../components/alf/homeComponents/fourth/fourthfirst.vue'
export { default as AlfHomeComponentsFourthFourthfourth } from '../../components/alf/homeComponents/fourth/fourthfourth.vue'
export { default as AlfHomeComponentsFourthFourthsecond } from '../../components/alf/homeComponents/fourth/fourthsecond.vue'
export { default as AlfHomeComponentsFourthFourththird } from '../../components/alf/homeComponents/fourth/fourththird.vue'
export { default as AlfHomeComponentsFourth } from '../../components/alf/homeComponents/fourth/index.vue'
export { default as AlfHomeComponentsSecond } from '../../components/alf/homeComponents/second/index.vue'
export { default as AlfHomeComponentsSecondSecondfifth } from '../../components/alf/homeComponents/second/secondfifth.vue'
export { default as AlfHomeComponentsSecondSecondfirst } from '../../components/alf/homeComponents/second/secondfirst.vue'
export { default as AlfHomeComponentsSecondSecondfourth } from '../../components/alf/homeComponents/second/secondfourth.vue'
export { default as AlfHomeComponentsSecondSecondsecond } from '../../components/alf/homeComponents/second/secondsecond.vue'
export { default as AlfHomeComponentsSecondSecondthird } from '../../components/alf/homeComponents/second/secondthird.vue'
export { default as AlfHomeComponentsThird } from '../../components/alf/homeComponents/third/index.vue'
export { default as AlfHomeComponentsThirdThirdfifth } from '../../components/alf/homeComponents/third/thirdfifth.vue'
export { default as AlfHomeComponentsThirdThirdfirst } from '../../components/alf/homeComponents/third/thirdfirst.vue'
export { default as AlfHomeComponentsThirdThirdfourth } from '../../components/alf/homeComponents/third/thirdfourth.vue'
export { default as AlfHomeComponentsThirdThirdsecond } from '../../components/alf/homeComponents/third/thirdsecond.vue'
export { default as AlfHomeComponentsThirdThirdthird } from '../../components/alf/homeComponents/third/thirdthird.vue'
export { default as PartialHeadersSharedCartMenu } from '../../components/partial/headers/shared/CartMenu.vue'
export { default as PartialHeadersSharedCompareMenu } from '../../components/partial/headers/shared/CompareMenu.vue'
export { default as PartialHeadersSharedHeaderSearch } from '../../components/partial/headers/shared/HeaderSearch.vue'
export { default as PartialHeadersSharedMainMenu } from '../../components/partial/headers/shared/MainMenu.vue'
export { default as PartialHeadersSharedWishlistMenu } from '../../components/partial/headers/shared/WishlistMenu.vue'
export { default as PartialHeadersSharedProfile } from '../../components/partial/headers/shared/profile.vue'
export { default as PartialProductDetailsDetailOne } from '../../components/partial/product/details/DetailOne.vue'
export { default as PartialProductDetailsDetailThree } from '../../components/partial/product/details/DetailThree.vue'
export { default as PartialProductDetailsDetailTwo } from '../../components/partial/product/details/DetailTwo.vue'
export { default as PartialProductDetails } from '../../components/partial/product/details/ProductDetails.vue'
export { default as PartialProductGalleryExtended } from '../../components/partial/product/gallery/GalleryExtended.vue'
export { default as PartialProductGalleryHorizontal } from '../../components/partial/product/gallery/GalleryHorizontal.vue'
export { default as PartialProductGalleryMasonry } from '../../components/partial/product/gallery/GalleryMasonry.vue'
export { default as PartialProductGallerySticky } from '../../components/partial/product/gallery/GallerySticky.vue'
export { default as PartialProductGalleryVertical } from '../../components/partial/product/gallery/GalleryVertical.vue'
export { default as PartialProductGalleryNeoOne } from '../../components/partial/product/gallery/NeoOne.vue'
export { default as PartialProductInfoOne } from '../../components/partial/product/info-tabs/InfoOne.vue'
export { default as PartialProductInfoThree } from '../../components/partial/product/info-tabs/InfoThree.vue'
export { default as PartialProductInfoTwo } from '../../components/partial/product/info-tabs/InfoTwo.vue'
export { default as PartialProductInfoTabsHospitalTab } from '../../components/partial/product/info-tabs/hospitalTab.vue'
export { default as PartialProductRelatedAlfRelatedOne } from '../../components/partial/product/related/AlfRelatedOne.vue'
export { default as PartialProductRelatedAlfRelatedTwo } from '../../components/partial/product/related/AlfRelatedTwo.vue'
export { default as PartialProductRelatedProductsOne } from '../../components/partial/product/related/RelatedProductsOne.vue'
export { default as PartialProductRelatedProductsTwo } from '../../components/partial/product/related/RelatedProductsTwo.vue'
export { default as PartialProductSidebar } from '../../components/partial/product/sidebar/Sidebar.vue'
export { default as PartialShopSidebarPlaceSidebar } from '../../components/partial/shop/sidebar/PlaceSidebar.vue'
export { default as PartialShopSidebarOne } from '../../components/partial/shop/sidebar/ShopSidebarOne.vue'
export { default as PartialShopSidebarThree } from '../../components/partial/shop/sidebar/ShopSidebarThree.vue'
export { default as PartialShopSidebarTwo } from '../../components/partial/shop/sidebar/ShopSidebarTwo.vue'
export { default as PartialShopSidebarBrandSidebar } from '../../components/partial/shop/sidebar/brandSidebar.vue'
export { default as PartialShopListPlacesList } from '../../components/partial/shop/list/PlacesList.vue'
export { default as PartialShopListOne } from '../../components/partial/shop/list/ShopListOne.vue'
export { default as PartialShopListThree } from '../../components/partial/shop/list/ShopListThree.vue'
export { default as PartialShopListTwo } from '../../components/partial/shop/list/ShopListTwo.vue'
export { default as PartialShopListBrandList } from '../../components/partial/shop/list/brandList.vue'

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
