var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", {}, [
    _vm.shouldRender
      ? _c("nav", { attrs: { "aria-label": "Page navigation" } }, [
          _c(
            "ul",
            { staticClass: "pagination justify-content-center" },
            [
              _c(
                "li",
                {
                  staticClass: "page-item",
                  class: { disabled: _vm.currentPage < 2 },
                  on: { click: _vm.reloadPage }
                },
                [
                  _c(
                    "nuxt-link",
                    {
                      staticClass: "page-link page-link-prev",
                      attrs: { to: _vm.getPageUrl(_vm.currentPage - 1) }
                    },
                    [
                      _c("span", { attrs: { "aria-hidden": "true" } }, [
                        _c("i", { staticClass: "icon-long-arrow-left" })
                      ]),
                      _vm._v("Prev\n                ")
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _vm.pagesToBeShown.length
                ? _vm._l(_vm.pagesToBeShown, function(page) {
                    return _c(
                      "li",
                      {
                        key: `page-${page}`,
                        staticClass: "page-item",
                        on: { click: _vm.clearScroll }
                      },
                      [
                        _c(
                          "nuxt-link",
                          {
                            staticClass: "page-link",
                            attrs: {
                              "exact-active-class": "active",
                              to: _vm.getPageUrl(page)
                            }
                          },
                          [_vm._v(_vm._s(page))]
                        )
                      ],
                      1
                    )
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.lastPage > 3
                ? _c("li", { staticClass: "page-item-total pl-3" }, [
                    _vm._v(
                      "\n                of " +
                        _vm._s(_vm.lastPage) +
                        "\n            "
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "li",
                {
                  staticClass: "page-item",
                  class: { disabled: _vm.currentPage === _vm.lastPage },
                  on: { click: _vm.reloadPage }
                },
                [
                  _c(
                    "nuxt-link",
                    {
                      staticClass: "page-link page-link-next",
                      attrs: { to: _vm.getPageUrl(_vm.currentPage + 1) }
                    },
                    [
                      _vm._v(
                        "\n                    Next\n                    "
                      ),
                      _c("span", { attrs: { "aria-hidden": "true" } }, [
                        _c("i", { staticClass: "icon-long-arrow-right" })
                      ])
                    ]
                  )
                ],
                1
              )
            ],
            2
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }