var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      !_vm.loading
        ? [
            _c(
              "div",
              { staticClass: "col-9 m-auto skeleton-body skel-shop-products" },
              [
                _vm._l(1, function(item) {
                  return _c("div", {
                    key: item,
                    staticClass: "skel-pro skel-pro-list"
                  })
                })
              ],
              2
            )
          ]
        : _c(
            "div",
            {},
            _vm._l(_vm.banners.second, function(banner) {
              return _c(
                "section",
                { key: banner.id, staticClass: "travel-sec py-5" },
                [
                  _vm.$i18n.locale == "en"
                    ? _c(
                        "a",
                        {
                          attrs: { href: banner.button_link, target: "_blank" }
                        },
                        [
                          _c("img", {
                            directives: [
                              {
                                name: "lazy",
                                rawName: "v-lazy",
                                value: banner.background_image,
                                expression: "banner.background_image"
                              }
                            ]
                          })
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.$i18n.locale == "ar"
                    ? _c(
                        "a",
                        {
                          attrs: { href: banner.button_link, target: "_blank" }
                        },
                        [
                          _c("img", {
                            directives: [
                              {
                                name: "lazy",
                                rawName: "v-lazy",
                                value: banner.background_image_ar,
                                expression: "banner.background_image_ar"
                              }
                            ]
                          })
                        ]
                      )
                    : _vm._e()
                ]
              )
            }),
            0
          )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }