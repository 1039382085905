var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container new new-arrivals" }, [
    _c("div", { staticClass: "heading heading-flex mb-3" }, [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "heading-right" },
        [
          _c("tabs", {
            staticClass: "nav nav-pills nav-border-anim justify-content-center",
            attrs: { data: _vm.tabsData, id: "new" }
          })
        ],
        1
      )
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "tab-content tab-content-carousel just-action-icons-sm" },
      [
        _c(
          "div",
          {
            staticClass: "tab-pane p-0 fade show active",
            attrs: { id: "new-all" }
          },
          [
            _c(
              "div",
              { staticClass: "swiper-carousel carousel-with-shadow swiper-1" },
              [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "swiper",
                        rawName: "v-swiper:swiper1",
                        value: _vm.carouselSetting1,
                        expression: "carouselSetting1",
                        arg: "swiper1"
                      }
                    ],
                    staticClass: "mb-0"
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "swiper-wrapper" },
                      _vm._l(_vm.products, function(product, index) {
                        return _c(
                          "div",
                          { key: index, staticClass: "swiper-slide" },
                          [
                            _c("product-twelve", {
                              attrs: { product: product }
                            })
                          ],
                          1
                        )
                      }),
                      0
                    )
                  ]
                ),
                _vm._v(" "),
                _vm._m(1),
                _vm._v(" "),
                _c("div", { staticClass: "swiper-dots mt-2" })
              ]
            )
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "tab-pane p-0 fade",
            attrs: { id: "new-accessories" }
          },
          [
            _c(
              "div",
              { staticClass: "swiper-carousel carousel-with-shadow swiper-2" },
              [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "swiper",
                        rawName: "v-swiper:swiper2",
                        value: _vm.carouselSetting2,
                        expression: "carouselSetting2",
                        arg: "swiper2"
                      }
                    ],
                    staticClass: "mb-0"
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "swiper-wrapper" },
                      _vm._l(_vm.accessories, function(product, index) {
                        return _c(
                          "div",
                          { key: index, staticClass: "swiper-slide" },
                          [
                            _c("product-twelve", {
                              attrs: { product: product }
                            })
                          ],
                          1
                        )
                      }),
                      0
                    )
                  ]
                ),
                _vm._v(" "),
                _vm._m(2),
                _vm._v(" "),
                _c("div", { staticClass: "swiper-dots mt-2" })
              ]
            )
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "tab-pane p-0 fade", attrs: { id: "new-camera" } },
          [
            _c(
              "div",
              { staticClass: "swiper-carousel carousel-with-shadow swiper-3" },
              [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "swiper",
                        rawName: "v-swiper:swiper3",
                        value: _vm.carouselSetting3,
                        expression: "carouselSetting3",
                        arg: "swiper3"
                      }
                    ],
                    staticClass: "mb-0"
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "swiper-wrapper" },
                      _vm._l(_vm.camera, function(product, index) {
                        return _c(
                          "div",
                          { key: index, staticClass: "swiper-slide" },
                          [
                            _c("product-twelve", {
                              attrs: { product: product }
                            })
                          ],
                          1
                        )
                      }),
                      0
                    )
                  ]
                ),
                _vm._v(" "),
                _vm._m(3),
                _vm._v(" "),
                _c("div", { staticClass: "swiper-dots mt-2" })
              ]
            )
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "tab-pane p-0 fade", attrs: { id: "new-computers" } },
          [
            _c(
              "div",
              { staticClass: "swiper-carousel carousel-with-shadow swiper-4" },
              [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "swiper",
                        rawName: "v-swiper:swiper4",
                        value: _vm.carouselSetting4,
                        expression: "carouselSetting4",
                        arg: "swiper4"
                      }
                    ],
                    staticClass: "mb-0"
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "swiper-wrapper" },
                      _vm._l(_vm.computers, function(product, index) {
                        return _c(
                          "div",
                          { key: index, staticClass: "swiper-slide" },
                          [
                            _c("product-twelve", {
                              attrs: { product: product }
                            })
                          ],
                          1
                        )
                      }),
                      0
                    )
                  ]
                ),
                _vm._v(" "),
                _vm._m(4),
                _vm._v(" "),
                _c("div", { staticClass: "swiper-dots mt-2" })
              ]
            )
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "tab-pane p-0 fade",
            attrs: { id: "new-entertainment" }
          },
          [
            _c(
              "div",
              { staticClass: "swiper-carousel carousel-with-shadow swiper-5" },
              [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "swiper",
                        rawName: "v-swiper:swiper5",
                        value: _vm.carouselSetting5,
                        expression: "carouselSetting5",
                        arg: "swiper5"
                      }
                    ],
                    staticClass: "mb-0"
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "swiper-wrapper" },
                      _vm._l(_vm.entertainment, function(product, index) {
                        return _c(
                          "div",
                          { key: index, staticClass: "swiper-slide" },
                          [
                            _c("product-twelve", {
                              attrs: { product: product }
                            })
                          ],
                          1
                        )
                      }),
                      0
                    )
                  ]
                ),
                _vm._v(" "),
                _vm._m(5),
                _vm._v(" "),
                _c("div", { staticClass: "swiper-dots mt-2" })
              ]
            )
          ]
        )
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "heading-left" }, [
      _c("h2", { staticClass: "title" }, [_vm._v("New Arrivals")])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "swiper-nav nav-side" }, [
      _c("div", { staticClass: "swiper-prev" }, [
        _c("i", { staticClass: "icon-angle-left" })
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "swiper-next" }, [
        _c("i", { staticClass: "icon-angle-right" })
      ])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "swiper-nav nav-side" }, [
      _c("div", { staticClass: "swiper-prev" }, [
        _c("i", { staticClass: "icon-angle-left" })
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "swiper-next" }, [
        _c("i", { staticClass: "icon-angle-right" })
      ])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "swiper-nav nav-side" }, [
      _c("div", { staticClass: "swiper-prev" }, [
        _c("i", { staticClass: "icon-angle-left" })
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "swiper-next" }, [
        _c("i", { staticClass: "icon-angle-right" })
      ])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "swiper-nav nav-side" }, [
      _c("div", { staticClass: "swiper-prev" }, [
        _c("i", { staticClass: "icon-angle-left" })
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "swiper-next" }, [
        _c("i", { staticClass: "icon-angle-right" })
      ])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "swiper-nav nav-side" }, [
      _c("div", { staticClass: "swiper-prev" }, [
        _c("i", { staticClass: "icon-angle-left" })
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "swiper-next" }, [
        _c("i", { staticClass: "icon-angle-right" })
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }