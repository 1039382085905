<template>
    <div>
        <!-- <First /> -->

        <!-- <Second /> -->

        <Third />

        <!-- <LazyHydrate when-visible data-aos="fade-left" data-aos-duration="1000" data-aos-delay="150">
            <Fourth />
        </LazyHydrate>
        <LazyHydrate when-visible data-aos="fade-left" data-aos-duration="1000" data-aos-delay="200">
            <Fifth />
        </LazyHydrate> -->
    </div>
</template>

<script>
import LazyHydrate from 'vue-lazy-hydration';

export default {
    components: {
        LazyHydrate,
        // First: () =>
        //     import('~/components/alf/homeComponents/fifth/fifthfirst.vue'),
        // Second: () =>
        //     import('~/components/alf/homeComponents/fifth/fifthsecond.vue'),
        Third: () =>
            import('~/components/alf/homeComponents/fifth/fifththird.vue'),
        // Fourth: () =>
        //     import('~/components/alf/homeComponents/fifth/fifththfourth.vue'),
        // Fifth: () =>
        //     import('~/components/alf/homeComponents/fifth/fifththfifth.vue'),
    },
    methods: {
        // setScroll() {
        //     document.addEventListener('DOMContentLoaded', function (event) {
        //         var scrollpos = localStorage.getItem('scrollpos') + 500;
        //         if (scrollpos) window.scrollTo(0, scrollpos);
        //     });
        //     window.onbeforeunload = function (e) {
        //         localStorage.setItem('scrollpos', window.scrollY);
        //     };
        // },
        // myScroll() {
        //     var scrollpos = localStorage.getItem('scrollpos');
        //     window.scrollTo({
        //         top: scrollpos,
        //         behavior: 'smooth',
        //     });
        // },
    },
};
</script>

<style>
</style>