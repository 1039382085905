var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.product
    ? _c(
        "div",
        { staticClass: "product-gallery product-gallery-separated" },
        [
          _vm.product.new
            ? _c("span", { staticClass: "product-label label-new" }, [
                _vm._v("New")
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.product.sale_price
            ? _c("span", { staticClass: "product-label label-sale" }, [
                _vm._v("Sale")
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.product.top
            ? _c("span", { staticClass: "product-label label-top" }, [
                _vm._v("Top")
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.product.stock === 0
            ? _c("span", { staticClass: "product-label label-out" }, [
                _vm._v("Out Of Stock")
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm._l(_vm.product.pictures, function(picture, index) {
            return _c(
              "figure",
              { key: index, staticClass: "product-main-image" },
              [
                _c("img", {
                  directives: [
                    {
                      name: "lazy",
                      rawName: "v-lazy",
                      value: `${_vm.baseUrl}${picture.url}`,
                      expression: "`${baseUrl}${picture.url}`"
                    }
                  ],
                  attrs: {
                    id: "product-zoom",
                    alt: "product",
                    width: picture.width,
                    height: picture.height
                  }
                }),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "btn-product-gallery",
                    attrs: { href: "#", id: "btn-product-gallery" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.openLightBox(index)
                      }
                    }
                  },
                  [_c("i", { staticClass: "icon-arrows" })]
                )
              ]
            )
          }),
          _vm._v(" "),
          _c("light-box", {
            ref: "lightbox",
            staticClass: "light-box",
            attrs: {
              media: _vm.lightBoxMedia,
              "show-caption": true,
              "show-light-box": false
            }
          })
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }