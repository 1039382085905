var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "product product-sm align-items-center" }, [
    _c(
      "figure",
      { staticClass: "product-media" },
      [
        _c(
          "nuxt-link",
          { attrs: { to: "/product/default/" + _vm.product.slug } },
          [
            _c("img", {
              directives: [
                {
                  name: "lazy",
                  rawName: "v-lazy",
                  value: `${_vm.baseUrl}${_vm.product.sm_pictures[0].url}`,
                  expression: "`${baseUrl}${product.sm_pictures[0].url}`"
                }
              ],
              staticClass: "product-image",
              attrs: {
                alt: "Product",
                width: _vm.product.sm_pictures[0].width,
                height: _vm.product.sm_pictures[0].height
              }
            }),
            _vm._v(" "),
            _vm.product.sm_pictures[1]
              ? _c("img", {
                  directives: [
                    {
                      name: "lazy",
                      rawName: "v-lazy",
                      value: `${_vm.baseUrl}${_vm.product.sm_pictures[1].url}`,
                      expression: "`${baseUrl}${product.sm_pictures[1].url}`"
                    }
                  ],
                  staticClass: "product-image-hover",
                  attrs: {
                    alt: "Product",
                    width: _vm.product.sm_pictures[1].width,
                    height: _vm.product.sm_pictures[1].height
                  }
                })
              : _vm._e()
          ]
        )
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "product-body py-0 pl-4" },
      [
        _c(
          "h3",
          { staticClass: "product-title" },
          [
            _c(
              "nuxt-link",
              { attrs: { to: "/product/default/" + _vm.product.slug } },
              [_vm._v(_vm._s(_vm.product.name))]
            )
          ],
          1
        ),
        _vm._v(" "),
        _vm.product.stock == 0
          ? _c("div", { key: "outPrice", staticClass: "product-price" }, [
              _c("span", { staticClass: "out-price" }, [
                _vm._v("$" + _vm._s(_vm.product.price.toFixed(2)))
              ])
            ])
          : [
              _vm.minPrice == _vm.maxPrice
                ? _c("div", { staticClass: "product-price" }, [
                    _vm._v("$" + _vm._s(_vm.minPrice.toFixed(2)))
                  ])
                : [
                    _vm.product.variants.length == 0
                      ? _c("div", { staticClass: "product-price" }, [
                          _c("span", { staticClass: "new-price" }, [
                            _vm._v("$" + _vm._s(_vm.minPrice.toFixed(2)))
                          ]),
                          _vm._v(" "),
                          _c("span", { staticClass: "old-price" }, [
                            _vm._v("$" + _vm._s(_vm.maxPrice.toFixed(2)))
                          ])
                        ])
                      : _c("div", { staticClass: "product-price" }, [
                          _vm._v(
                            "$" +
                              _vm._s(_vm.minPrice.toFixed(2)) +
                              "–$" +
                              _vm._s(_vm.maxPrice.toFixed(2))
                          )
                        ])
                  ]
            ]
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }