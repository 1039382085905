var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "places-holder text-left" }, [
      _c("section", { staticClass: "test featured" }, [
        _c("div", { staticClass: "swiper-slide bg-transparent" }, [
          _c("div", { staticClass: "container" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-md-4 col-sm-12 p-2" }, [
                _c("div", { staticClass: "card-holder whitlist-holder" }, [
                  _c("img", {
                    staticClass: "img-fluid w-100 bg-transparent feature_image",
                    attrs: { src: _vm.place.main_image, alt: "image" }
                  }),
                  _vm._v(" "),
                  _c(
                    "ul",
                    { staticClass: "list-unstyled featured-card-info my-4" },
                    [
                      _c("li", { staticClass: "name" }, [
                        _vm.place.rating === "0"
                          ? _c(
                              "span",
                              _vm._l(5, function(x) {
                                return _c("i", {
                                  key: x,
                                  staticClass: "far fa-star"
                                })
                              }),
                              0
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.place.rating === "1"
                          ? _c(
                              "span",
                              [
                                _c("i", { staticClass: "fas fa-star colored" }),
                                _vm._v(" "),
                                _vm._l(4, function(x) {
                                  return _c("i", {
                                    key: x,
                                    staticClass: "far fa-star"
                                  })
                                })
                              ],
                              2
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.place.rating === "2"
                          ? _c(
                              "span",
                              [
                                _c("i", { staticClass: "fas fa-star colored" }),
                                _vm._v(" "),
                                _c("i", { staticClass: "fas fa-star colored" }),
                                _vm._v(" "),
                                _vm._l(3, function(x) {
                                  return _c("i", {
                                    key: x,
                                    staticClass: "far fa-star"
                                  })
                                })
                              ],
                              2
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.place.rating === "3"
                          ? _c(
                              "span",
                              [
                                _c("i", { staticClass: "fas fa-star colored" }),
                                _vm._v(" "),
                                _c("i", { staticClass: "fas fa-star colored" }),
                                _vm._v(" "),
                                _c("i", { staticClass: "fas fa-star colored" }),
                                _vm._v(" "),
                                _vm._l(2, function(x) {
                                  return _c("i", {
                                    key: x,
                                    staticClass: "far fa-star"
                                  })
                                })
                              ],
                              2
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.place.rating === "3.5"
                          ? _c(
                              "span",
                              [
                                _c("i", { staticClass: "fas fa-star colored" }),
                                _vm._v(" "),
                                _c("i", { staticClass: "fas fa-star colored" }),
                                _vm._v(" "),
                                _c("i", { staticClass: "fas fa-star colored" }),
                                _vm._v(" "),
                                _c("i", {
                                  staticClass: "fas fa-star-half-alt colored"
                                }),
                                _vm._v(" "),
                                _vm._l(1, function(x) {
                                  return _c("i", {
                                    key: x,
                                    staticClass: "far fa-star"
                                  })
                                })
                              ],
                              2
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.place.rating === "4"
                          ? _c("span", [
                              _c("i", { staticClass: "fas fa-star colored" }),
                              _vm._v(" "),
                              _c("i", { staticClass: "fas fa-star colored" }),
                              _vm._v(" "),
                              _c("i", { staticClass: "fas fa-star colored" }),
                              _vm._v(" "),
                              _c("i", { staticClass: "fas fa-star colored" }),
                              _vm._v(" "),
                              _c("i", { staticClass: "far fa-star" })
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.place.rating === "5"
                          ? _c(
                              "span",
                              _vm._l(5, function(x) {
                                return _c("i", {
                                  key: x,
                                  staticClass: "fas fa-star colored"
                                })
                              }),
                              0
                            )
                          : _vm._e()
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "hospital-name" }, [
                    _c(
                      "ul",
                      {
                        staticClass:
                          "list-unstyled d-flex justify-content-between"
                      },
                      [
                        _c("nuxt-link", { attrs: { to: "" } }, [
                          _c(
                            "li",
                            { staticClass: "mb-2" },
                            [
                              _c(
                                "nuxt-link",
                                {
                                  staticClass: "hospital-name mb-2 text-dimied",
                                  attrs: { to: `/pages/places/${_vm.place.id}` }
                                },
                                [
                                  _vm._v(
                                    "\n                                                    " +
                                      _vm._s(_vm.place.name) +
                                      "\n                                                "
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "li",
                            [
                              _c(
                                "nuxt-link",
                                {
                                  staticClass: "hospital-name",
                                  attrs: { to: `/pages/places/${_vm.place.id}` }
                                },
                                [
                                  _vm._v(
                                    "\n                                                    " +
                                      _vm._s(_vm.place.brief) +
                                      "\n                                                "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ]),
                        _vm._v(" "),
                        _vm.place.premium == 1
                          ? _c("li", [
                              _c("img", {
                                directives: [
                                  {
                                    name: "lazy",
                                    rawName: "v-lazy",
                                    value:
                                      "./images/Alf/alf/places/premium.png",
                                    expression:
                                      "\n                                                    './images/Alf/alf/places/premium.png'\n                                                "
                                  }
                                ],
                                staticClass: "bg-transparent",
                                attrs: { alt: "" }
                              })
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.place.verified == 1
                          ? _c("li", [_vm._m(0)])
                          : _vm._e()
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "d-flex justify-content-between" }, [
                    _c("div", [
                      _c("ul", { staticClass: "list-unstyled hospital-info" }, [
                        _vm.place.phone
                          ? _c("li", [
                              _vm._v(
                                "\n                                                " +
                                  _vm._s(_vm.place.phone) +
                                  "\n                                            "
                              )
                            ])
                          : _c("li", [_vm._v("no number")])
                      ])
                    ]),
                    _vm._v(" "),
                    _vm._m(1)
                  ])
                ])
              ])
            ])
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "check-icon" }, [
      _c("i", { staticClass: "icon-check" })
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("div", { staticClass: "myWhitlist" }, [
        _c("i", { staticClass: "far fa-heart fa-2x" })
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }