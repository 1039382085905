var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "container newsletter-popup-container",
      attrs: { id: "newsletter-popup-form" }
    },
    [
      _c("div", { staticClass: "row justify-content-center" }, [
        _c("div", { staticClass: "col-12" }, [
          _c(
            "div",
            { staticClass: "row no-gutters bg-white newsletter-popup-content" },
            [
              _c(
                "div",
                { staticClass: "col-xl-3-5col col-lg-7 banner-content-wrap" },
                [
                  _c("div", { staticClass: "banner-content text-center" }, [
                    _c("img", {
                      staticClass: "logo bg-white",
                      attrs: {
                        src: require("static/images/popup/newsletter/logo.png"),
                        alt: "logo",
                        width: "60",
                        height: "15"
                      }
                    }),
                    _vm._v(" "),
                    _vm._m(0),
                    _vm._v(" "),
                    _c("p", [
                      _vm._v(
                        "Subscribe to the Molla eCommerce newsletter to receive timely updates from your favorite products."
                      )
                    ]),
                    _vm._v(" "),
                    _vm._m(1),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "custom-control custom-checkbox mb-3" },
                      [
                        _c("input", {
                          staticClass: "custom-control-input",
                          attrs: { type: "checkbox", id: "register-policy-2" },
                          on: { change: _vm.changeCheck }
                        }),
                        _vm._v(" "),
                        _c(
                          "label",
                          {
                            staticClass: "custom-control-label",
                            attrs: { for: "register-policy-2" }
                          },
                          [_vm._v("Do not show this popup again")]
                        )
                      ]
                    )
                  ])
                ]
              ),
              _vm._v(" "),
              _vm._m(2)
            ]
          )
        ])
      ]),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "mfp-close",
          attrs: { title: "Close (Esc)", type: "button" },
          on: { click: _vm.closeModal }
        },
        [_c("span", [_vm._v("×")])]
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("h2", { staticClass: "banner-title" }, [
      _vm._v("\n                            get\n                            "),
      _c("span", [
        _vm._v(
          "\n                                25\n                                "
        ),
        _c("span", { staticClass: "font-weight-normal" }, [_vm._v("%")])
      ]),
      _vm._v(" off\n                        ")
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("form", { attrs: { action: "#" } }, [
      _c("div", { staticClass: "input-group input-group-round" }, [
        _c("input", {
          staticClass: "form-control form-control-white",
          attrs: {
            type: "email",
            placeholder: "Your Email Address",
            "aria-label": "Email Adress",
            required: ""
          }
        }),
        _vm._v(" "),
        _c("div", { staticClass: "input-group-append" }, [
          _c("button", { staticClass: "btn", attrs: { type: "submit" } }, [
            _c("span", [_vm._v("go")])
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-xl-2-5col col-lg-5" }, [
      _c("img", {
        staticClass: "newsletter-img",
        attrs: {
          src: require("static/images/popup/newsletter/img-1.jpg"),
          width: "396",
          height: "420",
          alt: "newsletter"
        }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }