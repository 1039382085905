var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container for-you" }, [
    _c("div", { staticClass: "heading heading-flex mb-3" }, [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "heading-right" },
        [
          _c(
            "nuxt-link",
            {
              staticClass: "title-link",
              attrs: { to: "/shop/nosidebar/fullwidth" }
            },
            [
              _vm._v(
                "\n                View All Recommendadion\n                "
              ),
              _c("i", { staticClass: "icon-long-arrow-right" })
            ]
          )
        ],
        1
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "products" }, [
      _c(
        "div",
        { staticClass: "row justify-content-center" },
        _vm._l(_vm.products.slice(0, 8), function(product, index) {
          return _c(
            "div",
            { key: index, staticClass: "col-6 col-md-4 col-lg-3" },
            [_c("product-twelve", { attrs: { product: product } })],
            1
          )
        }),
        0
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "heading-left" }, [
      _c("h2", { staticClass: "title" }, [_vm._v("Recommendation For You")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }