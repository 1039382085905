var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.product
    ? _c("div", { staticClass: "product-gallery product-gallery-vertical" }, [
        _vm.product[0].images.length > 0
          ? _c("div", { staticClass: "row m-0 images-carousel" }, [
              _c("div", { staticClass: "swiper-carousel swiper-11" }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "swiper",
                        rawName: "v-swiper:swiper11",
                        value: _vm.carouselSetting1,
                        expression: "carouselSetting1",
                        arg: "swiper11"
                      }
                    ]
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "swiper-wrapper" },
                      _vm._l(_vm.product[0].images, function(image) {
                        return _c(
                          "div",
                          { key: image.id, staticClass: "swiper-slide" },
                          [
                            _c("div", { staticClass: "image-carousel" }, [
                              _c("img", {
                                directives: [
                                  {
                                    name: "lazy",
                                    rawName: "v-lazy",
                                    value: image,
                                    expression: "image"
                                  }
                                ],
                                staticClass: "bg-transparent",
                                attrs: {
                                  alt: "product side image",
                                  width: "450",
                                  height: "27"
                                }
                              })
                            ])
                          ]
                        )
                      }),
                      0
                    )
                  ]
                ),
                _vm._v(" "),
                _vm._m(0),
                _vm._v(" "),
                _c("div", { staticClass: "swiper-dots swiper-dots-inner" })
              ])
            ])
          : _c("div", [
              _c(
                "div",
                { staticClass: "swiper-carousel carousel-with-shadow" },
                [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "swiper",
                          rawName: "v-swiper:swiper2",
                          value: _vm.carouselSetting,
                          expression: "carouselSetting",
                          arg: "swiper2"
                        }
                      ]
                    },
                    [
                      _c("div", { staticClass: "swiper-wrapper" }, [
                        _c("img", {
                          directives: [
                            {
                              name: "lazy",
                              rawName: "v-lazy",
                              value: "/images/Alf/unnamed.png",
                              expression: "'/images/Alf/unnamed.png'"
                            }
                          ],
                          staticClass: "w-100",
                          attrs: { alt: "image" }
                        })
                      ])
                    ]
                  )
                ]
              )
            ])
      ])
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "swiper-nav nav-rounded" }, [
      _c("div", { staticClass: "swiper-prev" }, [
        _c("i", { staticClass: "icon-angle-left" })
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "swiper-next" }, [
        _c("i", { staticClass: "icon-angle-right" })
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }