<template>
    <div class="sidebar-shop" :class="isSidebar ? 'sidebar-filter' : 'sidebar'">
        <div :class="{ 'sidebar-filter-wrapper': isSidebar }">
            <div class="widget widget-clean">
                <label class="filters">Filtered By</label>
                <a class="sidebar-filter-clear" @click.prevent="cleanAll"
                    >Clean All</a
                >
            </div>
            <div class="widget widget-collapsible">
                <h3 class="widget-title mb-2">
                    <a
                        href="#widget-1"
                        :class="{ collapsed: !toggleStates[0] }"
                        @click.prevent="toggleSlide(0)"
                        >{{$t('productsSidebar.category')}}</a
                    >
                </h3>

                <vue-slide-toggle
                    :open="toggleStates[0]"
                    class="show"
                    :duration="200"
                >
                    <div class="widget-body pt-0">
                        <div class="filter-items filter-items-count">
                            <div class="filter-items">
                                <div
                                    class="filter-item"
                                    v-for="(item, index) in categories"
                                    :key="index"
                                >
                                    <div class="custom-control custom-radio">
                                        <input
                                            type="radio"
                                            class="custom-control-input"
                                            :id="'category-' + index"
                                            @click="setmyCategoryFilter(item)"
                                            :checked="myCategoryChecked(item)"
                                            name="category"
                                        />
                                        <label
                                            class="custom-control-label"
                                            :for="'category-' + index"
                                            >{{ item.name }}</label
                                        >
                                    </div>
                                </div>
                                <!-- <div
                                    class="custom-control custom-radio"
                                    v-for="(item, index) in categories"
                                    :key="index"
                                >
                                    <input
                                        type="radio"
                                        class="filter-item custom-control-input"
                                        name="category"
                                        :id="item.id"
                                        :value="item.name"
                                    />
                                    <label
                                        class="custom-control-label"
                                        :for="'category-' + index"
                                        >{{ item.name }}</label
                                    >
                                </div> -->
                            </div>
                        </div>

                        <div
                            class="widget widget-collapsible mt-3"
                            v-if="
                                $route.query.category &&
                                subcategories.length > 0
                            "
                        >
                            <h3 class="widget-title mb-2">
                                <a
                                    href="#widget-4"
                                    :class="{ collapsed: !toggleStates[1] }"
                                    @click.prevent="toggleSlide(1)"
                                    >{{$t('productsSidebar.subCategory')}}</a
                                >
                            </h3>

                            <vue-slide-toggle
                                :open="toggleStates[1]"
                                class="show"
                                :duration="200"
                            >
                                <div class="widget-body pt-0">
                                    <div class="filter-items">
                                        <div
                                            class="filter-item"
                                            v-for="(
                                                item, index
                                            ) in subcategories"
                                            :key="index"
                                        >
                                            <!-- <div
                                                class="
                                                    custom-control
                                                    custom-checkbox
                                                "
                                            >
                                                <input
                                                    type="checkbox"
                                                    class="custom-control-input"
                                                    :id="'subcategory-' + index"
                                                    @click="
                                                        setSubCategoryFilter(
                                                            item
                                                        )
                                                    "
                                                    :checked="
                                                        SubCategoryChecked(item)
                                                    "
                                                />
                                                <label
                                                    class="custom-control-label"
                                                    :for="
                                                        'subcategory-' + index
                                                    "
                                                    >{{ item.name }}</label
                                                >
                                            </div> -->

                                            <div
                                                class="
                                                    custom-control custom-radio
                                                "
                                            >
                                                <input
                                                    type="radio"
                                                    class="custom-control-input"
                                                    :id="'subcategory-' + index"
                                                    @click="
                                                        setSubCategoryFilter(
                                                            item
                                                        )
                                                    "
                                                    :checked="
                                                        SubCategoryChecked(item)
                                                    "
                                                    name="subcategory"
                                                />
                                                <label
                                                    class="custom-control-label"
                                                    :for="
                                                        'subcategory-' + index
                                                    "
                                                    >{{ item.name }}</label
                                                >
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </vue-slide-toggle>
                        </div>
                    </div>
                </vue-slide-toggle>
            </div>
            <div class="widget widget-collapsible">
                <h3 class="widget-title mb-2">
                    <a
                        href="#widget-4"
                        :class="{ collapsed: !toggleStates[2] }"
                        @click.prevent="toggleSlide(2)"
                        >Price Ranges</a
                    >
                </h3>

                <vue-slide-toggle
                    :open="toggleStates[2]"
                    class="show"
                    :duration="200"
                >
                    <div class="widget-body pt-0">
                        <div class="filter-items">
                            <div
                                class="filter-item"
                                v-for="(item, index) in prices"
                                :key="index"
                            >
                                <!-- <div class="custom-control custom-checkbox">
                                    <input
                                        type="checkbox"
                                        class="custom-control-input"
                                        :id="'priceRange-' + index"
                                        @click="setPriceFilter(item)"
                                        :checked="priceChecked(item)"
                                    />
                                    <label
                                        class="custom-control-label"
                                        :for="'priceRange-' + index"
                                        >{{ item.name }}</label
                                    >
                                </div> -->
                                <div class="custom-control custom-radio">
                                    <input
                                        type="radio"
                                        class="custom-control-input"
                                        :id="'priceRange-' + index"
                                        @click="setPriceFilter(item)"
                                        :checked="priceChecked(item)"
                                        name="priceRange"
                                    />
                                    <label
                                        class="custom-control-label"
                                        :for="'priceRange-' + index"
                                        >{{ item.name }}</label
                                    >
                                </div>
                            </div>
                        </div>
                    </div>
                </vue-slide-toggle>
            </div>
            <div class="widget widget-collapsible">
                <h3 class="widget-title mb-2">
                    <a
                        href="#widget-4"
                        :class="{ collapsed: !toggleStates[3] }"
                        @click.prevent="toggleSlide(3)"
                        >Age Stages</a
                    >
                </h3>

                <vue-slide-toggle
                    :open="toggleStates[3]"
                    class="show"
                    :duration="200"
                >
                    <div class="widget-body pt-0">
                        <div class="filter-items">
                            <div
                                class="filter-item"
                                v-for="(item, index) in ages"
                                :key="index"
                            >
                                <!-- <div class="custom-control custom-checkbox">
                                    <input
                                        type="checkbox"
                                        class="custom-control-input"
                                        :id="'age-' + index"
                                        @click="setAgeFilter(item)"
                                        :checked="ageChecked(item)"
                                    />
                                    <label
                                        class="custom-control-label"
                                        :for="'age-' + index"
                                        >{{ item.from }} - {{ item.to }}</label
                                    >
                                </div> -->
                                <div class="custom-control custom-radio">
                                    <input
                                        type="radio"
                                        class="custom-control-input"
                                        :id="'age-' + index"
                                        @click="setAgeFilter(item)"
                                        :checked="ageChecked(item)"
                                        name="age"
                                    />
                                    <label
                                        class="custom-control-label"
                                        :for="'age-' + index"
                                        >{{ item.from }} - {{ item.to }}</label
                                    >
                                </div>
                            </div>
                        </div>
                    </div>
                </vue-slide-toggle>
            </div>
            <div class="widget widget-collapsible">
                <h3 class="widget-title mb-2">
                    <a
                        href="#widget-1"
                        :class="{ collapsed: !toggleStates[4] }"
                        @click.prevent="toggleSlide(4)"
                        >Cities</a
                    >
                </h3>

                <vue-slide-toggle
                    :open="toggleStates[4]"
                    class="show"
                    :duration="200"
                >
                    <div class="widget-body pt-0">
                        <div class="filter-items filter-items-count">
                            <div class="filter-items">
                                <div
                                    class="filter-item"
                                    v-for="(item, index) in cities"
                                    :key="index"
                                >
                                    <!-- <div class="custom-control custom-checkbox">
                                        <input
                                            type="checkbox"
                                            class="custom-control-input"
                                            :id="'city-' + index"
                                            @click="cityFilter(item)"
                                            :checked="cityChecked(item)"
                                        />
                                        <label
                                            class="custom-control-label"
                                            :for="'city-' + index"
                                            >{{ item.name }}</label
                                        >
                                    </div> -->
                                    <div class="custom-control custom-radio">
                                        <input
                                            type="radio"
                                            class="custom-control-input"
                                            :id="'city-' + index"
                                            @click="cityFilter(item)"
                                            :checked="cityChecked(item)"
                                            name="city"
                                        />
                                        <label
                                            class="custom-control-label"
                                            :for="'city-' + index"
                                            >{{ item.name }}</label
                                        >
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div
                            class="widget widget-collapsible mt-3"
                            v-if="$route.query.city && cities.length > 0"
                        >
                            <h3 class="widget-title mb-2">
                                <a
                                    href="#widget-4"
                                    :class="{ collapsed: !toggleStates[5] }"
                                    @click.prevent="toggleSlide(5)"
                                    >Areas</a
                                >
                            </h3>

                            <vue-slide-toggle
                                :open="toggleStates[5]"
                                class="show"
                                :duration="200"
                            >
                                <div class="widget-body pt-0">
                                    <div class="filter-items">
                                        <div
                                            class="filter-item"
                                            v-for="(item, index) in areas"
                                            :key="index"
                                        >
                                            <div
                                                class="
                                                    custom-control custom-radio
                                                "
                                            >
                                                <input
                                                    type="radio"
                                                    class="custom-control-input"
                                                    :id="'area-' + index"
                                                    @click="areaFilter(item)"
                                                    :checked="areaChecked(item)"
                                                    name="area"
                                                />
                                                <label
                                                    class="custom-control-label"
                                                    :for="'area-' + index"
                                                    >{{ item.name }}</label
                                                >
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </vue-slide-toggle>
                        </div>
                    </div>
                </vue-slide-toggle>
            </div>
            <div class="widget widget-collapsible">
                <h3 class="widget-title mb-2">
                    <a
                        href="#widget-4"
                        :class="{ collapsed: !toggleStates[6] }"
                        @click.prevent="toggleSlide(6)"
                        >Grade Type</a
                    >
                </h3>

                <vue-slide-toggle
                    :open="toggleStates[6]"
                    class="show"
                    :duration="200"
                >
                    <div class="widget-body pt-0">
                        <div class="filter-items">
                            <div
                                class="filter-item"
                                v-for="(item, index) in grades"
                                :key="index"
                            >
                                <div class="custom-control custom-radio">
                                    <input
                                        type="radio"
                                        class="custom-control-input"
                                        :id="'grade-' + index"
                                        @click="gradeFilter(item)"
                                        :checked="gradeChecked(item)"
                                        name="grade"
                                    />
                                    <label
                                        class="custom-control-label"
                                        :for="'grade-' + index"
                                        >{{ item.name }}</label
                                    >
                                </div>
                            </div>
                        </div>
                    </div>
                </vue-slide-toggle>
            </div>
            <div class="widget widget-collapsible">
                <h3 class="widget-title mb-2">
                    <a
                        href="#widget-4"
                        :class="{ collapsed: !toggleStates[7] }"
                        @click.prevent="toggleSlide(7)"
                        >Social Profile</a
                    >
                </h3>

                <vue-slide-toggle
                    :open="toggleStates[7]"
                    class="show"
                    :duration="200"
                >
                    <div class="widget-body pt-0">
                        <div class="filter-items">
                            <div
                                class="filter-item"
                                v-for="(item, index) in types"
                                :key="index"
                            >
                                <div class="custom-control custom-radio">
                                    <input
                                        type="radio"
                                        class="custom-control-input"
                                        :id="'social-' + index"
                                        @click="socialFilter(item)"
                                        :checked="socialChecked(item)"
                                        name="social"
                                    />
                                    <label
                                        class="custom-control-label"
                                        :for="'social-' + index"
                                        >{{ item.name }}</label
                                    >
                                </div>
                            </div>
                        </div>
                    </div>
                </vue-slide-toggle>
            </div>
            <button class="sidebar-filter-clear" @click.prevent="searchAll"
                >search now</button
            >
            <!-- <div class="widget widget-collapsible">
                <h3 class="widget-title mb-2">
                    <a
                        href="#widget-4"
                        :class="{ collapsed: !toggleStates[4] }"
                        @click.prevent="toggleSlide(4)"
                        >Category</a
                    >
                </h3>

                <vue-slide-toggle
                    :open="toggleStates[4]"
                    class="show"
                    :duration="200"
                >
                    <div class="widget-body pt-0">
                        <div class="filter-items">
                            <div
                                class="filter-item"
                                v-for="(item, index) in filterData.Categories"
                                :key="index"
                            >
                                <div class="custom-control custom-checkbox">
                                    <input
                                        type="checkbox"
                                        class="custom-control-input"
                                        :id="'category-' + index"
                                        @click="setCategoryFilter(item)"
                                        :checked="categoryChecked(item)"
                                    />
                                    <label
                                        class="custom-control-label"
                                        :for="'category-' + index"
                                        >{{ item.category }}</label
                                    >
                                </div>
                            </div>
                        </div>
                    </div>
                </vue-slide-toggle>
            </div> -->

            <!-- <div class="widget widget-collapsible">
                <h3 class="widget-title mb-2">
                    <a
                        href="#widget-5"
                        :class="{ collapsed: !toggleStates[4] }"
                        @click.prevent="toggleSlide(4)"
                        >Price</a
                    >
                </h3>

                <vue-slide-toggle
                    :open="toggleStates[4]"
                    class="show"
                    :duration="200"
                >
                    <div class="widget-body pt-0">
                        <div class="filter-price">
                            <div
                                class="
                                    filter-price-text
                                    d-flex
                                    justify-content-between
                                "
                            >
                                <span>
                                    Price Range:
                                    <span id="filter-price-range">{{
                                        priceRangeText
                                    }}</span>
                                </span>
                                <nuxt-link
                                    :to="localePath(priceFilterRoute)"
                                    class="pr-2"
                                    >Filter</nuxt-link
                                >
                            </div>

                            <vue-nouislider
                                :config="priceSliderConfig"
                                :values="priceValues"
                                id="price-slider"
                                v-if="loaded"
                            ></vue-nouislider>
                        </div>
                    </div>
                </vue-slide-toggle>
            </div> -->
        </div>
    </div>
</template>

<script>
import { VueSlideToggle } from 'vue-slide-toggle';
import { places } from '~/utilities/data';
import Repository, { listinbaseUrl } from '~/repositories/repository.js';
export default {
    components: {
        VueSlideToggle,
    },
    props: {
        isSidebar: Boolean,
    },
    data: function () {
        return {
            loaded: true,
            priceValues: [0, 1000],
            priceSliderConfig: {
                connect: true,
                step: 50,
                margin: 100,
                range: {
                    min: 0,
                    max: 1000,
                },
            },
            toggleStates: [true, true, true, true, true, true, true, true],
            filterData: places,
            categories: [],
            subcategories: [],
            prices: [],
            ages: [],
            cities: [],
            areas: [],
            grades: [],
            types: [],
            categoryId: '',
            subCategoryId: '',
            priceRange: '',
            ageStage: '',
            cityId: '',
            areaId: '',
            gradeId: '',
            socialId: '',
        };
    },
    computed: {
        priceRangeText: function () {
            return (
                '$' +
                parseInt(this.priceValues[0]) +
                ' — $' +
                parseInt(this.priceValues[1])
            );
        },
        priceFilterRoute: function () {
            let query = {};
            if (this.$route.query.page) {
                for (let key in this.$route.query) {
                    if (key !== 'page') {
                        query[key] = this.$route.query[key];
                    }
                }
            } else {
                query = { ...this.$route.query };
            }

            return {
                path: this.$route.path,
                query: {
                    ...query,
                    minPrice: this.priceValues[0],
                    maxPrice: this.priceValues[1],
                },
            };
        },
    },
    created: function () {
        document
            .querySelector('body')
            .classList.remove('sidebar-filter-active');
        if (this.$route.query.minPrice && this.$route.query.maxPrice) {
            this.loaded = false;
            this.priceValues = [
                this.$route.query.minPrice,
                this.$route.query.maxPrice,
            ];
            this.$nextTick(function () {
                this.loaded = true;
            });
        } else {
            this.loaded = false;
            this.$nextTick(function () {
                this.loaded = true;
            });
        }
        this.getCategories();
        this.getPriceRange();
        this.getAges();
        this.getCities();
        this.getGrades();
        this.getsocial();
        // this.getSubCategories();
    },
    methods: {
        cleanAll: function () {
            this.loaded = false;
            this.priceValues = [0, 1000];
            this.$nextTick(function () {
                this.orderBy = 'default';
                this.loaded = true;
                this.$router.push(this.$route.path);
            });
        },
        toggleSlide: function (index) {
            this.toggleStates = this.toggleStates.reduce((acc, cur, id) => {
                if (id == index) return [...acc, !cur];
                else return [...acc, cur];
            }, []);
        },
        sizeChecked: function (item) {
            return (
                this.$route.query.size &&
                this.$route.query.size.split(',').includes(item.slug)
            );
        },
        categoryChecked: function (item) {
            return (
                this.$route.query.category &&
                this.$route.query.category.split(',').includes(item.slug)
            );
        },
        categorySelected: function (item) {
            this.getSubCategories(item);
            return (
                this.$route.query.category &&
                this.$route.query.category == item.name
            );
        },
        colorSelected: function (item) {
            return (
                this.$route.query.color &&
                this.$route.query.color.split(',').includes(item.color_name)
            );
        },
        setSizeFilter: function (item) {
            let query = {};
            if (this.$route.query.page) {
                for (let key in this.$route.query) {
                    if (key !== 'page') {
                        query[key] = this.$route.query[key];
                    }
                }
            } else {
                query = { ...this.$route.query };
            }

            if (!this.$route.query.size) {
                query = {
                    ...query,
                    size: item.slug,
                };
            } else if (this.$route.query.size.split(',').includes(item.slug)) {
                query = {
                    ...query,
                    size: query.size
                        .split(',')
                        .filter((slug) => slug !== item.slug)
                        .join(','),
                };
            } else {
                query = {
                    ...query,
                    size: [...query.size.split(','), item.slug].join(','),
                };
            }

            this.$router.push({
                path: this.$route.path,
                query: query,
            });
        },
        setCategoryFilter: function (item) {
            let query = {};
            if (this.$route.query.page) {
                for (let key in this.$route.query) {
                    if (key !== 'page') {
                        query[key] = this.$route.query[key];
                    }
                }
            } else {
                query = { ...this.$route.query };
            }

            if (!this.$route.query.brand) {
                query = {
                    ...query,
                    category: item.slug,
                };
            } else if (this.$route.query.brand.split(',').includes(item.slug)) {
                query = {
                    ...query,
                    category: query.category
                        .split(',')
                        .filter((slug) => slug !== item.slug)
                        .join(','),
                };
            } else {
                query = {
                    ...query,
                    category: [...query.category.split(','), item.slug].join(
                        ','
                    ),
                };
            }

            this.$router.push({
                path: this.$route.path,
                query: query,
            });
        },
        getColorUrl: function (item) {
            let query = {};

            if (!this.$route.query.color) {
                query = {
                    ...this.$route.query,
                    color: item.color_name,
                };
            } else if (
                this.$route.query.color.split(',').includes(item.color_name)
            ) {
                query = {
                    ...this.$route.query,
                    color: this.$route.query.color
                        .split(',')
                        .filter((slug) => slug !== item.color_name)
                        .join(','),
                };
            } else {
                query = {
                    ...this.$route.query,
                    color: [
                        ...this.$route.query.color.split(','),
                        item.color_name,
                    ].join(','),
                };
            }

            let temp = {};
            if (query.page) {
                for (let key in query) {
                    if (key !== 'page') {
                        temp[key] = query[key];
                    }
                }
            } else {
                temp = { ...query };
            }

            return {
                path: this.$route.path,
                query: temp,
            };
        },

        // category
        getCategories: async function () {
            await Repository.get(`${listinbaseUrl}/categories`)
                .then((resp) => {
                    // console.log('categories', resp);
                    this.categories = resp.data.categories;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        myCategoryChecked: function (item) {
            return (
                this.$route.query.category &&
                this.$route.query.category.includes(item.id)
            );

            // return (
            //     this.$route.query.category &&
            //     this.$route.query.category.split(',').includes(item.name)
            // );
        },
        setmyCategoryFilter: function (item) {
            this.getSubCategories(item);
            console.log('CategoryId', item.id);
            this.categoryId = item.id;
            let query = {};

            this.$router.push({
                path: this.$route.path,
                query: {
                    ...query,
                    category: this.categoryId,
                    // category: item.name.replace(/\s/g, '-'),
                },
            });
        },

        //subCategory
        getSubCategories: async function (item) {
            await Repository.post(`${listinbaseUrl}/subcategories`, {
                category_id: item.id,
            })
                .then((resp) => {
                    console.log('subcategories', resp);
                    // console.log(item.id);
                    this.subcategories = resp.data.subcategories;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        SubCategoryChecked: function (item) {
            return (
                this.$route.query.subCategory &&
                this.$route.query.subCategory.includes(item.id)
            );
        },
        setSubCategoryFilter: function (item) {
            console.log('subCategoryId', item.id);
            this.subCategoryId = item.id;
            

            this.$router.push({
                path: this.$route.path,
                query: {
                    ...this.$route.query,
                    subCategory: this.subCategoryId,
                    // subCategory: item.name.replace(/\s/g, '-'),
                },
            });

            // let query = {};
            // if (this.$route.query.page) {
            //     for (let key in this.$route.query) {
            //         if (key !== 'page') {
            //             query[key] = this.$route.query[key];
            //         }
            //     }
            // } else {
            //     query = { ...this.$route.query };
            // }

            // if (!this.$route.query.subCategory) {
            //     query = {
            //         ...query,
            //         subCategory: item.name.replace(/\s/g, '-'),
            //     };
            //     console.log('subCategoryId', item.id);
            //     this.subCategoryId = item.id;
            // } else if (
            //     this.$route.query.subCategory
            //         .split(',')
            //         .includes(item.name.replace(/\s/g, '-'))
            // ) {
            //     query = {
            //         ...query,
            //         subCategory: query.subCategory
            //             .split(',')
            //             .filter(
            //                 (name) => name !== item.name.replace(/\s/g, '-')
            //             )
            //             .join(','),
            //     };
            //     this.subCategoryId = item.id;
            // } else {
            //     query = {
            //         ...query,
            //         subCategory: [
            //             ...query.subCategory.split(','),
            //             item.name.replace(/\s/g, '-'),
            //         ].join(','),
            //     };
            // }

            // this.$router.push({
            //     path: this.$route.path,
            //     query: query,
            // });
        },

        // price
        async getPriceRange() {
            await Repository.get(`${listinbaseUrl}/price-ranges`)
                .then((resp) => {
                    // console.log('prices', resp);
                    this.prices = resp.data.prices;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        priceChecked: function (item) {
            return (
                this.$route.query.priceRange &&
                this.$route.query.priceRange.includes(item.id)
            );
        },
        setPriceFilter: function (item) {
            console.log('priceId', item.id);
            this.priceRange = item.id;
            // let query = {};

            this.$router.push({
                path: this.$route.path,
                query: {
                    ...this.$route.query,
                    priceRange: this.priceRange,
                    // priceRange: item.name.replace(/\s/g, '-'),
                },
            });
            // let query = {};
            // if (this.$route.query.page) {
            //     for (let key in this.$route.query) {
            //         if (key !== 'page') {
            //             query[key] = this.$route.query[key];
            //         }
            //     }
            // } else {
            //     query = { ...this.$route.query };
            // }

            // if (!this.$route.query.priceRange) {
            //     query = {
            //         ...query,
            //         priceRange: item.name.replace(/\s/g, '-'),
            //     };
            //     console.log('priceRange', item.id);
            //     this.priceRange = item.id;
            // } else if (
            //     this.$route.query.priceRange
            //         .split(',')
            //         .includes(item.name.replace(/\s/g, '-'))
            // ) {
            //     query = {
            //         ...query,
            //         priceRange: query.priceRange
            //             .split(',')
            //             .filter(
            //                 (name) => name !== item.name.replace(/\s/g, '-')
            //             )
            //             .join(','),
            //     };

            //     this.priceRange = item.id;
            // } else {
            //     query = {
            //         ...query,
            //         priceRange: [
            //             ...query.priceRange.split(','),
            //             item.name.replace(/\s/g, '-'),
            //         ].join(','),
            //     };
            // }

            // this.$router.push({
            //     path: this.$route.path,
            //     query: query,
            // });
        },

        // ages
        async getAges() {
            await Repository.get(`${listinbaseUrl}/age-stages`)
                .then((resp) => {
                    // console.log('ages', resp);
                    this.ages = resp.data.ages;
                })
                .catch((err) => {
                    console.log(err);
                });
        },

        ageChecked: function (item) {
            // let range = item.from + '-' + item.to;
            return (
                this.$route.query.range &&
                this.$route.query.range.includes(item.id)
            );
        },
        setAgeFilter: function (item) {
            let range = item.from + '-' + item.to;
            console.log('ageStage', item.id);
            this.ageStage = item.id;
            let query = {};

            this.$router.push({
                path: this.$route.path,
                query: {
                    ...this.$route.query,
                    ageStage: this.ageStage,
                    // ageStage: range.replace(/\s/g, '-'),
                },
            });
            // let query = {};
            // if (this.$route.query.page) {
            //     for (let key in this.$route.query) {
            //         if (key !== 'page') {
            //             query[key] = this.$route.query[key];
            //         }
            //     }
            // } else {
            //     query = { ...this.$route.query };
            // }

            // if (!this.$route.query.age) {
            //     query = {
            //         ...query,
            //         age: range,
            //     };
            //     console.log('age_stage_id', item.id);
            //     this.ageStage = item.id;
            // } else if (this.$route.query.age.split(',').includes(range)) {
            //     query = {
            //         ...query,
            //         age: query.age
            //             .split(',')
            //             .filter((range) => range !== range)
            //             .join(','),
            //     };

            //     this.ageStage = item.id;
            // } else {
            //     query = {
            //         ...query,
            //         age: [...query.age.split(','), range].join(','),
            //     };
            // }

            // this.$router.push({
            //     path: this.$route.path,
            //     query: query,
            // });
        },

        // cities
        getCities: async function () {
            await Repository.get(`${listinbaseUrl}/cities`)
                .then((resp) => {
                    // console.log('cities', resp);
                    this.cities = resp.data.cities;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        cityChecked: function (item) {
            return (
                this.$route.query.city &&
                this.$route.query.city.split(',').includes(item.id)
            );
        },
        cityFilter: function (item) {
            this.getAreas(item);
            console.log('cityId', item.id);
            this.cityId = item.id;
            // let query = {};

            this.$router.push({
                path: this.$route.path,
                query: {
                    ...this.$route.query,
                    city: this.cityId,
                    // city: item.name.replace(/\s/g, '-'),
                },
            });
            // let query = {};
            // if (this.$route.query.page) {
            //     for (let key in this.$route.query) {
            //         if (key !== 'page') {
            //             query[key] = this.$route.query[key];
            //         }
            //     }
            // } else {
            //     query = { ...this.$route.query };
            // }

            // if (!this.$route.query.city) {
            //     query = {
            //         ...query,
            //         city: item.name.replace(/\s/g, '-'),
            //     };
            //     console.log('cityId', item.id);
            //     this.cityId = item.id;
            // } else if (
            //     this.$route.query.city
            //         .split(',')
            //         .includes(item.name.replace(/\s/g, '-'))
            // ) {
            //     query = {
            //         ...query,
            //         city: query.city
            //             .split(',')
            //             .filter(
            //                 (name) => name !== item.name.replace(/\s/g, '-')
            //             )
            //             .join(','),
            //     };

            //     this.cityId = item.id;
            // } else {
            //     query = {
            //         ...query,
            //         city: [
            //             ...query.city.split(','),
            //             item.name.replace(/\s/g, '-'),
            //         ].join(','),
            //     };
            // }

            // this.$router.push({
            //     path: this.$route.path,
            //     query: query,
            // });
        },

        //areas
        getAreas: async function (item) {
            await Repository.post(`${listinbaseUrl}/areas`, {
                city_id: item.id,
            })
                .then((resp) => {
                    // console.log('areas', resp);
                    // console.log(item.id);
                    this.areas = resp.data.areas;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        areaChecked: function (item) {
            return (
                this.$route.query.area &&
                this.$route.query.area.split(',').includes(item.id)
            );
        },
        areaFilter: function (item) {
            console.log('areaId', item.id);
            this.areaId = item.id;
            // let query = {};

            this.$router.push({
                path: this.$route.path,
                query: {
                    ...this.$route.query,
                    area: this.areaId,
                    // area: item.name.replace(/\s/g, '-'),
                },
            });
            // let query = {};
            // if (this.$route.query.page) {
            //     for (let key in this.$route.query) {
            //         if (key !== 'page') {
            //             query[key] = this.$route.query[key];
            //         }
            //     }
            // } else {
            //     query = { ...this.$route.query };
            // }

            // if (!this.$route.query.area) {
            //     query = {
            //         ...query,
            //         area: item.name.replace(/\s/g, '-'),
            //     };
            //     console.log('areaId', item.id);
            //     this.areaId = item.id;
            // } else if (
            //     this.$route.query.area
            //         .split(',')
            //         .includes(item.name.replace(/\s/g, '-'))
            // ) {
            //     query = {
            //         ...query,
            //         area: query.area
            //             .split(',')
            //             .filter(
            //                 (name) => name !== item.name.replace(/\s/g, '-')
            //             )
            //             .join(','),
            //     };
            //     this.areaId = item.id;
            // } else {
            //     query = {
            //         ...query,
            //         area: [
            //             ...query.area.split(','),
            //             item.name.replace(/\s/g, '-'),
            //         ].join(','),
            //     };
            // }

            // this.$router.push({
            //     path: this.$route.path,
            //     query: query,
            // });
        },

        //grade types
        getGrades: async function (item) {
            await Repository.get(`${listinbaseUrl}/grade-types`)
                .then((resp) => {
                    // console.log('grades', resp);
                    // console.log(item.id);
                    this.grades = resp.data.grades;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        gradeChecked: function (item) {
            return (
                this.$route.query.grade &&
                this.$route.query.grade.split(',').includes(item.id)
            );
        },
        gradeFilter: function (item) {
            console.log('gradeId', item.id);
            this.gradeId = item.id;
            // let query = {};

            this.$router.push({
                path: this.$route.path,
                query: {
                    ...this.$route.query,
                    grade: this.gradeId,
                    // grade: item.name.replace(/\s/g, '-'),
                },
            });
            // let query = {};
            // if (this.$route.query.page) {
            //     for (let key in this.$route.query) {
            //         if (key !== 'page') {
            //             query[key] = this.$route.query[key];
            //         }
            //     }
            // } else {
            //     query = { ...this.$route.query };
            // }

            // if (!this.$route.query.grade) {
            //     query = {
            //         ...query,
            //         grade: item.name.replace(/\s/g, '-'),
            //     };
            //     console.log('gradeId', item.id);
            //     this.gradeId = item.id;
            // } else if (
            //     this.$route.query.grade
            //         .split(',')
            //         .includes(item.name.replace(/\s/g, '-'))
            // ) {
            //     query = {
            //         ...query,
            //         grade: query.grade
            //             .split(',')
            //             .filter(
            //                 (name) => name !== item.name.replace(/\s/g, '-')
            //             )
            //             .join(','),
            //     };

            //     this.gradeId = item.id;
            // } else {
            //     query = {
            //         ...query,
            //         grade: [
            //             ...query.grade.split(','),
            //             item.name.replace(/\s/g, '-'),
            //         ].join(','),
            //     };
            // }

            // this.$router.push({
            //     path: this.$route.path,
            //     query: query,
            // });
        },
        //social profiles
        getsocial: async function (item) {
            await Repository.get(`${listinbaseUrl}/social-profile-types`)
                .then((resp) => {
                    // console.log('types', resp);
                    // console.log(item.id);
                    this.types = resp.data.types;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        socialChecked: function (item) {
            return (
                this.$route.query.socialType &&
                this.$route.query.socialType.split(',').includes(item.id)
            );
        },
        socialFilter: function (item) {
            console.log('socialId', item.id);
            this.socialId = item.id;
            // let query = {};

            this.$router.push({
                path: this.$route.path,
                query: {
                    ...this.$route.query,
                    social: this.socialId,
                    // social: item.name.replace(/\s/g, '-'),
                },
            });
            // let query = {};
            // if (this.$route.query.page) {
            //     for (let key in this.$route.query) {
            //         if (key !== 'page') {
            //             query[key] = this.$route.query[key];
            //         }
            //     }
            // } else {
            //     query = { ...this.$route.query };
            // }

            // if (!this.$route.query.socialType) {
            //     query = {
            //         ...query,
            //         socialType: item.name.replace(/\s/g, '-'),
            //     };
            //     console.log('socialId', item.id);
            //     this.socialId = item.id;
            // } else if (
            //     this.$route.query.socialType
            //         .split(',')
            //         .includes(item.name.replace(/\s/g, '-'))
            // ) {
            //     query = {
            //         ...query,
            //         socialType: query.socialType
            //             .split(',')
            //             .filter(
            //                 (name) => name !== item.name.replace(/\s/g, '-')
            //             )
            //             .join(','),
            //     };

            //     this.socialId = item.id;
            // } else {
            //     query = {
            //         ...query,
            //         socialType: [
            //             ...query.socialType.split(','),
            //             item.name.replace(/\s/g, '-'),
            //         ].join(','),
            //     };
            // }

            // this.$router.push({
            //     path: this.$route.path,
            //     query: query,
            // });
        },

        searchAll() {
            this.$router.push({
                path: '/pages/places/advancedSearchResult',
                query: {
                    ...this.$route.query,
                },
            });
        },
    },
    mounted() {
        this.$router.push(this.$route.path);
    },
};
</script>
<style lang="scss" scoped>
.filters {
    font-size: 22px;
    color: #144b96;
    // color: #848c9e;
    font-weight: 500;
}
.sidebar-filter-clear {
    cursor: pointer;
}
</style>