var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "quickView-container m-4" }, [
    _c("div", { staticClass: "quickView-content horizontal skeleton-body" }, [
      _c(
        "div",
        {
          staticClass: "row skel-pro-single skel-quickview m-0",
          class: { loaded: _vm.loaded }
        },
        [
          _c("div", { staticClass: "col-lg-6 p-0 pr-2" }, [
            _c("div", { staticClass: "skel-product-gallery" }),
            _vm._v(" "),
            _c("div", { staticClass: "product-lg mb-1 position-relative" }, [
              _vm.product.new
                ? _c("span", { staticClass: "product-label label-new" }, [
                    _vm._v("New")
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.product.sale_price
                ? _c("span", { staticClass: "product-label label-sale" }, [
                    _vm._v("Sale")
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.product.top
                ? _c("span", { staticClass: "product-label label-top" }, [
                    _vm._v("Top")
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.product.stock === 0
                ? _c("span", { staticClass: "product-label label-out" }, [
                    _vm._v("Out Of Stock")
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "swiper-carousel swiper-1" }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "swiper",
                        rawName: "v-swiper:swiper1",
                        value: _vm.carouselSetting,
                        expression: "carouselSetting",
                        arg: "swiper1"
                      }
                    ],
                    on: { slideChangeTransitionEnd: _vm.slideChange }
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "swiper-wrapper" },
                      [
                        _c("div", { staticClass: "swiper-slide" }, [
                          _c("img", {
                            directives: [
                              {
                                name: "lazy",
                                rawName: "v-lazy",
                                value: _vm.product.main_image,
                                expression: "product.main_image"
                              }
                            ],
                            attrs: {
                              alt: "product",
                              width: _vm.product.width,
                              height: _vm.product.height
                            }
                          })
                        ]),
                        _vm._v(" "),
                        _vm._l(_vm.product.pictures, function(picture, index) {
                          return _c(
                            "div",
                            { key: index, staticClass: "swiper-slide" },
                            [
                              _c("img", {
                                directives: [
                                  {
                                    name: "lazy",
                                    rawName: "v-lazy",
                                    value: _vm.product.main_image,
                                    expression: "product.main_image"
                                  }
                                ],
                                attrs: {
                                  alt: "product",
                                  width: picture.width,
                                  height: picture.height
                                }
                              })
                            ]
                          )
                        })
                      ],
                      2
                    )
                  ]
                )
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "product-sm row px-2" })
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "col-lg-6 quickview-desc pl-0 pl-lg-4 pr-0 mt-3 mt-lg-0"
            },
            [
              _vm._m(0),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "product-summary pr-4" },
                [
                  _c("detail-one", {
                    staticClass: "mb-0",
                    attrs: { product: _vm.product }
                  })
                ],
                1
              )
            ]
          )
        ]
      )
    ]),
    _vm._v(" "),
    _c(
      "button",
      {
        staticClass: "mfp-close",
        attrs: { title: "Close (Esc)", type: "button" },
        on: {
          click: function($event) {
            return _vm.$emit("close")
          }
        }
      },
      [_c("span", [_vm._v("×")])]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "entry-summary row" }, [
      _c("div", { staticClass: "col-md-12" }, [
        _c("div", { staticClass: "entry-summary1" })
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-md-12" }, [
        _c("div", { staticClass: "entry-summary2" })
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }