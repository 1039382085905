import { render, staticRenderFns } from "./layOutContact.vue?vue&type=template&id=8b7617dc&scoped=true&lang=html&"
import script from "./layOutContact.vue?vue&type=script&lang=js&"
export * from "./layOutContact.vue?vue&type=script&lang=js&"
import style0 from "./layOutContact.vue?vue&type=style&index=0&id=8b7617dc&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8b7617dc",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/html/royalbeeseg.com/Demo-4/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('8b7617dc')) {
      api.createRecord('8b7617dc', component.options)
    } else {
      api.reload('8b7617dc', component.options)
    }
    module.hot.accept("./layOutContact.vue?vue&type=template&id=8b7617dc&scoped=true&lang=html&", function () {
      api.rerender('8b7617dc', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "layouts/layOutContact.vue"
export default component.exports