<template>
    <div>
        <div v-for="product in product" :key="product.id">
            <div class="product-details" v-if="product">
                <h1 class="product-title mb-4">{{ product.name }}</h1>
                <div class="ratings-container">
                </div>
                <div
                    class="product-price  justify-content-between col-4"
                    v-if="product.stock == 0"
                    key="outPrice"
                >
                    <span class="out-price"> {{ product.price }} </span>
                </div>
                <div class="product-price mt-2 justify-content-between col-6" v-else key="outPrice">
                    <span class="out-price text-danger mr-5"
                        > {{ product.price_after_discount }}</span
                    >
                    <span
                        class="out-price mr-2 ml-3 "
                        v-if="product.discount_int !== null"
                        > <del> {{ product.price }} جنية </del> </span
                    >
                </div>
                <div class="row">
                <div class="col-md-6">
                <h5>كود المنتج</h5>
                </div>
                <div class="col-md-6">
                    <h6>
                        <span> {{ product.unique_id }}</span>
                    </h6>
                </div>
                <div class="col-md-6">
                <h5>مواصفات المنتج</h5>
                </div>
                <div
                    class="col-md-6 first-attr"
                    v-for="x in product.attributes"
                    :key="x.id"
                >
                    <h6>
                        {{ x.attribute_type_name }} :<span> {{ x.name }}</span>
                    </h6>
                </div>
                </div>

                <template v-for="(element, index) in product.anotherAttributes">
                    <nuxt-link
                        class="d-flex anotherAttributes justify-content-between"
                        :to="
                            localePath(`/product/default/${element.product_id}`)"
                    >
                          <div class="row">
                <div class="col-md-6">
                <h5>احجام اخرى</h5>
                </div>
                <div
                            class="first-attr"
                            v-for="x in element.attributes"
                            :key="(index,x)"
                        >
                            <p>
                                {{ x.attribute_type_name }} :<span>
                                    {{ x.name }}</span
                                >
                            </p>
                        </div>
                        </div>
                    </nuxt-link>
                </template>

                <div class="product-details-footer">
                    <div class="wishlist-outline d-flex justify-content-start">
                        <nuxt-link
                            :to="localePath('/shop/wishlist')"
                            class="btn-product btn-wishlist added-to-wishlist"
                            v-if="isInWishlist(product)"
                            key="inWishlist"
                        >
                            <span>{{ $t('product.inWishlist') }}</span>
                        </nuxt-link>
                        <a
                            class="btn-product btn-wishlist"
                            @click.prevent="addToWishlist({ product: product })"
                            v-else
                            key="notInWishlist"
                        >
                            <span>{{ $t('product.addToWishlist') }}</span>
                        </a>
                    </div>
                    <div class="wishlist-outline">
                        <button
                            class="btn-product btn-cart"
                            @click.prevent="addToCart({ product: product })"
                        >
                            <span>{{ $t('product.addToCart') }}</span>
                        </button>
                    </div>
                </div>
                <div class="order-date-box">
                    <div class="row">
                        <div class="col-md-8 col-sm-12">
                            <p v-if="$i18n.locale == 'en'">
                                <span class="order-now">Order Now </span>and get
                                it by
                                <span class="date" v-if="days > 0"><b>{{days}} days</b></span>
                                <span class="date" v-else><b>today </b></span>
                            </p>
                            <p v-else>
                                <span class="order-now">اطلب الان </span>وتحصل
                                عليه فى
                                <span class="date" v-if="days > 0"><b>{{days}} يوم </b></span>
                                <span class="date" v-else><b>نفس اليوم </b></span>
                            </p>
                        </div>
                        <div class="col-md-4 col-sm-12">
                            <p v-if="$i18n.locale == 'en'">
                                | &nbsp; to
                                <span class="date"><b>Egypt</b></span>
                            </p>
                            <p v-else>
                                | &nbsp; الى
                                <span class="date"><b>مصر</b></span>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="bayment mt-3">
                    <div class="row">
                        <div class="col-md-6 col-sm-12">
                            <p>
                                <span class="mr-2"
                                    ><i class="fas fa-shield-alt"></i
                                ></span>
                                <span>{{ $t('payment.payment') }}</span>
                            </p>
                        </div>
                        <div class="col-md-6 col-sm-12">
                            <p>
                                <span class="mr-2"
                                    ><i class="fas fa-shield-alt"></i
                                ></span>
                                <span v-if="$i18n.locale == 'en'"
                                    >100% Authentic</span
                                >
                                <span v-else>أصلى 100%</span>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="returns mt-3">
                    <h4 v-if="$i18n.locale == 'en'">Returns:</h4>
                    <h4 v-else>المرتجع</h4>
                    <h5 v-if="$i18n.locale == 'en'">
                        Returns within 7 days of delivery.
                    </h5>
                    <h5 v-else>يتم استرجاع المنتج فى 7 ايام</h5>
                    <div class="product-details-footer mt-3">
                        <h5 v-if="$i18n.locale == 'en'">
                            Purchase this item and earn
                            <span class="points">{{ points }}</span>
                        </h5>
                        <h5 v-else>
                            شراء هذا العنصر وكسب
                            <span class="points">{{points}} نقطة </span>
                        </h5>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { VueSlideToggle } from 'vue-slide-toggle';
import { baseUrl } from '~/repositories/repository.js';
import { mapGetters, mapActions } from 'vuex';
import axios from 'axios';
export default {
    components: {
        VueSlideToggle
    },
    props: {
        product: {
            type: Array
        }
    },
    data: function() {
        return {
            points: '',
            days:''
        };
    },
    methods: {
        ...mapActions('cart', ['addToCart']),
        ...mapActions('wishlist', ['addToWishlist']),
        ...mapActions('compare', ['addToCompare']),
        getPaymentData() {
            let token = sessionStorage.getItem('token');
            let data = { id: this.$route.params.slug, api_token: token };
            var config = {
                method: 'post',
                url: 'https://admin.royalbeeseg.com/public/api/product',
                data: data
            };

            axios(config)
                .then(resp => {
                    // console.log('payment', resp);a
                    this.points = resp.data.points;
                    this.days = resp.data.total_delivery_days
                })
                .catch(error => {
                    console.log(error);
                });
        }
    },
    computed: {
        ...mapGetters('cart', ['canAddToCart']),
        ...mapGetters('wishlist', ['isInWishlist']),
        ...mapGetters('compare', ['isInCompare'])
    },
    created() {
        this.getPaymentData();
    }
};
</script>
<style lang="scss" scoped>
.product-content {
    word-break: break-all;
}
.wishlist-outline {
    border: 2px dotted #ccc;
    padding: 0 20px;
    margin: 20px;
    transition: all 0.3s ease-in-out;
    &:hover {
        border: 2px solid #fcb932;
        transform: scale(1.1);
    }
}
.order-date-box {
    padding: 15px;
    background-color: #eef5f8;
    color: #000;
    .order-now {
        color: #71bc69;
        font-size: 15px;
    }
    .date {
        color: #302f2f;
    }
}
.bayment {
    padding: 15px;
    background-color: #f8f8f8;
    color: #302f2f;
    font-weight: bold;
}
.returns {
    .points {
        color: #77c1e6;
    }
}
.vendor_name {
    text-decoration: underline;
    font-size: 20px;
}
.product-details-footer {
    justify-content: start;
}
.anotherAttributes {
    p{
        transition: 0.3s;
    }
    p:hover {
        color: #e98411;
    }
}
p,div,a,h1{
        font-family: 'CairoRegular'
}
</style>
