var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "skeleton-body" }, [
    _c(
      "div",
      { staticClass: "skel-pro-single", class: { loaded: _vm.loaded } },
      [
        _c("div", { staticClass: "skel-widget" }),
        _vm._v(" "),
        _c("div", { staticClass: "skel-widget" }),
        _vm._v(" "),
        _c(
          "div",
          {
            class: _vm.toggleState
              ? "sidebar-filter right sidebar-product"
              : "sidebar sidebar-product"
          },
          [
            _c(
              "button",
              {
                staticClass: "mfp-close",
                attrs: { title: "Close (Esc)", type: "button" },
                on: { click: _vm.toggleSidebar }
              },
              [_c("span", [_vm._v("×")])]
            ),
            _vm._v(" "),
            _c("related-products-two", {
              attrs: { products: _vm.relatedProducts }
            }),
            _vm._v(" "),
            _c("div", { staticClass: "widget widget-banner-sidebar" }, [
              _c("div", { staticClass: "banner-sidebar-title" }, [
                _vm._v("ad box 280 x 280")
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "banner-sidebar banner-overlay" },
                [
                  _c(
                    "nuxt-link",
                    { attrs: { to: _vm.localePath("/shop/sidebar/4cols") } },
                    [
                      _c("img", {
                        directives: [
                          {
                            name: "lazy",
                            rawName: "v-lazy",
                            value: "./images/blog/sidebar/banner.jpg",
                            expression: "'./images/blog/sidebar/banner.jpg'"
                          }
                        ],
                        attrs: { alt: "banner" }
                      })
                    ]
                  )
                ],
                1
              )
            ])
          ],
          1
        ),
        _vm._v(" "),
        _vm.toggleState
          ? _c(
              "button",
              {
                staticClass: "sidebar-fixed-toggler right",
                on: { click: _vm.toggleSidebar }
              },
              [_c("i", { staticClass: "icon-cog" })]
            )
          : _vm._e(),
        _vm._v(" "),
        _c("div", {
          staticClass: "sidebar-filter-overlay",
          on: { click: _vm.hideSidebar }
        })
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }