var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      !_vm.loading
        ? [
            _c(
              "div",
              { staticClass: "col-9 m-auto skeleton-body skel-shop-products" },
              [
                _vm._l(1, function(item) {
                  return _c("div", {
                    key: item,
                    staticClass: "skel-pro skel-pro-list"
                  })
                })
              ],
              2
            )
          ]
        : _c(
            "div",
            {},
            _vm._l(_vm.banners.seventh, function(banner) {
              return _c(
                "section",
                {
                  directives: [
                    {
                      name: "lazy",
                      rawName: "v-lazy:background-image",
                      value: banner.background_image,
                      expression: "banner.background_image",
                      arg: "background-image"
                    }
                  ],
                  key: banner.id,
                  staticClass: "super-sale"
                },
                [
                  _c("div", { staticClass: "super-sale-holder" }, [
                    _c("div", { staticClass: "container" }, [
                      _c("div", { staticClass: "holder p-5 p" }, [
                        _c("h2", { staticClass: "h1 hug text-white" }, [
                          _vm._v(
                            "\n                            " +
                              _vm._s(banner.title) +
                              "\n                        "
                          )
                        ]),
                        _vm._v(" "),
                        _c("p", { staticClass: "text-dark" }, [
                          _vm._v(
                            "\n                            " +
                              _vm._s(banner.description) +
                              "\n                        "
                          )
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "my-5" },
                          [
                            _c(
                              "nuxt-link",
                              {
                                staticClass: "btn toyButton text-white",
                                attrs: {
                                  to: _vm.localePath("/shop/sidebar/list")
                                }
                              },
                              [_c("span", [_vm._v("Order Now")])]
                            )
                          ],
                          1
                        )
                      ])
                    ])
                  ])
                ]
              )
            }),
            0
          )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }