import { render, staticRenderFns } from "./fourthfirst.vue?vue&type=template&id=1ab6f03c&"
import script from "./fourthfirst.vue?vue&type=script&lang=js&"
export * from "./fourthfirst.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/html/royalbeeseg.com/Demo-4/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1ab6f03c')) {
      api.createRecord('1ab6f03c', component.options)
    } else {
      api.reload('1ab6f03c', component.options)
    }
    module.hot.accept("./fourthfirst.vue?vue&type=template&id=1ab6f03c&", function () {
      api.rerender('1ab6f03c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "components/alf/homeComponents/fourth/fourthfirst.vue"
export default component.exports