<template>
    <div class="sidebar-shop" :class="isSidebar ? 'sidebar-filter' : 'sidebar'">
        <div :class="{ 'sidebar-filter-wrapper': isSidebar }">
            <div class="widget widget-clean">
                <label class="filters"> فلتر ب </label>
                <a class="sidebar-filter-clear" @click.prevent="cleanAll"
                    >مسح الكل</a
                >
            </div>
            <div class="widget widget-collapsible">
                <h3 class="widget-title mb-2">
                    <a
                        href="#widget-1"
                        :class="{ collapsed: !toggleStates[0] }"
                        @click.prevent="toggleSlide(0)"
                    >
                        {{ $t('productsSidebar.category') }}
                    </a>
                </h3>

                <vue-slide-toggle
                    :open="toggleStates[0]"
                    class="show"
                    :duration="200"
                >
                    <div class="widget-body pt-0">
                        <div class="filter-items filter-items-count">
                            <div class="filter-items">
                                <div
                                    class="filter-item"
                                    v-for="(item, index) in categories"
                                    :key="index"
                                >
                                    <div class="custom-control custom-checkbox">
                                        <input
                                            type="checkbox"
                                            class="custom-control-input"
                                            :id="'category-' + index"
                                            @click="setmyCategoryFilter(item)"
                                            :checked="myCategoryChecked(item)"
                                        />
                                        <label
                                            class="custom-control-label"
                                            :for="'category-' + index"
                                            >{{ item.name }}</label
                                        >
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </vue-slide-toggle>
            </div>
            <div
                class="widget widget-collapsible mt-3"
                v-if="$route.query.category && subcategories !== ''"
            >
                <h3 class="widget-title mb-2">
                    <a
                        href="#widget-2"
                        :class="{ collapsed: !toggleStates[1] }"
                        @click.prevent="toggleSlide(1)"
                        >{{ $t('productsSidebar.subCategory') }}</a
                    >
                </h3>

                <vue-slide-toggle
                    :open="toggleStates[1]"
                    class="show"
                    :duration="200"
                >
                    <div class="widget-body pt-0">
                        <div class="filter-items">
                            <div
                                class="filter-item"
                                v-for="(item, index) in subcategories"
                                :key="index"
                            >
                                <div class="custom-control custom-checkbox">
                                    <input
                                        type="checkbox"
                                        class="custom-control-input"
                                        :id="'subcategory-' + index"
                                        @click="setSubCategoryFilter(item)"
                                        :checked="SubCategoryChecked(item)"
                                        name="subcategory"
                                    />
                                    <label
                                        class="custom-control-label"
                                        :for="'subcategory-' + index"
                                        >{{ item.name }}</label
                                    >
                                </div>
                            </div>
                        </div>
                    </div>
                </vue-slide-toggle>
            </div>
        </div>
    </div>
</template>

<script>
import { VueSlideToggle } from 'vue-slide-toggle';
import { places } from '~/utilities/data';
import Repository, { baseUrl } from '~/repositories/repository.js';
export default {
    components: {
        VueSlideToggle
    },
    props: {
        isSidebar: Boolean
    },
    data: function() {
        return {
            loaded: true,
            priceValues: [0, 1000],
            priceSliderConfig: {
                connect: true,
                step: 50,
                margin: 100,
                range: {
                    min: 0,
                    max: 1000
                }
            },
            toggleStates: [true, true, true, true, true, true, true, true],
            filterData: places,
            categories: [],
            subcategories: [],
            prices: [],
            ages: [],
            cities: [],
            areas: [],
            grades: [],
            types: [],
            categoryId: '',
            subCategoryId: '',
            priceRange: '',
            ageStage: '',
            cityId: '',
            areaId: '',
            gradeId: '',
            socialId: '',

            colorName: '',
            colorAttr: [],
            colorAttrId: '',
            sizeName: '',
            sizeAttr: [],
            sizeAttrId: '',
            matrialName: '',
            matrialAttr: [],
            matrialAttrId: '',
            genderName: '',
            genderAttr: [],
            genderAttrId: '',
            brands: [],
            brandId: '',
            perPage: 5,
            products: [],
            isActive: false,
            attr: '',
            values: '',
            vendor: '',
            subCategoryValue: '',
            filterSubcategories: []
        };
    },
    created: function() {
        document
            .querySelector('body')
            .classList.remove('sidebar-filter-active');

        this.getCategories();

        this.getBrands();
        this.getAttr();
    },
    methods: {
        cleanAll: function() {
            this.loaded = false;
            this.priceValues = [0, 1000];
            this.$nextTick(function() {
                this.orderBy = 'default';
                this.loaded = true;
                this.$router.push(this.$route.path);
            });
        },
        toggleSlide: function(index) {
            this.toggleStates = this.toggleStates.reduce((acc, cur, id) => {
                if (id == index) return [...acc, !cur];
                else return [...acc, cur];
            }, []);
        },

        // category
        getCategories: async function() {
            await Repository.get(`${baseUrl}/categoryTree`)
                .then(resp => {
                    // console.log('categories', resp);
                    this.categories = resp.data.categories;
                    console.log(this.categories);
                })
                .catch(err => {
                    console.log(err);
                });
        },
        myCategoryChecked: function(item) {
            return (
                this.$route.query.category &&
                this.$route.query.category.split(',').includes(String(item.id))
            );
        },
        setmyCategoryFilter: function(item) {
            this.categoryId = item.id;
            // let query = {};
            this.isActive = 'd-none';

            let query = {};
            if (this.$route.query.page) {
                for (let key in this.$route.query) {
                    if (key !== 'page') {
                        query[key] = this.$route.query[key];
                    }
                }
            } else {
                query = { ...this.$route.query };
            }

            if (!this.$route.query.category) {
                query = {
                    ...query,
                    category: String(item.id)
                };
            } else if (
                this.$route.query.category &&
                this.$route.query.category.split(',').includes(String(item.id))
            ) {
                query = {
                    ...query,
                    category: query.category
                        .split(',')
                        .filter(slug => slug !== String(item.id))
                        .join(',')
                };
            } else {
                query = {
                    ...query,
                    category: [
                        ...query.category.split(','),
                        String(item.id)
                    ].join(',')
                };
            }

            query = {};
            this.$router.push({
                path: this.localePath('/shop/sidebar/advancedProducts'),

                query: {
                    ...this.$route.query,
                    category: this.categoryId
                }
                // query: query
            });
            var a = query.category;
            console.log('ss', a);
            if(a){
                var b = a.split(',').map(function(item) {
                    return parseInt(item, 10);
                });
            }
           
            console.log('bbbb', b);
            this.values = b;
            console.log('q', this.values);
            this.getSubCategories(item);
        },

        //subCategory
        getSubCategories: async function(item) {
            console.log('pla');
            await Repository.post(
                `${baseUrl}/products/filter_by_category`,
                {
                    category_ids: this.values
                },
                {
                    params: {
                        per_page: this.perPage
                    }
                }
            )
                .then(resp => {
                    console.log('advanced products', resp);
                    this.subcategories = resp.data.sub_categories;
                    this.products = resp.data.products;
                    console.log('this.attr', this.attr);
                    if (resp.data.code == 200) {
                        this.loaded = true;
                    } else {
                        this.loaded = false;
                    }
                })
                .catch(err => {
                    console.log('advanced products error', err);
                    console.log(err);
                });
        },
        SubCategoryChecked: function(item) {
            return (
                this.$route.query.subCategory &&
                this.$route.query.subCategory
                    .split(',')
                    .includes(String(item.id))
            );
        },
        setSubCategoryFilter: function(item) {
            let query = {};
            if (this.$route.query.page) {
                for (let key in this.$route.query) {
                    if (key !== 'page') {
                        query[key] = this.$route.query[key];
                    }
                }
            } else {
                query = { ...this.$route.query };
            }

            if (!this.$route.query.subCategory) {
                query = {
                    ...query,
                    subCategory: String(item.id)
                };
            } else if (
                this.$route.query.subCategory
                    .split(',')
                    .includes(String(item.id))
            ) {
                query = {
                    ...query,
                    subCategory: query.subCategory
                        .split(',')
                        .filter(slug => slug !== String(item.id))
                        .join(',')
                };
            } else {
                query = {
                    ...query,
                    subCategory: [
                        ...query.subCategory.split(','),
                        String(item.id)
                    ].join(',')
                };
            }
            this.$router.push({
                path: this.$route.path,
                query: query
            });
            var subCategory = query.subCategory;
            console.log('a', subCategory);
            if(subCategory){
                var b = subCategory.split(',').map(function(item) {
                    return parseInt(item, 10);
                });
            }
            
            console.log('bb', b);
            this.subCategoryValue = b;
            this.filterSUbCategory(item);
        },
        // filter SUb Category
        filterSUbCategory: async function(item) {
            await Repository.post(
                `${baseUrl}/products/filter_by_subcategory`,
                {
                    subcategory_ids: this.subCategoryValue
                },
                {
                    params: {
                        per_page: this.perPage
                    }
                }
            )
                .then(resp => {
                    console.log('resp', resp);
                    this.filterSubcategories = resp.data.products;
                    // this.products = resp.data.products;
                    console.log(
                        ' this.filterSubcategories',
                        this.filterSubcategories
                    );
                    if (resp.data.code == 200) {
                        this.loaded = true;
                    } else {
                        this.loaded = false;
                    }
                })
                .catch(err => {
                    console.log('advanced products error', err);
                    console.log(err);
                });
        },

        // brands
        getBrands: async function() {
            await Repository.get(`${baseUrl}/vendors_logo`)
                .then(resp => {
                    // console.log('categories', resp);
                    this.brands = resp.data.brands;
                    console.log('jjj', this.brands);
                })
                .catch(err => {
                    console.log(err);
                });
        },
        myBrandsChecked: function(item) {
            return (
                this.$route.query.brand &&
                this.$route.query.brand.includes(item.id)
            );
        },
        setmyBrandsFilter: function(item) {
            console.log('CategoryId', item.id);
            this.brandId = item.id;

            let query = {};
            if (this.$route.query.page) {
                for (let key in this.$route.query) {
                    if (key !== 'page') {
                        query[key] = this.$route.query[key];
                    }
                }
            } else {
                query = { ...this.$route.query };
            }

            if (!this.$route.query.brand) {
                query = {
                    ...query,
                    brand: String(item.id)
                };
            } else if (
                this.$route.query.brand &&
                this.$route.query.brand.split(',').includes(String(item.id))
            ) {
                query = {
                    ...query,
                    brand: query.brand
                        .split(',')
                        .filter(slug => slug !== String(item.id))
                        .join(',')
                };
            } else {
                query = {
                    ...query,
                    brand: [...query.brand.split(','), String(item.id)].join(
                        ','
                    )
                };
            }
            this.$router.push({
                path: this.localePath('/shop/sidebar/advancedBrand'),
                query: query
            });
        },
        // gat attr
        getAttr: async function() {
            await Repository.get(`${baseUrl}/attributeTree`)
                .then(resp => {
                    this.attributes = resp.data.attributes;
                    for (var x = 0; x <= this.attributes.length; x++) {
                        this.colorName = this.attributes[0].name;
                        console.log('this.colorName', this.colorName);
                        this.colorAttr = this.attributes[0].attributes;
                        console.log('this.', this.colorAttr);
                        this.sizeName = this.attributes[1].name;
                        this.sizeAttr = this.attributes[1].attributes;
                        this.matrialName = this.attributes[2].name;
                        this.matrialAttr = this.attributes[2].attributes;
                        this.genderName = this.attributes[3].name;
                        this.genderAttr = this.attributes[3].attributes;
                    }
                })
                .catch(err => {
                    console.log(err);
                });
        },
        setcolorAttrFilter: function(item) {
            console.log('CategoryId', item.id);
            this.colorAttrId = item.id;
            this.sizeAttrId = item.id;
            this.matrialAttrId = item.id;
            // let query = {};

            let query = {};
            if (this.$route.query.page) {
                for (let key in this.$route.query) {
                    if (key !== 'page') {
                        query[key] = this.$route.query[key];
                    }
                }
            } else {
                query = { ...this.$route.query };
            }

            if (!this.$route.query.colorAttr) {
                query = {
                    ...query,
                    colorAttr: String(item.id)
                };
            } else if (
                this.$route.query.colorAttr &&
                this.$route.query.colorAttr.split(',').includes(String(item.id))
            ) {
                query = {
                    ...query,
                    colorAttr: query.colorAttr
                        .split(',')
                        .filter(slug => slug !== String(item.id))
                        .join(',')
                };
            } else {
                query = {
                    ...query,
                    colorAttr: [
                        ...query.colorAttr.split(','),
                        String(item.id)
                    ].join(',')
                };
            }
            // this.$router.push({
            //     path: this.$route.path,
            //     query: query
            // });
            this.$router.push({
                path: this.localePath('/shop/sidebar/advancedAttr'),
                // query: {
                //     ...this.$route.query
                // }
                query: query
            });
        },
        colorAttrChecked: function(item) {
            return (
                this.$route.query.colorAttr &&
                this.$route.query.colorAttr.includes(item.id)
            );
        },

        setsizeAttrFilter: function(item) {
            console.log('CategoryId', item.id);
            this.sizeAttrId = item.id;
            // let query = {};

            let query = {};
            if (this.$route.query.page) {
                for (let key in this.$route.query) {
                    if (key !== 'page') {
                        query[key] = this.$route.query[key];
                    }
                }
            } else {
                query = { ...this.$route.query };
            }

            if (!this.$route.query.sizeAttr) {
                query = {
                    ...query,
                    sizeAttr: String(item.id)
                };
            } else if (
                this.$route.query.sizeAttr &&
                this.$route.query.sizeAttr.split(',').includes(String(item.id))
            ) {
                query = {
                    ...query,
                    sizeAttr: query.sizeAttr
                        .split(',')
                        .filter(slug => slug !== String(item.id))
                        .join(',')
                };
            } else {
                query = {
                    ...query,
                    sizeAttr: [
                        ...query.sizeAttr.split(','),
                        String(item.id)
                    ].join(',')
                };
            }
            // this.$router.push({
            //     path: this.$route.path,
            //     query: query
            // });
            this.$router.push({
                path: this.localePath('/shop/sidebar/advancedAttr'),
                // query: {
                //     ...this.$route.query
                // }
                query: query
            });
        },
        sizeAttrChecked: function(item) {
            return (
                this.$route.query.sizeAttr &&
                this.$route.query.sizeAttr.includes(item.id)
            );
        },

        setmatrialAttrFilter: function(item) {
            console.log('CategoryId', item.id);
            this.matrialAttrId = item.id;
            // let query = {};

            this.$router.push({
                path: this.$route.path,
                query: {
                    ...this.$route.query,
                    matrialAttr: this.matrialAttrId
                }
            });
        },
        matrialAttrChecked: function(item) {
            return (
                this.$route.query.matrialAttr &&
                this.$route.query.matrialAttr.includes(item.id)
            );
        },

        setgenderAttrFilter: function(item) {
            console.log('CategoryId', item.id);
            this.genderAttrId = item.id;
            // let query = {};

            this.$router.push({
                path: this.$route.path,
                query: {
                    ...this.$route.query,
                    genderAttr: this.genderAttrId
                    // category: item.name.replace(/\s/g, '-'),
                }
            });
        },
        genderAttrChecked: function(item) {
            return (
                this.$route.query.genderAttr &&
                this.$route.query.genderAttr.includes(item.id)
            );
        }
        // searchAll() {
        //     this.$router.push({
        //         path: '/shop/sidebar/advancedProducts',
        //         query: {
        //             ...this.$route.query
        //         }
        //     });
        // }
    }
    // mounted() {
    //     this.$router.push(this.$route.path);
    // },
};
</script>
<style lang="scss" scoped>
.filters {
    font-size: 22px;
    size: #144b96;
    // color: #848c9e;
    font-weight: 500;
}
.sidebar-filter-clear {
    cursor: pointer;
}
.color {
    display: none;
}
a.sidebar-filter-clear {
    margin: auto;
    margin-left: 0;
    font-size: 15px;
}
</style>
