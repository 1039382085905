import { render, staticRenderFns } from "./contactHeader.vue?vue&type=template&id=54caa86a&scoped=true&"
import script from "./contactHeader.vue?vue&type=script&lang=js&"
export * from "./contactHeader.vue?vue&type=script&lang=js&"
import style0 from "./contactHeader.vue?vue&type=style&index=0&id=54caa86a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "54caa86a",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/html/royalbeeseg.com/Demo-4/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('54caa86a')) {
      api.createRecord('54caa86a', component.options)
    } else {
      api.reload('54caa86a', component.options)
    }
    module.hot.accept("./contactHeader.vue?vue&type=template&id=54caa86a&scoped=true&", function () {
      api.rerender('54caa86a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "components/partial/headers/contactHeader.vue"
export default component.exports