<template>
    <div>
        <!-- new  -->

        <First />

        <Second />

        <Third />

        <Fourth />

    </div>
</template>

<script>
import LazyHydrate from 'vue-lazy-hydration';

export default {
    components: {
        LazyHydrate,
        First: () => import('~/components/alf/homeComponents/first/first.vue'),
        Second: () =>
            import('~/components/alf/homeComponents/first/second.vue'),
        Third: () => import('~/components/alf/homeComponents/first/third.vue'),
        Fourth: () =>
            import('~/components/alf/homeComponents/first/fourth.vue'),
    },
};
</script>

<style>
</style>