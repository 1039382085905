<template>
    <div>
        <template v-if="!loading">
            <div class="col-9 m-auto skeleton-body skel-shop-products">
                <template>
                    <div
                        class="skel-pro skel-pro-list"
                        v-for="item in 1"
                        :key="item"
                    ></div>
                </template>
            </div>
        </template>
        <div class="" v-else>
            <section class="toy2">
                <div class="container text-center">
                    <div class="row">
                        <div
                            class="col-md-4 col-sm-12"
                            v-for="second in secondListPhotos"
                            :key="second.id"
                        >
                            <div
                                class="toy-holder text-center"
                                v-lazy:background-image="second.first_image"
                            >
                                <h3
                                    class="text-white"
                                    v-if="$i18n.locale == 'en'"
                                >
                                    {{ second.title.en }}
                                </h3>
                                <h3 class="text-white" v-else>
                                    {{ second.title.en }}
                                </h3>
                                <img
                                    v-lazy="second.second_image"
                                    class="bg-transparent"
                                    alt="Alf Logo"
                                    height="27"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import axios from 'axios';
import {
    carouselSettingSingle,
    carouselSettingDefault,
} from '~/utilities/carousel';
import Repository, { baseUrl } from '~/repositories/repository.js';
export default {
    data: function () {
        return {
            firstListPhotos: [],
            secondListPhotos: [],
            thirdListPhotos: [],
            loading: false,
        };
    },
    methods: {
        getData: async function () {
            let listPhotos = `${baseUrl}/list_photos`;
            // list photos

            await Repository.get(listPhotos).then((resp) => {
                if (resp.data.code == 200) {
                    this.loading = true;
                }
                this.firstListPhotos = resp.data.list_photos_type1;
                this.secondListPhotos = resp.data.list_photos_type2;
                this.thirdListPhotos = resp.data.list_photos_type3;
                // console.log('listPhotos', resp);
            });
        },
    },
    mounted() {
        this.getData();
    },
    computed: {
        ...mapGetters('cart', ['canAddToCart']),
        ...mapGetters('wishlist', ['isInWishlist']),
        ...mapGetters('compare', ['isInCompare']),
    },
    props: ['product'],
};
</script>

<style>
</style>