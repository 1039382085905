<template>
    <div>
        <!-- new arrival  -->
        <template v-if="!loading">
            <div class="col-9 m-auto skeleton-body skel-shop-products">
                <template>
                    <div
                        class="skel-pro skel-pro-list"
                        v-for="item in 1"
                        :key="item"
                    ></div>
                </template>
            </div>
        </template>
        <div
            class="newArrival"
            id="recommendations"
            v-else
            v-lazy:background-image="'./images/home/honeycomb-with-jar (4).png'"
        >
            <div class="img">
                <img v-lazy="'./images/home/Asset 2@2x.png'" />
            </div>
            <section
                v-if="newArrival.length > 0"
                class="
                    baby-producst
                    recommendations
                    text-center
                    
                    fourth-carousel
                "
            >
                <div class="wrapper-with-margin">
                    <div class="container">
                        <div class="row align-items-center mb-5">
                            <div class="col-6">
                                <h1 class="text-right text-gray m-3 mb-2">
                                    احدث العروض
                                </h1>
                            </div>
                            <hr>
                            <div class="col-6 show-all">
                                <nuxt-link
                                    :to="localePath('/shop/sidebar/list')"
                                    class="btn btn-primary btn-round p-4"
                                >
                                    <span>عرض الكل</span>
                                </nuxt-link>
                            </div>
                        </div>
                        <div
                            class="
                                swiper-carousel
                                new-arrival
                                swiper-2
                                pb-5
                                mt-5
                            "
                        >
                            <div v-swiper:swiper9="carouselSetting9">
                                <div class="swiper-wrapper">
                                    <div
                                        class="swiper-slide"
                                        v-for="myNew in newArrival"
                                        :key="myNew.id"
                                    >
                                        <div class="rating card-info">
                                            <i class="fas fa-star"></i>
                                            <span>4.5</span>
                                        </div>
                                        <div class="card-holder">
                                            <div class="card text-center">
                                                <div
                                                    class="card-info text-white"
                                                    v-if="
                                                        myNew.discount_int !== null
                                                            
                                                    "
                                                >
                                                    Sale!
                                                </div>
                                                <div class="image-box">
                                                    <nuxt-link
                                                        :to="
                                                            localePath(
                                                                `/product/default/${myNew.id}`
                                                            )
                                                        "
                                                    >
                                                   
                                                        <img
                                                            v-if="
                                                                myNew.product_main_image !==''
                                                            "
                                                            v-lazy="
                                                                myNew.product_main_image
                                                            "
                                                            class="
                                                                bg-transparent
                                                            "
                                                            alt="Alf Logo"
                                                            height="27"
                                                        />
                                                        
                                                        <img
                                                            v-else
                                                            v-lazy="
                                                                '~/static/images/Alf/alf/recomm/sv-row10301-dockatot-deluxe-pod-pristine-white-1605564557.png'
                                                            "
                                                            class="
                                                                bg-transparent
                                                            "
                                                            alt="Alf Logo"
                                                            height="27"
                                                        />
                                                    </nuxt-link>
                                                </div>

                                                <div class="card-content">
                                                    <h2 class="card-title">
                                                        <nuxt-link
                                                            :to="
                                                                localePath(
                                                                    `/product/default/${myNew.id}`
                                                                )
                                                            "
                                                        >
                                                            <p>
                                                                {{ myNew.name }}
                                                            </p>
                                                        </nuxt-link>
                                                    </h2>
                                                    <div
                                                        class="rating"
                                                        v-if="
                                                            myNew.reviews
                                                                .length > 0
                                                        "
                                                    >
                                                        <i
                                                            class="fas fa-star"
                                                        ></i>
                                                        ({{
                                                            myNew.reviews[0]
                                                                .rate
                                                        }}
                                                        rate)
                                                        <!-- <i class="fas fa-star"></i>
                                                    <i class="fas fa-star"></i>
                                                    <i class="fas fa-star"></i>
                                                    <i class="fas fa-star"></i> -->
                                                    </div>
                                                    <div
                                                        class="
                                                            price-box
                                                            text-center
                                                        "
                                                        v-if="myNew.discount_int !== null"
                                                    >
                                                        <p class="price">
                                                            <span
                                                                class="
                                                                    text-primary
                                                                "
                                                            >
                                                                {{
                                                                    myNew.productPrice_after_discount
                                                                }}
                                                            </span>
                                                        </p>
                                                        <p
                                                            class="old-price"
                                                           
                                                        >
                                                            <del>
                                                                {{
                                                                    myNew.price
                                                                }}
                                                            </del>
                                                        </p>
                                                    </div>
                                                     <div
                                                        class="
                                                            price-box
                                                            text-center
                                                        "
                                                        v-else
                                                    >
                                                        <p class="price">
                                                            <span
                                                                class="
                                                                    text-primary
                                                                "
                                                            >
                                                               {{
                                                                    myNew.price
                                                                }}
                                                            </span>
                                                        </p>
                                                    </div>
                                                    <div class="actions">
                                                        <div class="row">
                                                            <div class="col">
                                                                <div
                                                                    class="
                                                                        img-holder
                                                                        d-flex
                                                                        justify-content-center
                                                                        align-items-center
                                                                    "
                                                                    :class="{
                                                                        'btn-disabled': !canAddToCart(
                                                                            myNew,
                                                                            1
                                                                        )
                                                                    }"
                                                                >
                                                                    <button
                                                                        class=""
                                                                        @click.prevent="
                                                                            addToCart(
                                                                                {
                                                                                    product: myNew
                                                                                }
                                                                            )
                                                                        "
                                                                    >
                                                                        <i
                                                                            class="
                                                                                fas
                                                                                fa-cart-plus
                                                                                fa-2x
                                                                            "
                                                                        ></i>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                            <div class="col">
                                                                <div
                                                                    class="
                                                                        img-holder
                                                                        d-flex
                                                                        justify-content-center
                                                                        align-items-center
                                                                    "
                                                                >
                                                                    <div
                                                                        class="
                                                                            wishlist-outline
                                                                            d-flex
                                                                            justify-content-start
                                                                        "
                                                                    >
                                                                        <nuxt-link
                                                                            :to="
                                                                                localePath(
                                                                                    '/shop/wishlist'
                                                                                )
                                                                            "
                                                                            class="
                                                                                btn-product
                                                                            "
                                                                            v-if="
                                                                                isInWishlist(
                                                                                    myNew
                                                                                )
                                                                            "
                                                                            key="inWishlist"
                                                                        >
                                                                            <i
                                                                                class="
                                                                                    fas
                                                                                    fa-heart
                                                                                "
                                                                            ></i>
                                                                        </nuxt-link>
                                                                        <a
                                                                            class="
                                                                                btn-product
                                                                            "
                                                                            @click.prevent="
                                                                                (
                                                                                    {
                                                                                        product: myNew
                                                                                    }
                                                                                )
                                                                            "
                                                                            v-else
                                                                            key="notInWishlist"
                                                                        >
                                                                            <i
                                                                                class="
                                                                                    far
                                                                                    fa-heart
                                                                                    fa-2x
                                                                                "
                                                                            ></i>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <!-- <div class="col">
                                                                <div
                                                                    class="
                                                                        img-holder
                                                                        share
                                                                    "
                                                                >
                                                                    <nuxt-link
                                                                        to="/"
                                                                    >
                                                                        <i
                                                                            class="
                                                                                fas
                                                                                fa-share
                                                                                fa-2x
                                                                            "
                                                                        ></i>
                                                                    </nuxt-link>
                                                                </div>
                                                            </div> -->
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="swiper-nav">
                                <div class="swiper-prev">
                                    <i class="icon-angle-left"></i>
                                </div>
                                <div class="swiper-next">
                                    <i class="icon-angle-right"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import axios from 'axios';
import {
    carouselSettingSingle,
    carouselSettingDefault
} from '~/utilities/carousel';
import Repository, { baseUrl } from '~/repositories/repository.js';

export default {
    data: function() {
        return {
            carouselSetting9: {
                ...carouselSettingDefault,
                slidesPerView: 4,
                spaceBetween: 30,
                navigation: {
                    nextEl: '.new-arrival .swiper-next',
                    prevEl: '.new-arrival .swiper-prev'
                },
                breakpoints: {
                    992: {
                        slidesPerView: 2
                    },
                    768: {
                        slidesPerView: 2,
                        navigation: true
                    },
                    576: {
                        slidesPerView: 1,
                        navigation: true
                    },
                    480: {
                        slidesPerView: 1,
                        navigation: true
                    }
                }
            },
            newArrival: [],
            loading: false
        };
    },
    methods: {
        ...mapActions('cart', ['addToCart']),
        ...mapActions('wishlist', ['addToWishlist']),
        ...mapActions('compare', ['addToCompare']),
        getData: async function() {
            let newArrival = `${baseUrl}/latest/products`;
            // new arrival
            await Repository.post(newArrival)
                .then(resp => {
                    if (resp.data.code == 200) {
                        this.loading = true;
                    }
                    this.newArrival = resp.data.latest_products;
                    console.log('newArrival', resp);
                })
                .finally(() => (this.loading = true));
        }
    },
    mounted() {
        this.getData();
    },
    computed: {
        ...mapGetters('cart', ['canAddToCart']),
        ...mapGetters('wishlist', ['isInWishlist']),
        ...mapGetters('compare', ['isInCompare']),
        fakeArray: function() {
            let temp = [];
            for (let i = 0; i < this.perPage; i++) {
                temp.push(i);
            }
            return temp;
        }
    },
    props: ['product']
};
</script>

<style lang="scss">
hr{
    width: 50%;
    position: absolute;
    left: 19%;
    border-top: 1px solid #9a9999;
}
.fa-share {
    transform: rotate(-45deg);
}
.col{
    padding-left: 5px;
    padding-right: 5px;
}
h1,
a,
p,
span,
div {
    font-family: 'CairoRegular';
}
.rating.card-info {
    margin: auto;
    margin-left: 0;
    border-radius: 5px;
    background: transparent;
    border: 1px solid #ddd;
    padding: 0;
    width: 50px;
    border-radius: 5px;
    i {
        color: #ffe500;
        span {
            color: #818181;
        }
    }
    span {
        color: #818181;
    }
}
.newArrival {
    background-repeat: no-repeat;
    background-position: inherit;
    background-color: #f1f2f1;
      background-position: center;
    img {
        width: 20%;
        background: transparent !important;
    }
    div:first-child {
        height: auto;
    }
    .swiper-slide {
        padding: 10px;
        border: 1px solid #fff;
        border-radius: 10px;
        .card-holder {
            margin-top: 10px;
            .card-content {
                p {
                    margin-top: 0;
                    margin-bottom: 10px;
                }
            }
        }
        .col {
            div.img-holder {
                box-shadow: none !important;
                background: #fff;
                a {
                    font-size: 14px;
                }
            }
        }
    }
    .image-box {
        background: #fff !important;
    }
    .card {
        border: none !important;
        .card-content {
            background: #f1f2f1;
        }
    }
}
.show-all {
    text-align: left;
    a {
        background: transparent;
        border: 1px solid #bdbdbd;
        color: #676767;
        width: 100px !important;
        min-width: 100px;
        font-weight: bold;
    }
}
@media (max-width: 768px) {
    .recommendations {
      
            .row {
                margin-bottom: 10px !important;
                h1 {
                    font-size: 30px;
                }
                a {
                    padding: 10px !important;
                }
            }
       .swiper-carousel{
           margin-top:0 !important;
           p{
               font-size: 15px;
           }
       }
    }
}
@media(max-width: 425px){
    hr{
        display: none;
    }

}
</style>
