<template>
    <div class="my-4">
        <div class="container">
            <div class="plans">
                <div class="title" v-if="userName">
                    {{$t('payment.deliveredTo')}} : {{ userName }}
                </div>
                <label
                    class="plan basic-plan"
                    for="basic"
                    v-for="address in addresses"
                    :key="address.id"
                >
                    <!-- <input checked type="radio" name="plan" id="basic" /> -->
                    <div class="plan-content mb-2">
                        <!-- <input
                            type="radio"
                            :id="address.id"
                            :value="address.id"
                            v-model="selectedAddress"
                            checked="checked"
                            @change="changeSelection"
                        /> -->
                        <!-- <img
                            loading="lazy"
                            src="https://raw.githubusercontent.com/ismailvtl/ismailvtl.github.io/master/images/life-saver-img.svg"
                            alt=""
                        /> -->
                        <div class="plan-details mr-7">
                            <span>
                                <i class="icon-home"></i>
                                {{ address.zone_obj.name }}
                                <!-- <input
                                    type="text"
                                    v-model="address.zone_obj.name"
                                    class="editableField"
                                /> -->
                            </span>
                            <span>
                                <i class="icon-map-marker"></i>
                                {{ address.street }}
                            </span>
                            <span v-if="address.phone">
                                <i class="icon-phone"></i> no phon till now
                            </span>
                        </div>
                    </div>
                </label>
                <!-- <hr class="w-50 m-auto my-3" /> -->
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import Repository, { baseUrl } from '~/repositories/repository.js';

export default {
    name:'UserAddress',
    data() {
        return {
            profileData: [],
            userName: '',
            addresses: [],
            addressName: '',
            selectedAddress: '',
        };
    },
    methods: {
        getProfileData() {
            let token = sessionStorage.getItem('token');
            axios
                .post(`${baseUrl}/profile`, {
                    api_token: token,
                })
                .then((resp) => {
                    this.profileData = resp.data.profile;
                    this.userName = resp.data.profile.name;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        getUserAddresses() {
            let token = sessionStorage.getItem('token');
            axios
                .post(`${baseUrl}/user-addresses/all`, {
                    api_token: token,
                })
                .then((resp) => {
                    this.addresses = resp.data.addresses;
                    this.addressName = resp.data.addresses.zone_obj.name;
                    console.log();
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        getCheckoutData() {
            let token = sessionStorage.getItem('token');
            let data = {
                api_token: token,
                address_id: this.selectedAddress,
            };

            var config = {
                method: 'post',
                url: `${baseUrl}/buy`,
                // headers: {
                //     language: 'LANGUAGE1',
                // },
                 headers: {
                        language:
                            this.$i18n.locale == 'en'
                                ? 'LANGUAGE2'
                                : 'LANGUAGE1'
                    },
                data: data,
            };

            axios(config)
                .then((resp) => {
                    // console.log('chekout', resp);
                    this.checkoutData = resp.data;
                    if (resp.data.code == 200) {
                        sessionStorage.setItem('address', this.selectedAddress);
                    } else {
                        this.selectFirst = resp.data.message;
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        changeSelection() {
            this.getCheckoutData();
        },
    },
    created() {
        this.getProfileData();
        this.getUserAddresses();
    },
  
};
</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap');

* {
    margin: 0;
    padding: 0;
}

body {
    background: #ecf2fe;
    height: 100vh;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-family: 'Roboto', sans-serif;
}

.plans {
    display: -webkit-box;
    display: -ms-flexbox;
    // display: flex;
    // -webkit-box-pack: justify;
    // -ms-flex-pack: justify;
    // justify-content: space-between;

    max-width: 970px;
    padding: 30px 50px;
    box-sizing: border-box;
    background: #fff;
    border-radius: 8px;
    // box-shadow: 0px 0px 4px 0px #d8dfeb;
    align-items: center;
    flex-wrap: wrap;
}

.plans .plan input[type='radio'] {
    // position: absolute;
    // opacity: 0;
    width: 20px;
    height: 20px;
    top: 50%;
    position: absolute;
    transform: translateY(-50%);
    cursor: pointer;
    right: 0;
}

.plans .plan {
    cursor: pointer;
    width: 90%;
}

.plans .plan .plan-content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 30px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border: 2px solid #e1e2e7;
    border-radius: 10px;
    -webkit-transition: -webkit-box-shadow 0.4s;
    transition: -webkit-box-shadow 0.4s;
    -o-transition: box-shadow 0.4s;
    transition: box-shadow 0.4s;
    transition: box-shadow 0.4s, -webkit-box-shadow 0.4s;
    position: relative;
}

.plans .plan .plan-content img {
    margin-right: 30px;
    height: 72px;
}

.plans .plan .plan-details span {
    margin-bottom: 10px;
    display: block;
    font-size: 20px;
    line-height: 24px;
    color: #252f42;
}

.container .title {
    font-size: 16px;
    font-weight: 500;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    color: #252f42;
    margin-bottom: 20px;
}

.plans .plan .plan-details p {
    color: #646a79;
    font-size: 14px;
    line-height: 18px;
}

.plans .plan .plan-content:hover {
    -webkit-box-shadow: 0px 3px 5px 0px #e8e8e8;
    box-shadow: 0px 3px 5px 0px #e8e8e8;
}

.plans .plan input[type='radio']:checked + .plan-content:after {
    content: '';
    position: absolute;
    height: 20px;
    width: 20px;
    background: #216fe0;
    left: 20px;
    top: calc(50% - 10px);
    border-radius: 100%;
    border: 4px solid #fff;
    -webkit-box-shadow: 0px 0px 0px 2px #0066ff;
    box-shadow: 0px 0px 0px 2px #0066ff;
}

.plans .plan input[type='radio']:checked + .plan-content {
    border: 2px solid #216ee0;
    background: #eaf1fe;
    -webkit-transition: ease-in 0.3s;
    -o-transition: ease-in 0.3s;
    transition: ease-in 0.3s;
}

@media screen and (max-width: 991px) {
    .plans {
        margin: 0 20px;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
        padding: 40px;
    }

    .plans .plan {
        width: 100%;
    }

    .plan.complete-plan {
        margin-top: 20px;
    }

    .plans .plan .plan-content .plan-details {
        width: 70%;
        display: inline-block;
    }

    .plans .plan input[type='radio']:checked + .plan-content:after {
        top: 45%;
        -webkit-transform: translate(-50%);
        -ms-transform: translate(-50%);
        transform: translate(-50%);
    }
}

@media screen and (max-width: 767px) {
    .plans .plan .plan-content .plan-details {
        width: 60%;
        display: inline-block;
    }
}

@media screen and (max-width: 540px) {
    .plans .plan .plan-content img {
        margin-bottom: 20px;
        height: 56px;
        -webkit-transition: height 0.4s;
        -o-transition: height 0.4s;
        transition: height 0.4s;
    }

    .plans .plan input[type='radio']:checked + .plan-content:after {
        top: 20px;
        right: 10px;
    }

    .plans .plan .plan-content .plan-details {
        width: 100%;
    }

    .plans .plan .plan-content {
        padding: 20px;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: baseline;
        -ms-flex-align: baseline;
        align-items: baseline;
    }
}

/* inspiration */
.inspiration {
    font-size: 14px;
    margin-top: 50px;
    position: absolute;
    bottom: 10px;
    font-weight: 300;
}

.inspiration a {
    color: #666;
}
@media screen and (max-width: 767px) {
    /* inspiration */
    .inspiration {
        display: none;
    }
}
.editableField {
    background-color: transparent;
    border: none;
}
</style>