<template>
    <div class="sidebar-shop" :class="isSidebar ? 'sidebar-filter' : 'sidebar'">
        <div :class="{ 'sidebar-filter-wrapper': isSidebar }">
            <div class="widget widget-clean">
                <label class="filters">Filtered By</label>
                <a class="sidebar-filter-clear" @click.prevent="cleanAll"
                    >Clean All</a
                >
            </div>
            <div class="widget widget-collapsible">
                <h3 class="widget-title mb-2">
                    <a
                        href="#widget-1"
                        :class="{ collapsed: !toggleStates[0] }"
                        @click.prevent="toggleSlide(0)"
                        >{{ $t('brandSidebar.category') }}</a
                    >
                </h3>

                <vue-slide-toggle
                    :open="toggleStates[0]"
                    class="show"
                    :duration="200"
                >
                    <div class="widget-body pt-0">
                        <div class="filter-items filter-items-count">
                            <div class="filter-items">
                                <div
                                    class="filter-item"
                                    v-for="(item, index) in brands"
                                    :key="index"
                                >
                                    <div class="custom-control custom-radio">
                                        <input
                                            type="radio"
                                            class="custom-control-input"
                                            :id="'brand-' + index"
                                            @click="setmyBrandsFilter(item)"
                                            :checked="myBrandsChecked(item)"
                                            name="brand"
                                        />
                                        <label
                                            class="custom-control-label"
                                            :for="'brand-' + index"
                                            >{{ item.name }}</label
                                        >
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </vue-slide-toggle>
            </div>

            <div class="widget widget-collapsible">
                <h3 class="widget-title mb-2">
                    <a
                        href="#widget-1"
                        :class="{ collapsed: !toggleStates[1] }"
                        @click.prevent="toggleSlide(1)"
                        >{{ colorName }}</a
                    >
                </h3>

                <vue-slide-toggle
                    :open="toggleStates[1]"
                    class="show"
                    :duration="200"
                >
                    <div class="widget-body pt-0">
                        <div class="filter-items filter-items-count">
                            <div class="filter-items">
                                <div
                                    class="filter-item"
                                    v-for="(item, index) in colorAttr"
                                    :key="index"
                                >
                                    <div class="custom-control custom-radio">
                                        <input
                                            type="radio"
                                            class="custom-control-input"
                                            :id="'colorAttr-' + index"
                                            @click="setcolorAttrFilter(item)"
                                            :checked="colorAttrChecked(item)"
                                            name="colorAttr"
                                        />
                                        <label
                                            class="custom-control-label"
                                            :for="'colorAttr-' + index"
                                            >{{ item.name }}</label
                                        >
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </vue-slide-toggle>
            </div>

            <div class="widget widget-collapsible">
                <h3 class="widget-title mb-2">
                    <a
                        href="#widget-1"
                        :class="{ collapsed: !toggleStates[2] }"
                        @click.prevent="toggleSlide(2)"
                        >{{ sizeName }}</a
                    >
                </h3>

                <vue-slide-toggle
                    :open="toggleStates[2]"
                    class="show"
                    :duration="200"
                >
                    <div class="widget-body pt-0">
                        <div class="filter-items filter-items-count">
                            <div class="filter-items">
                                <div
                                    class="filter-item"
                                    v-for="(item, index) in sizeAttr"
                                    :key="index"
                                >
                                    <div class="custom-control custom-radio">
                                        <input
                                            type="radio"
                                            class="custom-control-input"
                                            :id="'sizeAttr-' + index"
                                            @click="setsizeAttrFilter(item)"
                                            :checked="sizeAttrChecked(item)"
                                            name="sizeAttr"
                                        />
                                        <label
                                            class="custom-control-label"
                                            :for="'sizeAttr-' + index"
                                            >{{ item.name }}</label
                                        >
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </vue-slide-toggle>
            </div>

            <div class="widget widget-collapsible">
                <h3 class="widget-title mb-2">
                    <a
                        href="#widget-1"
                        :class="{ collapsed: !toggleStates[3] }"
                        @click.prevent="toggleSlide(3)"
                        >{{ matrialName }}</a
                    >
                </h3>

                <vue-slide-toggle
                    :open="toggleStates[3]"
                    class="show"
                    :duration="200"
                >
                    <div class="widget-body pt-0">
                        <div class="filter-items filter-items-count">
                            <div class="filter-items">
                                <div
                                    class="filter-item"
                                    v-for="(item, index) in matrialAttr"
                                    :key="index"
                                >
                                    <div class="custom-control custom-radio">
                                        <input
                                            type="radio"
                                            class="custom-control-input"
                                            :id="'matrialAttr-' + index"
                                            @click="setmatrialAttrFilter(item)"
                                            :checked="matrialAttrChecked(item)"
                                            name="matrialAttr"
                                        />
                                        <label
                                            class="custom-control-label"
                                            :for="'matrialAttr-' + index"
                                            >{{ item.name }}</label
                                        >
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </vue-slide-toggle>
            </div>
            <div class="widget widget-collapsible">
                <h3 class="widget-title mb-2">
                    <a
                        href="#widget-1"
                        :class="{ collapsed: !toggleStates[4] }"
                        @click.prevent="toggleSlide(4)"
                        >{{ genderName }}</a
                    >
                </h3>

                <vue-slide-toggle
                    :open="toggleStates[4]"
                    class="show"
                    :duration="200"
                >
                    <div class="widget-body pt-0">
                        <div class="filter-items filter-items-count">
                            <div class="filter-items">
                                <div
                                    class="filter-item"
                                    v-for="(item, index) in genderAttr"
                                    :key="index"
                                >
                                    <div class="custom-control custom-radio">
                                        <input
                                            type="radio"
                                            class="custom-control-input"
                                            :id="'genderAttr-' + index"
                                            @click="setgenderAttrFilter(item)"
                                            :checked="genderAttrChecked(item)"
                                            name="genderAttr"
                                        />
                                        <label
                                            class="custom-control-label"
                                            :for="'genderAttr-' + index"
                                            >{{ item.name }}</label
                                        >
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </vue-slide-toggle>
            </div>
<div class="widget widget-collapsible">
                <h3 class="widget-title mb-2">
                    <a
                        href="#widget-1"
                        :class="{ collapsed: !toggleStates[5] }"
                        @click.prevent="toggleSlide(5)"
                        >
                        {{$t('productsSidebar.category')}}
                        </a
                    >
                </h3>

                <vue-slide-toggle
                    :open="toggleStates[5]"
                    class="show"
                    :duration="200"
                >
                    <div class="widget-body pt-0">
                        <div class="filter-items filter-items-count">
                            <div class="filter-items">
                                <div
                                    class="filter-item"
                                    v-for="(item, index) in categories"
                                    :key="index"
                                >
                                    <div class="custom-control custom-radio">
                                        <input
                                            type="radio"
                                            class="custom-control-input"
                                            :id="'category-' + index"
                                            @click="setmyCategoryFilter(item)"
                                            :checked="myCategoryChecked(item)"
                                            name="category"
                                        />
                                        <label
                                            class="custom-control-label"
                                            :for="'category-' + index"
                                            >{{ item.name }}</label
                                        >
                                    </div>
                                </div>
                                <!-- <div
                                    class="custom-control custom-radio"
                                    v-for="(item, index) in categories"
                                    :key="index"
                                >
                                    <input
                                        type="radio"
                                        class="filter-item custom-control-input"
                                        name="category"
                                        :id="item.id"
                                        :value="item.name"
                                    />
                                    <label
                                        class="custom-control-label"
                                        :for="'category-' + index"
                                        >{{ item.name }}</label
                                    >
                                </div> -->
                            </div>
                        </div>
                    </div>
                </vue-slide-toggle>
            </div>
             <div
                class="widget widget-collapsible mt-3"
                
            >
                <h3 class="widget-title mb-2">
                    <a
                        href="#widget-2"
                        :class="{ collapsed: !toggleStates[6] }"
                        @click.prevent="toggleSlide(6)"
                        >{{$t('productsSidebar.subCategory')}}</a
                    >
                </h3>

                <vue-slide-toggle
                    :open="toggleStates[6]"
                    class="show"
                    :duration="200"
                >
                    <div class="widget-body pt-0">
                        <div class="filter-items">
                            <div
                                class="filter-item"
                                v-for="(item, index) in subcategories"
                                :key="index"
                            >

                                <div class="custom-control custom-radio">
                                    <input
                                        type="radio"
                                        class="custom-control-input"
                                        :id="'subcategory-' + index"
                                        @click="setSubCategoryFilter(item)"
                                        :checked="SubCategoryChecked(item)"
                                        name="subcategory"
                                    />
                                    <label
                                        class="custom-control-label"
                                        :for="'subcategory-' + index"
                                        >{{ item.name }}</label
                                    >
                                </div>
                            </div>
                        </div>
                    </div>
                </vue-slide-toggle>
            </div>
            <button class="sidebar-filter-clear" @click.prevent="searchAll">
                search now
            </button>
        </div>
    </div>
</template>

<script>
import { VueSlideToggle } from 'vue-slide-toggle';
import { places } from '~/utilities/data';
import Repository, { baseUrl } from '~/repositories/repository.js';
export default {
    components: {
        VueSlideToggle
    },
    props: {
        isSidebar: Boolean
    },
    data: function() {
        return {
            loaded: true,
            priceValues: [0, 1000],
            priceSliderConfig: {
                connect: true,
                step: 50,
                margin: 100,
                range: {
                    min: 0,
                    max: 1000
                }
            },
            toggleStates: [true, true, true, true, true, true, true, true],
            filterData: places,
            brands: [],
            subcategories: [],
            prices: [],
            ages: [],
            cities: [],
            areas: [],
            grades: [],
            types: [],
            brandId: '',
            subCategoryId: '',
            priceRange: '',
            ageStage: '',
            cityId: '',
            areaId: '',
            gradeId: '',
            socialId: '',
            attributes: [],
            colorName: '',
            colorAttr: [],
            colorAttrId: '',
            sizeName: '',
            sizeAttr:[],
            sizeAttrId: '',
            matrialName: '',
            matrialAttr: [],
            matrialAttrId: '',
            genderName:'',
            genderAttr:[],
            genderAttrId:'',
            categories:[]

        };
    },
    computed: {
        priceRangeText: function() {
            return (
                '$' +
                parseInt(this.priceValues[0]) +
                ' — $' +
                parseInt(this.priceValues[1])
            );
        },
        priceFilterRoute: function() {
            let query = {};
            if (this.$route.query.page) {
                for (let key in this.$route.query) {
                    if (key !== 'page') {
                        query[key] = this.$route.query[key];
                    }
                }
            } else {
                query = { ...this.$route.query };
            }

            return {
                path: this.$route.path,
                query: {
                    ...query,
                    minPrice: this.priceValues[0],
                    maxPrice: this.priceValues[1]
                }
            };
        }
    },
    created: function() {
        document
            .querySelector('body')
            .classList.remove('sidebar-filter-active');
        if (this.$route.query.minPrice && this.$route.query.maxPrice) {
            this.loaded = false;
            this.priceValues = [
                this.$route.query.minPrice,
                this.$route.query.maxPrice
            ];
            this.$nextTick(function() {
                this.loaded = true;
            });
        } else {
            this.loaded = false;
            this.$nextTick(function() {
                this.loaded = true;
            });
        }
        this.getBrands();
        this.getAttr();
         this.getCategories();
        this.getSubCategories();
    },
    methods: {
        cleanAll: function() {
            this.loaded = false;
            this.priceValues = [0, 1000];
            this.$nextTick(function() {
                this.orderBy = 'default';
                this.loaded = true;
                this.$router.push(this.$route.path);
            });
        },
        toggleSlide: function(index) {
            this.toggleStates = this.toggleStates.reduce((acc, cur, id) => {
                if (id == index) return [...acc, !cur];
                else return [...acc, cur];
            }, []);
        },
        sizeChecked: function(item) {
            return (
                this.$route.query.size &&
                this.$route.query.size.split(',').includes(item.slug)
            );
        },
        categoryChecked: function(item) {
            return (
                this.$route.query.category &&
                this.$route.query.category.split(',').includes(item.slug)
            );
        },
        categorySelected: function(item) {
            this.getSubCategories(item);
            return (
                this.$route.query.category &&
                this.$route.query.category == item.name
            );
        },
        colorSelected: function(item) {
            return (
                this.$route.query.color &&
                this.$route.query.color.split(',').includes(item.color_name)
            );
        },
        setSizeFilter: function(item) {
            let query = {};
            if (this.$route.query.page) {
                for (let key in this.$route.query) {
                    if (key !== 'page') {
                        query[key] = this.$route.query[key];
                    }
                }
            } else {
                query = { ...this.$route.query };
            }

            if (!this.$route.query.size) {
                query = {
                    ...query,
                    size: item.slug
                };
            } else if (this.$route.query.size.split(',').includes(item.slug)) {
                query = {
                    ...query,
                    size: query.size
                        .split(',')
                        .filter(slug => slug !== item.slug)
                        .join(',')
                };
            } else {
                query = {
                    ...query,
                    size: [...query.size.split(','), item.slug].join(',')
                };
            }

            this.$router.push({
                path: this.$route.path,
                query: query
            });
        },
        setCategoryFilter: function(item) {
            let query = {};
            if (this.$route.query.page) {
                for (let key in this.$route.query) {
                    if (key !== 'page') {
                        query[key] = this.$route.query[key];
                    }
                }
            } else {
                query = { ...this.$route.query };
            }

            if (!this.$route.query.brand) {
                query = {
                    ...query,
                    category: item.slug
                };
            } else if (this.$route.query.brand.split(',').includes(item.slug)) {
                query = {
                    ...query,
                    category: query.category
                        .split(',')
                        .filter(slug => slug !== item.slug)
                        .join(',')
                };
            } else {
                query = {
                    ...query,
                    category: [...query.category.split(','), item.slug].join(
                        ','
                    )
                };
            }

            this.$router.push({
                path: this.$route.path,
                query: query
            });
        },
        getColorUrl: function(item) {
            let query = {};

            if (!this.$route.query.color) {
                query = {
                    ...this.$route.query,
                    color: item.color_name
                };
            } else if (
                this.$route.query.color.split(',').includes(item.color_name)
            ) {
                query = {
                    ...this.$route.query,
                    color: this.$route.query.color
                        .split(',')
                        .filter(slug => slug !== item.color_name)
                        .join(',')
                };
            } else {
                query = {
                    ...this.$route.query,
                    color: [
                        ...this.$route.query.color.split(','),
                        item.color_name
                    ].join(',')
                };
            }

            let temp = {};
            if (query.page) {
                for (let key in query) {
                    if (key !== 'page') {
                        temp[key] = query[key];
                    }
                }
            } else {
                temp = { ...query };
            }

            return {
                path: this.$route.path,
                query: temp
            };
        },
    // category
        getCategories: async function () {
            await Repository.get(`${baseUrl}/categoryTree`)
                .then((resp) => {
                    // console.log('categories', resp);
                    this.categories = resp.data.categories;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        myCategoryChecked: function (item) {
            return (
                this.$route.query.category &&
                this.$route.query.category.includes(item.name)
            );

            // return (
            //     this.$route.query.category &&
            //     this.$route.query.category.split(',').includes(item.name)
            // );
        },
        setmyCategoryFilter: function (item) {
            
            console.log('CategoryId', item.id);
            this.categoryId = item.id;
            let query = {};

            this.$router.push({
                path: this.$route.path,
                query: {
                    ...query,
                    category: this.categoryId,
                    // category: item.name.replace(/\s/g, '-'),
                },
            });
        },

        //subCategory
        getSubCategories: async function () {
            await Repository.get(`${baseUrl}/subcategoryTree`)
                .then((resp) => {
                    console.log('subcategories', resp);
                    // console.log(item.id);
                    this.subcategories = resp.data.subcategories;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        SubCategoryChecked: function (item) {
            return (
                this.$route.query.subCategory &&
                this.$route.query.subCategory.includes(item.name)
            );
        },
        setSubCategoryFilter: function (item) {
            console.log('subCategoryId', item.id);
            this.subCategoryId = item.id;

            this.$router.push({
                path: this.$route.path,
                query: {
                    ...this.$route.query,
                    subCategory: this.subCategoryId,
                    // subCategory: item.name.replace(/\s/g, '-'),
                },
            });

            // let query = {};
            // if (this.$route.query.page) {
            //     for (let key in this.$route.query) {
            //         if (key !== 'page') {
            //             query[key] = this.$route.query[key];
            //         }
            //     }
            // } else {
            //     query = { ...this.$route.query };
            // }

            // if (!this.$route.query.subCategory) {
            //     query = {
            //         ...query,
            //         subCategory: item.name.replace(/\s/g, '-'),
            //     };
            //     console.log('subCategoryId', item.id);
            //     this.subCategoryId = item.id;
            // } else if (
            //     this.$route.query.subCategory
            //         .split(',')
            //         .includes(item.name.replace(/\s/g, '-'))
            // ) {
            //     query = {
            //         ...query,
            //         subCategory: query.subCategory
            //             .split(',')
            //             .filter(
            //                 (name) => name !== item.name.replace(/\s/g, '-')
            //             )
            //             .join(','),
            //     };
            //     this.subCategoryId = item.id;
            // } else {
            //     query = {
            //         ...query,
            //         subCategory: [
            //             ...query.subCategory.split(','),
            //             item.name.replace(/\s/g, '-'),
            //         ].join(','),
            //     };
            // }

            // this.$router.push({
            //     path: this.$route.path,
            //     query: query,
            // });
        },
        // brands
        getBrands: async function() {
            await Repository.get(`${baseUrl}/vendors_logo`)
                .then(resp => {
                    // console.log('categories', resp);
                    this.brands = resp.data.brands;
                    console.log(this.brands);
                })
                .catch(err => {
                    console.log(err);
                });
        },
        myBrandsChecked: function(item) {
            return (
                this.$route.query.brand &&
                this.$route.query.brand.includes(item.id)
            );
        },
        setmyBrandsFilter: function(item) {
            console.log('CategoryId', item.id);
            this.brandId = item.id;
            let query = {};

            this.$router.push({
                path: this.$route.path,
                query: {
                    ...query,
                    brand: this.brandId
                    // category: item.name.replace(/\s/g, '-'),
                }
            });
        },
        // gat attr
        getAttr: async function() {
            await Repository.get(`${baseUrl}/attributeTree`)
                .then(resp => {
                    // console.log('categories', resp);
                    this.attributes = resp.data.attributes;
                    console.log('this.attributes', this.attributes);
                    for (var x = 0; x <= this.attributes.length; x++) {
                        this.colorName = this.attributes[0].name;
                        console.log('this.colorName', this.colorName);
                        this.colorAttr = this.attributes[0].attributes;
                        console.log('this.', this.colorAttr);
                        this.sizeName = this.attributes[1].name;
                        this.sizeAttr = this.attributes[1].attributes;
                        this.matrialName = this.attributes[2].name;
                        this.matrialAttr = this.attributes[2].attributes;
                        this.genderName = this.attributes[3].name;
                        this.genderAttr = this.attributes[3].attributes;
                    }
                })
                .catch(err => {
                    console.log(err);
                });
        },
        setcolorAttrFilter: function(item) {
            console.log('CategoryId', item.id);
            this.colorAttrId = item.id;
            // let query = {};

            this.$router.push({
                path: this.$route.path,
                query: {
                    ...this.$route.query,
                    colorAttr: this.colorAttrId
                    // category: item.name.replace(/\s/g, '-'),
                }
            });
        },
        colorAttrChecked: function(item) {
            return (
                this.$route.query.colorAttr &&
                this.$route.query.colorAttr.includes(item.id)
            );
        },

        setsizeAttrFilter: function(item) {
            console.log('CategoryId', item.id);
            this.sizeAttrId = item.id;
            // let query = {};

            this.$router.push({
                path: this.$route.path,
                query: {
                    ...this.$route.query,
                    sizeAttr: this.sizeAttrId
                    // category: item.name.replace(/\s/g, '-'),
                }
            });
        },
        sizeAttrChecked: function(item) {
            return (
                this.$route.query.sizeAttr &&
                this.$route.query.sizeAttr.includes(item.id)
            );
        },

        setmatrialAttrFilter: function(item) {
            console.log('CategoryId', item.id);
            this.matrialAttrId = item.id;
            // let query = {};

            this.$router.push({
                path: this.$route.path,
                query: {
                    ...this.$route.query,
                    matrialAttr: this.matrialAttrId
                    // category: item.name.replace(/\s/g, '-'),
                }
            });
        },
        matrialAttrChecked: function(item) {
            return (
                this.$route.query.matrialAttr &&
                this.$route.query.matrialAttr.includes(item.id)
            );
        },

         setgenderAttrFilter: function(item) {
            console.log('CategoryId', item.id);
            this.genderAttrId = item.id;
            // let query = {};

            this.$router.push({
                path: this.$route.path,
                query: {
                    ...this.$route.query,
                    genderAttr: this.genderAttrId
                    // category: item.name.replace(/\s/g, '-'),
                }
            });
        },
        genderAttrChecked: function(item) {
            return (
                this.$route.query.genderAttr &&
                this.$route.query.genderAttr.includes(item.id)
            );
        },

        searchAll() {
            this.$router.push({
                path: '/brands/sidebar/advancedBrand',
                query: {
                    ...this.$route.query
                }
            });
        }
    },
    mounted() {
        this.$router.push(this.$route.path);
    }
};
</script>
<style lang="scss" scoped>
.filters {
    font-size: 22px;
    color: #144b96;
    // color: #848c9e;
    font-weight: 500;
}
.sidebar-filter-clear {
    cursor: pointer;
}
</style>
