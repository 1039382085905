var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "services py-5" }, [
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "row" }, [
          _vm._m(0),
          _vm._v(" "),
          _c("div", { staticClass: "col-10" }, [
            _c(
              "div",
              {
                staticClass:
                  "accordion accordion-plus product-details-accordion mb-2"
              },
              [
                _c(
                  "div",
                  { staticClass: "card card-box card-sm" },
                  [
                    _c("div", { staticClass: "card-header" }, [
                      _c("h2", { staticClass: "card-title" }, [
                        _c(
                          "a",
                          {
                            staticClass: "toggle-button bg-transparent",
                            class: {
                              expanded: _vm.toggleState[0],
                              collapsed: !_vm.toggleState[0]
                            },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.changeToggle(0)
                              }
                            }
                          },
                          [_vm._v("Lab & Blood Draw Services")]
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _c(
                      "vue-slide-toggle",
                      { attrs: { open: _vm.toggleState[0] } },
                      [
                        _c("div", { staticClass: "card-body" }, [
                          _c("div", { staticClass: "product-desc-content" }, [
                            _c("p", [
                              _vm._v(
                                "\n                                            Lorem ipsum dolor sit amet,\n                                            consectetuer adipiscing elit.\n                                            Donec odio. Quisque volutpat\n                                            mattis eros. Nullam malesuada\n                                            erat ut turpis. Suspendisse urna\n                                            viverra non, semper suscipit.\n                                        "
                              )
                            ]),
                            _vm._v(" "),
                            _c("ul", [
                              _c("li", [
                                _vm._v(
                                  "\n                                                Nunc nec porttitor turpis.\n                                                In eu risus enim. In vitae\n                                                mollis elit.\n                                            "
                                )
                              ]),
                              _vm._v(" "),
                              _c("li", [
                                _vm._v(
                                  "\n                                                Vivamus finibus vel mauris\n                                                ut vehicula.\n                                            "
                                )
                              ]),
                              _vm._v(" "),
                              _c("li", [
                                _vm._v(
                                  "\n                                                Nullam a magna porttitor,\n                                                dictum risus nec, faucibus\n                                                sapien.\n                                            "
                                )
                              ])
                            ]),
                            _vm._v(" "),
                            _c("p", [
                              _vm._v(
                                "\n                                            Lorem ipsum dolor sit amet,\n                                            consectetuer adipiscing elit.\n                                            Donec odio. Quisque volutpat\n                                            mattis eros. Nullam malesuada\n                                            erat ut turpis. Suspendisse urna\n                                            viverra non, semper suscipit,\n                                            posuere a, pede. Donec nec justo\n                                            eget felis facilisis fermentum.\n                                            Aliquam porttitor mauris sit\n                                            amet orci. Aenean dignissim\n                                            pellentesque felis. Phasellus\n                                            ultrices nulla quis nibh.\n                                            Quisque a lectus. Donec\n                                            consectetuer ligula vulputate\n                                            sem tristique cursus.\n                                        "
                              )
                            ])
                          ])
                        ])
                      ]
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "card card-box card-sm" },
                  [
                    _c("div", { staticClass: "card-header" }, [
                      _c("h2", { staticClass: "card-title" }, [
                        _c(
                          "a",
                          {
                            staticClass: "toggle-button bg-transparent",
                            class: {
                              expanded: _vm.toggleState[1],
                              collapsed: !_vm.toggleState[1]
                            },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.changeToggle(1)
                              }
                            }
                          },
                          [_vm._v("Sleep Medicine Services")]
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _c(
                      "vue-slide-toggle",
                      { attrs: { open: _vm.toggleState[1] } },
                      [
                        _c("div", { staticClass: "card-body" }, [
                          _c("div", { staticClass: "product-desc-content" }, [
                            _c("p", { staticClass: "mb-2" }, [
                              _vm._v(
                                "\n                                            Lorem ipsum dolor sit amet,\n                                            consectetuer adipiscing elit.\n                                            Donec odio. Quisque volutpat\n                                            mattis eros. Nullam malesuada\n                                            erat ut turpis.\n                                        "
                              )
                            ]),
                            _vm._v(" "),
                            _c("h3", [_vm._v("Fabric & care")]),
                            _vm._v(" "),
                            _c("ul", [
                              _c("li", [_vm._v("Faux suede fabric")]),
                              _vm._v(" "),
                              _c("li", [
                                _vm._v(
                                  "\n                                                Gold tone metal hoop\n                                                handles.\n                                            "
                                )
                              ]),
                              _vm._v(" "),
                              _c("li", [_vm._v("RI branding")]),
                              _vm._v(" "),
                              _c("li", [
                                _vm._v(
                                  "\n                                                Snake print trim interior\n                                            "
                                )
                              ]),
                              _vm._v(" "),
                              _c("li", [
                                _vm._v(
                                  "\n                                                Adjustable cross body strap\n                                            "
                                )
                              ]),
                              _vm._v(" "),
                              _c("li", [
                                _vm._v(
                                  "\n                                                Height: 31cm; Width: 32cm;\n                                                Depth: 12cm; Handle Drop:\n                                                61cm\n                                            "
                                )
                              ])
                            ]),
                            _vm._v(" "),
                            _c("h3", [_vm._v("Size")]),
                            _vm._v(" "),
                            _c("p", [_vm._v("one size")])
                          ])
                        ])
                      ]
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "card card-box card-sm" },
                  [
                    _c("div", { staticClass: "card-header" }, [
                      _c("h2", { staticClass: "card-title" }, [
                        _c(
                          "a",
                          {
                            staticClass: "toggle-button bg-transparent",
                            class: {
                              expanded: _vm.toggleState[2],
                              collapsed: !_vm.toggleState[2]
                            },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.changeToggle(2)
                              }
                            }
                          },
                          [_vm._v("Surgical Services")]
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _c(
                      "vue-slide-toggle",
                      { attrs: { open: _vm.toggleState[2] } },
                      [
                        _c("div", { staticClass: "card-body" }, [
                          _c("div", { staticClass: "product-desc-content" }, [
                            _c("p", [
                              _vm._v(
                                "\n                                            We deliver to over 100 countries\n                                            around the world. For full\n                                            details of the delivery options\n                                            we offer, please view our\n                                            "
                              ),
                              _c("a", [_vm._v("Delivery information")]),
                              _vm._v(" "),
                              _c("br"),
                              _vm._v(
                                "We hope you’ll love every\n                                            purchase, but if you ever need\n                                            to return an item you can do so\n                                            within a month of receipt. For\n                                            full details of how to make a\n                                            return, please view our\n                                            "
                              ),
                              _c("a", [_vm._v("Returns information")])
                            ])
                          ])
                        ])
                      ]
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "card card-box card-sm" },
                  [
                    _c("div", { staticClass: "card-header" }, [
                      _c("h2", { staticClass: "card-title" }, [
                        _c(
                          "a",
                          {
                            staticClass: "toggle-button bg-transparent",
                            class: {
                              expanded: _vm.toggleState[3],
                              collapsed: !_vm.toggleState[3]
                            },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.changeToggle(3)
                              }
                            }
                          },
                          [_vm._v("Imaging & X-Ray Services")]
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _c(
                      "vue-slide-toggle",
                      { attrs: { open: _vm.toggleState[3] } },
                      [
                        _c("div", { staticClass: "card-body" }, [
                          _c("div", { staticClass: "product-desc-content" }, [
                            _c("p", [
                              _vm._v(
                                "\n                                            We deliver to over 100 countries\n                                            around the world. For full\n                                            details of the delivery options\n                                            we offer, please view our\n                                            "
                              ),
                              _c("a", [_vm._v("Delivery information")]),
                              _vm._v(" "),
                              _c("br"),
                              _vm._v(
                                "We hope you’ll love every\n                                            purchase, but if you ever need\n                                            to return an item you can do so\n                                            within a month of receipt. For\n                                            full details of how to make a\n                                            return, please view our\n                                            "
                              ),
                              _c("a", [_vm._v("Returns information")])
                            ])
                          ])
                        ])
                      ]
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "card card-box card-sm" },
                  [
                    _c("div", { staticClass: "card-header" }, [
                      _c("h2", { staticClass: "card-title" }, [
                        _c(
                          "a",
                          {
                            staticClass: "toggle-button bg-transparent",
                            class: {
                              expanded: _vm.toggleState[4],
                              collapsed: !_vm.toggleState[4]
                            },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.changeToggle(4)
                              }
                            }
                          },
                          [_vm._v("Infusion Clinic Services")]
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _c(
                      "vue-slide-toggle",
                      { attrs: { open: _vm.toggleState[4] } },
                      [
                        _c("div", { staticClass: "card-body" }, [
                          _c("div", { staticClass: "product-desc-content" }, [
                            _c("p", [
                              _vm._v(
                                "\n                                            We deliver to over 100 countries\n                                            around the world. For full\n                                            details of the delivery options\n                                            we offer, please view our\n                                            "
                              ),
                              _c("a", [_vm._v("Delivery information")]),
                              _vm._v(" "),
                              _c("br"),
                              _vm._v(
                                "We hope you’ll love every\n                                            purchase, but if you ever need\n                                            to return an item you can do so\n                                            within a month of receipt. For\n                                            full details of how to make a\n                                            return, please view our\n                                            "
                              ),
                              _c("a", [_vm._v("Returns information")])
                            ])
                          ])
                        ])
                      ]
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "card card-box card-sm" },
                  [
                    _c("div", { staticClass: "card-header" }, [
                      _c("h2", { staticClass: "card-title" }, [
                        _c(
                          "a",
                          {
                            staticClass: "toggle-button bg-transparent",
                            class: {
                              expanded: _vm.toggleState[5],
                              collapsed: !_vm.toggleState[5]
                            },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.changeToggle(5)
                              }
                            }
                          },
                          [_vm._v("24-Hour Emergency Care")]
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _c(
                      "vue-slide-toggle",
                      { attrs: { open: _vm.toggleState[5] } },
                      [
                        _c("div", { staticClass: "card-body" }, [
                          _c("div", { staticClass: "product-desc-content" }, [
                            _c("p", [
                              _vm._v(
                                "\n                                            We deliver to over 100 countries\n                                            around the world. For full\n                                            details of the delivery options\n                                            we offer, please view our\n                                            "
                              ),
                              _c("a", [_vm._v("Delivery information")]),
                              _vm._v(" "),
                              _c("br"),
                              _vm._v(
                                "We hope you’ll love every\n                                            purchase, but if you ever need\n                                            to return an item you can do so\n                                            within a month of receipt. For\n                                            full details of how to make a\n                                            return, please view our\n                                            "
                              ),
                              _c("a", [_vm._v("Returns information")])
                            ])
                          ])
                        ])
                      ]
                    )
                  ],
                  1
                )
              ]
            )
          ])
        ]),
        _vm._v(" "),
        _c("hr")
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-2" }, [_c("h4", [_vm._v("Services")])])
  }
]
render._withStripped = true

export { render, staticRenderFns }