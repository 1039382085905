var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "dropdown compare-dropdown" }, [
    _vm._m(0),
    _vm._v(" "),
    _vm.compareList.length > 0
      ? _c("div", { staticClass: "dropdown-menu dropdown-menu-right" }, [
          _c(
            "ul",
            { staticClass: "compare-products" },
            _vm._l(_vm.compareList, function(product, index) {
              return _c("li", { key: index, staticClass: "compare-product" }, [
                _c(
                  "a",
                  {
                    staticClass: "btn-remove",
                    attrs: { href: "#", title: "Remove Product" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.removeFromCompare({ product: product })
                      }
                    }
                  },
                  [_c("i", { staticClass: "icon-close" })]
                ),
                _vm._v(" "),
                _c(
                  "h4",
                  { staticClass: "compare-product-title" },
                  [
                    _c(
                      "nuxt-link",
                      { attrs: { to: "/product/default/" + product.slug } },
                      [_vm._v(_vm._s(product.name))]
                    )
                  ],
                  1
                )
              ])
            }),
            0
          ),
          _vm._v(" "),
          _c("div", { staticClass: "compare-actions" }, [
            _c(
              "a",
              {
                staticClass: "action-link",
                attrs: { href: "#" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.clearCompare.apply(null, arguments)
                  }
                }
              },
              [_vm._v("Clear All")]
            ),
            _vm._v(" "),
            _vm._m(1)
          ])
        ])
      : _c(
          "div",
          {
            key: "noCompare",
            staticClass: "dropdown-menu dropdown-menu-right"
          },
          [
            _c("p", { staticClass: "text-center" }, [
              _vm._v("No products in the compare.")
            ])
          ]
        )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "a",
      { staticClass: "dropdown-toggle", attrs: { href: "javascript:;" } },
      [
        _c("div", { staticClass: "icon" }, [
          _c("i", { staticClass: "icon-random" })
        ]),
        _vm._v(" "),
        _c("p", [_vm._v("Compare")])
      ]
    )
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "a",
      {
        staticClass: "btn btn-outline-primary-2",
        attrs: { href: "javascript:;" }
      },
      [
        _c("span", [_vm._v("Compare")]),
        _vm._v(" "),
        _c("i", { staticClass: "icon-long-arrow-right" })
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }