var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("article", { staticClass: "entry" }, [
    _vm.blog.image.length <= 1
      ? _c(
          "figure",
          {
            staticClass: "entry-media",
            class: _vm.blog.type === "video" ? "entry-video" : ""
          },
          [
            _vm.blog.type !== "video"
              ? _c(
                  "nuxt-link",
                  { attrs: { to: "/blog/single/default/" + _vm.blog.slug } },
                  [
                    _c("img", {
                      directives: [
                        {
                          name: "lazy",
                          rawName: "v-lazy",
                          value: `${_vm.baseUrl}${_vm.blog.image[0].url}`,
                          expression: "`${baseUrl}${blog.image[0].url}`"
                        }
                      ],
                      attrs: {
                        alt: "blog",
                        width: _vm.blog.image[0].width,
                        height: _vm.blog.image[0].height
                      }
                    })
                  ]
                )
              : [
                  _c("img", {
                    directives: [
                      {
                        name: "lazy",
                        rawName: "v-lazy",
                        value: `${_vm.baseUrl}${_vm.blog.image[0].url}`,
                        expression: "`${baseUrl}${blog.image[0].url}`"
                      }
                    ],
                    attrs: {
                      alt: "blog",
                      width: _vm.blog.image[0].width,
                      height: _vm.blog.image[0].height
                    }
                  }),
                  _vm._v(" "),
                  _vm.blog.type === "video"
                    ? _c(
                        "a",
                        {
                          staticClass: "btn-video btn-iframe",
                          attrs: {
                            href: "https://www.youtube.com/watch?v=vBPgmASQ1A0"
                          },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.openVideoModal.apply(null, arguments)
                            }
                          }
                        },
                        [_c("i", { staticClass: "icon-play" })]
                      )
                    : _vm._e()
                ]
          ],
          2
        )
      : _vm.blog.image.length > 1
      ? _c("figure", { staticClass: "entry-media" }, [
          _c(
            "div",
            {
              staticClass: "swiper-carousel",
              class: "swiper-media-" + _vm.blog.id
            },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "swiper",
                      rawName: "v-swiper:swiper1",
                      value: _vm.carouselSetting,
                      expression: "carouselSetting",
                      arg: "swiper1"
                    }
                  ]
                },
                [
                  _c(
                    "div",
                    { staticClass: "swiper-wrapper" },
                    _vm._l(_vm.blog.image, function(image, index) {
                      return _c(
                        "div",
                        { key: index, staticClass: "swiper-slide" },
                        [
                          _c(
                            "nuxt-link",
                            {
                              attrs: {
                                to: "/blog/single/default/" + _vm.blog.slug
                              }
                            },
                            [
                              _c("img", {
                                directives: [
                                  {
                                    name: "lazy",
                                    rawName: "v-lazy",
                                    value: `${_vm.baseUrl}${image.url}`,
                                    expression: "`${baseUrl}${image.url}`"
                                  }
                                ],
                                attrs: {
                                  alt: "blog",
                                  width: image.width,
                                  height: image.height
                                }
                              })
                            ]
                          )
                        ],
                        1
                      )
                    }),
                    0
                  )
                ]
              ),
              _vm._v(" "),
              _vm._m(0)
            ]
          )
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "entry-body" }, [
      _c(
        "div",
        { staticClass: "entry-meta" },
        [
          _vm.isAuthor
            ? _c(
                "span",
                { staticClass: "entry-author" },
                [
                  _vm._v("\n\t\t\t\tby\n\t\t\t\t"),
                  _c(
                    "nuxt-link",
                    { attrs: { to: "/blog/single/default/" + _vm.blog.slug } },
                    [_vm._v(_vm._s(_vm.blog.author))]
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.isAuthor
            ? _c("span", { staticClass: "meta-separator" }, [_vm._v("|")])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "nuxt-link",
            { attrs: { to: "/blog/single/default/" + _vm.blog.slug } },
            [_vm._v(_vm._s(_vm.date))]
          ),
          _vm._v(" "),
          _c("span", { staticClass: "meta-separator" }, [_vm._v("|")]),
          _vm._v(" "),
          _c(
            "nuxt-link",
            { attrs: { to: "/blog/single/default/" + _vm.blog.slug } },
            [_vm._v(_vm._s(_vm.blog.comments) + " Comments")]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "h2",
        { staticClass: "entry-title" },
        [
          _c(
            "nuxt-link",
            { attrs: { to: "/blog/single/default/" + _vm.blog.slug } },
            [_vm._v(_vm._s(_vm.blog.title))]
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.blog.blog_categories
        ? _c(
            "div",
            { staticClass: "entry-cats" },
            [
              _vm._v("\n\t\t\tin \n\t\t\t"),
              _vm._l(_vm.blog.blog_categories, function(cat, index) {
                return _c(
                  "span",
                  { key: index },
                  [
                    _c(
                      "nuxt-link",
                      {
                        attrs: { to: "/blog/single/default/" + _vm.blog.slug }
                      },
                      [_vm._v(_vm._s(cat.name))]
                    ),
                    _vm._v(
                      "\n\t\t\t\t" +
                        _vm._s(
                          _vm.blog.blog_categories.length - 1 > index
                            ? ", "
                            : ""
                        ) +
                        "\n\t\t\t"
                    )
                  ],
                  1
                )
              })
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isContent
        ? _c(
            "div",
            { staticClass: "entry-content" },
            [
              _c("p", [_vm._v(_vm._s(_vm.blog.content))]),
              _vm._v(" "),
              _c(
                "nuxt-link",
                {
                  staticClass: "read-more",
                  attrs: { to: "/blog/single/default/" + _vm.blog.slug }
                },
                [_vm._v("Continue Reading")]
              )
            ],
            1
          )
        : _vm._e()
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "swiper-nav entry-nav" }, [
      _c("div", { staticClass: "swiper-prev" }, [
        _c("i", { staticClass: "icon-angle-left" })
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "swiper-next" }, [
        _c("i", { staticClass: "icon-angle-right" })
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }