var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.product
    ? _c(
        "div",
        { staticClass: "swiper-carousel product-gallery-carousel swiper-1" },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "swiper",
                  rawName: "v-swiper:swiper1",
                  value: _vm.carouselSetting,
                  expression: "carouselSetting",
                  arg: "swiper1"
                }
              ]
            },
            [
              _c(
                "div",
                { staticClass: "swiper-wrapper" },
                _vm._l(_vm.product.pictures, function(picture, index) {
                  return _c(
                    "div",
                    { key: index, staticClass: "swiper-slide" },
                    [
                      _c(
                        "figure",
                        { staticClass: "product-gallery-image p-0" },
                        [
                          _c("img", {
                            directives: [
                              {
                                name: "lazy",
                                rawName: "v-lazy",
                                value: `${_vm.baseUrl}${picture.url}`,
                                expression: "`${baseUrl}${picture.url}`"
                              }
                            ],
                            attrs: {
                              alt: "product",
                              width: picture.width,
                              height: picture.height
                            }
                          })
                        ]
                      )
                    ]
                  )
                }),
                0
              )
            ]
          ),
          _vm._v(" "),
          _vm._m(0)
        ]
      )
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "swiper-nav nav-side" }, [
      _c("div", { staticClass: "swiper-prev" }, [
        _c("i", { staticClass: "icon-angle-left" })
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "swiper-next" }, [
        _c("i", { staticClass: "icon-angle-right" })
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }