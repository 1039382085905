var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "product product-7 text-center" }, [
    _c(
      "figure",
      { staticClass: "product-media" },
      [
        _vm.product.new
          ? _c("span", { staticClass: "product-label label-new" }, [
              _vm._v("New")
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.product.discount
          ? _c("span", { staticClass: "product-label label-sale" }, [
              _vm._v("Sale")
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.product.top
          ? _c("span", { staticClass: "product-label label-top" }, [
              _vm._v("Top")
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.product.stock === 0
          ? _c("span", { staticClass: "product-label label-out" }, [
              _vm._v("Out Of Stock")
            ])
          : _vm._e(),
        _vm._v(" "),
        _c(
          "nuxt-link",
          { attrs: { to: "/product/default/" + _vm.product.id } },
          [
            _c("img", {
              directives: [
                {
                  name: "lazy",
                  rawName: "v-lazy",
                  value: _vm.product.main_image,
                  expression: "product.main_image"
                }
              ],
              staticClass: "product-image",
              attrs: { alt: "Product" }
            })
          ]
        ),
        _vm._v(" "),
        _vm.product.stock !== 0
          ? _c(
              "div",
              { staticClass: "product-action-vertical" },
              [
                _vm.isInWishlist(_vm.product)
                  ? _c(
                      "nuxt-link",
                      {
                        key: "inWishlist",
                        staticClass:
                          "btn-product-icon btn-wishlist added-to-wishlist",
                        attrs: { to: "/shop/wishlist" }
                      },
                      [_c("span", [_vm._v("go to wishlist")])]
                    )
                  : _c(
                      "a",
                      {
                        key: "notInWishlist",
                        staticClass: "btn-product-icon btn-wishlist",
                        attrs: { href: "javascript:;" },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.addToWishlist({ product: _vm.product })
                          }
                        }
                      },
                      [
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("product.addToWishlist")))
                        ])
                      ]
                    ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "btn-product-icon btn-quickview",
                    attrs: { href: "javascript:;", title: "Quick view" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.quickView({ product: _vm.product })
                      }
                    }
                  },
                  [_c("span", [_vm._v("Quick view")])]
                )
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.product.stock !== 0
          ? _c(
              "div",
              { staticClass: "product-action" },
              [
                _vm.product.length > 0
                  ? _c(
                      "nuxt-link",
                      {
                        staticClass: "btn btn-product btn-cart btn-select",
                        attrs: { to: "/product/default/" + _vm.product.id }
                      },
                      [_c("span", [_vm._v("select options")])]
                    )
                  : _c(
                      "a",
                      {
                        staticClass: "btn btn-product btn-cart",
                        attrs: { href: "javascript:;" },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.addToCart({ product: _vm.product })
                          }
                        }
                      },
                      [_c("span", [_vm._v("add to cart")])]
                    )
              ],
              1
            )
          : _vm._e()
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "product-body" }, [
      _c(
        "h3",
        { staticClass: "product-title" },
        [
          _c(
            "nuxt-link",
            { attrs: { to: "/product/default/" + _vm.product.id } },
            [_vm._v(_vm._s(_vm.product.name))]
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.product.price_after_discount
        ? _c("div", { staticClass: "product-price" }, [
            _c("span", { staticClass: "out-price" }, [
              _vm._v(_vm._s(_vm.product.price_after_discount))
            ])
          ])
        : _c("div", { staticClass: "product-price" }, [
            _c("span", { staticClass: "out-price" }, [
              _vm._v(_vm._s(_vm.product.price))
            ])
          ]),
      _vm._v(" "),
      _c("div", { staticClass: "ratings-container" }, [
        _c("div", { staticClass: "ratings" }, [
          _c("div", {
            staticClass: "ratings-val",
            style: { width: _vm.product.ratings * 20 + "%" }
          }),
          _vm._v(" "),
          _c("span", { staticClass: "tooltip-text" }, [
            _vm._v(_vm._s(_vm.product.ratings))
          ])
        ]),
        _vm._v(" "),
        _c("span", { staticClass: "ratings-text" }, [
          _vm._v("( " + _vm._s(_vm.product.review) + " Reviews )")
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }