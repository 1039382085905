<template>
    <div>
        <template v-if="!loading">
            <div class="col-9 m-auto skeleton-body skel-shop-products">
                <template>
                    <div
                        class="skel-pro skel-pro-list"
                        v-for="item in 1"
                        :key="item"
                    ></div>
                </template>
            </div>
        </template>
        <div class="" v-else>
            <!-- <section
                v-for="banner in banners.third"
                :key="banner.id"
                class="eid py-5 mt-4"
                v-lazy:background-image="banner.background_image"
            > -->
            <section
                v-for="banner in banners.third"
                :key="banner.id"
                class="eid py-5 mt-4"
            >
                <a
                    :href="banner.button_link"
                    v-if="$i18n.locale == 'en' && banner.background_image"
                    target="_blank"
                >
                    <img v-lazy="banner.background_image" />
                </a>
                <a
                    v-else-if="
                        $i18n.locale == 'en' && banner.background_image == ''
                    "
                >
                    <img
                        src="../../../../static/images/Alf/unnamed.png"
                        alt="product"
                        :width="category.width"
                        :height="category.height"
                    />
                </a>
                <a
                    :href="banner.button_link"
                    v-if="$i18n.locale == 'ar' && banner.background_image"
                    target="_blank"
                >
                    <img v-lazy="banner.background_image_ar" />
                </a>
                <a
                    v-else-if="
                        $i18n.locale == 'ar' && banner.background_image == ''
                    "
                >
                    <img
                        src="../../../../static/images/Alf/unnamed.png"
                        alt="product"
                        :width="category.width"
                        :height="category.height"
                    />
                </a>
                <!-- <div class="eid-holder">
                    <div class="container text-center">
                        <div class="row">
                            <div class="offset-md-5">
                                
                                <h2 class="h1 hug text-white" >
                                    {{ banner.title }}
                                </h2>
                                
                                <p class="text-white" >
                                    {{ banner.description }}
                                </p>
                                <div class="my-5">
                                    <a
                                        :href="banner.button_link"
                                        class="btn btn-primary btn-round p-4"
                                    >
                                        
                                        <span >
                                            {{ banner.button_name }}
                                        </span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->
            </section>
        </div>
    </div>
</template>

<script>
import Repository, { baseUrl } from '~/repositories/repository.js';

export default {
    data: function() {
        return {
            banners: {
                third: []
            },
            loading: false
        };
    },
    methods: {
        getData: async function() {
            let banners = `${baseUrl}/banners`;

            // banners
            await Repository.get(banners).then(resp => {
                //banners
                if (resp.data.code == 200) {
                    this.loading = true;
                }
                this.banners = resp.data.banners;
                // console.log('banners', this.banners);
            });
        }
    },
    mounted() {
        this.getData();
    }
};
</script>

<style></style>
