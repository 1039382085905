var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "accordion accordion-plus product-details-accordion mb-2" },
    [
      _c(
        "div",
        { staticClass: "card card-box card-sm" },
        [
          _c("div", { staticClass: "card-header" }, [
            _c("h2", { staticClass: "card-title" }, [
              _c(
                "a",
                {
                  staticClass: "toggle-button",
                  class: {
                    expanded: _vm.toggleState[0],
                    collapsed: !_vm.toggleState[0]
                  },
                  attrs: { href: "#" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.changeToggle(0)
                    }
                  }
                },
                [_vm._v("Description")]
              )
            ])
          ]),
          _vm._v(" "),
          _c("vue-slide-toggle", { attrs: { open: _vm.toggleState[0] } }, [
            _c("div", { staticClass: "card-body" }, [
              _c("div", { staticClass: "product-desc-content" }, [
                _c("p", [
                  _vm._v(
                    "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio. Quisque volutpat mattis eros. Nullam malesuada erat ut turpis. Suspendisse urna viverra non, semper suscipit, posuere a, pede. Donec nec justo eget felis facilisis fermentum. Aliquam porttitor mauris sit amet orci. Aenean dignissim pellentesque felis. Phasellus ultrices nulla quis nibh. Quisque a lectus. Donec consectetuer ligula vulputate sem tristique cursus."
                  )
                ]),
                _vm._v(" "),
                _c("ul", [
                  _c("li", [
                    _vm._v(
                      "Nunc nec porttitor turpis. In eu risus enim. In vitae mollis elit."
                    )
                  ]),
                  _vm._v(" "),
                  _c("li", [_vm._v("Vivamus finibus vel mauris ut vehicula.")]),
                  _vm._v(" "),
                  _c("li", [
                    _vm._v(
                      "Nullam a magna porttitor, dictum risus nec, faucibus sapien."
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio. Quisque volutpat mattis eros. Nullam malesuada erat ut turpis. Suspendisse urna viverra non, semper suscipit, posuere a, pede. Donec nec justo eget felis facilisis fermentum. Aliquam porttitor mauris sit amet orci. Aenean dignissim pellentesque felis. Phasellus ultrices nulla quis nibh. Quisque a lectus. Donec consectetuer ligula vulputate sem tristique cursus."
                  )
                ])
              ])
            ])
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "card card-box card-sm" },
        [
          _c("div", { staticClass: "card-header" }, [
            _c("h2", { staticClass: "card-title" }, [
              _c(
                "a",
                {
                  staticClass: "toggle-button",
                  class: {
                    expanded: _vm.toggleState[1],
                    collapsed: !_vm.toggleState[1]
                  },
                  attrs: { href: "#" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.changeToggle(1)
                    }
                  }
                },
                [_vm._v("Additional Information")]
              )
            ])
          ]),
          _vm._v(" "),
          _c("vue-slide-toggle", { attrs: { open: _vm.toggleState[1] } }, [
            _c("div", { staticClass: "card-body" }, [
              _c("div", { staticClass: "product-desc-content" }, [
                _c("p", { staticClass: "mb-2" }, [
                  _vm._v(
                    "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio. Quisque volutpat mattis eros. Nullam malesuada erat ut turpis. Suspendisse urna viverra non, semper suscipit, posuere a, pede. Donec nec justo eget felis facilisis fermentum. Aliquam porttitor mauris sit amet orci."
                  )
                ]),
                _vm._v(" "),
                _c("h3", [_vm._v("Fabric & care")]),
                _vm._v(" "),
                _c("ul", [
                  _c("li", [_vm._v("Faux suede fabric")]),
                  _vm._v(" "),
                  _c("li", [_vm._v("Gold tone metal hoop handles.")]),
                  _vm._v(" "),
                  _c("li", [_vm._v("RI branding")]),
                  _vm._v(" "),
                  _c("li", [_vm._v("Snake print trim interior")]),
                  _vm._v(" "),
                  _c("li", [_vm._v("Adjustable cross body strap")]),
                  _vm._v(" "),
                  _c("li", [
                    _vm._v(
                      "Height: 31cm; Width: 32cm; Depth: 12cm; Handle Drop: 61cm"
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("h3", [_vm._v("Size")]),
                _vm._v(" "),
                _c("p", [_vm._v("one size")])
              ])
            ])
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "card card-box card-sm" },
        [
          _c("div", { staticClass: "card-header" }, [
            _c("h2", { staticClass: "card-title" }, [
              _c(
                "a",
                {
                  staticClass: "toggle-button",
                  class: {
                    expanded: _vm.toggleState[2],
                    collapsed: !_vm.toggleState[2]
                  },
                  attrs: { href: "#" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.changeToggle(2)
                    }
                  }
                },
                [_vm._v("Shipping & Returns")]
              )
            ])
          ]),
          _vm._v(" "),
          _c("vue-slide-toggle", { attrs: { open: _vm.toggleState[2] } }, [
            _c("div", { staticClass: "card-body" }, [
              _c("div", { staticClass: "product-desc-content" }, [
                _c("p", [
                  _vm._v(
                    "\n                        We deliver to over 100 countries around the world. For full details of the delivery options we offer, please view our\n                        "
                  ),
                  _c("a", { attrs: { href: "#" } }, [
                    _vm._v("Delivery information")
                  ]),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(
                    "We hope you’ll love every purchase, but if you ever need to return an item you can do so within a month of receipt. For full details of how to make a return, please view our\n                        "
                  ),
                  _c("a", { attrs: { href: "#" } }, [
                    _vm._v("Returns information")
                  ])
                ])
              ])
            ])
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "card card-box card-sm" },
        [
          _c("div", { staticClass: "card-header" }, [
            _c("h2", { staticClass: "card-title" }, [
              _c(
                "a",
                {
                  staticClass: "toggle-button",
                  class: {
                    expanded: _vm.toggleState[3],
                    collapsed: !_vm.toggleState[3]
                  },
                  attrs: { href: "#" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.changeToggle(3)
                    }
                  }
                },
                [_vm._v("Reviews(2)")]
              )
            ])
          ]),
          _vm._v(" "),
          _c("vue-slide-toggle", { attrs: { open: _vm.toggleState[3] } }, [
            _c("div", { staticClass: "card-body" }, [
              _c("div", { staticClass: "reviews" }, [
                _c("div", { staticClass: "review" }, [
                  _c("div", { staticClass: "row no-gutters" }, [
                    _c("div", { staticClass: "col-auto" }, [
                      _c("h4", [
                        _c("a", { attrs: { href: "#" } }, [
                          _vm._v("Samanta J.")
                        ])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "ratings-container" }, [
                        _c("div", { staticClass: "ratings" }, [
                          _c("div", {
                            staticClass: "ratings-val",
                            staticStyle: { width: "80%" }
                          }),
                          _vm._v(" "),
                          _c("span", { staticClass: "tooltip-text" }, [
                            _vm._v("4")
                          ])
                        ])
                      ]),
                      _vm._v(" "),
                      _c("span", { staticClass: "review-date" }, [
                        _vm._v("6 days ago")
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col" }, [
                      _c("h4", [_vm._v("Good, perfect size")]),
                      _vm._v(" "),
                      _c("div", { staticClass: "review-content" }, [
                        _c("p", [
                          _vm._v(
                            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ducimus cum dolores assumenda asperiores facilis porro reprehenderit animi culpa atque blanditiis commodi perspiciatis doloremque, possimus, explicabo, autem fugit beatae quae voluptas!"
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "review-action" }, [
                        _c("a", { attrs: { href: "#" } }, [
                          _c("i", { staticClass: "icon-thumbs-up" }),
                          _vm._v(
                            "Helpful (2)\n                                    "
                          )
                        ]),
                        _vm._v(" "),
                        _c("a", { attrs: { href: "#" } }, [
                          _c("i", { staticClass: "icon-thumbs-down" }),
                          _vm._v(
                            "Unhelpful (0)\n                                    "
                          )
                        ])
                      ])
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "review border-0" }, [
                  _c("div", { staticClass: "row no-gutters" }, [
                    _c("div", { staticClass: "col-auto" }, [
                      _c("h4", [
                        _c("a", { attrs: { href: "#" } }, [_vm._v("John Doe")])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "ratings-container" }, [
                        _c("div", { staticClass: "ratings" }, [
                          _c("div", {
                            staticClass: "ratings-val",
                            staticStyle: { width: "100%" }
                          }),
                          _vm._v(" "),
                          _c("span", { staticClass: "tooltip-text" }, [
                            _vm._v("5")
                          ])
                        ])
                      ]),
                      _vm._v(" "),
                      _c("span", { staticClass: "review-date" }, [
                        _vm._v("5 days ago")
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col" }, [
                      _c("h4", [_vm._v("Very good")]),
                      _vm._v(" "),
                      _c("div", { staticClass: "review-content" }, [
                        _c("p", [
                          _vm._v(
                            "Sed, molestias, tempore? Ex dolor esse iure hic veniam laborum blanditiis laudantium iste amet. Cum non voluptate eos enim, ab cumque nam, modi, quas iure illum repellendus, blanditiis perspiciatis beatae!"
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "review-action" }, [
                        _c("a", { attrs: { href: "#" } }, [
                          _c("i", { staticClass: "icon-thumbs-up" }),
                          _vm._v(
                            "Helpful (0)\n                                    "
                          )
                        ]),
                        _vm._v(" "),
                        _c("a", { attrs: { href: "#" } }, [
                          _c("i", { staticClass: "icon-thumbs-down" }),
                          _vm._v(
                            "Unhelpful (0)\n                                    "
                          )
                        ])
                      ])
                    ])
                  ])
                ])
              ])
            ])
          ])
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }