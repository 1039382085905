// export const actions = {
//     async nuxtServerInit ( { commit, dispatch } ) {
//     }
// }
import Repository, { baseUrl } from '~/repositories/repository.js';

import axios from 'axios';
import _ from 'lodash';
const initialState = () => ({
    status: '',
    token: sessionStorage.getItem('token') || '',
    user: {},
    counter: 0
    // Question: [],
    // current: 0,
    // products: [],
    // product: null,
    // cart: [],
    // wishList: [],
    // wishListQt: 0
    // language:'LANGUAGE2'
});
export const state = initialState;

export const mutations = {
    auth_request(state) {
        state.status = 'loading';
    },
    auth_success(state, token, user) {
        state.status = 'success';
        state.token = token;
        state.user = user;
    },
    auth_error(state) {
        state.status = 'error';
    },
    logout(state) {
        state.status = '';
        state.token = '';
    }
};

export const actions = {
    login({ commit }, user) {
        return new Promise((resolve, reject) => {
            commit('auth_request');
            axios({
                url: `${baseUrl}/login`,
                headers: {
                    language:
                        this.$i18n.locale == 'en' ? 'LANGUAGE2' : 'LANGUAGE1'
                },
                data: user,
                method: 'POST'
            })
                .then(resp => {
                    const token = resp.data.api_token;
                    // console.log(token);
                    const user = resp.data.username;
                    if (resp.data.code == 200) {
                        // alert("pla")
                        sessionStorage.setItem('token', token);
                        sessionStorage.setItem('username', user);
                        console.log(token);
                        // Add the following line:
                        axios.defaults.headers.common['Authorization'] = token;
                        commit('auth_success', token, user);
                        sessionStorage.removeItem('Alf-localStorage');
                        setTimeout(() => {
                            this.$router.go(this.$router.currentRouter);
                        }, 1200);
                    } else {
                        sessionStorage.removeItem('token');
                        localStorage.removeItem('Alf-localStorage');
                    }
                    if (resp.data.code === 205) {
                        this.$router.push('/pages/activate');
                        // this.$emit('close');
                        setTimeout(() => {
                            this.$router.go(this.$router.currentRoute);
                        }, 500);
                    }
                    resolve(resp);
                })
                .catch(err => {
                    commit('auth_error');
                    sessionStorage.removeItem('token');
                    reject(err);
                });
        });
    },
    register({ commit }, user) {
        return new Promise((resolve, reject) => {
            commit('auth_request');
            axios({
                url: `${baseUrl}/register`,
                headers: {
                    language:
                        this.$i18n.locale == 'en' ? 'LANGUAGE2' : 'LANGUAGE1'
                },
                data: user,
                method: 'POST'
            })
                .then(resp => {
                    const token = resp.data.api_token;
                    const user = resp.data.username;
                    if (resp.data.code == 200) {
                        sessionStorage.setItem('token', token);
                        sessionStorage.setItem('username', user);
                        // Add the following line:
                        axios.defaults.headers.common['Authorization'] = token;
                        commit('auth_success', token, user);
                    }
                    resolve(resp);
                })
                .catch(err => {
                    commit('auth_error', err);
                    sessionStorage.removeItem('token');
                    localStorage.removeItem('Alf-localStorage');
                    reject(err);
                });
        });
    },
    logout({ commit }) {
        return new Promise(resolve => {
            commit('logout');
            // sessionStorage.removeItem('token');
            sessionStorage.clear();
            localStorage.clear();

            delete axios.defaults.headers.common['Authorization'];
            resolve();
            setTimeout(() => {
                this.$router.go(this.$router.currentRoute);
            }, 1200);
        });
    },
    async nuxtServerInit({ commit, dispatch }) {}
};
export const getters = {
    isLoggedIn: state => !!state.token,
    authStatus: state => state.status
};
