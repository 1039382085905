<template>
    <div>
        <!-- eid fashion  -->
        <template v-if="!loading">
            <div class="col-9 m-auto skeleton-body skel-shop-products">
                <template>
                    <div
                        class="skel-pro skel-pro-list"
                        v-for="item in 1"
                        :key="item"
                    ></div>
                </template>
            </div>
        </template>
        <div class="" v-else>
            <section class="eid-fashion">
                <div
                    class="eid-fashion-holder"
                    v-for="sixth in banners.sixth"
                    :key="sixth.id"
                    v-lazy:background-image="sixth.background_image"
                >
                    <div class="container" v-if="banners.sixth.length > 0">
                        <div class="row">
                            <div class="col-md-6 col-sm-12 eid-text">
                                <h2
                                    class="hug text-white"
                                   
                                >
                                    {{ sixth.title }}
                                </h2>
                                
                                
                                <p
                                    class="text-break text-white"
                                    
                                >
                                    {{ sixth.description }}
                                </p>
                            </div>
                            <div class="col-md-6 col-sm-12">
                                <img
                                    v-lazy="sixth.primary_image"
                                    class="bg-transparent"
                                    alt="Alf Logo"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
import Repository, { baseUrl } from '~/repositories/repository.js';

export default {
    data: function () {
        return {
            banners: {
                first: [],
                seconnd: [],
                third: [],
                fourth: [],
                fifth: [],
                sixth: [],
                seventh: [],
            },
            loading: false,
        };
    },
    methods: {
        getData: async function () {
            let banners = `${baseUrl}/banners`;
            // banners
            await Repository.get(banners).then((resp) => {
                //banners
                if (resp.data.code == 200) {
                    this.loading = true;
                }
                this.banners = resp.data.banners;
                // console.log('banners', this.banners);
            });
        },
    },
    mounted() {
        this.getData();
    },
    props: ['product'],
};
</script>

<style>
</style>