<template>
    <div class="modal-body m-3">
        <button type="button" class="close" @click="$emit('close')">
            <span aria-hidden="true">
                <i class="icon-close"></i>
            </span>
        </button>
        <div class="form-box text-right">
            <h3>: {{ item }}</h3>
            <p>{{ address }}</p>
            <p>{{ phone }}</p>
        </div>
    </div>
</template>
<script>
export default {
    props: ['item', 'address', 'phone'],
    data: function() {
        return {
            branches: []
        };
    },
    methods: {
        getBranches: async function() {
            await Repository.get(`${baseUrl}/stores/address`)
                .then(resp => {
                    console.log(resp);
                    this.branches = resp.data.stores;
                    console.log('this.branches', this.branches);
                })
                .catch(err => {
                    console.log(err);
                });
        }
    }
};
</script>

<style lang="scss" scoped>
.form-box {
    border-radius: 10px;
    overflow: auto !important;
}
.modal-body {
    height: auto;
}
p {
    font-size: 18px;
    font-family: SimeRegular;
}
</style>
