var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      !_vm.loading
        ? [
            _c(
              "div",
              { staticClass: "col-9 m-auto skeleton-body skel-shop-products" },
              [
                _vm._l(1, function(item) {
                  return _c("div", {
                    key: item,
                    staticClass: "skel-pro skel-pro-list"
                  })
                })
              ],
              2
            )
          ]
        : _c("div", {}, [
            _vm.brands.length > 0
              ? _c("section", { staticClass: "lover-brands text-center" }, [
                  _c("div", { staticClass: "lover-brands-holder" }, [
                    _c("div", { staticClass: "mb-5" }, [
                      _c("h2", { staticClass: "h1 text-gray my-3 Offers" }, [
                        _vm._v(
                          "\n                        " +
                            _vm._s(_vm.$t("brands.title")) +
                            "\n                    "
                        )
                      ]),
                      _vm._v(" "),
                      _c("hr", { staticClass: "divider1" })
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "container" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "swiper-carousel vendors-slider swiper-2 mb-5 mt-5"
                        },
                        [
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "swiper",
                                  rawName: "v-swiper:swiper8",
                                  value: _vm.carouselSetting8,
                                  expression: "carouselSetting8",
                                  arg: "swiper8"
                                }
                              ]
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "swiper-wrapper" },
                                _vm._l(_vm.brands, function(brand, index) {
                                  return _c(
                                    "div",
                                    { key: index, staticClass: "swiper-slide" },
                                    [
                                      _c(
                                        "nuxt-link",
                                        {
                                          attrs: {
                                            to: `/shop/sidebar/advancedBrand?brand=${brand.id}`
                                          }
                                        },
                                        [
                                          brand.image
                                            ? _c("img", {
                                                directives: [
                                                  {
                                                    name: "lazy",
                                                    rawName: "v-lazy",
                                                    value: brand.image,
                                                    expression: "brand.image"
                                                  }
                                                ],
                                                staticClass: "bg-transparent",
                                                attrs: { alt: "Alf Logo" }
                                              })
                                            : _vm._e(),
                                          _vm._v(" "),
                                          brand.name
                                            ? _c(
                                                "p",
                                                { staticClass: "text-dark" },
                                                [
                                                  _vm._v(
                                                    "\n                                            " +
                                                      _vm._s(brand.name) +
                                                      "\n                                        "
                                                  )
                                                ]
                                              )
                                            : _vm._e()
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                }),
                                0
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _vm._m(0)
                        ]
                      )
                    ])
                  ])
                ])
              : _vm._e()
          ])
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "swiper-nav" }, [
      _c("div", { staticClass: "swiper-next" }, [
        _c("i", { staticClass: "icon-angle-right" })
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }