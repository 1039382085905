var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "places-holder text-left" }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-12" }, [
          _c(
            "figure",
            { staticClass: "product-media" },
            [
              _vm.brand.new
                ? _c("span", { staticClass: "product-label label-new" }, [
                    _vm._v(_vm._s(_vm.$t("product.new")))
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.brand.sale_price ||
              _vm.brand.price != _vm.brand.price_after_discount
                ? _c("span", { staticClass: "product-label label-sale" }, [
                    _vm._v(_vm._s(_vm.$t("product.sale")))
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.brand.top
                ? _c("span", { staticClass: "product-label label-top" }, [
                    _vm._v(_vm._s(_vm.$t("product.top")))
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.brand.stock === 0
                ? _c("span", { staticClass: "product-label label-out" }, [
                    _vm._v(_vm._s(_vm.$t("product.outOfStock")))
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "nuxt-link",
                {
                  attrs: {
                    to: _vm.localePath("/product/default/" + _vm.brand.id)
                  }
                },
                [
                  _vm.brand.main_image
                    ? _c("img", {
                        directives: [
                          {
                            name: "lazy",
                            rawName: "v-lazy",
                            value: _vm.brand.main_image,
                            expression: "brand.main_image"
                          }
                        ],
                        staticClass: "w-100 h-100",
                        attrs: {
                          alt: "brand",
                          width: _vm.brand.main_image,
                          height: _vm.brand.main_image
                        }
                      })
                    : _c("img", {
                        staticClass: "brand-image-hover",
                        attrs: {
                          src: _vm.brand.main_image,
                          alt: "brand",
                          width: _vm.brand.main_image,
                          height: _vm.brand.main_image
                        }
                      })
                ]
              )
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "col-12 d-flex justify-content-center align-items-center flex-column"
          },
          [
            _c("div", { staticClass: "product-body product-action-inner" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-md-6 col-sm-12" }, [
                  _c("div", { staticClass: "product-info1" }, [
                    _c(
                      "h3",
                      { staticClass: "product-title" },
                      [
                        _c(
                          "nuxt-link",
                          {
                            attrs: {
                              to: _vm.localePath(
                                "/product/default/" + _vm.brand.id
                              )
                            }
                          },
                          [_vm._v(_vm._s(_vm.brand.name))]
                        )
                      ],
                      1
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-md-6 col-sm-12" }, [
                  _c(
                    "div",
                    { staticClass: "product-info2" },
                    [
                      _vm.brand.stock == 0
                        ? _c(
                            "div",
                            { key: "outPrice", staticClass: "product-price" },
                            [
                              _c("span", { staticClass: "out-price" }, [
                                _vm._v(_vm._s(_vm.$t("product.outOfStock")))
                              ])
                            ]
                          )
                        : [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "product-price d-flex flex-column justify-content-start"
                              },
                              [
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "new-price lead text-primary mr-0"
                                  },
                                  [
                                    _c("span", {}, [
                                      _vm._v(
                                        _vm._s(_vm.$t("product.price")) +
                                          " :\n                                                " +
                                          _vm._s(_vm.brand.price_after_discount)
                                      )
                                    ])
                                  ]
                                ),
                                _vm._v(" "),
                                _vm.brand.price !=
                                _vm.brand.price_after_discount
                                  ? _c("p", { staticClass: "old-price lead" }, [
                                      _c(
                                        "del",
                                        { staticClass: "text-danger" },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.$t("product.price")) +
                                              " :\n                                                " +
                                              _vm._s(_vm.brand.price)
                                          )
                                        ]
                                      )
                                    ])
                                  : _vm._e()
                              ]
                            )
                          ]
                    ],
                    2
                  )
                ])
              ])
            ]),
            _vm._v(" "),
            _vm._m(0)
          ]
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "product-list-action" }, [
      _c("div", { staticClass: "product-action2 d-flex" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }