var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      !_vm.loading
        ? [
            _c(
              "div",
              { staticClass: "col-9 m-auto skeleton-body skel-shop-products" },
              [
                _vm._l(1, function(item) {
                  return _c("div", {
                    key: item,
                    staticClass: "skel-pro skel-pro-list"
                  })
                })
              ],
              2
            )
          ]
        : _c("div", { staticClass: "mt-5" }, [
            _vm.secondListCategories.length > 0
              ? _c("section", { staticClass: "baby-producst text-center" }, [
                  _c("div", { staticClass: "wrapper-with-margin" }, [
                    _c("div", { staticClass: "container" }, [
                      _c(
                        "div",
                        { staticClass: "row align-items-center mb-5" },
                        [
                          _vm._m(0),
                          _vm._v(" "),
                          _c("hr"),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "col-6 show-all" },
                            [
                              _c(
                                "nuxt-link",
                                {
                                  staticClass: "btn btn-primary btn-round p-4",
                                  attrs: {
                                    to: _vm.localePath("/shop/sidebar/list")
                                  }
                                },
                                [_c("span", [_vm._v("عرض الكل")])]
                              )
                            ],
                            1
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "swiper-carousel brands-slider swiper-2 mb-5 mt-5"
                        },
                        [
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "swiper",
                                  rawName: "v-swiper:swiper2",
                                  value: _vm.carouselSetting2,
                                  expression: "carouselSetting2",
                                  arg: "swiper2"
                                }
                              ]
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "swiper-wrapper" },
                                _vm._l(_vm.secondListCategories, function(
                                  listCategory
                                ) {
                                  return _c(
                                    "div",
                                    {
                                      key: listCategory.id,
                                      staticClass: "swiper-slide"
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "card-holder" },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "card text-center" },
                                            [
                                              listCategory.discount_int !== null
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "card-info text-white"
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                    Sale!\n                                "
                                                      )
                                                    ]
                                                  )
                                                : _vm._e()
                                            ]
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _vm._m(1, true),
                                      _vm._v(" "),
                                      _c(
                                        "nuxt-link",
                                        {
                                          attrs: {
                                            to: _vm.localePath(
                                              `/product/default/${listCategory.id}`
                                            )
                                          }
                                        },
                                        [
                                          _c("img", {
                                            directives: [
                                              {
                                                name: "lazy",
                                                rawName: "v-lazy",
                                                value: listCategory.main_image,
                                                expression:
                                                  "listCategory.main_image"
                                              }
                                            ],
                                            staticClass: "bg-transparent",
                                            attrs: {
                                              alt: "Alf Logo",
                                              height: "27"
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "p",
                                            { staticClass: "text-dark" },
                                            [
                                              _vm._v(
                                                "\n                                            " +
                                                  _vm._s(listCategory.name) +
                                                  "\n                                        "
                                              )
                                            ]
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      listCategory.discount_int !== null
                                        ? _c(
                                            "div",
                                            { staticClass: "row text-center" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "col-md-6 col-sm-12"
                                                },
                                                [
                                                  _c(
                                                    "p",
                                                    {
                                                      staticClass:
                                                        "text-primary"
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                                " +
                                                          _vm._s(
                                                            listCategory.price_after_discount
                                                          ) +
                                                          "\n                                                جنية\n                                            "
                                                      )
                                                    ]
                                                  )
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "col-md-6 col-sm-12"
                                                },
                                                [
                                                  _c(
                                                    "p",
                                                    {
                                                      staticClass:
                                                        "text-primary w-100"
                                                    },
                                                    [
                                                      _c("del", [
                                                        _vm._v(
                                                          "\n                                                    " +
                                                            _vm._s(
                                                              listCategory.price
                                                            )
                                                        )
                                                      ])
                                                    ]
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        : _c(
                                            "div",
                                            { staticClass: "row text-center" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "col-md-12 col-sm-12"
                                                },
                                                [
                                                  _c(
                                                    "p",
                                                    {
                                                      staticClass:
                                                        "text-primary w-100"
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                              \n                                                    " +
                                                          _vm._s(
                                                            listCategory.price
                                                          ) +
                                                          "\n                                                \n                                            "
                                                      )
                                                    ]
                                                  )
                                                ]
                                              )
                                            ]
                                          ),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "actions" }, [
                                        _c("div", { staticClass: "row" }, [
                                          _c("div", { staticClass: "col" }, [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "img-holder d-flex justify-content-center align-items-center",
                                                class: {
                                                  "btn-disabled": !_vm.canAddToCart(
                                                    listCategory,
                                                    1
                                                  )
                                                }
                                              },
                                              [
                                                _c(
                                                  "button",
                                                  {
                                                    on: {
                                                      click: function($event) {
                                                        $event.preventDefault()
                                                        return _vm.addToCart({
                                                          product: listCategory
                                                        })
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "fas fa-cart-plus fa-2x"
                                                    })
                                                  ]
                                                )
                                              ]
                                            )
                                          ]),
                                          _vm._v(" "),
                                          _c("div", { staticClass: "col" }, [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "img-holder d-flex justify-content-center align-items-center"
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "wishlist-outline d-flex justify-content-start"
                                                  },
                                                  [
                                                    _vm.isInWishlist(
                                                      listCategory
                                                    )
                                                      ? _c(
                                                          "nuxt-link",
                                                          {
                                                            key: "inWishlist",
                                                            staticClass:
                                                              "btn-product",
                                                            attrs: {
                                                              to: _vm.localePath(
                                                                "/shop/wishlist"
                                                              )
                                                            }
                                                          },
                                                          [
                                                            _c("i", {
                                                              staticClass:
                                                                "fas fa-heart"
                                                            })
                                                          ]
                                                        )
                                                      : _c(
                                                          "a",
                                                          {
                                                            key:
                                                              "notInWishlist",
                                                            staticClass:
                                                              "btn-product",
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                $event.preventDefault()
                                                                return _vm.addToWishlist(
                                                                  {
                                                                    product: listCategory
                                                                  }
                                                                )
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _c("i", {
                                                              staticClass:
                                                                "far fa-heart fa-2x"
                                                            })
                                                          ]
                                                        )
                                                  ],
                                                  1
                                                )
                                              ]
                                            )
                                          ])
                                        ])
                                      ])
                                    ],
                                    1
                                  )
                                }),
                                0
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _vm._m(2)
                        ]
                      )
                    ])
                  ])
                ])
              : _vm._e()
          ])
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-6" }, [
      _c("h1", { staticClass: "text-right text-gray m-3 mb-2" }, [
        _vm._v(
          "\n                            عسل الزيرو سكروز\n                        "
        )
      ])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "rating card-info" }, [
      _c("i", { staticClass: "fas fa-star" }),
      _vm._v(" "),
      _c("span", [_vm._v("4.5")])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "swiper-nav" }, [
      _c("div", { staticClass: "swiper-prev" }, [
        _c("i", { staticClass: "icon-angle-left" })
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "swiper-next" }, [
        _c("i", { staticClass: "icon-angle-right" })
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }