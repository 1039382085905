<template>
    <div class="quickView-container">
        <div class="product-list-action">
            <div class="product-action2 d-flex">
                <div>
                    <div class="card shadow rounded" style="border: none">
                        <img
                            v-lazy="category.main_image"
                            class="card-img-top p-3"
                            alt="Card image cap"
                        />

                        <div class="card-body p-3">
                            <h5 class="card-title">
                                {{ category.subcategory.name }}
                            </h5>
                            <br />
                            <button
                                @click.prevent="
                                    quickView({ category: category })
                                "
                                class="button"
                                data-toggle="modal"
                                data-target="#addPlanModal"
                            >
                                عرض المزيد
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: ['category'],
    watch: {
        $route: function () {
            this.$modal.hideAll();
        },
    },
    methods: {
        quickView: function () {
            this.$modal.show(
                () => import('./compDetails'),
                {
                    category: this.category,
                },
                { width: '1030', height: 'auto', adaptive: true }
            );
        },
    },
};
</script>