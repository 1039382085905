<template>
    <div>
        <template v-if="!loading">
            <div class="col-9 m-auto skeleton-body skel-shop-products">
                <template>
                    <div
                        class="skel-pro skel-pro-list"
                        v-for="item in 1"
                        :key="item"
                    ></div>
                </template>
            </div>
        </template>
        <div class="" v-else>
            <!-- <section
                class="free-delivery mb-4"
                v-for="banner in banners.first"
                :key="banner.id"
                v-lazy:background-image="banner.background_image"
            > -->
            <section
                class="free-delivery"
                v-for="banner in banners.first"
                :key="banner.id"
            >
                <a :href="banner.button_link" v-if="$i18n.locale == 'en'" target="_blank">
                    <img v-lazy="banner.background_image" />
                </a>
                <a :href="banner.button_link" v-if="$i18n.locale == 'ar'" target="_blank">
                    <img v-lazy="banner.background_image_ar" />
                </a>
                <!-- <div class="container text-center">
                    <div class="row">
                        <div class="col-md-7 col-sm-12">
                            <h2 class="h1 text-white">
                                {{ banner.title }}
                            </h2>

                            <p class="text-white">
                                {{ banner.description }}
                            </p>

                            <div class="my-5">
                            </div>
                        </div>
                        <div class="col-md-5 col-sm-12">
                            <img
                                v-lazy="banner.primary_image"
                                class="bg-transparent"
                                alt="Alf Logo"
                            />
                        </div>
                    </div>
                </div> -->
            </section>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import Repository, { baseUrl } from '~/repositories/repository.js';

export default {
    data: function() {
        return {
            banners: {
                first: [],
                seconnd: [],
                third: [],
                fourth: [],
                fifth: [],
                sixth: [],
                seventh: []
            },
            loading: false
        };
    },
    methods: {
        // getData() {
        //     let banners = `${baseUrl}/banners`;

        //     // banners
        //     axios.get(banners).then((resp) => {
        //         //banners
        //         if (resp.data.code == 200) {
        //             this.loading = true;
        //         }
        //         this.banners = resp.data.banners;
        //         // console.log('banners', this.banners);
        //     });
        // },

        getData() {
            axios
                .get(`${baseUrl}/banners`, {
                    headers: {
                        language:
                            this.$i18n.locale == 'en'
                                ? 'LANGUAGE2'
                                : 'LANGUAGE1'
                    }
                })
                .then(resp => {
                    if (resp.data.code == 200) {
                        this.loading = true;
                        this.banners = resp.data.banners;
                        console.log('banners', this.banners);
                        this.banners.first = this.banners.first;
                        console.log('first', this.banners.first);
                    }
                    this.banners = resp.data.banners;
                })
                .catch(err => {
                    console.log(err);
                });
        }
    },
    mounted() {
        this.getData();
    },
    computed: {
        fakeArray: function() {
            let temp = [];
            for (let i = 0; i < this.perPage; i++) {
                temp.push(i);
            }
            return temp;
        }
    }
};
</script>
