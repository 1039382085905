var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "lazy",
          rawName: "v-lazy:background-image",
          value: "./images/page-header-bg.jpg",
          expression: "'./images/page-header-bg.jpg'",
          arg: "background-image"
        }
      ],
      staticClass: "page-header text-center"
    },
    [
      _c("div", { staticClass: "container" }, [
        _c("h1", { staticClass: "page-title" }, [
          _vm._v("\n            " + _vm._s(_vm.title) + "\n            "),
          _vm.subtitle ? _c("span", [_vm._v(_vm._s(_vm.subtitle))]) : _vm._e()
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }