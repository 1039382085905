var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "quickView-container m-4" }, [
    _c("div", { staticClass: "quickView-content horizontal skeleton-body" }, [
      _c(
        "div",
        {
          staticClass: "row skel-pro-single skel-quickview m-0",
          class: { loaded: _vm.loaded }
        },
        [
          _c("div", { staticClass: "col-lg-6 p-0 pr-2" }, [
            _c("div", { staticClass: "skel-product-gallery" }),
            _vm._v(" "),
            _c("div", { staticClass: "product-lg mb-1 position-relative" }, [
              _c("div", { staticClass: "swiper-carousel swiper-1" }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "swiper",
                        rawName: "v-swiper:swiper1",
                        value: _vm.carouselSetting,
                        expression: "carouselSetting",
                        arg: "swiper1"
                      }
                    ],
                    on: { slideChangeTransitionEnd: _vm.slideChange }
                  },
                  [
                    _c("div", { staticClass: "swiper-wrapper" }, [
                      _vm.category.main_image
                        ? _c("div", { staticClass: "swiper-slide" }, [
                            _c("img", {
                              directives: [
                                {
                                  name: "lazy",
                                  rawName: "v-lazy",
                                  value: _vm.category.main_image,
                                  expression: "category.main_image"
                                }
                              ],
                              attrs: {
                                alt: "product",
                                width: _vm.category.width,
                                height: _vm.category.height
                              }
                            })
                          ])
                        : _c("div", { staticClass: "swiper-slide" }, [
                            _c("img", {
                              attrs: {
                                src: require("../../../static/images/Alf/unnamed.png"),
                                alt: "product",
                                width: _vm.category.width,
                                height: _vm.category.height
                              }
                            })
                          ])
                    ])
                  ]
                )
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "product-sm row px-2" })
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "col-lg-6 quickview-desc pl-0 pl-lg-4 pr-0 mt-3 mt-lg-0"
            },
            [
              _vm._m(0),
              _vm._v(" "),
              _c("div", { staticClass: "product-summary pr-4" }, [
                _vm._v(
                  "\n                    name : " +
                    _vm._s(_vm.category.name) +
                    "\n                "
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "product-summary pr-4" }, [
                _vm._v(
                  "\n                    data : " +
                    _vm._s(_vm.category.updated_at) +
                    "\n                "
                )
              ])
            ]
          )
        ]
      )
    ]),
    _vm._v(" "),
    _c(
      "button",
      {
        staticClass: "mfp-close",
        attrs: { title: "Close (Esc)", type: "button" },
        on: {
          click: function($event) {
            return _vm.$emit("close")
          }
        }
      },
      [_c("span", [_vm._v("×")])]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "entry-summary row" }, [
      _c("div", { staticClass: "col-md-12" }, [
        _c("div", { staticClass: "entry-summary1" })
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-md-12" }, [
        _c("div", { staticClass: "entry-summary2" })
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }