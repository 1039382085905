<template>
    <div>
        <template v-if="!loading">
            <div class="col-9 m-auto skeleton-body skel-shop-products">
                <template>
                    <div
                        class="skel-pro skel-pro-list"
                        v-for="item in 1"
                        :key="item"
                    ></div>
                </template>
            </div>
        </template>
        <div class="" v-else>
            <section
                class="baby-producst third-carousel text-center mt-5"
                v-if="hotDicounts.length > 0"
            >
                <div class="wrapper-with-margin">
                    <div class="container">
                        <h2 class="h1 text-gray my-3 Offers">
                            {{ $t('discountOffer.title') }}
                        </h2>
                        <div
                            class="
                                swiper-carousel
                                brands-slider
                                swiper-2
                                mb-5
                                mt-5
                            "
                        >
                            <div v-swiper:swiper3="carouselSetting3">
                                <div class="swiper-wrapper">
                                    <div
                                        class="swiper-slide"
                                        v-for="hot in hotDicounts"
                                        :key="hot.id"
                                    >
                                        <nuxt-link
                                            :to="
                                                localePath(
                                                    `/product/default/${hot.id}`
                                                )
                                            "
                                            class="brand"
                                        >
                                            <img
                                                v-if="hot.product_main_image"
                                                v-lazy="hot.product_main_image"
                                                class="bg-transparent"
                                                alt="Alf Logo"
                                                height="27"
                                                width="200"
                                            />
                                            <p class="text-dark">
                                                {{ hot.name }}
                                            </p>
                                            <div
                                                v-if="
                                                    hot.productPrice_after_discount
                                                "
                                            >
                                                <span
                                                    class="
                                                        text-primary
                                                        mr-5
                                                        text-bold
                                                    "
                                                >
                                                    <b>
                                                        {{
                                                            hot.productPrice_after_discount
                                                        }}
                                                    </b>
                                                </span>

                                                <span class="text-danger">
                                                    <b>
                                                        <del>{{
                                                            hot.price
                                                        }}</del>
                                                    </b>
                                                </span>
                                            </div>
                                        </nuxt-link>
                                    </div>
                                </div>
                            </div>
                            <div class="swiper-nav">
                                <div class="swiper-prev">
                                    <i class="icon-angle-left"></i>
                                </div>
                                <div class="swiper-next">
                                    <i class="icon-angle-right"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
import {
    carouselSettingSingle,
    carouselSettingDefault
} from '~/utilities/carousel';
import Repository, { baseUrl } from '~/repositories/repository.js';

export default {
    data: function() {
        return {
            carouselSetting3: {
                ...carouselSettingDefault,
                slidesPerView: 6,
                spaceBetween: 30,
                navigation: {
                    nextEl: '.brands-slider .swiper-next',
                    prevEl: '.brands-slider .swiper-prev'
                },
                breakpoints: {
                    992: {
                        slidesPerView: 5
                    },
                    768: {
                        slidesPerView: 4
                    },
                    576: {
                        slidesPerView: 3
                    },
                    480: {
                        slidesPerView: 2
                    }
                }
            },
            hotDicounts: [],
            loading: false
        };
    },
    methods: {
        getData: async function() {
            let hotDicounts = `${baseUrl}/hot/discount/offers`;
            // hot discounts
            await Repository.post(hotDicounts).then(resp => {
                if (resp.data.code == 200) {
                    this.loading = true;
                }
                this.hotDicounts = resp.data.offered_products;
                // console.log('hotDicounts', resp);
            });
        }
    },
    mounted() {
        this.getData();
    },

    props: ['product']
};
</script>

