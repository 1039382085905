import { render, staticRenderFns } from "./SignInModal.vue?vue&type=template&id=16a979ef&scoped=true&"
import script from "./SignInModal.vue?vue&type=script&lang=js&"
export * from "./SignInModal.vue?vue&type=script&lang=js&"
import style0 from "./SignInModal.vue?vue&type=style&index=0&id=16a979ef&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "16a979ef",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/html/royalbeeseg.com/Demo-4/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('16a979ef')) {
      api.createRecord('16a979ef', component.options)
    } else {
      api.reload('16a979ef', component.options)
    }
    module.hot.accept("./SignInModal.vue?vue&type=template&id=16a979ef&scoped=true&", function () {
      api.rerender('16a979ef', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "components/elements/modals/SignInModal.vue"
export default component.exports