<template>
    <div class="product-details-tab">
        <tabs class="nav-pills justify-content-start" :data="tabsData"></tabs>
        <div class="tab-content" v-for="product in product" :key="product.id">
            <div class="tab-pane fade show active" id="tab1">
                <div class="product-desc-content">
                    <h3>الوصف</h3>
                    <p class="mb-1" v-html="product.description"></p>
                    <!-- <ul>
                        <li>Nunc nec porttitor turpis. In eu risus enim. In vitae mollis elit.</li>
                        <li>Vivamus finibus vel mauris ut vehicula.</li>
                        <li>Nullam a magna porttitor, dictum risus nec, faucibus sapien.</li>
                    </ul> -->
                </div>
            </div>

            <div class="tab-pane fade" id="tab2">
                <h4 class="textReview">اكتب مراجعتك</h4>
                <div class="product-desc-content">
                    <!-- <h3>Information</h3> -->
                    <div class="row mt-3">
                        <!-- <div class="col-auto testimonial">
                            <img
                                alt="user"
                                width="70"
                                height="70"
                                v-lazy="'./images/Alf/alf/last/Group 404.png'"
                                lazy="loaded"
                                class="fade-in"
                            />
                        </div> -->
                        <div class="col">
                            <div class="row">
                                <div class="col-md-6 col-sm-12">
                                    <div class="ratings-container">
                                        <!-- <div class="ratings">
                                            <div
                                                class="ratings-val"
                                                :style="{
                                                    width:
                                                        product.ratings * 20 +
                                                        '%',
                                                }"
                                            ></div>
                                            <span class="tooltip-text">{{
                                                product.ratings
                                            }}</span>
                                        </div> -->
                                        <star-rating
                                            :increment="0.5"
                                            :star-size="25"
                                            @rating-selected="setRating"
                                        ></star-rating>
                                        <span class="ratings-text mt-0"
                                            >( {{ product.review }} مشاهده
                                            )</span
                                        >
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="review-content" v-if="product.review">
                                <p>
                                    {{ product.review }}
                                </p>
                            </div> -->
                            <!-- <div class="review-content" v-else>
                                <p>no reviews till now</p>
                            </div> -->
                        </div>
                    </div>
                    <div class="write-review">
                        <form @submit.prevent="writeAreviewHandleSubmit">
                            <div class="form-group">
                                <!-- <label for="singin-email-2"
                                    >Write Your Review
                                    <span class="text-danger">*</span></label
                                > -->
                                <!-- <input
                                    type="text"
                                    v-model="user.message"
                                    id="message"
                                    name="message"
                                    class="form-control"
                                    :class="{
                                        'is-invalid':
                                            submitted && $v.user.message.$error,
                                    }"
                                /> -->
                                <textarea
                                    id="message"
                                    name="message"
                                    class="form-control"
                                    :class="{
                                        'is-invalid':
                                            submitted && $v.user.message.$error
                                    }"
                                    cols="15"
                                    rows="7"
                                    v-model="user.message"
                                ></textarea>
                                <div
                                    v-if="
                                        submitted && !$v.user.message.required
                                    "
                                    class="invalid-feedback"
                                >
                                    الحقل مطلوب
                                </div>
                            </div>

                            <div
                                class="submitButton d-flex justify-content-end"
                            >
                                <button
                                    type="submit"
                                    class="btn btn-outline-primary-2"
                                >
                                    <span class="p-3">اضافه مراجعه</span>
                                    <!-- <i class="icon-long-arrow-right"></i> -->
                                    <!-- <i class="fas fa-sign-in-alt fa-2x"></i> -->
                                    <!-- <i class=" fa-2x"></i> -->
                                </button>
                            </div>
                        </form>
                    </div>
                    <div class="reviews">
                        <h4 class="textReview">مراجعات</h4>
                        <div
                            class="col-md-6 col-sm-12"
                            v-for="review in review"
                            :key="review.id"
                        >
                            <div class="row align-items-center justify-content-around;">
                                <div class="">
                                    <h4 v-if="review.user_name" style="margin: 0;">
                                        {{ review.user_name }}
                                    </h4>
                                </div>

                                <div class="" v-if="review.rate">
                                    <div class="rating-holder">
                                        <div
                                            class="rating"
                                            v-if="review.rate == 1"
                                        >
                                            <i class="fas fa-star gold"></i>
                                            <i
                                                class="far fa-star"
                                                v-for="x in 4"
                                                :key="x.id"
                                            ></i>
                                        </div>
                                        <div
                                            class="rating"
                                            v-if="review.rate == 1.5"
                                        >
                                            <i class="fas fa-star gold"></i>
                                            <i
                                                class="
                                                    fas
                                                    fa-star-half-alt
                                                    gold
                                                "
                                            ></i>
                                            <i
                                                class="far fa-star"
                                                v-for="x in 3"
                                                :key="x.id"
                                            ></i>
                                        </div>
                                        <div
                                            class="rating"
                                            v-if="review.rate == 2"
                                        >
                                            <i
                                                class="fas fa-star gold"
                                                v-for="x in 2"
                                                :key="x.id"
                                            ></i>
                                            <i
                                                class="far fa-star"
                                                v-for="x in 3"
                                                :key="x.id"
                                            ></i>
                                        </div>
                                        <div
                                            class="rating"
                                            v-if="review.rate == 2.5"
                                        >
                                            <i
                                                class="fas fa-star gold"
                                                v-for="x in 2"
                                                :key="x.id"
                                            ></i>
                                            <i
                                                class="
                                                    fas
                                                    fa-star-half-alt
                                                    gold
                                                "
                                            ></i>
                                            <i
                                                class="far fa-star"
                                                v-for="x in 2"
                                                :key="x.id"
                                            ></i>
                                        </div>

                                        <div
                                            class="rating"
                                            v-if="review.rate == 3"
                                        >
                                            <i
                                                class="fas fa-star gold"
                                                v-for="x in 3"
                                                :key="x.id"
                                            ></i>
                                            <i
                                                class="far fa-star"
                                                v-for="x in 2"
                                                :key="x.id"
                                            ></i>
                                        </div>
                                        <div
                                            class="rating"
                                            v-if="review.rate == 3.5"
                                        >
                                            <i
                                                class="fas fa-star gold"
                                                v-for="x in 3"
                                                :key="x.id"
                                            ></i>
                                            <i
                                                class="
                                                    fas
                                                    fa-star-half-alt
                                                    gold
                                                "
                                            ></i>
                                            <i
                                                class="far fa-star"
                                                v-for="x in 1"
                                                :key="x.id"
                                            ></i>
                                        </div>
                                        <div
                                            class="rating"
                                            v-if="review.rate == 4"
                                        >
                                            <i
                                                class="fas fa-star gold"
                                                v-for="x in 4"
                                                :key="x.id"
                                            ></i>
                                            <i
                                                class="far fa-star"
                                                v-for="x in 1"
                                                :key="x.id"
                                            ></i>
                                        </div>
                                        <div
                                            class="rating"
                                            v-if="review.rate == 4.5"
                                        >
                                            <i
                                                class="fas fa-star gold"
                                                v-for="x in 4"
                                                :key="x.id"
                                            ></i>
                                            <i
                                                class="
                                                    fas
                                                    fa-star-half-alt
                                                    gold
                                                "
                                            ></i>
                                            <!-- <i class="far fa-star" v-for="x in 1 " :key="x.id"></i> -->
                                        </div>
                                        <div
                                            class="rating"
                                            v-if="review.rate == 5"
                                        >
                                            <i
                                                class="fas fa-star gold"
                                                v-for="x in 5"
                                                :key="x.id"
                                            ></i>
                                        </div>
                                        <!-- <span class="mx-2">
                                        ( {{ review.rate }} )
                                    </span> -->
                                    </div>
                                </div>
                                 <p v-if="review.body" class="ml-5">
                                {{ review.body }}
                            </p>
                            
                            <p v-if="review.created_at" class="ml-5">
                                {{ review.created_at }}
                            </p>
                            </div>

                           
                            <!-- <h4 v-else>Supporter</h4> -->
                        </div>
                    </div>
                </div>
            </div>

            <!-- <div class="tab-pane fade" id="tab3">
                <div class="product-desc-content">
                    <h3>Delivery & returns</h3>
                    <p>
                        We deliver to over 100 countries around the world. For full details of the delivery options we offer, please view our
                        <a
                            href="#"
                        >Delivery information</a>
                        <br />We hope you’ll love every purchase, but if you ever need to return an item you can do so within a month of receipt. For full details of how to make a return, please view our
                        <a
                            href="#"
                        >Returns information</a>
                    </p>
                </div>
            </div> -->

            <!-- <div class="tab-pane fade" id="tab4">
                <div class="reviews">
                    <h3>Reviews (2)</h3>
                    <div class="review">
                        <div class="row no-gutters">
                            <div class="col-auto">
                                <h4>
                                    <a href="#">Samanta J.</a>
                                </h4>
                                <div class="ratings-container">
                                    <div class="ratings">
                                        <div class="ratings-val" style="width: 80%;"></div>
                                        <span class="tooltip-text">4</span>
                                    </div>
                                </div>

                                <span class="review-date">6 days ago</span>
                            </div>

                            <div class="col">
                                <h4>Good, perfect size</h4>

                                <div class="review-content">
                                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ducimus cum dolores assumenda asperiores facilis porro reprehenderit animi culpa atque blanditiis commodi perspiciatis doloremque, possimus, explicabo, autem fugit beatae quae voluptas!</p>
                                </div>

                                <div class="review-action">
                                    <a href="#">
                                        <i class="icon-thumbs-up"></i>Helpful (2)
                                    </a>
                                    <a href="#">
                                        <i class="icon-thumbs-down"></i>Unhelpful (0)
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="review border-0">
                        <div class="row no-gutters">
                            <div class="col-auto">
                                <h4>
                                    <a href="#">John Doe</a>
                                </h4>
                                <div class="ratings-container">
                                    <div class="ratings">
                                        <div class="ratings-val" style="width: 100%;"></div>
                                        <span class="tooltip-text">5</span>
                                    </div>
                                </div>

                                <span class="review-date">5 days ago</span>
                            </div>

                            <div class="col">
                                <h4>Very good</h4>

                                <div class="review-content">
                                    <p>Sed, molestias, tempore? Ex dolor esse iure hic veniam laborum blanditiis laudantium iste amet. Cum non voluptate eos enim, ab cumque nam, modi, quas iure illum repellendus, blanditiis perspiciatis beatae!</p>
                                </div>

                                <div class="review-action">
                                    <a href="#">
                                        <i class="icon-thumbs-up"></i>Helpful (0)
                                    </a>
                                    <a href="#">
                                        <i class="icon-thumbs-down"></i>Unhelpful (0)
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->
        </div>
    </div>
</template>
<script>
import { required } from 'vuelidate/lib/validators';
import Vue from 'vue';
import Vuelidate from 'vuelidate';

Vue.use(Vuelidate);
import Tabs from '~/components/elements/Tabs';
import axios from 'axios';
import StarRating from 'vue-star-rating';
import Repository, { baseUrl } from '~/repositories/repository.js';

export default {
    components: {
        Tabs,
        StarRating
    },
    props: {
        product: Array
    },
    data: function() {
        return {
            tabsData: [
                {
                    id: 'tab1',
                    title: 'الوصف',
                    active: true
                },
                {
                    id: 'tab2',
                    title: 'المراجعات'
                }
                // {
                //     id: 'tab3',
                //     title: 'Shipping & Returns'
                // },
                // {
                //     id: 'tab4',
                //     title: 'Reviews(2)'
                // }
            ],
            user: {
                message: ''
            },
            submitted: false,
            message: false,
            fail: false,
            success: false,
            rate: '',
             rating: "No Rating Selected",
            currentRating: 'No Rating',
            currentSelectedRating: 'No Current Rating',
            boundRating: 3,
            product: [],
            review: []
        };
    },
    validations: {
        user: {
            message: { required }
        }
    },
    methods: {
        getPaymentData() {
            console.log('lkd');
            let token = sessionStorage.getItem('token');
            let data = { id: this.$route.params.slug, api_token: token };
            var config = {
                method: 'post',
                url: 'https://admin.royalbeeseg.com/public/api/product',
                data: data
            };

            axios(config)
                .then(resp => {
                    console.log('payment', resp);
                    this.product = resp.data.product;
                    for (var x = 0; x <= this.product.length; x++) {
                        console.log(this.product[x].reviews);
                        this.review = this.product[x].reviews
                    }
                    console.log('this.rate', this.product);
                })
                .catch(error => {
                    console.log(error);
                });
        },

        writeAreviewHandleSubmit(e) {
            // stop here if form is invalid
            this.submitted = true;
            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }
            this.writeAreview();

            // alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.user));
        },
        writeAreview: function() {
            let review = this.user.message;
            let token = sessionStorage.getItem('token');
            axios
                .post(
                    `${baseUrl}/review/add`,
                    {
                        api_token: token,
                        product_id: this.$route.params.slug,
                        rate: this.rating,
                        review:  this.user.message
                    },
                    {
                        review
                    }
                )
                .then(resp => {
                    let settingsObject = {
                        withBackdrop: false,
                        position: 'top-right',
                        successDuration: 2000
                    };
                    this.$vToastify.setSettings(settingsObject);
                    console.log(resp);

                    console.log('message', resp.data.message);
                    if (resp.data.code === 200) {
                        this.success = true;
                        this.fail = false;
                        this.$vToastify.success(resp.data.message);
                        // setTimeout(() => {
                        //     this.$router.go(this.$router.currentRoute);
                        // }, 2500);
                    } else {
                        this.success = false;
                        this.fail = true;
                        this.$vToastify.error(resp.data.message);
                    }
                    // let myRate = document.querySelector(
                    //     '.vue-star-rating-rating-text'
                    // ).innerHTML;
                    // console.log('my rate', myRate);
                    // this.rate = myRate;
                })
                .catch(err => {
                    console.log(err);
                    this.success = false;
                    this.fail = true;
                });
        },
        setRating: function(rating) {
            // this.rating = 'You have Selected: ' + rating + ' stars';
            this.rating = rating;
            console.log('my new rate', this.rating);
            // this.rate = myRate;
        },
        showCurrentRating: function(rating) {
            this.currentRating =
                rating === 0
                    ? this.currentSelectedRating
                    : 'Click to select ' + rating + ' stars';
            this.rate = myRate;
        },
        setCurrentSelectedRating: function(rating) {
            this.currentSelectedRating =
                'You have Selected: ' + rating + ' stars';
            this.rate = myRate;
        }
    },
    mounted() {
        let myRate = document.querySelector('.vue-star-rating-rating-text')
            .innerHTML;
        console.log('my rate', myRate);
        this.rate = myRate;
    },
    created() {
        this.getPaymentData();
    }
};
</script>
<style lang="scss" scoped>
.form-control {
    margin-bottom: 0;
    padding: 25px 15px;
    box-shadow: 0px 0px 2px 0px #e3e1e1;
    border-radius: 5px;
    margin: 5px 0;
    &:focus {
        border-color: #fcb932;
        border-left: 4px solid #fcb932;
        border-radius: 0 5px 5px 0;
    }
}
.modal-body {
    min-height: 100vh;
}
.textReview {
    color: #144b96;
}
textarea {
    resize: none;
    border: 2px solid rgba(230, 230, 230, 0.59);
    &:focus {
        background: inherit;
    }
}
.rating .gold {
    color: gold;
}
</style>
