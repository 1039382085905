var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("h2", { staticClass: "title text-center mb-4" }, [
      _vm._v("You May Also Like")
    ]),
    _vm._v(" "),
    _vm.products.length == 0
      ? _c("div", [
          _c("div", { staticClass: "swiper-carousel carousel-with-shadow" }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "swiper",
                    rawName: "v-swiper:swiper2",
                    value: _vm.carouselSetting,
                    expression: "carouselSetting",
                    arg: "swiper2"
                  }
                ]
              },
              [
                _c(
                  "div",
                  { staticClass: "swiper-wrapper" },
                  _vm._l([1, 2, 3, 4], function(item) {
                    return _c(
                      "div",
                      { key: item, staticClass: "swiper-slide" },
                      [_c("div", { staticClass: "skel-pro" })]
                    )
                  }),
                  0
                )
              ]
            )
          ])
        ])
      : _c(
          "div",
          { staticClass: "swiper-carousel carousel-with-shadow swiper-1" },
          [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "swiper",
                    rawName: "v-swiper:swiper1",
                    value: _vm.carouselSetting,
                    expression: "carouselSetting",
                    arg: "swiper1"
                  }
                ]
              },
              [
                _c(
                  "div",
                  { staticClass: "swiper-wrapper" },
                  _vm._l(_vm.products, function(product, index) {
                    return _c(
                      "div",
                      { key: index, staticClass: "swiper-slide" },
                      [_c("product-nine", { attrs: { product: product } })],
                      1
                    )
                  }),
                  0
                )
              ]
            ),
            _vm._v(" "),
            _vm._m(0)
          ]
        )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "swiper-nav" }, [
      _c("div", { staticClass: "swiper-prev" }, [
        _c("i", { staticClass: "icon-angle-left" })
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "swiper-next" }, [
        _c("i", { staticClass: "icon-angle-right" })
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }