export default {
    filter :{
        filteredBy: "فاتر ب",
        clean : "مسح الكل"
    },
    nav: {
        home: 'الرئيسية',
        about: 'من نحن',
        products: 'المنتجات',
        blog: 'موسوعه عسل النحل',
        places: 'كوكب الاباء',
        contactUs: 'تواصل معنا',
        search: 'بحث',
        profile: 'الملف الشخصى',
        searchField: 'ابحث عن المنتجات',
        default:'الاساسى',
        customer:'عميل',
        partner:'شريك',
        mostProduct:'أحدث العروض'
    },
    helping:{
        title:"احصل علي المساعده",
        story:"عن الموقع",
        contact:"تواصل معنا",
        faq:"الاسئله الشائعه",
        term:"سياسه الخصوصيه",
        cancel:"الغاءالدفع",
        sub:"اشترك معنا",
        subDes:"اشترك الان و احصل على 10% تخفيض",
        
    },
    shop: {
        list: 'القائمة',
        shop: 'المتجر',
        category: 'القسم',
        subCategory: 'القسم الفرعى',
        showing: 'إظهار',
        relatedProducts: 'المنتجات المماثلة'
    },
    wishlist: {
        title: 'قائمة الأمنيات'
    },
    cart: {
        empty: 'لا يوجد منتجات فى عربة التسوق',
        title: 'عربة التسوق',
        product: 'المنتج',
        price: 'السعر',
        quantity: 'الكمية',
        updatecart: 'تحديث عربةالتسوق',
        continue: 'تابع التسوق',
        total: 'إجمالى عربة التسوق',
        subtotal: 'الإجمالى الجزئى',
        shipping: 'الشحن',
        estimate: 'المتوقع لبلدك',
        changeaddress: 'تغيير العنوان ',
        gotocheckout: 'الذهاب الى الدفع',
        return: 'الرجوع للمتجر',
        login: 'برجاء تسجيل الدخول اولاً',
        shipment:'الشحن'
    },
    home: {
        recommendations: 'مقترحات خصيصأ لك'
    },
    login: {
        signIn: 'تسجيل دخول',
        signInMessage: 'البريد الإلكترونى',
        register: 'تسجيل حساب',
        password: 'كلمة السر',
        passwordForget: 'هل نسيت كلمة السر؟',
        login: 'تسجيل دخول',
        registerBtn: 'تسجيل',
        userName: 'اسم المستخدم',
        confirmPassword: 'تاكيد كلمة السر',
        welcome: 'اهلاً',
        logout: 'تسجيل خروج'
    },
    header: {
        total: 'الإجمالى'
    },
    payment: {
        checkout: 'الشراء',
        payment: 'الدفع',
        deliveredTo: 'التوصيل الى ',
        deliveryAddress: 'عنوان التوصيل',
        selectAddress: 'برجاء إختيار العنوان',
        cart: 'عربة التسوق',
        viewCart: 'إظهار عربة التسوق',
        wishlist: 'قائمة الأمنيات',
        cartNoData: 'لا يوجد منتجات فى عربة التسوق.',
        wishlistNoData: 'لا يوجد منتجات فى قائمة الأمنيات',
        coupon: 'هل لديك كوبون خصم ؟',
        couponPlaceHolder: 'اضغط هنا لإدخال الكوبون'
    },

    buttons: {
        changeAddress: 'تغيير العنوان',
        readMore: 'قراة المزيد',
        filter: 'الفلتر',
        shopMore: 'تسوق اكثر',
        continue: 'اكمل'
    },
    categories: {
        name: 'الفئات'
    },
    product: {
        new: 'جديد',
        sale: 'خصم',
        top: 'الاعلى',
        outOfStock: 'نفذت الكمية',
        desc: 'شرح المنتج',
        quickView: 'نظرة سريعة',
        addToCart: 'إضافة الى عربة التسوق',
        addToWishlist: 'إضافة الى قائمة الأمنيات',
        inWishlist: 'فى قائمة الأمنيات',
        price: 'السعر'
    },
    places: {
        search: 'ابحث عن مكان',
        coveTitle1: 'شارك فى مجتمعنا',
        coveTitle2: 'اضف مكانك الان..',
        mainTitle: 'اهلاً، برجاء الإختيار',
        places:"الاماكن",
    },
    choose: {
        title1: 'لماذا ',
        title2: 'تختارنا',
        secOneTitle: 'توصيل مجانى',
        secOnePara:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua',

        secTwoTitle: '100% امن',
        secTwoPara:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua',

        secThreeTitle: 'حجز فورى',
        secThreePara:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua',

        secFourTitle: 'اختار الوقت',
        secFourPara:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua'
    },
   
    trending: {
        title: 'منتجات مميزه'
    },
    brands: {
        title: 'اكثر العلامات شهرة'
    },
    topCategories: {
        title: 'الفئات الاعلى'
    },
    homeBlog: {
        title: 'الف اكاديمى',
        popularPosts: 'المقالات الاكثر شهرة'
    },
    findUs: {
        title1: "لنجد",
        title2: 'مكانك الان..',
        desc:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua'
    },
    parentsSay: {
        title: 'ماذا يقول الاباء عنا'
    },
    productsSidebar: {
        filteredBy: 'الفلترة عن طريق',
        clearAll: 'إلغاء الكل',
        category: 'الفئة',
        subCategory: 'الفئة الفرعية'
    },
    brandSidebar: {
        filteredBy: 'الفلترة عن طريق',
        clearAll: 'إلغاء الكل',
        category: 'الماركة',
        subCategory: 'الفئة الفرعية'
    },
    AgeRange:{
        name :'متوسط العمر'
    },
    PopularArticles:{
        name: 'مقالات  مشهوره'
    },
    randomArticles:{
        name: 'مقالات دلاليه '
    },
    filter:{
        name:'فلتر'
    },
    blog: {
        coverTitle:
            'Certainty listening no behaviour existence assurance situation',
        ageRange: 'Age Range',
        popularArticles: 'Popular Articles',
        search:"ابحث عن مقاله"
    },
    discountOffer: {
        title: 'العروض الاكثر و الاشهر'
    },
    featuredoffer: {
        title: 'العروض الخطيره'
    },
    newarrival: {
        title: 'وصل حديثاً'
    },
    contactus: {
        header1: 'اهلاً ، بماذا تفكر؟',
        description: 'يمكنك إرسال لنا ما ترغب',
        name: 'الاسم*',
        email: 'البريد الإلكترونى*',
        namerequired: 'من فضلك ادخل الاسم',
        subject: 'العنوان',
        selectone: 'من فضلك اختار تصنيف',
        getsupport: 'طلب الدعم',
        delays: 'الإلغاء و التاخير',
        sales: 'تكلم الى البائعين',
        feedback: 'تواصل مع الإدارة',
        message: 'الرسالة',
        submit: 'ارسال',
        
    },
    require: {
        message:"من فضلك سجل دخولك"
    },
    contactusTwo: {
        header1: 'اهلاً ، بماذا تفكر؟',
        description: 'يمكنك إرسال لنا ما ترغب',
        name: 'الاسم*',
        email: 'البريد الإلكترونى*',
        namerequired: 'من فضلك ادخل الاسم',
        subject: 'العنوان',
        selectone: 'من فضلك اختار تصنيف',
        genral: 'استفسار عام',
        payment: 'مدفوعات و تخفيضات',
        order: 'مشاكل في الطلب',
        shipping: 'التوصيل و الشحن',
        refund: 'الاسترجاع او الاستبدال',
        account: 'اداره الحساب',
        newPartner:'شريك جديد',
        exist:'شريك حالى'
        
    },
    profile: {
        title: 'بيانات الاكونت',
        home: 'الرئيسية',
        fullname: 'الاسم بالكامل',
        fullnamerequired: 'برجاء إدخال الاسم',
        email: 'البريد الإلكترونى',
        emailrequired: 'برجاء إدخال البريد الإلكترونى',
        emailinvalid: 'البريد الإلكترونى غير صحيح',
        phone: 'رقم التليفون',
        phonerequired: 'برجاء إدخال رقم التليفون',
        phoneinvalid: 'العدد المسموح ب ١١ رقم فقط',
        details: 'بياناتى',
        address: 'عناوين الشحن',
        password: 'تغيير كلمة السر',
        trackorder: 'تتبع الطلبات',
        myorders: 'طلباتى',
        wishlist: 'قائمة الامنيات',
        mycart: 'عربة التسوق',
        save: 'حفظ',        

    },
    changepass: {
        changepass: 'تغيير كلمة السر',
        current: 'كلمة السر الحالية',
        currentrequired: 'برجاء إدخال كلمة السر الحالية',
        currentlength: 'كلمة السر يجب ان تكون اكثر من ٨ حروف',
        new: 'كلمة السر الجديدة',
        newrequired: 'برجاء إدخال كلمة السر الجديدة',
        newlength: 'كلمة السر الجديدة يجب ان تكون اكثر من ٨ حروف',
        confirm: 'تاكيد كلمة السر الجديدة',
        confirmrequired: 'برجاء إدخال تاكيد كلمة السر الجديدة',
        confirmmatch: 'كلمة السر و التاكيد غير متطابقين',
        button: 'حفظ',
    },
    buttonPlace:{
            title:"اماكن اكثر"
    },
    address: {
        title: 'إضافة عنوان',
        country: 'البلد',
        countryrequired: 'حقل البلد مطلوب',
        city: 'المدينة',
        cityrequired: 'حقل المدينة مطلوب',
        area: 'المنطقة',
        areareuired: 'حقل المنطقة مطلوب',
        street: 'اسم الشارع',
        streetrequired: 'اسم الشارع مطلوب',
        buildingnumber: 'رقم العمارة',
        buildingnumberrequired: 'رقم العمارة مطلوب ',
        floor: 'الدور',
        floorrequired: 'رقم الدور مطلوب',
        aprtmentnumber: 'رقم الشقة',
        aprtmentnumberrequired: 'رقم الشقة مطلوب',
        landmark: 'علامة مميزة',
        save: 'حفظ',
    },       
    footer: {
        uperFooter: {
            easyReturns: 'إرجاع سهل',
            freeDelivery: 'شحن سريع',
            bestGuarantee: 'افضل سعر',
            right:', حقوق النسخ محفوظه لالف بلانت',
            continue:'2022 | جميع الحقوق محفوظه'
        },
        company: 'شركة',
        contactUs: 'تواصل معنا',
        subscribe: 'الاشتراك',
        subscribePara:
            'اشترك فى البريد الإلكترونى الان لتحصل على احدث و اجدد العروض الخاصة ب الف بلانيت',
        reportAptoblem: 'اخبرنا بوجود مشكلة',
        location:"الدقى، القاهرة ... مصر",
        description:"اول موقع رائد يساعد الام و الطفل منذ ٢٠٠٠ فى مصر",
        Copyright:"جميع الحقوق محفوظة الف بلانيت ٢٠٢٢",
        emailaddressplaceholder:'ادخل بريدك الإلكترونى',
        subscribe:"اشترك الان",
    }
};
