<template>
    <div class="wishlist" v-if="isLoggedIn">
        <nuxt-link :to="localePath('/pages/account/profile')" :title="$t('nav.profile')">
            <div class="icon">
                <!-- <i class="icon-heart-o"></i> -->
                <img
                    v-lazy="'./images/Alf/profile.png'"
                    class="bg-transparent"
                    alt="Molla Logo"
                   width="100%"
                    height="100%"
                />
                <!-- <img
                    v-lazy="'./images/Alf/profile.png'"
                    class="bg-transparent"
                    alt="Molla Logo"
                /> -->
                <!-- <span class="wishlist-count badge">{{ wishlistQty }}</span> -->
            </div>
            <!-- <p>Wishlist</p> -->
        </nuxt-link>
    </div>
</template>
<script>
export default {
    computed: {
        isLoggedIn() {
            return this.$store.getters.isLoggedIn;
        },
    },
};
</script>

