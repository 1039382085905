var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "sidebar sidebar-blog" }, [
    _c(
      "div",
      { staticClass: "widget widget-search" },
      [
        _c("h3", { staticClass: "widget-title" }, [
          _vm._v(" " + _vm._s(_vm.$t("PopularArticles.name")))
        ]),
        _vm._v(" "),
        _vm._l(_vm.popularArticles, function(popularArticle) {
          return _c(
            "div",
            { key: popularArticle.id, staticClass: "row mb-2" },
            [
              _c(
                "nuxt-link",
                { attrs: { to: `/blog/single/default/${popularArticle.id}` } },
                [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-6" }, [
                      _c("div", { staticClass: "sidebar-blog__img" }, [
                        _c("img", {
                          directives: [
                            {
                              name: "lazy",
                              rawName: "v-lazy",
                              value: popularArticle.image,
                              expression: "popularArticle.image"
                            }
                          ]
                        })
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-6" }, [
                      _c("div", { staticClass: "sidebar-blog__desc" }, [
                        _c("p", { staticStyle: { "font-size": "10px" } }, [
                          _vm._v(
                            "\n                                " +
                              _vm._s(popularArticle.short_description) +
                              "\n                            "
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-8" }, [
                          _c("div", { staticClass: "sider-blog__date" }, [
                            _c("p", { staticStyle: { "font-size": "10px" } }, [
                              _c("i", { staticClass: "far fa-calendar-alt" }),
                              _vm._v(
                                "\n                                        " +
                                  _vm._s(popularArticle.time) +
                                  "\n                                    "
                              )
                            ])
                          ])
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-4" }, [
                          _c("div", { staticClass: "sider-blog__review" }, [
                            _c("p", { staticStyle: { "font-size": "12px" } }, [
                              _c("i", { staticClass: "far fa-eye" }),
                              _vm._v(
                                "\n                                        " +
                                  _vm._s(popularArticle.views) +
                                  "\n                                    "
                              )
                            ])
                          ])
                        ])
                      ])
                    ])
                  ])
                ]
              )
            ],
            1
          )
        })
      ],
      2
    ),
    _vm._v(" "),
    _c("hr"),
    _vm._v(" "),
    _c("div", { staticClass: "widget" }, [
      _c("h3", { staticClass: "widget-title" }, [
        _vm._v(_vm._s(_vm.$t("randomArticles.name")))
      ]),
      _vm._v(" "),
      _c("section", { staticClass: "blog" }, [
        _c(
          "div",
          {},
          _vm._l(_vm.randomArticles, function(randomArticle) {
            return _c(
              "div",
              { key: randomArticle.id, staticClass: "row" },
              [
                _c(
                  "nuxt-link",
                  { attrs: { to: `/blog/single/default/${randomArticle.id}` } },
                  [
                    randomArticle.image
                      ? _c(
                          "div",
                          { staticClass: "d-flex justify-content-start" },
                          [
                            _c("img", {
                              directives: [
                                {
                                  name: "lazy",
                                  rawName: "v-lazy",
                                  value: randomArticle.image,
                                  expression: "randomArticle.image"
                                }
                              ],
                              staticClass: "img-fluid bg-transparent",
                              attrs: { alt: "blog cover" }
                            })
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", {}, [
                      _c("div", { staticClass: "data-holder py-4" }, [
                        _c("h6", [
                          _vm._v(
                            "\n                                    " +
                              _vm._s(randomArticle.title) +
                              "\n                                "
                          )
                        ]),
                        _vm._v(" "),
                        _c("p", { staticStyle: { "font-size": "11px" } }, [
                          _vm._v(
                            "\n                                    " +
                              _vm._s(randomArticle.short_description) +
                              "\n                                "
                          )
                        ]),
                        _vm._v(" "),
                        _c(
                          "ul",
                          {
                            staticClass:
                              "list-unstyled d-flex justify-content-around",
                            staticStyle: { "margin-top": "10px" }
                          },
                          [
                            _c(
                              "li",
                              {
                                staticClass: "d-flex align-items-center",
                                staticStyle: { "font-size": "10px" }
                              },
                              [
                                _c("span", [
                                  _c("i", { staticClass: "icon-calendar" })
                                ]),
                                _vm._v(" "),
                                _c("span", [
                                  _vm._v(
                                    "\n                                            " +
                                      _vm._s(randomArticle.created_at)
                                  )
                                ])
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "li",
                              { staticClass: "d-flex align-items-center" },
                              [
                                _c("span", [
                                  _c("i", { staticClass: "icon-eye" })
                                ]),
                                _vm._v(" "),
                                _c("span", [
                                  _vm._v(
                                    "\n                                            " +
                                      _vm._s(randomArticle.views)
                                  )
                                ])
                              ]
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c("br")
                      ])
                    ])
                  ]
                )
              ],
              1
            )
          }),
          0
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }