<template>
    <div class="product-gallery product-gallery-vertical" v-if="product">
        <div class="row m-0" v-if="product[0].images.length > 0">
            <figure class="product-main-image">
                <span class="product-label label-new" v-if="product.new">New</span>
                <span class="product-label label-sale" v-if="product.sale_price">Sale</span>
                <span class="product-label label-top" v-if="product.top">Top</span>
                <span class="product-label label-out" v-if="product.stock === 0">Out Of Stock</span>
                <img
                    id="product-zoom"
                    v-lazy="product[0].images[currentIndex]"
                    alt="product image 0"
                    
                />

                <a
                    href="#"
                    id="btn-product-gallery"
                    class="btn-product-gallery"
                    @click.prevent="openLightBox"
                >
                    <i class="icon-arrows"></i>
                </a>
            </figure>

            <div id="product-zoom-gallery" class="product-image-gallery">
                <a
                    class="product-gallery-item h-100 h-lg-auto carousel-dot"
                    :class="{active: currentIndex== index}"
                    href="#"
                    v-for="(smPicture, index) in product[0].images"
                    :key="index"
                    @click.prevent="changePicture(index)"
                >
                    <img
                        v-lazy="product[0].images[index]"
                        :width="smPicture.width"
                        :height="smPicture.height"
                        alt="product side image"
                    />
                </a>
            </div>

            <light-box
                ref="lightbox"
                class="light-box"
                :media="lightBoxMedia"
                :show-caption="true"
                :show-light-box="false"
            ></light-box>
        </div>
        <div v-else>
            <div class="swiper-carousel carousel-with-shadow">
                <div v-swiper:swiper2="carouselSetting">
                    <div class="swiper-wrapper">
                        <!-- <div
                            class="swiper-slide w-100 h-100"
                            v-for="item in [1]"
                            :key="item"
                        >
                            <div class="skel-pro"></div>
                        </div> -->
                        <img v-lazy="'/images/Alf/default.png'" alt="image">
                    </div>
                </div>
                
            </div>
        </div>
    </div>
</template>
<script>
import LightBox from 'vue-image-lightbox';

import { VueLazyloadImage } from 'vue-lazyload';
import 'vue-image-lightbox/dist/vue-image-lightbox.min.css';
import { baseUrl } from '~/repositories/repository.js';
import { carouselSetting1 } from '~/utilities/carousel';

export default {
    components: {
        LightBox
    },
    props: {
        product: {
            type: Array,
            default: function() {
                return {
                    sm_pictures: [],
                    pictures: []
                };
            }
        }
    },
    data: function() {
        return {
            baseUrl: baseUrl,
            currentIndex: 0,
            carouselSetting: {
                ...carouselSetting1,
                navigation: {
                    nextEl: '.swiper-1 .swiper-next',
                    prevEl: '.swiper-1 .swiper-prev',
                },
                pagination: {
                    el: '.swiper-1 .swiper-dots',
                    clickable: true,
                },
            },
        };
    },
    computed: {
        lightBoxMedia: function() {
            return this.product[0].images.reduce((acc) => {
                return [
                    ...acc,
                    {

                        thumb: this.product[0].images[this.currentIndex],
                        src: this.product[0].images[this.currentIndex],
                        caption: this.product.name
                    }
                ];
            }, []);
        }
    },
    methods: {
        changePicture: function(index) {
            this.currentIndex = index;
        },
        openLightBox: function() {
            this.$refs.lightbox.showImage(this.currentIndex);
        }
    }
};
</script>
<style lang="scss">
// .vue-lb-thumbnail-wrapper{
//     display: none !important;
// }
</style>