var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      !_vm.loading
        ? [
            _c(
              "div",
              { staticClass: "col-9 m-auto skeleton-body skel-shop-products" },
              [
                _vm._l(1, function(item) {
                  return _c("div", {
                    key: item,
                    staticClass: "skel-pro skel-pro-list"
                  })
                })
              ],
              2
            )
          ]
        : _c("div", {}, [
            _c("section", { staticClass: "toy2" }, [
              _c("div", { staticClass: "container text-center" }, [
                _c(
                  "div",
                  { staticClass: "row" },
                  _vm._l(_vm.secondListPhotos, function(second) {
                    return _c(
                      "div",
                      { key: second.id, staticClass: "col-md-4 col-sm-12" },
                      [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "lazy",
                                rawName: "v-lazy:background-image",
                                value: second.first_image,
                                expression: "second.first_image",
                                arg: "background-image"
                              }
                            ],
                            staticClass: "toy-holder text-center"
                          },
                          [
                            _vm.$i18n.locale == "en"
                              ? _c("h3", { staticClass: "text-white" }, [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(second.title.en) +
                                      "\n                            "
                                  )
                                ])
                              : _c("h3", { staticClass: "text-white" }, [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(second.title.en) +
                                      "\n                            "
                                  )
                                ]),
                            _vm._v(" "),
                            _c("img", {
                              directives: [
                                {
                                  name: "lazy",
                                  rawName: "v-lazy",
                                  value: second.second_image,
                                  expression: "second.second_image"
                                }
                              ],
                              staticClass: "bg-transparent",
                              attrs: { alt: "Alf Logo", height: "27" }
                            })
                          ]
                        )
                      ]
                    )
                  }),
                  0
                )
              ])
            ])
          ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }