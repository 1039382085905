var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "my-4" }, [
    _c("div", { staticClass: "container" }, [
      _c(
        "div",
        { staticClass: "plans" },
        [
          _vm.userName
            ? _c("div", { staticClass: "title" }, [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.$t("payment.deliveredTo")) +
                    " : " +
                    _vm._s(_vm.userName) +
                    "\n            "
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm._l(_vm.addresses, function(address) {
            return _c(
              "label",
              {
                key: address.id,
                staticClass: "plan basic-plan",
                attrs: { for: "basic" }
              },
              [
                _c("div", { staticClass: "plan-content mb-2" }, [
                  _c("div", { staticClass: "plan-details mr-7" }, [
                    _c("span", [
                      _c("i", { staticClass: "icon-home" }),
                      _vm._v(
                        "\n                            " +
                          _vm._s(address.zone_obj.name) +
                          "\n                            "
                      )
                    ]),
                    _vm._v(" "),
                    _c("span", [
                      _c("i", { staticClass: "icon-map-marker" }),
                      _vm._v(
                        "\n                            " +
                          _vm._s(address.street) +
                          "\n                        "
                      )
                    ]),
                    _vm._v(" "),
                    address.phone
                      ? _c("span", [
                          _c("i", { staticClass: "icon-phone" }),
                          _vm._v(" no phon till now\n                        ")
                        ])
                      : _vm._e()
                  ])
                ])
              ]
            )
          })
        ],
        2
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }