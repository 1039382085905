<template>
    <div>
        <!--start eid section  -->
        <template v-if="!loading">
            <div class="col-9 m-auto skeleton-body skel-shop-products">
                <template>
                    <div
                        class="skel-pro skel-pro-list"
                        v-for="item in 1"
                        :key="item"
                    ></div>
                </template>
            </div>
        </template>
        <div class="" v-else>
            <section
                class="baby-producst baby-show baby-show-carousel text-center"
                v-if="fashionBaby.length > 0"
            >
                <div class="wrapper-with-margin">
                    <div class="container">
                        <div
                            class="
                                swiper-carousel
                                brands-slider
                                swiper-2
                                mb-5
                                mt-5
                            "
                        >
                            <div v-swiper:swiper5="carouselSetting5">
                                <div class="swiper-wrapper">
                                    <div
                                        class="swiper-slide"
                                        v-for="fashion in fashionBaby"
                                        :key="fashion.id"
                                    >
                                        <a :href="fashion.button_link">
                                            <!-- <div
                                                class="baby-show-holder"
                                                v-lazy:background-image="
                                                    fashion.background_image
                                                "
                                            > -->
                                            <div class="baby-show-holder">
                                                <!-- <h3 >
                                                {{ fashion.title }}
                                            </h3>-->
                                                <img
                                                    v-lazy="
                                                        fashion.background_image
                                                    "
                                                    class="bg-transparent"
                                                    alt="Alf Logo"
                                                    height="27"
                                                />
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="swiper-nav">
                                <div class="swiper-prev">
                                    <i class="icon-angle-left"></i>
                                </div>
                                <div class="swiper-next">
                                    <i class="icon-angle-right"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import {
    carouselSettingSingle,
    carouselSettingDefault
} from '~/utilities/carousel';
import Repository, { baseUrl } from '~/repositories/repository.js';

export default {
    data: function() {
        return {
            carouselSetting5: {
                ...carouselSettingDefault,
                slidesPerView: 4,
                spaceBetween: 30,
                navigation: {
                    nextEl: '.brands-slider .swiper-next',
                    prevEl: '.brands-slider .swiper-prev'
                },
                breakpoints: {
                    992: {
                        slidesPerView: 3
                    },
                    768: {
                        slidesPerView: 2,
                        navigation: true
                    },
                    576: {
                        slidesPerView: 1,
                        navigation: true
                    },
                    480: {
                        slidesPerView: 1,
                        navigation: true
                    }
                }
            },
            abouts: [],
            fashionBaby: [],
            loading: false
        };
    },
    methods: {
        getData: async function() {
            let sliders = `${baseUrl}/sliders`;

            //sliders
            await Repository.get(sliders).then(resp => {
                console.log('abouts', resp);
                if (resp.data.code == 200) {
                    this.loading = true;
                }
                this.abouts = resp.data.about_sliders;
                this.fashionBaby = resp.data.photos_sliders;
                // console.log(' this.fashionBaby', this.fashionBaby);
            });
            // .catch((err) => {
            //     console.log(err);
            // });
        }
    },
    mounted() {
        this.getData();
    },
    props: ['product']
};
</script>

<style>
.baby-show-holder {
    background-repeat: no-repeat;
}
</style>
