var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "modal-body m-3" }, [
    _c(
      "button",
      {
        staticClass: "close",
        attrs: { type: "button" },
        on: {
          click: function($event) {
            return _vm.$emit("close")
          }
        }
      },
      [_vm._m(0)]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "form-box text-right" }, [
      _c("h3", [_vm._v(": " + _vm._s(_vm.item))]),
      _vm._v(" "),
      _c("p", [_vm._v(_vm._s(_vm.address))]),
      _vm._v(" "),
      _c("p", [_vm._v(_vm._s(_vm.phone))])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { attrs: { "aria-hidden": "true" } }, [
      _c("i", { staticClass: "icon-close" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }