<template>
    <div class="product-gallery" v-if="product">
        <div v-images-loaded.on="handleIsotope" v-if="product[0].images.length > 0">
            <figure class="product-main-image">
                <span class="product-label label-new" v-if="product[0].new">New</span>
                <span class="product-label label-sale" v-if="product[0].sale_price">Sale</span>
                <span class="product-label label-top" v-if="product[0].top">Top</span>
                <span class="product-label label-out" v-if="product[0].stock === 0">Out Of Stock</span>
                <img
                    id="product-zoom"
                    v-lazy="product[0].images[currentIndex]"
                    alt="product"
                    
                />

                <a
                    href="#"
                    id="btn-product-gallery"
                    class="btn-product-gallery"
                    @click.prevent="openLightBox"
                >
                    <i class="icon-arrows"></i>
                </a>
            </figure>

            <isotope
                id="product-zoom-gallery"
                class="product-image-gallery product-gallery-masonry"
                ref="iso"
                :options="isotopeOptions"
                :list="product[0].images"
            >
                <a
                    class="product-gallery-item"
                    :class="{active: currentIndex== index}"
                    href="#"
                    v-for="(smPicture, index) in product[0].images"
                    :key="index"
                    @click.prevent="changePicture(index)"
                >
                    <img
                        v-lazy="product[0].images[index]"
                        
                        alt="product side image"
                    />
                    <!-- <img
                        v-lazy="product[0].images[index]"
                        :width="smPicture.width"
                        :height="smPicture.height"
                        alt="product side image"
                    /> -->
                </a>
                <a
                    href="#"
                    class="product-gallery-item grid-sizer"
                    key="grid-sizer"
                    
                ></a>
            </isotope>

            <light-box
                ref="lightbox"
                class="light-box"
                :media="lightBoxMedia"
                :show-caption="true"
                :show-light-box="false"
            ></light-box>
        </div>
    </div>
</template>
<script>
import LightBox from 'vue-image-lightbox';
import isotope from 'vueisotope';
import imagesLoaded from 'vue-images-loaded';
import { VueLazyloadImage } from 'vue-lazyload';
import 'vue-image-lightbox/dist/vue-image-lightbox.min.css';

import { baseUrl } from '~/repositories/repository.js';

export default {
    components: {
        LightBox,
        isotope
    },
    directives: {
        imagesLoaded
    },
    props: {
        product: {
            type: Array,
            default: function() {
                return {
                    sm_pictures: [],
                    pictures: []
                };
            }
        }
    },
    data: function() {
        return {
            baseUrl: baseUrl,
            currentIndex: 0,
            isotopeOptions: {
                itemSelector: 'product-gallery-item',
                layoutMode: 'masonry',
                percentPosition: false,
                masonry: {
                    columnWidth: '.grid-sizer'
                }
            }
        };
    },
    computed: {
        lightBoxMedia: function() {
            return this.product[0].images.reduce((acc) => {
                return [
                    ...acc,
                    {
                        thumb: this.product[0].images[this.currentIndex],
                        src: this.product[0].images[this.currentIndex],
                        caption: this.product.name
                    }
                ];
            }, []);
        },
        lightBoxMedia: function() {
            return this.product[0].images.reduce((acc) => {
                return [
                    ...acc,
                    {

                        thumb: this.product[0].images[this.currentIndex],
                        src: this.product[0].images[this.currentIndex],
                        caption: this.product.name
                    }
                ];
            }, []);
        }
    },
    methods: {
        changePicture: function(index) {
            this.currentIndex = index;
        },
        openLightBox: function() {
            this.$refs.lightbox.showImage(this.currentIndex);
        },
        handleIsotope: function() {
            if (this.$refs.iso) this.$refs.iso.layout('masonry');
        }
    }
};
</script>