var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "mobile-menu-container" }, [
    _c(
      "div",
      { staticClass: "mobile-menu-wrapper" },
      [
        _c(
          "span",
          {
            staticClass: "mobile-menu-close",
            on: { click: _vm.hideMobileMenu }
          },
          [_c("i", { staticClass: "icon-close" })]
        ),
        _vm._v(" "),
        _c("tabs", {
          staticClass: "nav-pills-mobile text-center",
          attrs: { data: _vm.tabsData }
        }),
        _vm._v(" "),
        _c("div", { staticClass: "tab-content" }, [
          _c(
            "div",
            {
              staticClass: "tab-pane fade show active",
              attrs: { id: "mobile-nav" }
            },
            [
              _c("nav", { staticClass: "mobile-nav" }, [
                _c("ul", { staticClass: "mobile-menu" }, [
                  _c(
                    "li",
                    {
                      staticClass: "megamenu-container",
                      class: { active: _vm.current == "/" }
                    },
                    [
                      _c("nuxt-link", { attrs: { to: _vm.localePath("/") } }, [
                        _vm._v(
                          "\n                                    " +
                            _vm._s(_vm.$t("nav.home"))
                        )
                      ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "li",
                    { class: { active: _vm.current == "shop" } },
                    [
                      _c(
                        "nuxt-link",
                        { attrs: { to: _vm.localePath("/shop/sidebar/list") } },
                        [_vm._v(_vm._s(_vm.$t("nav.products")))]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "li",
                    { class: { active: _vm.current == "about" } },
                    [
                      _c(
                        "nuxt-link",
                        { attrs: { to: _vm.localePath("/pages/aboutAlf") } },
                        [_vm._v(_vm._s(_vm.$t("nav.about")))]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "li",
                    { class: { active: _vm.current == "blog" } },
                    [
                      _c(
                        "nuxt-link",
                        { attrs: { to: _vm.localePath("/blog/classic") } },
                        [_vm._v(_vm._s(_vm.$t("nav.blog")))]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "li",
                    { class: { active: _vm.current == "contact" } },
                    [
                      _c(
                        "nuxt-link",
                        { attrs: { to: _vm.localePath("/pages/contact") } },
                        [_vm._v(_vm._s(_vm.$t("nav.contactUs")))]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "li",
                    { class: { active: _vm.current == "wishlist" } },
                    [
                      _c(
                        "nuxt-link",
                        { attrs: { to: _vm.localePath("/shop/wishlist") } },
                        [_vm._v("قائمة الامنيات")]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "li",
                    { class: { active: _vm.current == "cart" } },
                    [
                      _c(
                        "nuxt-link",
                        { attrs: { to: _vm.localePath("/shop/cart") } },
                        [_vm._v("عربة التسوق")]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "li",
                    { class: { active: _vm.current == "profile" } },
                    [
                      _c(
                        "nuxt-link",
                        {
                          attrs: {
                            to: _vm.localePath("/pages/account/profile")
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("profile.title")))]
                      )
                    ],
                    1
                  )
                ])
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "tab-pane fade",
              attrs: { id: "mobile-categories" }
            },
            [
              _c("nav", { staticClass: "mobile-cats-nav" }, [
                _c("ul", { staticClass: "mobile-cats-menu" }, [
                  _c(
                    "li",
                    { staticClass: "item-cats-lead" },
                    [
                      _c(
                        "nuxt-link",
                        {
                          attrs: {
                            to: "/shop/sidebar/3cols?category=electronics"
                          }
                        },
                        [_vm._v("Electronics")]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "li",
                    { staticClass: "item-cats-lead" },
                    [
                      _c(
                        "nuxt-link",
                        {
                          attrs: {
                            to: "/shop/sidebar/3cols?category=gift-idea"
                          }
                        },
                        [_vm._v("Gift Ideas")]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "li",
                    [
                      _c(
                        "nuxt-link",
                        { attrs: { to: "/shop/sidebar/3cols?category=beds" } },
                        [_vm._v("Beds")]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "li",
                    [
                      _c(
                        "nuxt-link",
                        {
                          attrs: { to: "/shop/sidebar/3cols?category=lighting" }
                        },
                        [_vm._v("Lighting")]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "li",
                    [
                      _c(
                        "nuxt-link",
                        {
                          attrs: {
                            to:
                              "/shop/sidebar/3cols?category=sofas-and-sleeper-sofas"
                          }
                        },
                        [_vm._v("Sofas & Sleeper sofas")]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "li",
                    [
                      _c(
                        "nuxt-link",
                        {
                          attrs: { to: "/shop/sidebar/3cols?category=storage" }
                        },
                        [_vm._v("Storage")]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "li",
                    [
                      _c(
                        "nuxt-link",
                        {
                          attrs: {
                            to:
                              "/shop/sidebar/3cols?category=armchairs-and-chaises"
                          }
                        },
                        [_vm._v("Armchairs & Chaises")]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "li",
                    [
                      _c(
                        "nuxt-link",
                        {
                          attrs: {
                            to: "/shop/sidebar/3cols?category=decoration"
                          }
                        },
                        [_vm._v("Decoration")]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "li",
                    [
                      _c(
                        "nuxt-link",
                        {
                          attrs: {
                            to: "/shop/sidebar/3cols?category=kitchen-cabinets"
                          }
                        },
                        [_vm._v("Kitchen Cabinets")]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "li",
                    [
                      _c(
                        "nuxt-link",
                        {
                          attrs: {
                            to: "/shop/sidebar/3cols?category=coffee-and-tables"
                          }
                        },
                        [_vm._v("Coffee & Tables")]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "li",
                    [
                      _c(
                        "nuxt-link",
                        {
                          attrs: {
                            to: "/shop/sidebar/3cols?category=furniture"
                          }
                        },
                        [_vm._v("Outdoor Furniture")]
                      )
                    ],
                    1
                  )
                ])
              ])
            ]
          )
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }