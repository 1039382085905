var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "widget widget-products" },
    [
      _c("h4", { staticClass: "widget-title" }, [_vm._v("Related Product")]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "products" },
        _vm._l(_vm.products, function(product, index) {
          return _c("product-eleven", {
            key: index,
            attrs: { product: product }
          })
        }),
        1
      ),
      _vm._v(" "),
      _c(
        "nuxt-link",
        {
          staticClass: "btn btn-outline-dark-3 text-truncate",
          attrs: { to: _vm.localePath("/shop/sidebar/4cols") }
        },
        [
          _c("span", [_vm._v("View More Products")]),
          _vm._v(" "),
          _c("i", { staticClass: "icon-long-arrow-right" })
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }