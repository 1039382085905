<template>
    <div class="container mb-5">
        <div class="row elements">
            <div class="col-xl-5col col-lg-4 col-md-3 col-6">
                <nuxt-link to="/elements/accordions" class="element-type">
                    <div class="element">
                        <i
                            class="element-img"
                            style="background-image: url('./images/elements.png')"
                        ></i>
                        <i
                            class="element-hover-img"
                            style="background-image: url('./images/elements.png')"
                        ></i>
                        <p>accordions</p>
                    </div>
                </nuxt-link>
            </div>
            <div class="col-xl-5col col-lg-4 col-md-3 col-6">
                <nuxt-link to="/elements/banners" class="element-type">
                    <div class="element">
                        <i
                            class="element-img"
                            style="background-image: url('./images/elements.png')"
                        ></i>
                        <i
                            class="element-hover-img"
                            style="background-image: url('./images/elements.png')"
                        ></i>
                        <p>banners</p>
                    </div>
                </nuxt-link>
            </div>
            <div class="col-xl-5col col-lg-4 col-md-3 col-6">
                <nuxt-link to="/elements/blog-posts" class="element-type">
                    <div class="element">
                        <i
                            class="element-img"
                            style="background-image: url('./images/elements.png')"
                        ></i>
                        <i
                            class="element-hover-img"
                            style="background-image: url('./images/elements.png')"
                        ></i>
                        <p>blog posts</p>
                    </div>
                </nuxt-link>
            </div>
            <div class="col-xl-5col col-lg-4 col-md-3 col-6">
                <nuxt-link to="/elements/buttons" class="element-type">
                    <div class="element">
                        <i
                            class="element-img"
                            style="background-image: url('./images/elements.png')"
                        ></i>
                        <i
                            class="element-hover-img"
                            style="background-image: url('./images/elements.png')"
                        ></i>
                        <p>buttons</p>
                    </div>
                </nuxt-link>
            </div>
            <div class="col-xl-5col col-lg-4 col-md-3 col-6">
                <nuxt-link to="/elements/cta" class="element-type">
                    <div class="element">
                        <i
                            class="element-img"
                            style="background-image: url('./images/elements.png')"
                        ></i>
                        <i
                            class="element-hover-img"
                            style="background-image: url('./images/elements.png')"
                        ></i>
                        <p>call to action</p>
                    </div>
                </nuxt-link>
            </div>
            <div class="col-xl-5col col-lg-4 col-md-3 col-6">
                <nuxt-link to="/elements/icon-boxes" class="element-type">
                    <div class="element">
                        <i
                            class="element-img"
                            style="background-image: url('./images/elements.png')"
                        ></i>
                        <i
                            class="element-hover-img"
                            style="background-image: url('./images/elements.png')"
                        ></i>
                        <p>icon boxes</p>
                    </div>
                </nuxt-link>
            </div>
            <div class="col-xl-5col col-lg-4 col-md-3 col-6">
                <nuxt-link to="/elements/portfolio" class="element-type">
                    <div class="element">
                        <i
                            class="element-img"
                            style="background-image: url('./images/elements.png')"
                        ></i>
                        <i
                            class="element-hover-img"
                            style="background-image: url('./images/elements.png')"
                        ></i>
                        <p>portfolio</p>
                    </div>
                </nuxt-link>
            </div>
            <div class="col-xl-5col col-lg-4 col-md-3 col-6">
                <nuxt-link to="/elements/categories" class="element-type">
                    <div class="element">
                        <i
                            class="element-img"
                            style="background-image: url('./images/elements.png')"
                        ></i>
                        <i
                            class="element-hover-img"
                            style="background-image: url('./images/elements.png')"
                        ></i>
                        <p>product catigories</p>
                    </div>
                </nuxt-link>
            </div>
            <div class="col-xl-5col col-lg-4 col-md-3 col-6">
                <nuxt-link to="/elements/products" class="element-type">
                    <div class="element">
                        <i
                            class="element-img"
                            style="background-image: url('./images/elements.png')"
                        ></i>
                        <i
                            class="element-hover-img"
                            style="background-image: url('./images/elements.png')"
                        ></i>
                        <p>products</p>
                    </div>
                </nuxt-link>
            </div>
            <div class="col-xl-5col col-lg-4 col-md-3 col-6">
                <nuxt-link to="/elements/tabs" class="element-type">
                    <div class="element">
                        <i
                            class="element-img"
                            style="background-image: url('./images/elements.png')"
                        ></i>
                        <i
                            class="element-hover-img"
                            style="background-image: url('./images/elements.png')"
                        ></i>
                        <p>tabs</p>
                    </div>
                </nuxt-link>
            </div>
            <div class="col-xl-5col col-lg-4 col-md-3 col-6">
                <nuxt-link to="/elements/testimonials" class="element-type">
                    <div class="element">
                        <i
                            class="element-img"
                            style="background-image: url('./images/elements.png')"
                        ></i>
                        <i
                            class="element-hover-img"
                            style="background-image: url('./images/elements.png')"
                        ></i>
                        <p>testimonials</p>
                    </div>
                </nuxt-link>
            </div>
            <div class="col-xl-5col col-lg-4 col-md-3 col-6">
                <nuxt-link to="/elements/titles" class="element-type">
                    <div class="element">
                        <i
                            class="element-img"
                            style="background-image: url('./images/elements.png')"
                        ></i>
                        <i
                            class="element-hover-img"
                            style="background-image: url('./images/elements.png')"
                        ></i>
                        <p>titles</p>
                    </div>
                </nuxt-link>
            </div>
            <div class="col-xl-5col col-lg-4 col-md-3 col-6">
                <nuxt-link to="/elements/typography" class="element-type">
                    <div class="element">
                        <i
                            class="element-img"
                            style="background-image: url('./images/elements.png')"
                        ></i>
                        <i
                            class="element-hover-img"
                            style="background-image: url('./images/elements.png')"
                        ></i>
                        <p>typography</p>
                    </div>
                </nuxt-link>
            </div>
            <div class="col-xl-5col col-lg-4 col-md-3 col-6">
                <nuxt-link to="/elements/video-banners" class="element-type">
                    <div class="element">
                        <i
                            class="element-img"
                            style="background-image: url('./images/elements.png')"
                        ></i>
                        <i
                            class="element-hover-img"
                            style="background-image: url('./images/elements.png')"
                        ></i>
                        <p>video</p>
                    </div>
                </nuxt-link>
            </div>
        </div>
    </div>
</template>
<script>
export default {};
</script>