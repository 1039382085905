<template>
    <div>
        <!-- <VueScrollProgress></VueScrollProgress> -->
        <nav class="main-nav">
            <ul class="menu sf-arrows">
                <li
                    class="megamenu-container"
                    :class="{ active: current == '/' }"
                >
                    <nuxt-link :to="localePath('/')">
                        {{ $t('nav.home') }}</nuxt-link
                    >
                </li>
                <li :class="{ active: current == 'shop' }">
                    <nuxt-link :to="localePath('/shop/sidebar/list')">{{
                        $t('nav.products')
                    }}</nuxt-link>
                </li>
                <li :class="{ active: current == 'about' }">
                    <nuxt-link :to="localePath('/pages/aboutAlf')">{{
                        $t('nav.about')
                    }}</nuxt-link>
                </li>

                <li :class="{ active: current == 'blog' }">
                    <nuxt-link :to="localePath('/blog/classic')">{{
                        $t('nav.blog')
                    }}</nuxt-link>
                </li>
                <li :class="{ active: current == 'contact' }">
                    <nuxt-link :to="localePath('/pages/contact')">{{
                        $t('nav.contactUs')
                    }}</nuxt-link>
                </li>
                <!-- <ul>
                    <li>
                        <nuxt-link to="/blog/classic">Classic</nuxt-link>
                    </li>
                    <li>
                        <nuxt-link to="/blog/listing">Listing</nuxt-link>
                    </li>
                    <li>
                        <nuxt-link to="/blog/grid/grid-2" class="sf-with-ul"
                            >Grid</nuxt-link
                        >
                        <ul>
                            <li>
                                <nuxt-link to="/blog/grid/grid-2"
                                    >Grid 2 columns</nuxt-link
                                >
                            </li>
                            <li>
                                <nuxt-link to="/blog/grid/grid-3"
                                    >Grid 3 columns</nuxt-link
                                >
                            </li>
                            <li>
                                <nuxt-link to="/blog/grid/grid-4"
                                    >Grid 4 columns</nuxt-link
                                >
                            </li>
                            <li>
                                <nuxt-link to="/blog/grid-sidebar"
                                    >Grid sidebar</nuxt-link
                                >
                            </li>
                        </ul>
                    </li>
                    <li>
                        <nuxt-link
                            to="/blog/masonry/masonry-2"
                            class="sf-with-ul"
                            >Masonry</nuxt-link
                        >
                        <ul>
                            <li>
                                <nuxt-link to="/blog/masonry/masonry-2"
                                    >Masonry 2 columns</nuxt-link
                                >
                            </li>
                            <li>
                                <nuxt-link to="/blog/masonry/masonry-3"
                                    >Masonry 3 columns</nuxt-link
                                >
                            </li>
                            <li>
                                <nuxt-link to="/blog/masonry/masonry-4"
                                    >Masonry 4 columns</nuxt-link
                                >
                            </li>
                            <li>
                                <nuxt-link to="/blog/masonry-sidebar"
                                    >Masonry sidebar</nuxt-link
                                >
                            </li>
                        </ul>
                    </li>
                    <li>
                        <nuxt-link to="/blog/mask/grid" class="sf-with-ul"
                            >Mask</nuxt-link
                        >
                        <ul>
                            <li>
                                <nuxt-link to="/blog/mask/grid"
                                    >Blog mask grid</nuxt-link
                                >
                            </li>
                            <li>
                                <nuxt-link to="/blog/mask/masonry"
                                    >Blog mask masonry</nuxt-link
                                >
                            </li>
                        </ul>
                    </li>
                    <li>
                        <nuxt-link
                            to="/blog/single/default/cras-ornare-tristique-elit."
                            class="sf-with-ul"
                            >Single Post</nuxt-link
                        >
                        <ul>
                            <li>
                                <nuxt-link
                                    to="/blog/single/default/cras-ornare-tristique-elit."
                                    >Default with sidebar</nuxt-link
                                >
                            </li>
                            <li>
                                <nuxt-link
                                    to="/blog/single/fullwidth/fusce-pellentesque-suscipit."
                                    >Fullwidth no sidebar</nuxt-link
                                >
                            </li>
                            <li>
                                <nuxt-link
                                    to="/blog/single/sidebar/utaliquam-sollicitzdvudin-leo"
                                    >Fullwidth with sidebar</nuxt-link
                                >
                            </li>
                        </ul>
                    </li>
                </ul> -->

                <!-- <li :class="{ active: current == 'places' }">
                    <nuxt-link :to="localePath('/pages/places')">{{
                        $t('nav.places')
                    }}</nuxt-link>
                </li> -->
                <!-- <li :class="{ active: current == 'pages' }">
                <a href="javascript:;" class="sf-with-ul">Pages</a>

                <ul>
                    <li>
                        <nuxt-link to="/pages/about" class="sf-with-ul"
                            >About</nuxt-link
                        >

                        <ul>
                            <li>
                                <nuxt-link to="/pages/about"
                                    >About 01</nuxt-link
                                >
                            </li>
                            <li>
                                <nuxt-link to="/pages/about-2"
                                    >About 02</nuxt-link
                                >
                            </li>
                        </ul>
                    </li>
                    <li>
                        <nuxt-link to="/pages/contact" class="sf-with-ul"
                            >Contact</nuxt-link
                        >

                        <ul>
                            <li>
                                <nuxt-link to="/pages/contact"
                                    >Contact 01</nuxt-link
                                >
                            </li>
                            <li>
                                <nuxt-link to="/pages/contact-2"
                                    >Contact 02</nuxt-link
                                >
                            </li>
                        </ul>
                    </li>
                    <li>
                        <nuxt-link to="/pages/login">Login</nuxt-link>
                    </li>
                    <li>
                        <nuxt-link to="/pages/faq">FAQs</nuxt-link>
                    </li>
                    <li>
                        <nuxt-link to="/pages/404">Error 404</nuxt-link>
                    </li>
                    <li>
                        <nuxt-link to="/pages/coming-soon"
                            >Coming Soon</nuxt-link
                        >
                    </li>
                </ul>
            </li> -->

                <!-- <li :class="{ active: current == 'elements' }">
                <nuxt-link to="/elements" class="sf-with-ul"
                    >Elements</nuxt-link
                >

                <ul>
                    <li>
                        <nuxt-link to="/elements/products">Products</nuxt-link>
                    </li>
                    <li>
                        <nuxt-link to="/elements/typography"
                            >Typography</nuxt-link
                        >
                    </li>
                    <li>
                        <nuxt-link to="/elements/titles">Titles</nuxt-link>
                    </li>
                    <li>
                        <nuxt-link to="/elements/banners">Banners</nuxt-link>
                    </li>
                    <li>
                        <nuxt-link to="/elements/categories"
                            >Product Category</nuxt-link
                        >
                    </li>
                    <li>
                        <nuxt-link to="/elements/video-banners"
                            >Video Banners</nuxt-link
                        >
                    </li>
                    <li>
                        <nuxt-link to="/elements/buttons">Buttons</nuxt-link>
                    </li>
                    <li>
                        <nuxt-link to="/elements/accordions"
                            >Accordions</nuxt-link
                        >
                    </li>
                    <li>
                        <nuxt-link to="/elements/tabs">Tabs</nuxt-link>
                    </li>
                    <li>
                        <nuxt-link to="/elements/testimonials"
                            >Testimonials</nuxt-link
                        >
                    </li>
                    <li>
                        <nuxt-link to="/elements/blog-posts"
                            >Blog Posts</nuxt-link
                        >
                    </li>
                    <li>
                        <nuxt-link to="/elements/portfolio"
                            >Portfolio</nuxt-link
                        >
                    </li>
                    <li>
                        <nuxt-link to="/elements/cta">Call to Action</nuxt-link>
                    </li>
                    <li>
                        <nuxt-link to="/elements/icon-boxes"
                            >Icon Boxes</nuxt-link
                        >
                    </li>
                </ul>
            </li> -->
            </ul>

            <header-search></header-search>
        </nav>
    </div>
</template>
<script>
import HeaderSearch from '~/components/partial/headers/shared/HeaderSearch';
// YourComponent.vue or main.js for global usage
import Vue from 'vue';
import VueScrollProgress from 'vue-scroll-progress';

Vue.use(VueScrollProgress);
export default {
    computed: {
        current: function() {
            if (this.$route.path.includes('elements')) return 'elements';
            if (this.$route.path.includes('shop')) return 'shop';
            if (this.$route.path.includes('contact')) return 'contact';
            if (this.$route.path.includes('blog')) return 'blog';
            if (this.$route.path.includes('about')) return 'about';
            if (this.$route.path.includes('product')) return 'product';
            if (this.$route.path.includes('places')) return 'places';
            if (this.$route.path.includes('pages')) return 'pages';

            return '/';
        }
    },
    methods: {
        viewAllDemos: function(e) {
            var list = document.querySelectorAll('.demo-list .hidden');
            for (let i = 0; i < list.length; i++) {
                list[i].classList.add('show');
            }

            e.currentTarget.parentElement.classList.add('d-none');
        }
    },
    components: {
        HeaderSearch,
        VueScrollProgress
    }
};
</script>
<style lang="scss" scoped>
/* play with some lines below */
#progress-container-el {
    /* background */
    background-color: transparent !important;
    top: calc(100% - 4px) !important; /* remove if Vue 3.x */
}
#progress-el {
    /* progress bar */
    background-color: #fcb932 !important;
}
.header-center {
    .sf-arrows {
        justify-content: center;
        li {
            &.active {
                a {
                    &::before {
                        transform: translateY(-50%) !important;
                    }
                }
            }
            a {
                font-family: 'CairoRegular';
                font-size: 15px !important;
                position: relative;
                color: #302f2f;
                padding: 0rem 1rem;
                &::before {
                    background: #fff;
                    position: absolute;
                    content: '';
                    left: 0;
                    height: 1.5rem;
                    width: 0.1rem;
                    top: 50%;
                    transform: translateY(-50%);
                    background-color: #ccc;
                }
            }
        }
    }
}
</style>
