var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "nav",
    {
      staticClass: "breadcrumb-nav border-0 mb-0",
      attrs: { "aria-label": "breadcrumb" }
    },
    [
      _c(
        "div",
        {
          class:
            "d-flex align-items-center " +
            (_vm.fullWidth ? "container-fluid" : "container")
        },
        [
          _c("ol", { staticClass: "breadcrumb" }, [
            _c(
              "li",
              { staticClass: "breadcrumb-item" },
              [
                _c("nuxt-link", { attrs: { to: _vm.localePath("/") } }, [
                  _vm._v("Home")
                ])
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "li",
              { staticClass: "breadcrumb-item" },
              [
                _c(
                  "nuxt-link",
                  { attrs: { to: _vm.localePath("/product/default/list") } },
                  [_vm._v("Product")]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c("li", { staticClass: "breadcrumb-item active" }, [
              _vm._v(_vm._s(_vm.current))
            ])
          ]),
          _vm._v(" "),
          _c(
            "nav",
            {
              staticClass: "product-pager ml-auto",
              attrs: { "aria-label": "Product" }
            },
            [
              _vm.prevProduct
                ? _c(
                    "nuxt-link",
                    {
                      staticClass: "product-pager-link product-pager-prev",
                      class: { "prev-only": !_vm.nextProduct },
                      attrs: { to: _vm.localePath(_vm.prevProduct.slug) }
                    },
                    [
                      _c("i", { staticClass: "icon-angle-left" }),
                      _vm._v(" "),
                      _c("span", [_vm._v("Prev")]),
                      _vm._v(" "),
                      _c("div", { staticClass: "product-detail" }, [
                        _c("figure", [
                          _c("img", {
                            directives: [
                              {
                                name: "lazy",
                                rawName: "v-lazy",
                                value: `${_vm.baseUrl}${_vm.prevProduct.sm_pictures[0].url}`,
                                expression:
                                  "\n                                `${baseUrl}${prevProduct.sm_pictures[0].url}`\n                            "
                              }
                            ],
                            attrs: {
                              alt: "product",
                              width: _vm.prevProduct.sm_pictures[0].width,
                              height: _vm.prevProduct.sm_pictures[0].height
                            }
                          })
                        ]),
                        _vm._v(" "),
                        _c("h3", { staticClass: "product-name" }, [
                          _vm._v(_vm._s(_vm.prevProduct.name))
                        ])
                      ])
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.nextProduct
                ? _c(
                    "nuxt-link",
                    {
                      staticClass: "product-pager-link product-pager-next",
                      attrs: { to: _vm.nextProduct.slug }
                    },
                    [
                      _c("span", [_vm._v("Next")]),
                      _vm._v(" "),
                      _c("i", { staticClass: "icon-angle-right" }),
                      _vm._v(" "),
                      _c("div", { staticClass: "product-detail" }, [
                        _c("figure", [
                          _c("img", {
                            directives: [
                              {
                                name: "lazy",
                                rawName: "v-lazy",
                                value: `${_vm.baseUrl}${_vm.nextProduct.sm_pictures[0].url}`,
                                expression:
                                  "\n                                `${baseUrl}${nextProduct.sm_pictures[0].url}`\n                            "
                              }
                            ],
                            attrs: {
                              alt: "product",
                              width: _vm.nextProduct.sm_pictures[0].width,
                              height: _vm.nextProduct.sm_pictures[0].height
                            }
                          })
                        ]),
                        _vm._v(" "),
                        _c("h3", { staticClass: "product-name" }, [
                          _vm._v(_vm._s(_vm.nextProduct.name))
                        ])
                      ])
                    ]
                  )
                : _vm._e()
            ],
            1
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }