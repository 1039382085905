// const state = () => ({
//     all: [
//         {
//             id: 1,
//             name: 'Linen crochet trim t-shirt',
//             price: 191.00,
//             offer: true,
//             offerPrice: 3,
//             latest: true,
//             bestSellers: false,
//             trending: false,
//             image: require('~/assets/img/img1.jpg'),
//             imageHover: require('~/assets/img/img-hover1.jpg'),
//             timePeriod: false,
//             dateTime: new Date("December 19, 2020 17:00:00 PDT")
//         },
//         {
//             id: 2,
//             name: 'Neck empire sleeve t-shirts',
//             price: 300.00,
//             offer: false,
//             offerPrice: 3,
//             latest: true,
//             bestSellers: false,
//             trending: false,
//             image: require('~/assets/img/img2.jpg'),
//             imageHover: require('~/assets/img/img-hover2.jpg'),
//             timePeriod: false,
//             dateTime: new Date("December 19, 2020 17:00:00 PDT")
//         },
//         {
//             id: 3,
//             name: 'Mermaid pencil midi lace',
//             price: 166.00,
//             offer: true,
//             offerPrice: 5,
//             latest: true,
//             bestSellers: false,
//             trending: false,
//             image: require('~/assets/img/img3.jpg'),
//             imageHover: require('~/assets/img/img-hover3.jpg'),
//             timePeriod: false,
//             dateTime: new Date("December 19, 2020 17:00:00 PDT")
//         },
//         {
//             id: 4,
//             name: 'Criss-cross V neck bodycon',
//             price: 200.00,
//             offer: false,
//             offerPrice: 7,
//             latest: true,
//             bestSellers: false,
//             trending: false,
//             image: require('~/assets/img/img4.jpg'),
//             imageHover: require('~/assets/img/img-hover4.jpg'),
//             timePeriod: false,
//             dateTime: new Date("December 19, 2020 17:00:00 PDT")
//         },
//         {
//             id: 5,
//             name: 'The cosmic cornucopia',
//             price: 150.00,
//             offer: true,
//             offerPrice: 7,
//             latest: true,
//             bestSellers: false,
//             trending: false,
//             image: require('~/assets/img/img5.jpg'),
//             imageHover: require('~/assets/img/img-hover5.jpg'),
//             timePeriod: false,
//             dateTime: new Date("December 19, 2020 17:00:00 PDT")
//         },
//         {
//             id: 6,
//             name: 'Tenku remastered',
//             price: 177.00,
//             offer: false,
//             offerPrice: 7,
//             latest: true,
//             bestSellers: false,
//             trending: false,
//             image: require('~/assets/img/img6.jpg'),
//             imageHover: require('~/assets/img/img-hover6.jpg'),
//             timePeriod: false,
//             dateTime: new Date("December 19, 2020 17:00:00 PDT")
//         },
//         {
//             id: 7,
//             name: 'Women\'s sherpa lined hoodie',
//             price: 300.00,
//             offer: true,
//             offerPrice: 4,
//             latest: true,
//             bestSellers: false,
//             trending: false,
//             image: require('~/assets/img/img7.jpg'),
//             imageHover: require('~/assets/img/img-hover7.jpg'),
//             timePeriod: false,
//             dateTime: new Date("December 19, 2020 17:00:00 PDT")
//         },
//         {
//             id: 8,
//             name: 'Belted chino trousers polo',
//             price: 191.00,
//             offer: false,
//             offerPrice: 4,
//             latest: true,
//             bestSellers: false,
//             trending: false,
//             image: require('~/assets/img/img8.jpg'),
//             imageHover: require('~/assets/img/img-hover8.jpg'),
//             timePeriod: false,
//             dateTime: new Date("December 19, 2020 17:00:00 PDT")
//         },
//         {
//             id: 9,
//             name: 'The cosmic cornucopia',
//             price: 150.00,
//             offer: true,
//             offerPrice: 7,
//             latest: false,
//             bestSellers: true,
//             trending: false,
//             image: require('~/assets/img/bestseller-img1.jpg'),
//             imageHover: require('~/assets/img/bestseller-hover-img1.jpg'),
//             timePeriod: true,
//             dateTime: new Date("August 19, 2022 17:00:00 PDT")
//         },
//         {
//             id: 10,
//             name: 'Tenku remastered',
//             price: 177.00,
//             offer: false,
//             offerPrice: 7,
//             latest: false,
//             bestSellers: true,
//             trending: false,
//             image: require('~/assets/img/bestseller-img2.jpg'),
//             imageHover: require('~/assets/img/bestseller-hover-img2.jpg'),
//             timePeriod: false,
//             trendingProducts: false,
//             dateTime: new Date("August 19, 2021 17:00:00 PDT")
//         },
//         {
//             id: 11,
//             name: 'Women\'s sherpa lined hoodie',
//             price: 300.00,
//             offer: true,
//             offerPrice: 4,
//             latest: false,
//             bestSellers: true,
//             trending: false,
//             image: require('~/assets/img/bestseller-img3.jpg'),
//             imageHover: require('~/assets/img/bestseller-hover-img3.jpg'),
//             timePeriod: true,
//             dateTime: new Date("December 19, 2020 17:00:00 PDT")
//         },
//         {
//             id: 12,
//             name: 'Belted chino trousers polo',
//             price: 191.00,
//             offer: false,
//             offerPrice: 4,
//             latest: false,
//             bestSellers: true,
//             trending: false,
//             image: require('~/assets/img/bestseller-img4.jpg'),
//             imageHover: require('~/assets/img/bestseller-hover-img4.jpg'),
//             timePeriod: false,
//             dateTime: new Date("April 19, 2020 17:00:00 PDT")
//         },
//         {
//             id: 13,
//             name: 'The cosmic cornucopia',
//             price: 150.00,
//             offer: true,
//             offerPrice: 7,
//             latest: false,
//             bestSellers: false,
//             trending: true,
//             image: require('~/assets/img/trending-img1.jpg'),
//             imageHover: require('~/assets/img/trending-hover-img1.jpg'),
//             timePeriod: false,
//             dateTime: new Date("December 19, 2020 17:00:00 PDT")
//         },
//         {
//             id: 14,
//             name: 'Tenku remastered',
//             price: 177.00,
//             offer: false,
//             offerPrice: 7,
//             latest: false,
//             bestSellers: false,
//             trending: true,
//             image: require('~/assets/img/trending-img2.jpg'),
//             imageHover: require('~/assets/img/trending-hover-img2.jpg'),
//             timePeriod: false,
//             dateTime: new Date("December 19, 2020 17:00:00 PDT")
//         },
//         {
//             id: 15,
//             name: 'Women\'s sherpa lined hoodie',
//             price: 300.00,
//             offer: true,
//             offerPrice: 4,
//             latest: false,
//             bestSellers: false,
//             trending: true,
//             image: require('~/assets/img/trending-img3.jpg'),
//             imageHover: require('~/assets/img/trending-hover-img3.jpg'),
//             timePeriod: false,
//             dateTime: new Date("December 19, 2020 17:00:00 PDT")
//         },
//         {
//             id: 16,
//             name: 'Belted chino trousers polo',
//             price: 191.00,
//             offer: false,
//             offerPrice: 4,
//             latest: false,
//             bestSellers: false,
//             trending: true,
//             image: require('~/assets/img/trending-img4.jpg'),
//             imageHover: require('~/assets/img/trending-hover-img4.jpg'),
//             timePeriod: false,
//             dateTime: new Date("December 19, 2020 17:00:00 PDT")
//         },
//     ]
// });

// export default {
//     state
// };