<template>
    <div>
        <!-- gift  -->
        <template v-if="!loading">
            <div class="col-9 m-auto skeleton-body skel-shop-products">
                <template>
                    <div
                        class="skel-pro skel-pro-list"
                        v-for="item in 1"
                        :key="item"
                    ></div>
                </template>
            </div>
        </template>
        <div class="" v-else>
            <section class="create-gift" v-if="thirdListPhotos.length > 0">
                <div class="container-fluid">
                    <div class="row">
                        <div
                            class="col-md-6 col-sm-12"
                            v-for="holder in thirdListPhotos"
                            :key="holder.id"
                        >
                            <div class="holder">
                                <img
                                    v-lazy="holder.first_image"
                                    alt="image"
                                    class="img-fluid bg-transparent"
                                />
                                
                                <p class="text-white" >
                                    {{ holder.title }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
import Repository, { baseUrl } from '~/repositories/repository.js';

export default {
    data: function () {
        return {
            firstListPhotos: [],
            secondListPhotos: [],
            thirdListPhotos: [],
            loading: false,
        };
    },
    methods: {
        getData: async function () {
            let listPhotos = `${baseUrl}/list_photos`;

            await Repository.get(listPhotos).then((resp) => {
                if (resp.data.code == 200) {
                    this.loading = true;
                }
                this.firstListPhotos = resp.data.list_photos_type1;
                this.secondListPhotos = resp.data.list_photos_type2;
                this.thirdListPhotos = resp.data.list_photos_type3;
                // console.log('listPhotos', resp);
            });
        },
    },
    mounted() {
        this.getData();
    },
};
</script>

<style>
</style>