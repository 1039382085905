var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      !_vm.loading
        ? [
            _c(
              "div",
              { staticClass: "col-9 m-auto skeleton-body skel-shop-products" },
              [
                _vm._l(1, function(item) {
                  return _c("div", {
                    key: item,
                    staticClass: "skel-pro skel-pro-list"
                  })
                })
              ],
              2
            )
          ]
        : _c("div", {}, [
            _vm.abouts.length > 0
              ? _c("section", { staticClass: "py-5" }, [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "lazy",
                          rawName: "v-lazy:background-image",
                          value: "./images/Alf/alf/last.png",
                          expression: "'./images/Alf/alf/last.png'",
                          arg: "background-image"
                        }
                      ],
                      staticClass: "about-testimonials bg-light-2 pt-6 pb-6"
                    },
                    [
                      _c("div", { staticClass: "container" }, [
                        _c("h2", { staticClass: "title text-center mb-3" }, [
                          _vm._v(
                            "\n                        " +
                              _vm._s(_vm.abouts.title) +
                              "\n                    "
                          )
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "swiper-carousel swiper-testimonials-photo swiper-11"
                          },
                          [
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "swiper",
                                    rawName: "v-swiper:swiper11",
                                    value: _vm.carouselSetting11,
                                    expression: "carouselSetting11",
                                    arg: "swiper11"
                                  }
                                ]
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "swiper-wrapper" },
                                  _vm._l(_vm.abouts, function(about) {
                                    return _c(
                                      "div",
                                      {
                                        key: about.id,
                                        staticClass: "swiper-slide",
                                        attrs: { productId: about.id }
                                      },
                                      [
                                        _c(
                                          "blockquote",
                                          {
                                            staticClass:
                                              "testimonial text-center"
                                          },
                                          [
                                            _c("h2", [
                                              _vm._v(
                                                "\n                                            " +
                                                  _vm._s(about.title) +
                                                  "\n                                        "
                                              )
                                            ]),
                                            _vm._v(" "),
                                            _c("p", { staticClass: "lead" }, [
                                              _vm._v(
                                                "\n                                            " +
                                                  _vm._s(about.description) +
                                                  "\n                                        "
                                              )
                                            ]),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              { staticClass: "info-person" },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "image mb-2" },
                                                  [
                                                    _c("img", {
                                                      directives: [
                                                        {
                                                          name: "lazy",
                                                          rawName: "v-lazy",
                                                          value:
                                                            about.background_image,
                                                          expression:
                                                            "\n                                                        about.background_image\n                                                    "
                                                        }
                                                      ],
                                                      attrs: { alt: "user" }
                                                    })
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c("cite", [
                                                  _vm._v(
                                                    "\n                                                " +
                                                      _vm._s(about.name) +
                                                      "\n\n                                                "
                                                  )
                                                ])
                                              ]
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  }),
                                  0
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _vm._m(0),
                            _vm._v(" "),
                            _c("div", { staticClass: "swiper-dots" })
                          ]
                        )
                      ])
                    ]
                  )
                ])
              : _vm._e()
          ])
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "swiper-nav d-none d-xl-block" }, [
      _c("div", { staticClass: "swiper-prev" }, [
        _c("i", { staticClass: "icon-angle-left" })
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "swiper-next" }, [
        _c("i", { staticClass: "icon-angle-right" })
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }