export const ADD_TO_WISHLIST = 'ADD_TO_WISHLIST';
export const REMOVE_FROM_WISHLIST = 'REMOVE_FROM_WISHLIST';
export const MOVE_TO_CART = 'MOVE_TO_CART';
import { baseUrl } from '~/repositories/repository.js';
import axios from 'axios';
export const state = () => ({
    data: []
});

export const getters = {
    wishlist: state => {
        return state.data;
    },
    wishlistQty: state => {
        return state.data.length;
    },
    isInWishlist: state => product => {
        return state.data.find(item => item.id == product.id) ? true : false;
    }
};

export const actions = {
    addToWishlist: function({ commit }, payload) {
        let token = sessionStorage.getItem('token');
        axios
            .post(`${baseUrl}/wish/add`, {
                api_token: token,
                product_id: payload.product.id,
                headers: {
                    language:
                        this.$i18n.locale == 'en' ? 'LANGUAGE2' : 'LANGUAGE1'
                }
            })
            .then(resp => {
                commit(ADD_TO_WISHLIST, payload);
                this._vm.$vToastify.setSettings({
                    withBackdrop: false,
                    position: 'top-right',
                    successDuration: 1500
                });
                if (resp.data.code == 200) {
                    
                    this._vm.$vToastify.success(resp.data.message);
                    setTimeout(() => {
                        this.$router.go(this.$router.currentRoute);
                    }, 1600);
                } else if(resp.data.code == 201 && resp.data.message == 'Please Sign in'){
                    this._vm.$vToastify.error('من فضلك قم بادخال حسابك');
                }else{
                    this._vm.$vToastify.error(resp.data.message)
                }
            })
            .catch(err => {
                console.log(err);
                this._vm.$vToastify.setSettings({
                    withBackdrop: false,
                    position: 'top-right',
                    successDuration: 1500
                });
                
                    this._vm.$vToastify.error(err.data.message)
                
                
                console.log(err.data.message)
            });
    },
    removeFromWishlist: function({ commit }, payload) {
        let token = sessionStorage.getItem('token');
        axios
            .post(`${baseUrl}/wish/remove`, {
                api_token: token,
                product_id: payload.wishlist.id,
                headers: {
                    language:
                        this.$i18n.locale == 'en' ? 'LANGUAGE2' : 'LANGUAGE1'
                }
            })
            .then(resp => {
                this._vm.$vToastify.setSettings({
                    withBackdrop: false,
                    position: 'top-right',
                    successDuration: 1500
                });
                if (resp.data.code == 200) {
                    console.log("resp.data.message" ,resp.data.message);
                    commit(REMOVE_FROM_WISHLIST, payload);
                    this._vm.$vToastify.success(resp.data.message);
                    setTimeout(() => {
                        this.$router.go(this.$router.currentRoute);
                    }, 1600);
                } else {
                    this._vm.$vToastify.error(resp.data.message);
                }
                if (resp.data.code == 201) {
                    this._vm.$vToastify.setSettings({
                        withBackdrop: false,
                        position: 'top-right',
                        successDuration: 1500
                    });
                    this._vm.$vToastify.success('login first');
                    setTimeout(() => {
                        // window.location.replace('/pages/login')
                        this.$router.go('/pages/login');
                    }, 1500);
                }
            })
            .catch(err => {
                console.log(err);
                this._vm.$vToastify.setSettings({
                    withBackdrop: false,
                    position: 'top-right',
                    successDuration: 1500
                });
                this._vm.$vToastify.error(err.data.message);
            });
    },
    moveToCart: function({ commit }, payload) {
        commit(REMOVE_FROM_WISHLIST, payload);
        this.commit('cart/ADD_TO_CART', {
            product: payload.product,
            qty: 1
        });
        console.log('move to cart payload', payload);
        this._vm.$vToastify.setSettings({
            withBackdrop: false,
            position: 'top-right',
            successDuration: 1500
        });
        this._vm.$vToastify.success('Product moved to cart');
        console.log(payload);
    }
};

export const mutations = {
    [ADD_TO_WISHLIST](state, payload) {
        var findIndex = state.data.findIndex(
            item => item.id === payload.product.id
        );
        if (findIndex == -1) {
            state.data = [...state.data, payload.product];
        }
    },

    [REMOVE_FROM_WISHLIST](state, payload) {
        state.data = state.data.filter(item => item.id !== payload.wishlist.id);
    }
};
