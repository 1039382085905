var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "bg-light pt-5 pb-5" }, [
    _c("div", { staticClass: "container trending-products" }, [
      _c("div", { staticClass: "heading heading-flex mb-3" }, [
        _vm._m(0),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "heading-right" },
          [
            _c("tabs", {
              staticClass:
                "nav nav-pills nav-border-anim justify-content-center",
              attrs: { data: _vm.tabsData, id: "trendy" }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-xl-5col d-none d-xl-block" }, [
          _c(
            "div",
            { staticClass: "banner" },
            [
              _c("nuxt-link", { attrs: { to: "/shop/sidebar/list" } }, [
                _c("img", {
                  directives: [
                    {
                      name: "lazy",
                      rawName: "v-lazy",
                      value: "./images/home/banners/banner-4.jpg",
                      expression: "'./images/home/banners/banner-4.jpg'"
                    }
                  ],
                  attrs: { alt: "banner", width: "218", height: "390" }
                })
              ])
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-xl-4-5col" }, [
          _c(
            "div",
            {
              staticClass:
                "tab-content tab-content-carousel just-action-icons-sm"
            },
            [
              _c(
                "div",
                {
                  staticClass: "tab-pane p-0 fade show active",
                  attrs: { id: "trendy-top-rated" }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "swiper-carousel carousel-with-shadow swiper-1"
                    },
                    [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "swiper",
                              rawName: "v-swiper:swiper1",
                              value: _vm.carouselSetting1,
                              expression: "carouselSetting1",
                              arg: "swiper1"
                            }
                          ],
                          staticClass: "mb-0"
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "swiper-wrapper" },
                            _vm._l(_vm.ratedProducts, function(product, index) {
                              return _c(
                                "div",
                                { key: index, staticClass: "swiper-slide" },
                                [
                                  _c("product-twelve", {
                                    staticClass: "mb-3",
                                    attrs: { product: product }
                                  })
                                ],
                                1
                              )
                            }),
                            0
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _vm._m(1)
                    ]
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "tab-pane p-0 fade",
                  attrs: { id: "trendy-best-selling" }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "swiper-carousel carousel-with-shadow swiper-2"
                    },
                    [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "swiper",
                              rawName: "v-swiper:swiper2",
                              value: _vm.carouselSetting2,
                              expression: "carouselSetting2",
                              arg: "swiper2"
                            }
                          ],
                          staticClass: "mb-0"
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "swiper-wrapper" },
                            _vm._l(_vm.featuredProducts, function(
                              product,
                              index
                            ) {
                              return _c(
                                "div",
                                { key: index, staticClass: "swiper-slide" },
                                [
                                  _c("product-twelve", {
                                    attrs: { product: product }
                                  })
                                ],
                                1
                              )
                            }),
                            0
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _vm._m(2)
                    ]
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "tab-pane p-0 fade",
                  attrs: { id: "trendy-on-sale" }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "swiper-carousel carousel-with-shadow swiper-3"
                    },
                    [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "swiper",
                              rawName: "v-swiper:swiper3",
                              value: _vm.carouselSetting3,
                              expression: "carouselSetting3",
                              arg: "swiper3"
                            }
                          ],
                          staticClass: "mb-0"
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "swiper-wrapper" },
                            _vm._l(_vm.saleProducts, function(product, index) {
                              return _c(
                                "div",
                                { key: index, staticClass: "swiper-slide" },
                                [
                                  _c("product-twelve", {
                                    attrs: { product: product }
                                  })
                                ],
                                1
                              )
                            }),
                            0
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _vm._m(3)
                    ]
                  )
                ]
              )
            ]
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "heading-left" }, [
      _c("h2", { staticClass: "title" }, [_vm._v("Trending Products")])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "swiper-nav nav-side" }, [
      _c("div", { staticClass: "swiper-prev" }, [
        _c("i", { staticClass: "icon-angle-left" })
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "swiper-next" }, [
        _c("i", { staticClass: "icon-angle-right" })
      ])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "swiper-nav nav-side" }, [
      _c("div", { staticClass: "swiper-prev" }, [
        _c("i", { staticClass: "icon-angle-left" })
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "swiper-next" }, [
        _c("i", { staticClass: "icon-angle-right" })
      ])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "swiper-nav nav-side" }, [
      _c("div", { staticClass: "swiper-prev" }, [
        _c("i", { staticClass: "icon-angle-left" })
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "swiper-next" }, [
        _c("i", { staticClass: "icon-angle-right" })
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }