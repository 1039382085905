<template>
    <div>
        <div class="product-holder text-left">
            <div class="row">
                <div class="col-12"> 
                    <figure class="product-media">
                        <span class="product-label label-new" v-if="product.new"
                            >{{$t('product.new')}}</span
                        >
                        <span
                            class="product-label label-sale"
                            v-if="
                                product.sale_price ||
                                product.price != product.price_after_discount
                            "
                            >{{$t('product.sale')}}</span
                        >
                        <span class="product-label label-top" v-if="product.top"
                            >{{$t('product.top')}}</span
                        >
                        <span
                            class="product-label label-out"
                            v-if="product.stock === 0"
                            >{{$t('product.outOfStock')}}</span
                        >

                        <nuxt-link
                            :to="localePath('/product/default/' + product.id)"
                        >
                            <img
                                v-lazy="product.main_image"
                                alt="Product"
                                :width="product.main_image"
                                :height="product.main_image"
                                v-if="product.main_image"
                                class="w-100 h-100"
                            />
                            <img
                                :src="product.main_image"
                                alt="Product"
                                :width="product.main_image"
                                :height="product.main_image"
                                class="product-image-hover"
                                v-else
                            />
                        </nuxt-link>
                    </figure>
                </div>
                <div
                    class="
                        col-12
                        d-flex
                        justify-content-center
                        align-items-center
                        flex-column
                    "
                >
                    <div class="product-body product-action-inner">
                        <div class="row">
                            <div class="col-md-12 col-sm-12">
                                <div class="product-info1">
                                    <h3 class="product-title">
                                       
                                        <nuxt-link
                                            :to="
                                                localePath(
                                                    '/product/default/' +
                                                        product.id
                                                )
                                            "
                                            :title="product.name"
                                            >{{ product.name }}</nuxt-link
                                        >
                                    </h3>

                                    
                                </div>
                            </div>

                            <div class="col-md-12 col-sm-12">
                                <div class="product-info2">
                                    <div
                                        class="product-price"
                                        v-if="product.stock == 0"
                                        key="outPrice"
                                    >
                                        <span class="out-price"
                                            >{{$t('product.outOfStock')}}</span
                                        >
                                    </div>

                                    <template v-else>
                                        <div
                                            class=" 
                                                product-price
                                                d-flex
                                                flex-column
                                                justify-content-start
                                            "
                                        >
                                            <p
                                                class="
                                                    new-price
                                                    lead
                                                    text-primary
                                                    mr-0
                                                "
                                            >
                                                <span class=""
                                                    >{{$t('product.price')}} :
                                                    {{
                                                        product.price_after_discount
                                                    }}</span
                                                >
                                            </p>

                                            <p
                                                class="old-price lead"
                                                v-if="
                                                    product.discount_int !== null
                                                
                                                "
                                            >
                                                <del class="text-danger"
                                                    >{{$t('product.price')}} :
                                                    {{ product.price }}</del
                                                >
                                            </p>
                                        </div>
                                    </template>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    <div class="product-list-action">
                        <div class="product-action2 d-flex">
                            
                                <!-- <span>quick view</span> -->
                            </button>
                            <!-- <nuxt-link
                                to="/shop/wishlist"
                                class="
                                    btn-product btn-wishlist
                                    added-to-wishlist
                                "
                                v-if="isInWishlist(product)"
                                key="inWishlist"
                            >
                                <span>wishlist</span>
                            </nuxt-link>
                            <a
                                href="javascript:;"
                                class="btn-product btn-wishlist"
                                @click.prevent="
                                    addToWishlist({ product: product })
                                "
                                v-else
                                key="notInWishlist"
                            >
                                <span>wishlist</span>
                            </a> -->
                            <button
                                title="Add to cart"
                                class="btn-product btn-cart"
                                :class="{
                                    'btn-disabled': !canAddToCart(product, 1),
                                }"
                                @click.prevent="addToCart({ product: product })"
                            >
                                <!-- <span>add to cart</span> -->
                            </button>

                            <nuxt-link
                                :title="$t('product.inWishlist')"
                                :to="localePath('/shop/wishlist')"
                                class="
                                    btn-product btn-wishlist
                                    added-to-wishlist
                                "
                                v-if="isInWishlist(product)"
                                key="inWishlist"
                            >
                                <!-- <span>In wishlist</span> -->
                            </nuxt-link>
                            <a
                                :title="$t('product.addToWishlist')"
                                class="btn-product btn-wishlist"
                                @click.prevent="
                                    addToWishlist({ product: product })
                                "
                                v-else
                                key="notInWishlist"
                            >
                                <!-- <span>add to wishlist</span> -->
                            </a>
                        </div>

                        <!-- <nuxt-link
                            :to="'/product/default/' + product.slug"
                            class="btn-product btn-cart btn-select"
                            v-if="product.length > 0"
                        >
                            <span>select options</span>
                        </nuxt-link> -->
                    </div>
                </div>
            </div>
            <br />
        </div>
    </div>
</template>

<script>
import { baseUrl } from '~/repositories/repository';
import { mapGetters, mapActions } from 'vuex';
import axios from 'axios';
export default {
    props: ['product'],
    data() {
        return {
            baseUrl: baseUrl,
            maxPrice: 0,
            minPrice: 99999,
        };
    },
    computed: {
        ...mapGetters('cart', ['canAddToCart']),
        ...mapGetters('wishlist', ['isInWishlist']),
        ...mapGetters('compare', ['isInCompare']),
        gridClass: function () {
            if (this.type === 'list' || this.type === '2cols') return 'col-6';
            if (this.type === '3cols') return 'col-6 col-md-4 col-lg-4';
            if (this.type === '4cols')
                return 'col-6 col-md-4 col-lg-4 col-xl-3';
        },
    },

    created: function () {
        let min = this.minPrice;
        let max = this.maxPrice;
        // this.product.map((item) => {
        //     if (min > item.price) min = item.price;
        //     if (max < item.price) max = item.price;
        // }, []);

        if (this.product.length == 0) {
            min = this.product.sale_price
                ? this.product.sale_price
                : this.product.price;
            max = this.product.price;
        }

        this.minPrice = min;
        this.maxPrice = max;
    },
    methods: {
        ...mapActions('cart', ['addToCart']),
        ...mapActions('wishlist', ['addToWishlist']),
        ...mapActions('compare', ['addToCompare']),
        quickView: function () {
            this.$modal.show(
                () => import('~/components/elements/modals/QuickViewModal'),
                {
                    product: this.product,
                },
                { width: '1030', height: 'auto', adaptive: true }
            );
        },
        // addToMyWishlist(product) {
        //     let token = sessionStorage.getItem('token');
        //     axios
        //         .post(
        //             `${baseUrl}/wish/add`,

        //             {
        //                 api_token: token,
        //                 product_id: this.product.id,
        //             }
        //         )
        //         .then((resp) => {
        //             console.log(resp);
        //             return this.$store.state.wishlistQty
        //         })
        //         .catch((err) => {
        //             console.log(err);
        //         });
        // },
    },
};
</script>

<style lang="scss" scoped>
.product-price {
    display: block;
}
.product-body {
    width: 100% !important;
}
button,
.btn-product {
    border: none !important;
    padding: 15px;
    box-shadow: 0px 0px 7px 3px #e3e3e3 !important;
    border-radius: 50%;
    line-height: 10px;
    width: 20px;
    height: 20px;
    margin: 0 15px;
}
button:before,
.btn-product:before {
    padding: 0;
    margin: 0;
}
</style>