<template>
    <div>
        <!-- <div :class="`loader ${spinner}`">
                <div class="loader__bar"></div>
                <div class="loader__bar"></div>
                <div class="loader__bar"></div>
                <div class="loader__bar"></div>
                <div class="loader__bar"></div>
                <div class="loader__ball"></div>
            </div> -->
        <!-- <div id="spinner" :class="`spinner ${spinner}`">
            <div class="d-flex justify-content-center align-items-center h-100">
                <div class="stage" style="width: 200px">
                    <div class="logo-loader">
                       
                        <div class="loading">
  <div class="boxLoading"></div>
</div>
                    </div>
                    <div class="loading text-center text-white my-5 w-100 mt-9">
                        <div class="" v-if="$i18n.locale == 'en'">
                            <h4>Loading , Please wait</h4>
                        </div>
                        <div class="" v-else>
                            <h4>برجاء الانتظار من فضلك</h4>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->

        <div class="holder">
            <LazyHydrate v-if="firstLoaded">
                <Allfirst />
            </LazyHydrate>
            <LazyHydrate v-if="secondLoaded">
                <Allsecond />
            </LazyHydrate>
            <LazyHydrate v-if="thirdLoaded">
                <Allthird />
            </LazyHydrate>
            <LazyHydrate v-if="fourthLoaded">
                <Allfourth />
            </LazyHydrate>
            <LazyHydrate v-if="fifthLoaded">
                <Allfifth />
            </LazyHydrate>
        </div>
        <!-- <div class="holder">
            <LazyHydrate when-visible data-aos="fade-left" data-aos-duration="1000" data-aos-delay="50" v-if="firstLoaded" >
                <Allfirst />
            </LazyHydrate>
            <LazyHydrate when-visible data-aos="fade-left" data-aos-duration="1000" data-aos-delay="100" v-if="secondLoaded">
                <Allsecond />
            </LazyHydrate>
            <LazyHydrate when-visible data-aos="fade-left" data-aos-duration="1000" data-aos-delay="150" v-if="thirdLoaded">
                <Allthird />
            </LazyHydrate>
            <LazyHydrate when-visible data-aos="fade-left" data-aos-duration="1000" data-aos-delay="200" v-if="fourthLoaded">
                <Allfourth />
            </LazyHydrate>
            <LazyHydrate when-visible data-aos="fade-left" data-aos-duration="1000" data-aos-delay="250" v-if="fifthLoaded">
                <Allfifth />
            </LazyHydrate>
        </div> -->
    </div>
</template>

<script>
import LazyHydrate from 'vue-lazy-hydration';

export default {
    data: function () {
        return {
            spinner: 'd-block',
            firstLoaded: false,
            secondLoaded: false,
            thirdLoaded: false,
            fourthLoaded: false,
            fifthLoaded: false,
            svgLoded: true,
        };
    },
    props: ['product'],
    mounted() {
        setTimeout(() => {
            this.spinner = 'd-none';
        }, 6500);
        // window.addEventListener('load', () => {
        //     this.spinner = 'd-none';
        // });
        // document.addEventListener('DOMContentLoaded', function () {
        //     this.spinner = 'd-none';
        // });
        setTimeout(() => {
            this.firstLoaded = true;
        }, 3600);
        setTimeout(() => {
            this.secondLoaded = true;
        }, 4000);
        setTimeout(() => {
            this.thirdLoaded = true;
        }, 4200);
        setTimeout(() => {
            this.fourthLoaded = true;
        }, 4500);
        setTimeout(() => {
            this.fifthLoaded = true;
        }, 5000);
        setTimeout(() => {
            this.setScroll();
            this.myScroll();
        }, 2000);
    },
    components: {
        LazyHydrate,
        Allfirst: () =>
            import('~/components/alf/homeComponents/first/index.vue'),
        Allsecond: () =>
            import('~/components/alf/homeComponents/second/index.vue'),
        Allthird: () =>
            import('~/components/alf/homeComponents/third/index.vue'),
        Allfourth: () =>
            import('~/components/alf/homeComponents/fourth/index.vue'),
        Allfifth: () =>
            import('~/components/alf/homeComponents/fifth/index.vue'),
    },
    methods: {
        setScroll() {
            document.addEventListener('DOMContentLoaded', function (event) {
                var scrollpos = sessionStorage.getItem('scrollpos') + 200;
                if (scrollpos) window.scrollTo(0, scrollpos);
            });
            window.onbeforeunload = function (e) {
                sessionStorage.setItem('scrollpos', window.scrollY);
            };
        },
        myScroll() {
            var scrollpos = sessionStorage.getItem('scrollpos');
            window.scrollTo({
                top: scrollpos,
                behavior: 'smooth',
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.last {
    .swiper-pagination-bullet.swiper-pagination-bullet-active {
        background: #fff !important;
        border-color: #fff !important;
    }
}
// Variables
$bar-color: #fff;
$ball-color: #fff;
$bg-color: #77c1e6;
$bg-color: #779ba7;
.spinner {
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: fixed;
    z-index: 999999;
    background-color: $bg-color;
}
.svgHolder {
    position: fixed;
    inset: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999999;
    background: $bg-color;
    transition: all 1s ease-in-out;
}

.loader {
    position: relative;
    width: 75px;
    height: 100px;

    &__bar {
        position: absolute;
        bottom: 0;
        width: 10px;
        height: 50%;
        background: $bar-color;
        transform-origin: center bottom;
        box-shadow: 1px 1px 0 rgba(0, 0, 0, 0.2);

        @for $i from 1 through 5 {
            &:nth-child(#{$i}) {
                left: ($i - 1) * 15px;
                transform: scale(1, $i * 0.2);
                animation: barUp#{$i} 4s infinite;
            }
        }
    }

    &__ball {
        position: absolute;
        bottom: 10px;
        left: 0;
        width: 10px;
        height: 10px;
        background: $ball-color;
        border-radius: 50%;
        animation: ball 4s infinite;
    }
}

@keyframes ball {
    0% {
        transform: translate(0, 0);
    }
    5% {
        transform: translate(8px, -14px);
    }
    10% {
        transform: translate(15px, -10px);
    }
    17% {
        transform: translate(23px, -24px);
    }
    20% {
        transform: translate(30px, -20px);
    }
    27% {
        transform: translate(38px, -34px);
    }
    30% {
        transform: translate(45px, -30px);
    }
    37% {
        transform: translate(53px, -44px);
    }
    40% {
        transform: translate(60px, -40px);
    }
    50% {
        transform: translate(60px, 0);
    }
    57% {
        transform: translate(53px, -14px);
    }
    60% {
        transform: translate(45px, -10px);
    }
    67% {
        transform: translate(37px, -24px);
    }
    70% {
        transform: translate(30px, -20px);
    }
    77% {
        transform: translate(22px, -34px);
    }
    80% {
        transform: translate(15px, -30px);
    }
    87% {
        transform: translate(7px, -44px);
    }
    90% {
        transform: translate(0, -40px);
    }
    100% {
        transform: translate(0, 0);
    }
}

@keyframes barUp1 {
    0% {
        transform: scale(1, 0.2);
    }
    40% {
        transform: scale(1, 0.2);
    }
    50% {
        transform: scale(1, 1);
    }
    90% {
        transform: scale(1, 1);
    }
    100% {
        transform: scale(1, 0.2);
    }
}
@keyframes barUp2 {
    0% {
        transform: scale(1, 0.4);
    }
    40% {
        transform: scale(1, 0.4);
    }
    50% {
        transform: scale(1, 0.8);
    }
    90% {
        transform: scale(1, 0.8);
    }
    100% {
        transform: scale(1, 0.4);
    }
}
@keyframes barUp3 {
    0% {
        transform: scale(1, 0.6);
    }
    100% {
        transform: scale(1, 0.6);
    }
}
@keyframes barUp4 {
    0% {
        transform: scale(1, 0.8);
    }
    40% {
        transform: scale(1, 0.8);
    }
    50% {
        transform: scale(1, 0.4);
    }
    90% {
        transform: scale(1, 0.4);
    }
    100% {
        transform: scale(1, 0.8);
    }
}
@keyframes barUp5 {
    0% {
        transform: scale(1, 1);
    }
    40% {
        transform: scale(1, 1);
    }
    50% {
        transform: scale(1, 0.2);
    }
    90% {
        transform: scale(1, 0.2);
    }
    100% {
        transform: scale(1, 1);
    }
}
.pl {
    display: block;
    width: 6.25em;
    height: 6.25em;
}
.pl__ring,
.pl__ball {
    animation: ring 2s ease-out infinite;
}
.pl__ball {
    animation-name: ball;
}

/* Dark theme  */
@media (prefers-color-scheme: dark) {
    :root {
        --bg: hsl(var(--hue), 10%, 10%);
        --fg: hsl(var(--hue), 10%, 90%);
    }
}

/* Animation */
@keyframes ring {
    from {
        stroke-dasharray: 0 257 0 0 1 0 0 258;
    }
    25% {
        stroke-dasharray: 0 0 0 0 257 0 258 0;
    }
    50%,
    to {
        stroke-dasharray: 0 0 0 0 0 515 0 0;
    }
}
@keyframes ball {
    from,
    50% {
        animation-timing-function: ease-in;
        stroke-dashoffset: 1;
    }
    64% {
        animation-timing-function: ease-in;
        stroke-dashoffset: -109;
    }
    78% {
        animation-timing-function: ease-in;
        stroke-dashoffset: -145;
    }
    92% {
        animation-timing-function: ease-in;
        stroke-dashoffset: -157;
    }
    57%,
    71%,
    85%,
    99%,
    to {
        animation-timing-function: ease-out;
        stroke-dashoffset: -163;
    }
}
.loading{
//   position:fixed;
  width:100%;
  height:100%;
//   background-color:#fff;
  z-index:999;
  h4{
      font-size: 28px;
    color: #fff;
  }
  .boxLoading{
    width:50px;
    height:50px;
    margin:auto;
    position:absolute;
    left:0;
    top:0;
    right:0;
    bottom:0;
    &::before{
      content:'';
      width:70px;
      height:30px;
      background:#000;
      opacity:0.1;
      position:absolute;
      top:59px;
      left:0;
      border-radius:50%;
      animation:shadow 0.5s linear infinite;
    }
    &::after{
      content:'';
      width:70px;
      height:70px;
      background:#fcb932;
      position:absolute;
      top:0;
      left:0;
      border-radius:3px;
      animation:animate 0.5s linear infinite;
    }
  }
}
// 方块动画
@keyframes animate{
  17%{
    border-bottom-right-radius:3px;
  }
  25%{
    transform:translateY(9px) rotate(22.5deg);
  }
  50%{
    transform:translateY(18px) rotate(45deg);
      border-bottom-right-radius:40px;
  }
  75%{
    transform:translateY(9px) rotate(67.5deg);
  }
  100%{
    transform:translateY(0) rotate(90deg);
  }
}
// 底部框动画
@keyframes shadow{
  0%,100%{
    transform:scale(1,1)
  }
  50%{
    transform:scale(1.2,1)
  }
}
.logo-loader {
    position: relative;
    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.467);
        animation: AA 3s alternate 0.1s infinite forwards;
        opacity: 1;
    }
}
// @keyframes svgAnimate {
//   to {
//     stroke-dashoffset: 0;
//   }
// }
@keyframes AA {
    to {
        height: 0%;
        opacity: 0;
    }
}
</style>