<template>
    <div>
        <!-- <First /> -->

        <Second />

        <Third />

        <!-- <Fourth /> -->

        <Fifth />
    </div>
</template>

<script>
import LazyHydrate from 'vue-lazy-hydration';

export default {
    components: {
        LazyHydrate,
        // First: () =>
        //     import('~/components/alf/homeComponents/fourth/fourthfirst.vue'),
        Second: () =>
            import('~/components/alf/homeComponents/fourth/fourthsecond.vue'),
        Third: () =>
            import('~/components/alf/homeComponents/fourth/fourththird.vue'),
        // Fourth: () =>
        //     import('~/components/alf/homeComponents/fourth/fourthfourth.vue'),
        Fifth: () =>
            import('~/components/alf/homeComponents/fourth/fourthfifth.vue'),
    },
};
</script>

<style>
</style>