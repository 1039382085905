var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "sidebar-shop",
      class: _vm.isSidebar ? "sidebar-filter" : "sidebar"
    },
    [
      _c("div", { class: { "sidebar-filter-wrapper": _vm.isSidebar } }, [
        _c(
          "div",
          { staticClass: "widget widget-clean" },
          [
            _c("label", [_vm._v("Filters:")]),
            _vm._v(" "),
            _c(
              "nuxt-link",
              {
                staticClass: "sidebar-filter-clear",
                attrs: { to: _vm.$route.path }
              },
              [_vm._v("Clean All")]
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "widget widget-collapsible" },
          [
            _c("h3", { staticClass: "widget-title" }, [
              _c(
                "a",
                {
                  class: { collapsed: !_vm.toggleStates[0] },
                  attrs: { href: "#widget-1" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.toggleSlide(0)
                    }
                  }
                },
                [_vm._v("Category")]
              )
            ]),
            _vm._v(" "),
            _c(
              "vue-slide-toggle",
              {
                staticClass: "show",
                attrs: { open: _vm.toggleStates[0], duration: 200 }
              },
              [
                _c("div", { staticClass: "widget-body" }, [
                  _c(
                    "div",
                    { staticClass: "filter-items filter-items-count" },
                    _vm._l(_vm.filterData.categories, function(
                      category,
                      index
                    ) {
                      return _c(
                        "div",
                        { key: index, staticClass: "filter-item" },
                        [
                          _c(
                            "nuxt-link",
                            {
                              class: { active: _vm.categorySelected(category) },
                              attrs: {
                                to: {
                                  path: _vm.$route.path,
                                  query: { category: category.slug }
                                }
                              }
                            },
                            [_vm._v(_vm._s(category.name))]
                          ),
                          _vm._v(" "),
                          _c("span", { staticClass: "item-count" }, [
                            _vm._v(_vm._s(category.count))
                          ])
                        ],
                        1
                      )
                    }),
                    0
                  )
                ])
              ]
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "widget widget-collapsible" },
          [
            _c("h3", { staticClass: "widget-title" }, [
              _c(
                "a",
                {
                  class: { collapsed: !_vm.toggleStates[3] },
                  attrs: { href: "#widget-4" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.toggleSlide(3)
                    }
                  }
                },
                [_vm._v("Brand")]
              )
            ]),
            _vm._v(" "),
            _c(
              "vue-slide-toggle",
              {
                staticClass: "show",
                attrs: { open: _vm.toggleStates[3], duration: 200 }
              },
              [
                _c("div", { staticClass: "widget-body" }, [
                  _c(
                    "div",
                    { staticClass: "filter-items" },
                    _vm._l(_vm.filterData.brands, function(item, index) {
                      return _c(
                        "div",
                        { key: index, staticClass: "filter-item" },
                        [
                          _c(
                            "div",
                            { staticClass: "custom-control custom-checkbox" },
                            [
                              _c("input", {
                                staticClass: "custom-control-input",
                                attrs: {
                                  type: "checkbox",
                                  id: "brand-" + index
                                },
                                domProps: { checked: _vm.brandChecked(item) },
                                on: {
                                  click: function($event) {
                                    return _vm.setBrandFilter(item)
                                  }
                                }
                              }),
                              _vm._v(" "),
                              _c(
                                "label",
                                {
                                  staticClass: "custom-control-label",
                                  attrs: { for: "brand-" + index }
                                },
                                [_vm._v(_vm._s(item.brand))]
                              )
                            ]
                          )
                        ]
                      )
                    }),
                    0
                  )
                ])
              ]
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "widget widget-collapsible" },
          [
            _c("h3", { staticClass: "widget-title" }, [
              _c(
                "a",
                {
                  class: { collapsed: !_vm.toggleStates[4] },
                  attrs: { href: "#widget-5" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.toggleSlide(4)
                    }
                  }
                },
                [_vm._v("Price")]
              )
            ]),
            _vm._v(" "),
            _c(
              "vue-slide-toggle",
              {
                staticClass: "show",
                attrs: { open: _vm.toggleStates[4], duration: 200 }
              },
              [
                _c("div", { staticClass: "widget-body" }, [
                  _c(
                    "div",
                    { staticClass: "filter-items" },
                    _vm._l(_vm.filterData.prices, function(price, index) {
                      return _c(
                        "div",
                        { key: index, staticClass: "filter-item" },
                        [
                          _c(
                            "div",
                            { staticClass: "custom-control custom-radio" },
                            [
                              _c("input", {
                                staticClass: "custom-control-input",
                                attrs: {
                                  type: "radio",
                                  id: "price-" + index,
                                  name: "filter-price"
                                },
                                domProps: {
                                  checked: _vm.priceChecked(
                                    price.min,
                                    price.max
                                  )
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.setPriceFilter(
                                      price.min,
                                      price.max
                                    )
                                  }
                                }
                              }),
                              _vm._v(" "),
                              _c(
                                "label",
                                {
                                  staticClass: "custom-control-label",
                                  attrs: { for: "price-" + index }
                                },
                                [_vm._v(_vm._s(price.name))]
                              )
                            ]
                          )
                        ]
                      )
                    }),
                    0
                  )
                ])
              ]
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "widget widget-collapsible" },
          [
            _c("h3", { staticClass: "widget-title" }, [
              _c(
                "a",
                {
                  class: { collapsed: !_vm.toggleStates[1] },
                  attrs: { href: "#widget-2" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.toggleSlide(1)
                    }
                  }
                },
                [_vm._v("Customer Rating")]
              )
            ]),
            _vm._v(" "),
            _c(
              "vue-slide-toggle",
              {
                staticClass: "show",
                attrs: { open: _vm.toggleStates[1], duration: 200 }
              },
              [
                _c("div", { staticClass: "widget-body" }, [
                  _c("div", { staticClass: "filter-items" }, [
                    _c("div", { staticClass: "filter-item" }, [
                      _c(
                        "div",
                        { staticClass: "custom-control custom-checkbox" },
                        [
                          _c("input", {
                            staticClass: "custom-control-input",
                            attrs: { type: "checkbox", id: "cus-rating-1" },
                            domProps: { checked: _vm.ratingChecked("5") },
                            on: {
                              change: function($event) {
                                return _vm.setRatingFilter("5")
                              }
                            }
                          }),
                          _vm._v(" "),
                          _c(
                            "label",
                            {
                              staticClass: "custom-control-label",
                              attrs: { for: "cus-rating-1" }
                            },
                            [
                              _c("span", { staticClass: "ratings-container" }, [
                                _c("span", { staticClass: "ratings" }, [
                                  _c("span", {
                                    staticClass: "ratings-val",
                                    staticStyle: { width: "100%" }
                                  })
                                ]),
                                _vm._v(" "),
                                _c("span", { staticClass: "ratings-text" })
                              ])
                            ]
                          )
                        ]
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "filter-item" }, [
                      _c(
                        "div",
                        { staticClass: "custom-control custom-checkbox" },
                        [
                          _c("input", {
                            staticClass: "custom-control-input",
                            attrs: { type: "checkbox", id: "cus-rating-2" },
                            domProps: { checked: _vm.ratingChecked("4") },
                            on: {
                              change: function($event) {
                                return _vm.setRatingFilter("4")
                              }
                            }
                          }),
                          _vm._v(" "),
                          _c(
                            "label",
                            {
                              staticClass: "custom-control-label",
                              attrs: { for: "cus-rating-2" }
                            },
                            [
                              _c("span", { staticClass: "ratings-container" }, [
                                _c("span", { staticClass: "ratings" }, [
                                  _c("span", {
                                    staticClass: "ratings-val",
                                    staticStyle: { width: "80%" }
                                  })
                                ]),
                                _vm._v(" "),
                                _c("span", { staticClass: "ratings-text" })
                              ])
                            ]
                          )
                        ]
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "filter-item" }, [
                      _c(
                        "div",
                        { staticClass: "custom-control custom-checkbox" },
                        [
                          _c("input", {
                            staticClass: "custom-control-input",
                            attrs: { type: "checkbox", id: "cus-rating-3" },
                            domProps: { checked: _vm.ratingChecked("3") },
                            on: {
                              change: function($event) {
                                return _vm.setRatingFilter("3")
                              }
                            }
                          }),
                          _vm._v(" "),
                          _c(
                            "label",
                            {
                              staticClass: "custom-control-label",
                              attrs: { for: "cus-rating-3" }
                            },
                            [
                              _c("span", { staticClass: "ratings-container" }, [
                                _c("span", { staticClass: "ratings" }, [
                                  _c("span", {
                                    staticClass: "ratings-val",
                                    staticStyle: { width: "60%" }
                                  })
                                ]),
                                _vm._v(" "),
                                _c("span", { staticClass: "ratings-text" })
                              ])
                            ]
                          )
                        ]
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "filter-item" }, [
                      _c(
                        "div",
                        { staticClass: "custom-control custom-checkbox" },
                        [
                          _c("input", {
                            staticClass: "custom-control-input",
                            attrs: { type: "checkbox", id: "cus-rating-4" },
                            domProps: { checked: _vm.ratingChecked("2") },
                            on: {
                              change: function($event) {
                                return _vm.setRatingFilter("2")
                              }
                            }
                          }),
                          _vm._v(" "),
                          _c(
                            "label",
                            {
                              staticClass: "custom-control-label",
                              attrs: { for: "cus-rating-4" }
                            },
                            [
                              _c("span", { staticClass: "ratings-container" }, [
                                _c("span", { staticClass: "ratings" }, [
                                  _c("span", {
                                    staticClass: "ratings-val",
                                    staticStyle: { width: "40%" }
                                  })
                                ]),
                                _vm._v(" "),
                                _c("span", { staticClass: "ratings-text" })
                              ])
                            ]
                          )
                        ]
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "filter-item" }, [
                      _c(
                        "div",
                        { staticClass: "custom-control custom-checkbox" },
                        [
                          _c("input", {
                            staticClass: "custom-control-input",
                            attrs: { type: "checkbox", id: "cus-rating-5" },
                            domProps: { checked: _vm.ratingChecked("1") },
                            on: {
                              change: function($event) {
                                return _vm.setRatingFilter("1")
                              }
                            }
                          }),
                          _vm._v(" "),
                          _c(
                            "label",
                            {
                              staticClass: "custom-control-label",
                              attrs: { for: "cus-rating-5" }
                            },
                            [
                              _c("span", { staticClass: "ratings-container" }, [
                                _c("span", { staticClass: "ratings" }, [
                                  _c("span", {
                                    staticClass: "ratings-val",
                                    staticStyle: { width: "20%" }
                                  })
                                ]),
                                _vm._v(" "),
                                _c("span", { staticClass: "ratings-text" })
                              ])
                            ]
                          )
                        ]
                      )
                    ])
                  ])
                ])
              ]
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "widget widget-collapsible" },
          [
            _c("h3", { staticClass: "widget-title" }, [
              _c(
                "a",
                {
                  class: { collapsed: !_vm.toggleStates[2] },
                  attrs: { href: "#widget-3" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.toggleSlide(2)
                    }
                  }
                },
                [_vm._v("Colour")]
              )
            ]),
            _vm._v(" "),
            _c(
              "vue-slide-toggle",
              {
                staticClass: "show",
                attrs: { open: _vm.toggleStates[2], duration: 200 }
              },
              [
                _c("div", { staticClass: "widget-body" }, [
                  _c(
                    "div",
                    { staticClass: "filter-colors" },
                    _vm._l(_vm.filterData.colors, function(item, index) {
                      return _c(
                        "nuxt-link",
                        {
                          key: index,
                          class: { selected: _vm.colorSelected(item) },
                          style: { "background-color": item.color },
                          attrs: { to: _vm.getColorUrl(item) }
                        },
                        [
                          _c("span", { staticClass: "sr-only" }, [
                            _vm._v(_vm._s(item.color_name))
                          ])
                        ]
                      )
                    }),
                    1
                  )
                ])
              ]
            )
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }