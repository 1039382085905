<template>
    <div class="product product-7 text-center">
        <figure class="product-media">
            <span class="product-label label-new" v-if="product.new">New</span>
            <!-- <span class="product-label label-sale" v-if="product.sale_price">Sale</span> -->
            <span class="product-label label-sale" v-if="product.discount"
                >Sale</span
            >
            <span class="product-label label-top" v-if="product.top">Top</span>
            <span class="product-label label-out" v-if="product.stock === 0"
                >Out Of Stock</span
            >

            <nuxt-link :to="'/product/default/' + product.id">
                <img
                    v-lazy="product.main_image"
                    alt="Product"
                    class="product-image"
                />
                <!-- <img
                    v-lazy="`${baseUrl}${product.sm_pictures[1].url}`"
                    alt="Product"
                    :width="product.sm_pictures[1].width"
                    :height="product.sm_pictures[1].height"
                    class="product-image-hover"
                    v-if="product.sm_pictures[1]"
                /> -->
            </nuxt-link>

            <div class="product-action-vertical" v-if="product.stock !== 0">
                <nuxt-link
                    to="/shop/wishlist"
                    class="btn-product-icon btn-wishlist added-to-wishlist"
                    v-if="isInWishlist(product)"
                    key="inWishlist"
                >
                    <span>go to wishlist</span>
                </nuxt-link>
                <a
                    href="javascript:;"
                    class="btn-product-icon btn-wishlist"
                    @click.prevent="addToWishlist({ product: product })"
                    v-else
                    key="notInWishlist"
                >
                    <span>{{$t('product.addToWishlist')}}</span>
                </a>
                <a
                    href="javascript:;"
                    class="btn-product-icon btn-quickview"
                    title="Quick view"
                    @click.prevent="quickView({ product: product })"
                >
                    <span>Quick view</span>
                </a>
                <!-- <a
                    href="javascript:;"
                    class="btn-product-icon btn-compare added-to-compare"
                    title="Compare"
                    v-if="isInCompare(product)"
                    key="inCompare"
                >
                    <span>Compare</span>
                </a> -->
                <!-- <a
                    href="#"
                    class="btn-product-icon btn-compare"
                    title="Compare"
                    @click.prevent="addToCompare({product: product})"
                    v-else
                    key="notInCompare"
                >
                    <span>Compare</span>
                </a> -->
            </div>

            <div class="product-action" v-if="product.stock !== 0">
                <nuxt-link
                    :to="'/product/default/' + product.id"
                    class="btn btn-product btn-cart btn-select"
                    v-if="product.length > 0"
                >
                    <span>select options</span>
                </nuxt-link>
                <a
                    href="javascript:;"
                    class="btn btn-product btn-cart"
                    @click.prevent="addToCart({ product: product })"
                    v-else
                >
                    <span>add to cart</span>
                </a>
            </div>
        </figure>

        <div class="product-body">
            <!-- <div class="product-cat">
                <span v-for="(cat, index) of product.category" :key="index">
                    <nuxt-link
                        :to="{path: '/shop/sidebar/list', query: {category: cat.slug}}"
                    >{{ cat.name }}</nuxt-link>
                    {{ index < product.category.length - 1 ? ',' : '' }}
                </span>
            </div> -->
            <h3 class="product-title">
                <nuxt-link :to="'/product/default/' + product.id">{{
                    product.name
                }}</nuxt-link>
            </h3>

            <!-- <div class="product-price" v-if="product.stock==0" key="outPrice">
                <span class="out-price">${{ product.price }}</span>
            </div>

            <template v-else>
                <div class="product-price" v-if="minPrice == maxPrice">${{ minPrice.toFixed(2) }}</div>
                <template v-else>
                    <div class="product-price" v-if="product.length == 0">
                        <span class="new-price">${{ minPrice.toFixed(2) }}</span>
                        <span class="old-price">${{ maxPrice.toFixed(2) }}</span>
                    </div>
                    <div
                        class="product-price"
                        v-else
                    >${{minPrice.toFixed(2)}}&ndash;${{maxPrice.toFixed(2)}}</div>
                </template>
            </template> -->

            <div class="product-price" v-if="product.price_after_discount">
                <span class="out-price"
                    >{{ product.price_after_discount }}</span
                >
            </div>
            <div class="product-price" v-else>
                <span class="out-price"
                    >{{ product.price }}</span
                >
            </div>

            <div class="ratings-container">
                <div class="ratings">
                    <div
                        class="ratings-val"
                        :style="{ width: product.ratings * 20 + '%' }"
                    ></div>
                    <span class="tooltip-text">{{
                        product.ratings
                    }}</span>
                </div>
                <span class="ratings-text"
                    >( {{ product.review }} Reviews )</span
                >
            </div>
            <!-- <div class="product-nav product-nav-dots" v-if="product.length > 0">
                <div class="row no-gutters">
                    <a
                        href="javascript:;"
                        :style="{ 'background-color': item.color }"
                        v-for="(item, index) in product.variants"
                        :key="index"
                    >
                        <span class="sr-only">Color name</span>
                    </a>
                </div>
            </div> -->
        </div>
    </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import { baseUrl } from '~/repositories/repository';
export default {
    props: {
        product:Object
    },
    data: function () {
        return {
            baseUrl: baseUrl,
            maxPrice: 0,
            minPrice: 99999,
        };
    },
    computed: {
        ...mapGetters('cart', ['canAddToCart']),
        ...mapGetters('wishlist', ['isInWishlist']),
        ...mapGetters('compare', ['isInCompare']),
    },

    created: function () {
        let min = this.minPrice;
        let max = this.maxPrice;
        // this.product.map((item) => {
        //     if (min > item.price) min = item.price;
        //     if (max < item.price) max = item.price;
        // }, []);

        if (this.product.length == 0) {
            min = this.product.sale_price
                ? this.product.sale_price
                : this.product.price;
            max = this.product.price;
        }

        this.minPrice = min;
        this.maxPrice = max;
    },
    methods: {
        ...mapActions('cart', ['addToCart']),
        ...mapActions('wishlist', ['addToWishlist']),
        ...mapActions('compare', ['addToCompare']),
        quickView: function () {
            this.$modal.show(
                () => import('~/components/elements/modals/QuickViewModal'),
                {
                    product: this.product,
                },
                { width: '1030', height: 'auto', adaptive: true }
            );
        },
    },
};
</script>