var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "video-modal bg-white m-3", attrs: { id: "video-modal" } },
    [
      _c(
        "button",
        {
          staticClass: "close",
          attrs: { type: "button" },
          on: {
            click: function($event) {
              return _vm.$emit("close")
            }
          }
        },
        [_vm._m(0)]
      ),
      _vm._v(" "),
      _c("iframe", {
        staticClass: "mfp-iframe",
        attrs: {
          src: "//www.youtube.com/embed/vBPgmASQ1A0?autoplay=1",
          frameborder: "0",
          title: "youtube"
        }
      })
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", [_c("i", { staticClass: "icon-close" })])
  }
]
render._withStripped = true

export { render, staticRenderFns }