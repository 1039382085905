var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: _vm.$i18n.locale },
    [
      _c(
        "div",
        { staticClass: "page-wrapper" },
        [
          _c("header-home"),
          _vm._v(" "),
          _c("nuxt"),
          _vm._v(" "),
          _c("footer-default"),
          _vm._v(" "),
          _c(
            "button",
            {
              ref: "scrollTop",
              attrs: { id: "scroll-top", title: "Back to Top" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.scrollTop.apply(null, arguments)
                }
              }
            },
            [_c("i", { staticClass: "icon-arrow-up" })]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("div", {
        staticClass: "mobile-menu-overlay",
        on: { click: _vm.hideMobileMenu }
      }),
      _vm._v(" "),
      _c("mobile-menu")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }