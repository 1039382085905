var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "aside",
    { staticClass: "sidebar-shop sidebar-filter sidebar-filter-banner" },
    [
      _c("div", { staticClass: "sidebar-filter-wrapper" }, [
        _c("div", { staticClass: "widget widget-clean" }, [
          _c("button", { on: { click: _vm.closeSidebar } }, [
            _c("i", { staticClass: "icon-close" }),
            _vm._v("Filters\n            ")
          ]),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "sidebar-filter-clear",
              attrs: { href: "#" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.cleanAllFilter.apply(null, arguments)
                }
              }
            },
            [_vm._v("Clean All")]
          )
        ]),
        _vm._v(" "),
        _vm._m(0)
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "widget" }, [
      _c("h3", { staticClass: "widget-title" }, [_vm._v("Browse Category")]),
      _vm._v(" "),
      _c("div", { staticClass: "widget-body" }, [
        _c("div", { staticClass: "filter-items filter-items-count" }, [
          _c("div", { staticClass: "filter-item" }, [
            _c("div", { staticClass: "custom-control custom-checkbox" }, [
              _c("input", {
                staticClass: "custom-control-input",
                attrs: { type: "checkbox", id: "cat-1" }
              }),
              _vm._v(" "),
              _c(
                "label",
                {
                  staticClass: "custom-control-label",
                  attrs: { for: "cat-1" }
                },
                [_vm._v("Women")]
              )
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "item-count" }, [_vm._v("3")])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "filter-item" }, [
            _c("div", { staticClass: "custom-control custom-checkbox" }, [
              _c("input", {
                staticClass: "custom-control-input",
                attrs: { type: "checkbox", id: "cat-2" }
              }),
              _vm._v(" "),
              _c(
                "label",
                {
                  staticClass: "custom-control-label",
                  attrs: { for: "cat-2" }
                },
                [_vm._v("Men")]
              )
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "item-count" }, [_vm._v("0")])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "filter-item" }, [
            _c("div", { staticClass: "custom-control custom-checkbox" }, [
              _c("input", {
                staticClass: "custom-control-input",
                attrs: { type: "checkbox", id: "cat-3" }
              }),
              _vm._v(" "),
              _c(
                "label",
                {
                  staticClass: "custom-control-label",
                  attrs: { for: "cat-3" }
                },
                [_vm._v("Holiday Shop")]
              )
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "item-count" }, [_vm._v("0")])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "filter-item" }, [
            _c("div", { staticClass: "custom-control custom-checkbox" }, [
              _c("input", {
                staticClass: "custom-control-input",
                attrs: { type: "checkbox", id: "cat-4" }
              }),
              _vm._v(" "),
              _c(
                "label",
                {
                  staticClass: "custom-control-label",
                  attrs: { for: "cat-4" }
                },
                [_vm._v("Gifts")]
              )
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "item-count" }, [_vm._v("0")])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "filter-item" }, [
            _c("div", { staticClass: "custom-control custom-checkbox" }, [
              _c("input", {
                staticClass: "custom-control-input",
                attrs: { type: "checkbox", id: "cat-5" }
              }),
              _vm._v(" "),
              _c(
                "label",
                {
                  staticClass: "custom-control-label",
                  attrs: { for: "cat-5" }
                },
                [_vm._v("Homeware")]
              )
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "item-count" }, [_vm._v("0")])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "filter-item" }, [
            _c("div", { staticClass: "custom-control custom-checkbox" }, [
              _c("input", {
                staticClass: "custom-control-input",
                attrs: { type: "checkbox", id: "cat-6", checked: "checked" }
              }),
              _vm._v(" "),
              _c(
                "label",
                {
                  staticClass: "custom-control-label",
                  attrs: { for: "cat-6" }
                },
                [_vm._v("Grid Categories Fullwidth")]
              )
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "item-count" }, [_vm._v("13")])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "sub-filter-items" }, [
            _c("div", { staticClass: "filter-item" }, [
              _c("div", { staticClass: "custom-control custom-checkbox" }, [
                _c("input", {
                  staticClass: "custom-control-input",
                  attrs: { type: "checkbox", id: "cat-7" }
                }),
                _vm._v(" "),
                _c(
                  "label",
                  {
                    staticClass: "custom-control-label",
                    attrs: { for: "cat-7" }
                  },
                  [_vm._v("Dresses")]
                )
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "item-count" }, [_vm._v("3")])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "filter-item" }, [
              _c("div", { staticClass: "custom-control custom-checkbox" }, [
                _c("input", {
                  staticClass: "custom-control-input",
                  attrs: { type: "checkbox", id: "cat-8" }
                }),
                _vm._v(" "),
                _c(
                  "label",
                  {
                    staticClass: "custom-control-label",
                    attrs: { for: "cat-8" }
                  },
                  [_vm._v("T-shirts")]
                )
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "item-count" }, [_vm._v("0")])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "filter-item" }, [
              _c("div", { staticClass: "custom-control custom-checkbox" }, [
                _c("input", {
                  staticClass: "custom-control-input",
                  attrs: { type: "checkbox", id: "cat-9" }
                }),
                _vm._v(" "),
                _c(
                  "label",
                  {
                    staticClass: "custom-control-label",
                    attrs: { for: "cat-9" }
                  },
                  [_vm._v("Bags")]
                )
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "item-count" }, [_vm._v("4")])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "filter-item" }, [
              _c("div", { staticClass: "custom-control custom-checkbox" }, [
                _c("input", {
                  staticClass: "custom-control-input",
                  attrs: { type: "checkbox", id: "cat-10" }
                }),
                _vm._v(" "),
                _c(
                  "label",
                  {
                    staticClass: "custom-control-label",
                    attrs: { for: "cat-10" }
                  },
                  [_vm._v("Jackets")]
                )
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "item-count" }, [_vm._v("2")])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "filter-item" }, [
              _c("div", { staticClass: "custom-control custom-checkbox" }, [
                _c("input", {
                  staticClass: "custom-control-input",
                  attrs: { type: "checkbox", id: "cat-11" }
                }),
                _vm._v(" "),
                _c(
                  "label",
                  {
                    staticClass: "custom-control-label",
                    attrs: { for: "cat-11" }
                  },
                  [_vm._v("Shoes")]
                )
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "item-count" }, [_vm._v("2")])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "filter-item" }, [
              _c("div", { staticClass: "custom-control custom-checkbox" }, [
                _c("input", {
                  staticClass: "custom-control-input",
                  attrs: { type: "checkbox", id: "cat-12" }
                }),
                _vm._v(" "),
                _c(
                  "label",
                  {
                    staticClass: "custom-control-label",
                    attrs: { for: "cat-12" }
                  },
                  [_vm._v("Jumpers")]
                )
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "item-count" }, [_vm._v("1")])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "filter-item" }, [
              _c("div", { staticClass: "custom-control custom-checkbox" }, [
                _c("input", {
                  staticClass: "custom-control-input",
                  attrs: { type: "checkbox", id: "cat-13" }
                }),
                _vm._v(" "),
                _c(
                  "label",
                  {
                    staticClass: "custom-control-label",
                    attrs: { for: "cat-13" }
                  },
                  [_vm._v("Jeans")]
                )
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "item-count" }, [_vm._v("1")])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "filter-item" }, [
              _c("div", { staticClass: "custom-control custom-checkbox" }, [
                _c("input", {
                  staticClass: "custom-control-input",
                  attrs: { type: "checkbox", id: "cat-14" }
                }),
                _vm._v(" "),
                _c(
                  "label",
                  {
                    staticClass: "custom-control-label",
                    attrs: { for: "cat-14" }
                  },
                  [_vm._v("Sportwear")]
                )
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "item-count" }, [_vm._v("0")])
            ])
          ])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }