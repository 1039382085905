<template>
    <div>
        <!-- <First />
        <Second />
        <Third />
        <Fourth />
        <Fifth /> -->

        <First />
        <Second />

        <Third />

        <Fourth />

        <Fifth />
    </div>
</template>

<script>
import LazyHydrate from 'vue-lazy-hydration';
export default {
    components: {
        LazyHydrate,
        First: () =>
            import('~/components/alf/homeComponents/second/secondfirst.vue'),
        Second: () =>
            import('~/components/alf/homeComponents/second/secondsecond.vue'),
        Third: () =>
            import('~/components/alf/homeComponents/second/secondthird.vue'),
        Fourth: () =>
            import('~/components/alf/homeComponents/second/secondfourth.vue'),
        Fifth: () =>
            import('~/components/alf/homeComponents/second/secondfifth.vue'),
    },
};
</script>

<style>
</style>