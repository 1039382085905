<template>
    <div>
        <First />

        <Second />

        <Third />

        <!-- <Fourth /> -->

        <!-- <Fifth /> -->
    </div>
</template>

<script>
import LazyHydrate from 'vue-lazy-hydration';

export default {
    components: {
        LazyHydrate,
        First: () =>
            import('~/components/alf/homeComponents/third/thirdfirst.vue'),
        Second: () =>
            import('~/components/alf/homeComponents/third/thirdsecond.vue'),
        Third: () =>
            import('~/components/alf/homeComponents/third/thirdthird.vue'),
        // Fourth: () =>
        //     import('~/components/alf/homeComponents/third/thirdfourth.vue'),
        // Fifth: () =>
        //     import('~/components/alf/homeComponents/third/thirdfifth.vue'),
    },
};
</script>

<style>
</style>