<template>
    <div class="product-details-tab">
        <tabs class="nav-pills justify-content-start" :data="tabsData"></tabs>
        <div class="tab-content">
            <div class="tab-pane fade show active" id="tab1">
                <div class="product-desc-content">
                    <h3>الوصف:</h3>
                    <p class="mb-1" v-if="infoTab.brief">
                        <!-- {{ placeDescription }} -->
                        {{ infoTab.brief }}
                    </p>
                    <div class="product-desc-info">
                        <div class="row">
                            <div class="col-2"><p>متوسط السعر :</p></div>
                            <div class="col-2 info">
                                <p v-if="price_range">
                                    {{ price_range }}
                                </p>
                            </div>
                        </div>
                        <div class="row" v-if="age_stages.length">
                            <div class="col-2"><p>Age Stages :</p></div>
                            <div class="col-2 info">
                                <p v-for="age in age_stages" :key="age.id">
                                    {{ age.from }} - {{ age.to }}
                                </p>
                            </div>
                        </div>
                        <div>
                            <template>
                                <div class="row" v-if="grades.length">
                                    <div class="col-2"><p>Grade :</p></div>
                                    <div class="col-2 info">
                                        <p
                                            v-for="grade in grades"
                                            :key="grade.id"
                                        >
                                            {{ grade.name }}
                                        </p>
                                    </div>
                                </div>
                            </template>
                        </div>

                        <div class="row" v-if="curriculums.length">
                            <div class="col-2"><p>Curriculum :</p></div>
                            <div class="col-2 info">
                                <p
                                    v-for="curriculum in curriculums"
                                    :key="curriculum.id"
                                >
                                    {{ curriculum.name }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="tab-pane fade review" id="tab2">
                <div class="product-desc-content">
                    <div class="comment-list" v-if="comments.length">
                        <div
                            class="col-6"
                            v-for="comment in comments"
                            :key="comment.id"
                        >
                            <div class="user d-flex">
                                <div class="thumb">
                                    <i
                                        class="fa fa-user"
                                        style="font-size: 30px; width: 30px"
                                    ></i>
                                </div>
                                <div class="desc">
                                    <div
                                        class="
                                            d-flex
                                            align-center
                                            desc__content
                                        "
                                    >
                                        <h5>{{ comment.name }}</h5>
                                        <p class="date"></p>
                                    </div>
                                    <p class="comment">
                                        {{ comment.comment }}
                                    </p>
                                </div>
                            </div>
                            <div class="reply-btn">
                                <a class="btn-reply text-uppercase">reply</a>
                            </div>
                        </div>
                    </div>
                    <div class="comment-list left-padding">
                        <div
                            class="
                                single-comment
                                justify-content-between
                                d-flex
                            "
                        >
                            <div class="user d-flex comment-replay">
                                <!-- <div class="thumb">
                                    <i
                                        class="fa fa-user"
                                        style="font-size: 30px; width: 30px"
                                    ></i>
                                </div> -->
                                <div class="desc">
                                    <div class="media my-5">
                                        <div class="pr-5">
                                            <img
                                                class="rounded-circle"
                                                alt="Bootstrap Media Another Preview"
                                                src="https://i.imgur.com/nAcoHRf.jpg"
                                                width="70"
                                            />
                                        </div>
                                        <div class="media-body">
                                            <div class="row">
                                                <div class="col-8">
                                                    <div class="row">
                                                        <div
                                                            class="
                                                                col-12
                                                                d-flex
                                                            "
                                                        >
                                                            <h5>
                                                                Emilly Blunt
                                                            </h5>

                                                            <p class="date">
                                                                December 4, 2017
                                                                at 3:12 pm
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <p class="comment">
                                                        Never say goodbye till
                                                        the end comes!
                                                    </p>
                                                </div>
                                                <div class="col-4">
                                                    <span
                                                        class="mx-5"
                                                        v-if="!commentField"
                                                    >
                                                        <button
                                                            class="btn"
                                                            @click="
                                                                commentField = !commentField
                                                            "
                                                        >
                                                            <i
                                                                class="
                                                                    fa fa-reply
                                                                "
                                                            ></i>
                                                            reply
                                                        </button>
                                                    </span>
                                                    <span
                                                        class="mx-5"
                                                        v-if="commentField"
                                                    >
                                                        <button
                                                            class="btn"
                                                            @click="
                                                                commentField = !commentField
                                                            "
                                                        >
                                                            <i
                                                                class="
                                                                    far
                                                                    fa-times-circle
                                                                "
                                                            ></i>
                                                            close
                                                        </button>
                                                    </span>
                                                </div>
                                                <form
                                                    class="replyArea w-100"
                                                    v-if="commentField"
                                                >
                                                    <textarea
                                                        required
                                                        rows="5"
                                                        placeholder="Write your reply"
                                                        class="
                                                            form-control
                                                            my-5
                                                        "
                                                        spellcheck="false"
                                                        cols="10"
                                                    ></textarea>
                                                    <div
                                                        class="
                                                            d-flex
                                                            justify-content-end
                                                        "
                                                    >
                                                        <input
                                                            type="submit"
                                                            value="Submit"
                                                            class="
                                                                btn btn-primary
                                                                py-3
                                                                px-5
                                                                submit-comment
                                                            "
                                                        />
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form">
                    <form action="#">
                        <div class="row">
                            <div
                                class="offset-lg-2 col-lg-8 col-md-8 col-sm-10"
                            >
                                <textarea
                                    rows="5"
                                    class="form-control"
                                    placeholder="Comment"
                                    required
                                ></textarea>
                            </div>
                        </div>
                        <br />
                        <div class="form-group" style="text-align: center">
                            <input
                                type="submit"
                                value="Submit"
                                class="btn btn-primary py-3 px-5 submit-comment"
                            />
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Tabs from '~/components/elements/Tabs';
import axios from 'axios';
export default {
    components: {
        Tabs
    },
    data: function() {
        return {
            // price: [],
            infoTab: [],
            id: this.$route.params.slug,
            price_range: '',
            age_stages: '',
            grades: '',
            curriculums: '',
            comments: '',
            tabsData: [
                {
                    id: 'tab1',
                    title: 'الوصف',
                    active: true
                },
                {
                    id: 'tab2',
                    title: 'المراجعه'
                }
            ],
            commentField: false,
            rate:[]
        };
    },

    // props: ['placeDescription', 'priceAvarage', 'ageStages'],
    mounted() {
        axios
            .get(
                `https://atwdemo.com/listingdirectory/public/api/place?id=${this.id}`
            )
            .then(resp => {
                // console.log(resp);
                this.infoTab = resp.data.places;
                this.price_range = resp.data.places.price_range.name;
                this.age_stages = resp.data.places.age_stages;
                this.grades = resp.data.places.grade_types;
                this.curriculums = resp.data.places.curriculums;
                this.comments = resp.data.places.comments;
                console.log(this.comments);
                // console.log(this.age_stages)
                // console.log(this.infoTab.price_range.name);
            });
    },
   
};
</script>
<style lang="scss" scoped>
.comment-list {
    .thumb {
        margin-right: 20px;
    }
    .desc {
        .desc__content {
            margin-bottom: 13px;
            align-items: center;
        }
        h5 {
            margin: 0 25px 0 0;
        }
    }

    .reply-btn {
        text-align: right;
    }
    .comment-replay {
        padding-left: 25px;
    }
    .form {
        padding-top: 35px;
    }
}
.product-desc-info {
    p {
        color: #181c28;
        font-weight: 500;
        font-size: 19px;
    }
    .info {
        p {
            font-size: 17px;
            color: #767676;
            font-weight: 400;
        }
    }
}
textarea {
    resize: none;
}
</style>
