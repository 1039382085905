import axios from 'axios';
import VueI18n from 'vue-i18n';
// const baseDomain = 'https://d-themes.com/vue/molla/server';
// const baseDomain = 'https://atwdemo.com/commerce/admin/public/api';

// const baseDomain = 'https://atwdemo.com/scomtesting/public/api';
const baseDomain = 'https://admin.royalbeeseg.com/public/api';
const listingBaseDomain = 'https://atwdemo.com/listingdirectory/public/api';
let currentLang = sessionStorage.getItem('language');
export const customHeaders = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    mode: 'cors',
    // language: 'LANGUAGE1' ? $i18n.locale == 'ar' : 'LANGUAGE2'
    // language: sessionStorage.getItem('language')
    language: currentLang ? currentLang :  'LANGUAGE2'
};

export const baseUrl = `${baseDomain}`;
export const listinbaseUrl = `${listingBaseDomain}`;

export default axios.create({
    baseUrl,
    listinbaseUrl,
    headers: customHeaders
});
