var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "product-holder text-left" }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-12" }, [
          _c(
            "figure",
            { staticClass: "product-media" },
            [
              _vm.product.new
                ? _c("span", { staticClass: "product-label label-new" }, [
                    _vm._v(
                      "\n                        " +
                        _vm._s(_vm.$t("product.new")) +
                        "\n                        "
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.product.sale_price ||
              _vm.product.price != _vm.product.price_after_discount
                ? _c("span", { staticClass: "product-label label-sale" }, [
                    _vm._v(
                      "\n                        " +
                        _vm._s(_vm.$t("product.sale")) +
                        "\n                        "
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.product.top
                ? _c("span", { staticClass: "product-label label-top" }, [
                    _vm._v(_vm._s(_vm.$t("product.top")))
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.product.stock === 0
                ? _c("span", { staticClass: "product-label label-out" }, [
                    _vm._v(_vm._s(_vm.$t("product.outOfStock")))
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "nuxt-link",
                {
                  attrs: {
                    to: _vm.localePath("/product/default/" + _vm.product.id)
                  }
                },
                [
                  _vm.product.main_image
                    ? _c("img", {
                        directives: [
                          {
                            name: "lazy",
                            rawName: "v-lazy",
                            value: _vm.product.main_image,
                            expression: "product.main_image"
                          }
                        ],
                        staticClass: "w-100 h-100",
                        attrs: {
                          alt: "Product",
                          width: _vm.product.main_image,
                          height: _vm.product.main_image
                        }
                      })
                    : _c("img", {
                        staticClass: "product-image-hover",
                        attrs: {
                          src: _vm.product.main_image,
                          alt: "Product",
                          width: _vm.product.main_image,
                          height: _vm.product.main_image
                        }
                      })
                ]
              )
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "col-12 d-flex justify-content-center align-items-center flex-column"
          },
          [
            _c("div", { staticClass: "product-body product-action-inner" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-md-6 col-sm-12" }, [
                  _c("div", { staticClass: "product-info1" }, [
                    _c(
                      "h3",
                      { staticClass: "product-title" },
                      [
                        _c(
                          "nuxt-link",
                          {
                            attrs: {
                              to: _vm.localePath(
                                "/product/default/" + _vm.product.id
                              )
                            }
                          },
                          [_vm._v(_vm._s(_vm.product.name))]
                        )
                      ],
                      1
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-md-6 col-sm-12" }, [
                  _c(
                    "div",
                    { staticClass: "product-info2" },
                    [
                      _vm.product.stock == 0
                        ? _c(
                            "div",
                            { key: "outPrice", staticClass: "product-price" },
                            [
                              _c("span", { staticClass: "out-price" }, [
                                _vm._v(_vm._s(_vm.$t("product.outOfStock")))
                              ])
                            ]
                          )
                        : [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "product-price d-flex flex-column justify-content-start"
                              },
                              [
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "new-price lead text-primary mr-0"
                                  },
                                  [
                                    _c("span", {}, [
                                      _vm._v(
                                        _vm._s(_vm.$t("payment.price")) +
                                          " :\n                                                " +
                                          _vm._s(
                                            _vm.product.price_after_discount
                                          )
                                      )
                                    ])
                                  ]
                                ),
                                _vm._v(" "),
                                _vm.product.price !=
                                _vm.product.price_after_discount
                                  ? _c("p", { staticClass: "old-price lead" }, [
                                      _c(
                                        "del",
                                        { staticClass: "text-danger" },
                                        [
                                          _vm._v(
                                            "Price :\n                                                " +
                                              _vm._s(_vm.product.price)
                                          )
                                        ]
                                      )
                                    ])
                                  : _vm._e()
                              ]
                            )
                          ]
                    ],
                    2
                  )
                ])
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "product-list-action" }, [
              _c(
                "div",
                { staticClass: "product-action2 d-flex" },
                [
                  _c(
                    "button",
                    {
                      staticClass: "btn-product btn-quickview",
                      attrs: { title: "Quick view" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.quickView({
                            product: _vm.product
                          })
                        }
                      }
                    },
                    [_c("span", [_vm._v(_vm._s(_vm.$t("product.quickView")))])]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn-product btn-cart",
                      class: {
                        "btn-disabled": !_vm.canAddToCart(_vm.product, 1)
                      },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.addToCart({
                            product: _vm.product
                          })
                        }
                      }
                    },
                    [_c("span", [_vm._v(_vm._s(_vm.$t("product.addToCart")))])]
                  ),
                  _vm._v(" "),
                  _vm.isInWishlist(_vm.product)
                    ? _c(
                        "nuxt-link",
                        {
                          key: "inWishlist",
                          staticClass:
                            "btn-product btn-wishlist added-to-wishlist",
                          attrs: { to: _vm.localePath("/shop/wishlist") }
                        },
                        [
                          _c("span", [
                            _vm._v(
                              "\n                                " +
                                _vm._s(_vm.$t("product.inWishlist")) +
                                "\n                            "
                            )
                          ])
                        ]
                      )
                    : _c(
                        "a",
                        {
                          key: "notInWishlist",
                          staticClass: "btn-product btn-wishlist",
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.addToWishlist({
                                product: _vm.product
                              })
                            }
                          }
                        },
                        [
                          _c("span", [
                            _vm._v(_vm._s(_vm.$t("product.addToWishlist")))
                          ])
                        ]
                      )
                ],
                1
              )
            ])
          ]
        )
      ]),
      _vm._v(" "),
      _c("br")
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }