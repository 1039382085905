<template>
    <div>
        <template v-if="!loading">
            <div class="col-9 m-auto skeleton-body skel-shop-products">
                <template>
                    <div
                        class="skel-pro skel-pro-list"
                        v-for="item in 1"
                        :key="item"
                    ></div>
                </template>
            </div>
        </template>
        <div class="" v-else>
            <section class="py-5" v-if="abouts.length > 0">
                <div
                    class="about-testimonials bg-light-2 pt-6 pb-6"
                    v-lazy:background-image="'./images/Alf/alf/last.png'"
                >
                    <div class="container">
                        <h2 class="title text-center mb-3">
                            {{ abouts.title }}
                        </h2>
                        <div
                            class="
                                swiper-carousel
                                swiper-testimonials-photo
                                swiper-11
                            "
                        >
                            <div v-swiper:swiper11="carouselSetting11">
                                <div class="swiper-wrapper">
                                    <div
                                        class="swiper-slide"
                                        v-for="about in abouts"
                                        :key="about.id"
                                        :productId="about.id"
                                    >
                                        <blockquote
                                            class="testimonial text-center"
                                        >
                                            <h2>
                                                {{ about.title }}
                                            </h2>
                                            <p class="lead">
                                                {{ about.description }}
                                            </p>
                                            <div class="info-person">
                                                <div class="image mb-2">
                                                    <img
                                                        v-lazy="
                                                            about.background_image
                                                        "
                                                        alt="user"
                                                    />
                                                </div>
                                                <cite>
                                                    {{ about.name }}

                                                    <!-- <span>Customer</span> -->
                                                </cite>
                                            </div>
                                        </blockquote>
                                    </div>
                                </div>
                            </div>
                            <div class="swiper-nav d-none d-xl-block">
                                <div class="swiper-prev">
                                    <i class="icon-angle-left"></i>
                                </div>
                                <div class="swiper-next">
                                    <i class="icon-angle-right"></i>
                                </div>
                            </div>
                            <div class="swiper-dots"></div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
import {
    carouselSettingSingle,
    carouselSettingDefault
} from '~/utilities/carousel';
import Repository, { baseUrl } from '~/repositories/repository.js';

export default {
    data: function() {
        return {
            carouselSetting11: {
                ...carouselSettingDefault,
                spaceBetween: 0,
                slidesPerView: 1,
                navigation: {
                    nextEl: '.swiper-11 .swiper-next',
                    prevEl: '.swiper-11 .swiper-prev'
                },
                pagination: {
                    el: '.swiper-11 .swiper-dots',
                    clickable: true
                }
            },
            abouts: [],
            loading: false
        };
    },
    methods: {
        getData: async function() {
            let sliders = `${baseUrl}/sliders`;
            //sliders
            await Repository.get(sliders).then(resp => {
                console.log('abouts', resp);
                if (resp.data.code == 200) {
                    this.loading = true;
                }
                this.abouts = resp.data.about_sliders;
                this.fashionBaby = resp.data.photos_sliders;
                // console.log(' this.fashionBaby', this.fashionBaby);
            });
            // .catch((err) => {
            //     console.log(err);
            // });
        }
    },
    mounted() {
        this.getData();
    }
};
</script>

<style lang="scss" scoped>
.lead {
    word-break: break-all;
}
.about-testimonials {
    background-repeat: no-repeat;
    .info-person {
        .image {
            width: 70px;
            height: 70px;
            margin: auto;
            img {
                width: 100%;
            }
        }
    }
}
</style>
