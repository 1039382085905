var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "product product-list" }, [
    _c("div", { staticClass: "row pr-2" }, [
      _c("div", { staticClass: "col-lg-3 col-md-3 col-6" }, [
        _c(
          "figure",
          { staticClass: "product-media" },
          [
            _vm.product.new
              ? _c("span", { staticClass: "product-label label-new" }, [
                  _vm._v("New")
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.product.sale_price
              ? _c("span", { staticClass: "product-label label-sale" }, [
                  _vm._v("Sale")
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.product.top
              ? _c("span", { staticClass: "product-label label-top" }, [
                  _vm._v("Top")
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.product.stock === 0
              ? _c("span", { staticClass: "product-label label-out" }, [
                  _vm._v("Out Of Stock")
                ])
              : _vm._e(),
            _vm._v(" "),
            _c(
              "nuxt-link",
              { attrs: { to: "/product/default/" + _vm.product.slug } },
              [
                _c("img", {
                  directives: [
                    {
                      name: "lazy",
                      rawName: "v-lazy",
                      value: `${_vm.baseUrl}${_vm.product.sm_pictures[0].url}`,
                      expression: "`${baseUrl}${product.sm_pictures[0].url}`"
                    }
                  ],
                  staticClass: "product-image",
                  attrs: {
                    alt: "Product",
                    width: _vm.product.sm_pictures[0].width,
                    height: _vm.product.sm_pictures[0].height
                  }
                }),
                _vm._v(" "),
                _vm.product.sm_pictures[1]
                  ? _c("img", {
                      directives: [
                        {
                          name: "lazy",
                          rawName: "v-lazy",
                          value: `${_vm.baseUrl}${_vm.product.sm_pictures[1].url}`,
                          expression:
                            "`${baseUrl}${product.sm_pictures[1].url}`"
                        }
                      ],
                      staticClass: "product-image-hover",
                      attrs: {
                        alt: "Product",
                        width: _vm.product.sm_pictures[1].width,
                        height: _vm.product.sm_pictures[1].height
                      }
                    })
                  : _vm._e()
              ]
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-lg-6 col-md-6 order-last" }, [
        _c("div", { staticClass: "product-body product-action-inner" }, [
          _c(
            "div",
            { staticClass: "product-cat" },
            _vm._l(_vm.product.category, function(cat, index) {
              return _c(
                "span",
                { key: index },
                [
                  _c(
                    "nuxt-link",
                    {
                      attrs: {
                        to: {
                          path: "/shop/sidebar/list",
                          query: { category: cat.slug }
                        }
                      }
                    },
                    [_vm._v(_vm._s(cat.name))]
                  ),
                  _vm._v(
                    "\n                        " +
                      _vm._s(
                        index < _vm.product.category.length - 1 ? "," : ""
                      ) +
                      "\n                    "
                  )
                ],
                1
              )
            }),
            0
          ),
          _vm._v(" "),
          _c(
            "h3",
            { staticClass: "product-title" },
            [
              _c(
                "nuxt-link",
                { attrs: { to: "/product/default/" + _vm.product.slug } },
                [_vm._v(_vm._s(_vm.product.name))]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "product-content" }, [
            _c("p", [_vm._v(_vm._s(_vm.product.short_desc))])
          ]),
          _vm._v(" "),
          _vm.product.length > 0
            ? _c("div", { staticClass: "product-nav product-nav-dots" }, [
                _c(
                  "div",
                  { staticClass: "row no-gutters" },
                  _vm._l(_vm.product, function(item, index) {
                    return _c(
                      "a",
                      {
                        key: index,
                        style: { "background-color": item.color },
                        attrs: { href: "javascript:;" }
                      },
                      [
                        _c("span", { staticClass: "sr-only" }, [
                          _vm._v("Color name")
                        ])
                      ]
                    )
                  }),
                  0
                )
              ])
            : _vm._e()
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-lg-3 col-md-3 col-6 order-md-last order-lg-last" },
        [
          _c(
            "div",
            { staticClass: "product-list-action" },
            [
              _vm.product.stock == 0
                ? _c("div", { key: "outPrice", staticClass: "product-price" }, [
                    _c("span", { staticClass: "out-price" }, [
                      _vm._v("$" + _vm._s(_vm.product.price.toFixed(2)))
                    ])
                  ])
                : [
                    _vm.minPrice == _vm.maxPrice
                      ? _c("div", { staticClass: "product-price" }, [
                          _vm._v("$" + _vm._s(_vm.minPrice.toFixed(2)))
                        ])
                      : [
                          _vm.product.length == 0
                            ? _c("div", { staticClass: "product-price" }, [
                                _c("span", { staticClass: "new-price" }, [
                                  _vm._v("$" + _vm._s(_vm.minPrice.toFixed(2)))
                                ]),
                                _vm._v(" "),
                                _c("span", { staticClass: "old-price" }, [
                                  _vm._v("$" + _vm._s(_vm.maxPrice.toFixed(2)))
                                ])
                              ])
                            : _c("div", { staticClass: "product-price" }, [
                                _vm._v(
                                  "$" +
                                    _vm._s(_vm.minPrice.toFixed(2)) +
                                    "–$" +
                                    _vm._s(_vm.maxPrice.toFixed(2))
                                )
                              ])
                        ]
                  ],
              _vm._v(" "),
              _c("div", { staticClass: "ratings-container" }, [
                _c("div", { staticClass: "ratings" }, [
                  _c("div", {
                    staticClass: "ratings-val",
                    style: { width: _vm.product.ratings * 20 + "%" }
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "tooltip-text" }, [
                    _vm._v(_vm._s(_vm.product.ratings.toFixed(2)))
                  ])
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "ratings-text" }, [
                  _vm._v("( " + _vm._s(_vm.product.review) + " Reviews )")
                ])
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "product-action" },
                [
                  _c(
                    "button",
                    {
                      staticClass: "btn-product btn-quickview",
                      attrs: { title: "Quick view" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.quickView({ product: _vm.product })
                        }
                      }
                    },
                    [_c("span", [_vm._v("quick view")])]
                  ),
                  _vm._v(" "),
                  _vm.isInWishlist(_vm.product)
                    ? _c(
                        "nuxt-link",
                        {
                          key: "inWishlist",
                          staticClass:
                            "btn-product btn-wishlist added-to-wishlist",
                          attrs: { to: "/shop/wishlist" }
                        },
                        [_c("span", [_vm._v("wishlist")])]
                      )
                    : _c(
                        "a",
                        {
                          key: "notInWishlist",
                          staticClass: "btn-product btn-wishlist",
                          attrs: { href: "javascript:;" },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.addToWishlist({ product: _vm.product })
                            }
                          }
                        },
                        [_c("span", [_vm._v("wishlist")])]
                      )
                ],
                1
              ),
              _vm._v(" "),
              _vm.product.length > 0
                ? _c(
                    "nuxt-link",
                    {
                      staticClass: "btn-product btn-cart btn-select",
                      attrs: { to: "/product/default/" + _vm.product.slug }
                    },
                    [_c("span", [_vm._v("select options")])]
                  )
                : _c(
                    "button",
                    {
                      staticClass: "btn-product btn-cart",
                      class: {
                        "btn-disabled": !_vm.canAddToCart(_vm.product, 1)
                      },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.addToCart({ product: _vm.product })
                        }
                      }
                    },
                    [_c("span", [_vm._v(_vm._s(_vm.$t("product.addToCart")))])]
                  )
            ],
            2
          )
        ]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }