var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      !_vm.loading
        ? [
            _c(
              "div",
              { staticClass: "col-9 m-auto skeleton-body skel-shop-products" },
              [
                _vm._l(1, function(item) {
                  return _c("div", {
                    key: item,
                    staticClass: "skel-pro skel-pro-list"
                  })
                })
              ],
              2
            )
          ]
        : _c("div", {}, [
            _c("section", { staticClass: "blog" }, [
              _c("div", { staticClass: "mb-5 text-center" }, [
                _c("h2", { staticClass: "h1 text-gray my-3 Offers" }, [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.$t("homeBlog.title")) +
                      "\n                "
                  )
                ]),
                _vm._v(" "),
                _c("hr", { staticClass: "divider1" })
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "container" }, [
                _c(
                  "div",
                  { staticClass: "row" },
                  [
                    _vm._l(_vm.blogPosts, function(post) {
                      return _c(
                        "div",
                        {
                          key: post.id,
                          staticClass: "col-md-6 col-sm-12 main-photo",
                          class: { "col-md-12": _vm.postaDeacrease.length <= 0 }
                        },
                        [
                          _c(
                            "nuxt-link",
                            {
                              attrs: { to: `/blog/single/default/${post.id}` }
                            },
                            [
                              _c("img", {
                                directives: [
                                  {
                                    name: "lazy",
                                    rawName: "v-lazy",
                                    value: post.image,
                                    expression: "post.image"
                                  }
                                ],
                                staticClass: "img-fluid bg-transparent m-auto",
                                attrs: { alt: "blog cover" }
                              }),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "data-holder text-center py-4" },
                                [
                                  _c("h4", {
                                    domProps: {
                                      textContent: _vm._s(_vm.postsTitle)
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "ul",
                                    { staticClass: "list-unstyled d-flex" },
                                    [
                                      _vm.posts.writer
                                        ? _c("li", [
                                            _c("span", [
                                              _vm._v(
                                                "\n                                            " +
                                                  _vm._s(_vm.posts.writer) +
                                                  "\n                                        "
                                              )
                                            ])
                                          ])
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c("li", { staticClass: "d-flex" }, [
                                        _c("span", [
                                          _c("i", {
                                            staticClass: "icon-calendar"
                                          })
                                        ]),
                                        _vm._v(" "),
                                        _c("span", {
                                          domProps: {
                                            textContent: _vm._s(_vm.postsTime)
                                          }
                                        })
                                      ]),
                                      _vm._v(" "),
                                      _vm.posts.comments
                                        ? _c("li", [
                                            _c("span", [
                                              _c("i", {
                                                staticClass: "icon-comments"
                                              })
                                            ]),
                                            _vm._v(" "),
                                            _c("span", [
                                              _vm._v(
                                                "\n                                            " +
                                                  _vm._s(_vm.posts.comments) +
                                                  "\n                                        "
                                              )
                                            ])
                                          ])
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c("li", { staticClass: "d-flex" }, [
                                        _c("span", [
                                          _c("i", { staticClass: "icon-eye" })
                                        ]),
                                        _vm._v(" "),
                                        _c("span", {
                                          domProps: {
                                            textContent: _vm._s(_vm.postsView)
                                          }
                                        })
                                      ])
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("p", { staticClass: "text-dimied" }),
                                  _vm._v(" "),
                                  _c("br")
                                ]
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "button" },
                            [
                              _c(
                                "nuxt-link",
                                {
                                  staticClass: "btn read-more",
                                  staticStyle: {
                                    "font-weight": "bold",
                                    float: "right",
                                    "font-size": "20px",
                                    color: "#ffe607"
                                  },
                                  attrs: { to: _vm.localePath("/blog/classic") }
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("buttons.readMore")) +
                                      "\n                            "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    }),
                    _vm._v(" "),
                    _vm.postaDeacrease.length > 0
                      ? _c("div", { staticClass: "col-md-6 col-sm-12" }, [
                          _c(
                            "div",
                            { staticClass: "blog-slides" },
                            [
                              _c("h4", [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(_vm.$t("homeBlog.popularPosts")) +
                                    "\n                            "
                                )
                              ]),
                              _vm._v(" "),
                              _c("span", { staticClass: "devider2" }),
                              _vm._v(" "),
                              _vm._l(_vm.postaDeacrease, function(post) {
                                return _c(
                                  "div",
                                  { key: post.id, staticClass: "media" },
                                  [
                                    _c(
                                      "nuxt-link",
                                      {
                                        attrs: {
                                          to: `/blog/single/default/${post.id}`
                                        }
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "media-body" },
                                          [
                                            _c("h5", { staticClass: "mt-0" }, [
                                              _vm._v(
                                                "\n                                            " +
                                                  _vm._s(post.title) +
                                                  "\n                                        "
                                              )
                                            ]),
                                            _vm._v(" "),
                                            _c(
                                              "ul",
                                              {
                                                staticClass:
                                                  "list-unstyled d-flex"
                                              },
                                              [
                                                post.writer
                                                  ? _c("li", [
                                                      _c("span", [
                                                        _vm._v(
                                                          "\n                                                    " +
                                                            _vm._s(
                                                              post.writer
                                                            ) +
                                                            "\n                                                "
                                                        )
                                                      ])
                                                    ])
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                _c(
                                                  "li",
                                                  { staticClass: "d-flex" },
                                                  [
                                                    _c("span", [
                                                      _c("i", {
                                                        staticClass:
                                                          "icon-calendar"
                                                      })
                                                    ]),
                                                    _vm._v(" "),
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(post.created_at)
                                                      )
                                                    ])
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                post.comments
                                                  ? _c("li", [
                                                      _c("span", [
                                                        _c("i", {
                                                          staticClass:
                                                            "icon-comments"
                                                        })
                                                      ]),
                                                      _vm._v(" "),
                                                      _c("span", [
                                                        _vm._v(
                                                          "\n                                                    " +
                                                            _vm._s(
                                                              post.comments
                                                            ) +
                                                            "\n                                                "
                                                        )
                                                      ])
                                                    ])
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                _c(
                                                  "li",
                                                  { staticClass: "d-flex" },
                                                  [
                                                    _c("span", [
                                                      _c("i", {
                                                        staticClass: "icon-eye"
                                                      })
                                                    ]),
                                                    _vm._v(" "),
                                                    _c("span", [
                                                      _vm._v(_vm._s(post.views))
                                                    ])
                                                  ]
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              })
                            ],
                            2
                          )
                        ])
                      : _vm._e()
                  ],
                  2
                )
              ])
            ])
          ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }