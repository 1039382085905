<template>
    <div>
        <!-- <div class="up-footer">
            <div class="py-3">
                <div class="container">
                    <div class="row">
                        <div
                            class="
                                col-md-3 col-sm-6
                                mb-2
                                d-flex
                                justify-content-center
                                align-items-end
                            "
                        >
                            <span class="mr-2">
                                <img
                                    v-lazy="
                                        './images/Alf/alf/icon/alf/surface1.png'
                                    "
                                    alt="image"
                                    class="bg-transparent"
                                    width="100%"
                                    height="100%"
                                />
                            </span>
                            <span class="text-white mx-2">
                                {{$t('footer.uperFooter.bestGuarantee')}}
                            </span>
                        </div>
                        <div
                            class="
                                col-md-3 col-sm-6
                                mb-2
                                d-flex
                                justify-content-center
                                align-items-end
                            "
                        >
                            <span class="mr-2">
                                <img
                                    v-lazy="
                                        './images/Alf/alf/icon/alf/delivery (1).png'
                                    "
                                    alt="image"
                                    class="bg-transparent"
                                    width="100%"
                                    height="100%"
                                />
                            </span>
                            <span class="text-white mx-2">
                                {{$t('footer.uperFooter.freeDelivery')}}
                            </span>
                        </div>
                        <div
                            class="
                                col-md-3 col-sm-6
                                mb-2
                                d-flex
                                justify-content-center
                                align-items-end
                            "
                        >
                            <span class="mr-2">
                                <img
                                    v-lazy="
                                        './images/Alf/alf/icon/alf/Solid.png'
                                    "
                                    alt="image"
                                    class="bg-transparent"
                                    width="100%"
                                    height="100%"
                                />
                            </span>
                            <span class="text-white mx-2">
                                {{$t('footer.uperFooter.easyReturns')}}
                            </span>
                        </div>
                        <div
                            class="
                                col-md-3 col-sm-6
                                d-flex
                                justify-content-center
                                align-items-end
                                mb-2
                            "
                        >
                            <span class="mr-2">
                                <img
                                    v-lazy="
                                        './images/Alf/alf/icon/alf/wallet (1).png'
                                    "
                                    alt="image"
                                    class="bg-transparent"
                                    width="100%"
                                    height="100%"
                                />
                            </span>
                            <span class="text-white mx-2"
                                >{{$t('payment.payment')}}</span
                            >
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
        <footer
            class="footer bg-info text-white"
            v-lazy:background-image="'./images/home/Group 16883.png'"
        >
            <div class="footer-middle p-0">
                <div :class="isFullwidth ? 'container-fluid' : 'container'">
                    <div class="row  footer-data align-items-center">
                        <div class="col-md-5 col-sm-3">
                            <div class="widget widget-about">
                                <nuxt-link :to="localePath('/')" class="logo">
                                    <img
                                        v-lazy="'./images/home/unnamed1.png'"
                                        class="footer-logo bg-transparent"
                                        alt="Footer Logo"
                                        width="150"
                                        height="27"
                                    />
                                    <!-- <img
                                v-lazy="'./images/home/WhatsApp Image 2021-12-08 at 9.57.02 AM.png'"
                                class="bg-transparent"
                                alt="Alef-planet Logo"
                                width="105"
                                height="27"
                            /> -->
                                </nuxt-link>
                                <p class="text-white">
                                    شركة مملكة النحل واحدة من الشركات الرائدة فى
                                    مصر و الوطن العربى بخبرة اكثر من ٣٠ سنة
                                </p>
                                <div class="footer-social social d-flex w-50">
                                    <ul class="list-unstyled d-flex">
                                        <li class="facebook">
                                            <a
                                                href="https://www.facebook.com/RoyalBeesEg"
                                                rel="noopener"
                                                class="facebook"
                                                target="_blank"
                                            >
                                                <i class="fab fa-facebook"></i>
                                            </a>
                                        </li>
                                        <li class="twitter">
                                            <a
                                                href="https://www.instagram.com/royalbeeseg"
                                                rel="noopener"
                                                target="_blank"
                                            >
                                                <i class="fab fa-instagram"></i>
                                            </a>
                                        </li>
                                        <li class="youtube">
                                            <a
                                                href="https://www.youtube.com/channel/UC86SCyXYV402gABz-IsUR6A"
                                                rel="noopener"
                                                target="_blank"
                                            >
                                                <i
                                                    class="fab fa-youtube fa-2x"
                                                ></i>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-7 col-9" :class="$i18n.locale">
                            <div class="row ">
                                <div class=" col-md-6 col-sm-6">
                                    <div class="widget">
                                        <h4 class="widget-title">
                                            الصفحات
                                        </h4>

                                        <ul class="widget-list">
                                            <li class="megamenu-container">
                                                <nuxt-link
                                                    :to="localePath('/')"
                                                >
                                                    {{
                                                        $t('nav.home')
                                                    }}</nuxt-link
                                                >
                                            </li>
                                            <li>
                                                <nuxt-link
                                                    :to="
                                                        localePath(
                                                            '/shop/sidebar/list'
                                                        )
                                                    "
                                                    >{{
                                                        $t('nav.products')
                                                    }}</nuxt-link
                                                >
                                            </li>
                                            <!-- <li
                                               
                                            >
                                                <nuxt-link
                                                    :to="
                                                        localePath(
                                                            '/pages/contact'
                                                        )
                                                    "
                                                    >{{
                                                        $t('nav.mostProduct')
                                                    }}</nuxt-link
                                                >
                                            </li> -->
                                            <!-- <li
                                               
                                            >
                                                <nuxt-link
                                                    :to="
                                                        localePath(
                                                            '/pages/aboutAlf'
                                                        )
                                                    "
                                                    >{{
                                       
                                                              $t('nav.about')
                                                    }}</nuxt-link
                                                >
                                            </li> -->

                                            <li>
                                                <nuxt-link
                                                    :to="
                                                        localePath(
                                                            '/blog/classic'
                                                        )
                                                    "
                                                    >{{
                                                        $t('nav.blog')
                                                    }}</nuxt-link
                                                >
                                            </li>
                                            <li>
                                                <nuxt-link
                                                    :to="
                                                        localePath(
                                                            '/pages/contact'
                                                        )
                                                    "
                                                    >{{
                                                        $t('nav.contactUs')
                                                    }}</nuxt-link
                                                >
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <div class=" col-md-5 col-sm-6">
                                    <div class="widget">
                                        <h4 class="widget-title">
                                            أتصل بنا
                                        </h4>

                                        <ul class="widget-list">
                                            <li>
                                                <a href="mailto:info@royalbeeseg.com" class="d-flex mb-3" target="_blank"
                                                    ><i
                                                        class="far fa-envelope ml-2"
                                                    ></i>
                                                    <p>info@royalbeeseg.com</p></a
                                                >
                                            </li>
                                            <li
                                                class="
                                            d-flex
                                            justify-content-start
                                            align-items-center
                                        "
                                            >
                                                <a href="tel:0238090519" class="d-flex mb-3" target="_blank"
                                                    ><i
                                                        class="fa fa-phone ml-2"
                                                    ></i>
                                                    <p>0238090519‎</p></a
                                                >
                                            </li>
                                            <li
                                                class="
                                            d-flex
                                            justify-content-start
                                            align-items-center
                                        "
                                            >
                                                <a href="http://maps.google.com/maps?saddr=Giza&daddr=Abu+an+Numros" class="d-flex mb-3" target="_blank"
                                                    ><i
                                                        class="fa fa-map-marker ml-2"
                                                    ></i>
                                                   ابو النمرس جيزة</a
                                                >
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <!-- <div class="col-lg-3 col-md-5 col-sm-6">
                                    <div class="widget">
                                        <h4 class="widget-title">
                                            {{ $t('nav.blog') }}
                                        </h4>

                                        <ul class="widget-list">
                                            <li
                                                class="
                                            d-flex
                                            justify-content-start
                                            align-items-center
                                            
                                        "
                                                v-for="categorie in categoriesBlog"
                                                :key="categorie.id"
                                            >
                                                <nuxt-link
                                                    :to="
                                                        localePath(
                                                            `/blog/single/default/${categorie.id}`
                                                        )
                                                    "
                                                    class=" h6 mr-4"
                                                >
                                                    {{ categorie.name }}
                                                </nuxt-link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div
                                    class="col-lg-3 col-md-4 col-sm-6 pl-5 d-lg-block d-none"
                                >
                                    <div class="widget">
                                        <h4 class="widget-title">
                                            {{ $t('helping.title') }}
                                        </h4>

                                        <ul class="widget-list">
                                            <li
                                                class="
                                            d-flex
                                            justify-content-start
                                            align-items-center
                                        "
                                            >
                                                <a href="/pages/aboutAlf">
                                                    {{ $t('helping.story') }}</a
                                                >
                                            </li>
                                            <li
                                                class="
                                            d-flex
                                            justify-content-start
                                            align-items-center
                                        "
                                            >
                                                <a href="/pages/contact">
                                                    {{
                                                        $t('helping.contact')
                                                    }}</a
                                                >
                                            </li>
                                            <li
                                                class="
                                            d-flex
                                            justify-content-start
                                            align-items-center
                                        "
                                            >
                                                <a href="#">
                                                    {{ $t('helping.faq') }}</a
                                                >
                                            </li>
                                            <li
                                                class="
                                            d-flex
                                            justify-content-start
                                            align-items-center
                                        "
                                            >
                                                <a href="#">
                                                    {{ $t('helping.term') }}</a
                                                >
                                            </li>
                                            <li
                                                class="
                                            d-flex
                                            justify-content-start
                                            align-items-center
                                        "
                                            >
                                                <a href="#">
                                                    {{
                                                        $t('helping.cancel')
                                                    }}</a
                                                >
                                            </li>
                                        </ul>
                                        <form action="" class="my-3 p-0">
                                            <h3>{{ $t('helping.sub') }}</h3>
                                            <p>
                                                {{ $t('helping.subDes') }}
                                            </p>
                                            <div class="form-group d-flex">
                                                <input
                                                    class="pl-2 email"
                                                    type="email"
                                                    name="email"
                                                    id="email"
                                                    :placeholder="
                                                        $t(
                                                            'footer.emailaddressplaceholder'
                                                        )
                                                    "
                                                />
                                                <div class="submit">
                                                    <input
                                                        class="bg-black text-white subscribe"
                                                        type="submit"
                                                        :value="
                                                            $t(
                                                                'footer.subscribe'
                                                            )
                                                        "
                                                    />
                                                </div>
                                            </div>
                                        </form>
                                        <ul class="list-unstyled p-0">
                                            <li class="my-2 text-md">
                                                <i
                                                    class="icon-exclamation-circle"
                                                ></i>
                                                <span>
                                                    <nuxt-link
                                                        :to="
                                                            localePath(
                                                                '/pages/contact'
                                                            )
                                                        "
                                                    >
                                                        {{
                                                            $t(
                                                                'footer.reportAptoblem'
                                                            )
                                                        }}
                                                    </nuxt-link>
                                                </span>
                                            </li>
                                        </ul>
                                    </div>
                                </div> -->
                            </div>
                            <!-- <div class="  d-lg-none d-block">
                                <div class="widget">
                                    <h4 class="widget-title">
                                        LET US HELP
                                    </h4>

                                    <ul class="widget-list">
                                        <li
                                            class="
                                            d-flex
                                            justify-content-start
                                            align-items-center
                                        "
                                        >
                                            <a href="/pages/aboutAlf"
                                                >Our story!</a
                                            >
                                        </li>
                                        <li
                                            class="
                                            d-flex
                                            justify-content-start
                                            align-items-center
                                        "
                                        >
                                            <a href="/pages/contact">
                                                contact us</a
                                            >
                                        </li>
                                        <li
                                            class="
                                            d-flex
                                            justify-content-start
                                            align-items-center
                                        "
                                        >
                                            <a href="#">FAQS</a>
                                        </li>
                                        <li
                                            class="
                                            d-flex
                                            justify-content-start
                                            align-items-center
                                        "
                                        >
                                            <a href="#">terms & policies</a>
                                        </li>
                                        <li
                                            class="
                                            d-flex
                                            justify-content-start
                                            align-items-center
                                        "
                                        >
                                            <a href="#"
                                                >cancellation payments</a
                                            >
                                        </li>
                                    </ul>
                                    <form action="" class="my-3 p-0">
                                        <h3>subscribe</h3>
                                        <p>
                                            subscribe now and get 10% discount
                                        </p>
                                        <div class="form-group d-flex">
                                            <input
                                                class="pl-2 email"
                                                type="email"
                                                name="email"
                                                id="email"
                                                :placeholder="
                                                    $t(
                                                        'footer.emailaddressplaceholder'
                                                    )
                                                "
                                            />
                                            <div class="submit">
                                                <input
                                                    class="bg-black text-white subscribe"
                                                    type="submit"
                                                    :value="
                                                        $t('footer.subscribe')
                                                    "
                                                />
                                            </div>
                                        </div>
                                    </form>
                                    <ul class="list-unstyled p-0">
                                        <li class="my-2 text-md">
                                            <i
                                                class="icon-exclamation-circle"
                                            ></i>
                                            <span>
                                                <nuxt-link
                                                    :to="
                                                        localePath(
                                                            '/pages/contact'
                                                        )
                                                    "
                                                >
                                                    {{
                                                        $t(
                                                            'footer.reportAptoblem'
                                                        )
                                                    }}
                                                </nuxt-link>
                                            </span>
                                        </li>
                                    </ul>
                                </div>
                            </div> -->
                        </div>
                        <!-- <p class="rights pb-2">
                            {{ $t('footer.uperFooter.right') }}
                            <i class="fa fa-copyright"></i>
                            {{ $t('footer.uperFooter.continue') }}
                        </p> -->
                    </div>
                </div>
            </div>

            <!-- <div class="footer-bottom"> -->
            <!-- <div
                    :class="isFullwidth ? 'container-fluid' : 'container'"
                    class="pb-2 pt-2"
                >
                    <p class="footer-copyright">
                        Copyright © {{ new Date().getFullYear() }} Molla Store.
                        All Rights Reserved.
                    </p>

                    <figure class="footer-payments">
                        <img
                            v-lazy="'./images/payments.png'"
                            alt="Payment methods"
                            class="bg-transparent"
                            width="272"
                            height="20"
                        />
                    </figure>
                </div> -->

            <!-- </div> -->

            <div class="mb-10" v-if="bottomSticky"></div>
            <!-- <div class="footer-bottom2 pt-3 last-foot bg-white">
                <div class="container">
                    <div class="row">
                        <div class="col-md-4 col-xs-6">
                            <div class="social">
                                <ul class="list-unstyled d-flex">
                                    <li class="facebook">
                                        <a
                                            href="https://www.facebook.com/alefplanet"
                                            rel="noopener"
                                            class="facebook"
                                            target="_blank"
                                        >
                                            <i
                                                class="fab fa-facebook-f fa-2x"
                                            ></i>
                                        </a>
                                    </li>
                                    <li class="youtube">
                                        <a
                                            href="https://www.youtube.com/channel/UC657uLMEPOf0r-x6Tl8KyRA"
                                            rel="noopener"
                                            target="_blank"
                                        >
                                            <span>
                                                <i
                                                    class="fab fa-youtube fa-2x"
                                                ></i>
                                            </span>
                                        </a>
                                    </li>
                                    <li class="instagram">
                                        <a
                                            href="https://www.instagram.com/alefplanet/"
                                            rel="noopener"
                                            target="_blank"
                                        >
                                            <span>
                                                <i
                                                    class="
                                                    fab
                                                    fa-instagram fa-2x
                                                "
                                                ></i>
                                            </span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-md-4 col-xs-6 mb-3">
                            <p class="text-dimied m-0 p-0">
                                {{ $t('footer.Copyright') }}
                            </p>
                        </div>
                        <div class="col-md-4 col-xs-6 visa">
                            <ul class="list-unstyled d-flex">
                                <li class="mx-4">
                                    <img
                                        v-lazy="
                                            './images/Alf/alf/icon/alf/noun_secure_282889.png'
                                        "
                                        alt="image"
                                        class="bg-transparent"
                                        width="100%"
                                        height="100%"
                                    />
                                </li>
                                <li>
                                    <img
                                        v-lazy="
                                            './images/Alf/alf/icon/alf/Group 65.png'
                                        "
                                        alt="image"
                                        class="bg-transparent"
                                        width="100%"
                                        height="100%"
                                    />
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div> -->
        </footer>
    </div>
</template>

<script>
import Repository, { baseUrl } from '~/repositories/repository.js';
import axios from 'axios';
export default {
    data: function() {
        return {
            bottomSticky: false,
            categories: [],
            categoriesBlog: []
        };
    },
    computed: {
        isFullwidth: function() {
            return this.$route.path.includes('fullwidth');
        }
    },
    watch: {
        $route: function() {
            this.handleBottomSticky();
        }
    },
    mounted: function() {
        this.handleBottomSticky();
        window.addEventListener('resize', this.handleBottomSticky, {
            passive: true
        });
        this.categoryTree();
        this.getBlog();
    },
    destroyed: function() {
        window.removeEventListener('resize', this.handleBottomSticky);
    },
    methods: {
        handleBottomSticky: function() {
            this.bottomSticky =
                this.$route.path.includes('/product/default') &&
                window.innerWidth > 991;
        },

        categoryTree() {
            axios
                .get(`${baseUrl}/categoryTree`, {
                    headers: {
                        language:
                            this.$i18n.locale == 'en'
                                ? 'LANGUAGE2'
                                : 'LANGUAGE1'
                    }
                })
                .then(resp => {
                    // console.log('categories', resp);
                    this.categories = resp.data.categories;
                    console.log('names', this.categories.name);
                })
                .catch(err => {
                    console.log(err);
                });
        },
        getBlog() {
            axios
                .get(`${baseUrl}/blog/blog_categories`, {
                    headers: {
                        language:
                            this.$i18n.locale == 'en'
                                ? 'LANGUAGE2'
                                : 'LANGUAGE1'
                    }
                })
                .then(resp => {
                    // console.log('categories', resp);
                    this.categoriesBlog = resp.data.categories;
                    // console.log('names', this.categories.name);
                })
                .catch(err => {
                    console.log(err);
                });
        }
    }
};
</script>

<style lang="scss" scoped>
.rights {
    width: 100%;
    text-align: center;
    font-size: 16px;
}
.logo {
    font-size: 50px;
    font-weight: bold;
    font-family: 'CairoRegular';
}
.footer {
    position: relative;
    background-position: left;

    h4,
    p,
    a {
        font-family: 'CairoRegular';
        text-transform: capitalize;
    }
    i.fa-map-marker {
        color: #fff;
    }
    h4 {
        text-transform: uppercase;
    }
    img {
        background: transparent;
    }

    img:nth-child(2) {
        position: absolute;
        bottom: 6%;
        left: 25%;
        width: 3%;
    }
    // .subscribe {
    //     background: linear-gradient(#ffdd00, #fbb034);
    //     position: relative;
    //     z-index: 0;
    //     &::after {
    //         content: '';
    //         display: block;
    //         width: 0;
    //         height: 100%;
    //         left: 0;
    //         position: absolute;
    //         top: 0;
    //         background: linear-gradient(#20bf55, #01baef);
    //         z-index: -1;
    //         border-radius: 24px;
    //         transition: 0.5s ease-in-out;
    //     }
    //     &:hover::after {
    //         width: 100%;
    //         transition: 0.5s;
    //         border-radius: 24px;
    //     }
    // }
    .footer-social {
        margin-top: 40px;
        .list-unstyled {
            justify-content: space-around;
            width: 65%;
            li {
                width: 23px;
                height: 23px;
                border-radius: 50%;
                background: #fff;
                text-align: center;
                a {
                    color: #337de7;
                    font-size: 9px;
                    font-weight: bold;
                    font-family: 'CairoRegular';
                    i {
                        font-size: 17px;
                    }
                }
            }
        }
    }
    li {
        a {
            font-size: 14px;
            font-family: 'CairoRegular';
        }
    }
    form {
        h3 {
            color: #fff;
            margin-bottom: 5px;
            font-size: 19px;
            margin-top: 25px;
            font-family: 'CairoRegular';
        }
        p {
            font-size: 10px;
            margin-bottom: 10px;
            font-family: 'CairoRegular';
        }
    }
}
.col-md-3 {
    line-height: 1.7;
    // opacity: 0.8;
}
// .footer-data {
//     align-items: center;
// }
.logo {
    margin: 0;
}
@media (max-width: 768px) {
    #email {
        width: 100%;
    }
    .widget {
        margin-top: 0;
    }
    .footer-data {
        align-items: unset;
        .logo {
            margin-top: 0;
            margin-bottom: 0;
        }
    }
}
</style>
