<template>
    <div>
        <!-- top 20 trending  -->
        <template v-if="!loading">
            <div class="col-9 m-auto skeleton-body skel-shop-products">
                <template>
                    <div
                        class="skel-pro skel-pro-list"
                        v-for="item in 1"
                        :key="item"
                    ></div>
                </template>
            </div>
        </template>
        <div class="mt-5" v-else>
            <section
                class="top-trending text-center"
                v-if="bestSelling.length > 0"
            >
                <div class="mb-5 text-right"></div>
                <div class="wrapper-with-margin">
                    <div class="container">
                        <div class="row align-items-center mb-5">
                            <div class="col-6">
                                <h2 class="h1 text-gray my-3 Offers text-right">
                                    {{ $t('trending.title') }}
                                </h2>
                            </div>
                            <hr>
                            <div class="col-6 show-all">
                                <nuxt-link
                                    :to="localePath('/shop/sidebar/list')"
                                    class="btn btn-primary btn-round p-4"
                                >
                                    <span>عرض الكل</span>
                                </nuxt-link>
                            </div>
                        </div>
                        <div
                            class="
                                swiper-carousel
                                brands-slider
                                swiper-2
                                mb-5
                                mt-5
                            "
                        >
                            <div v-swiper:swiper6="carouselSetting6">
                                <div class="swiper-wrapper">
                                    <div
                                        class="swiper-slide"
                                        v-for="trend in bestSelling"
                                        :key="trend.id"
                                    >
                                    <div class="card-holder">
 <div
                                                    class="card-info text-white"
                                                    
                                                >
                                                    Sale!
                                                </div>
                                        </div>
                                   
                                     <div class="rating card-info">
                                        <i class="fas fa-star"></i>
                                        <span>4.5</span>
                                    </div>
                                        <nuxt-link
                                            :to="
                                                localePath(
                                                    `/product/default/${trend.id}`
                                                )
                                            "
                                            class="brand"
                                            v-if="trend.main_image"
                                        >
                                            <img
                                                v-lazy="trend.main_image"
                                                class="bg-transparent"
                                                alt="Alf Logo"
                                                height="27"
                                                width="200"
                                            />
                                            <p class="text-dark h6 mt-2">
                                                {{ trend.name }}
                                            </p>
                                            <div class="row" v-if="trend.discount_int !== null">
                                                <div
                                                    class="col-md-6 col-sm-12"
                                                   
                                                >
                                                    <p class="price text-primary">
                                                      <span class="">  {{
                                                            trend.productPrice_after_discount
                                                        }} جنية</span>
                                                        
                                                    </p>
                                                </div>
                                                <div
                                                    class="col-md-6 col-sm-12"
                                                >
                                                    <p class="text-danger">
                                                        <del>{{
                                                            trend.price
                                                        }}</del>
                                                    </p>
                                                </div>
                                            </div>
                                            <div class="row" v-else>
                                                <div
                                                    class="col-md-12 col-sm-12"
                                                   
                                                >
                                                    <p class="price text-primary">
                                                      <span class="">  {{
                                                            trend.productPrice_after_discount
                                                        }} جنية</span>
                                                        
                                                    </p>
                                                </div>
                                            </div>
                                        </nuxt-link>
                                          <nuxt-link
                                            :to="
                                                localePath(
                                                    `/product/default/${trend.id}`
                                                )
                                            "
                                            class="brand"
                                            v-else
                                        >
                                            <img
                                                src="../../../../static/images/Alf/unnamed.png"
                                                class="bg-transparent"
                                                alt="Alf Logo"
                                                height="27"
                                                width="200"
                                            />
                                            <p class="text-dark h6 mt-2">
                                                {{ trend.name }}
                                            </p>
                                            <div class="row">
                                                <div
                                                    class="col-md-6 col-sm-12"
                                                    v-if="
                                                        trend.productPrice_after_discount
                                                    "
                                                >
                                                    <p class="text-primary">
                                                        {{
                                                            trend.productPrice_after_discount
                                                        }}
                                                    </p>
                                                </div>
                                                <div
                                                    class="col-md-6 col-sm-12"
                                                    v-if="
                                                        trend.price !=
                                                            trend.productPrice_after_discount
                                                    "
                                                >
                                                    <p class="text-danger">
                                                        <del>{{
                                                            trend.price
                                                        }}</del>
                                                    </p>
                                                </div>
                                            </div>
                                        </nuxt-link>
                                        <div class="actions">
                                            <div class="row">
                                                <div class="col">
                                                    <div
                                                        class="
                                                                        img-holder
                                                                        d-flex
                                                                        justify-content-center
                                                                        align-items-center
                                                                    "
                                                        :class="{
                                                            'btn-disabled': !canAddToCart(
                                                                trend,
                                                                1
                                                            )
                                                        }"
                                                    >
                                                        <button
                                                            class=""
                                                            @click.prevent="
                                                                addToCart({
                                                                    product: trend
                                                                })
                                                            "
                                                        >
                                                            <i
                                                                class="
                                                                                fas
                                                                                fa-cart-plus
                                                                                fa-2x
                                                                            "
                                                            ></i>
                                                        </button>
                                                    </div>
                                                </div>
                                                <div class="col">
                                                    <div
                                                        class="
                                                                        img-holder
                                                                        d-flex
                                                                        justify-content-center
                                                                        align-items-center
                                                                    "
                                                    >
                                                        <div
                                                            class="
                                                                            wishlist-outline
                                                                            d-flex
                                                                            justify-content-start
                                                                        "
                                                        >
                                                            <nuxt-link
                                                                :to="
                                                                    localePath(
                                                                        '/shop/wishlist'
                                                                    )
                                                                "
                                                                class="
                                                                                btn-product
                                                                            "
                                                                v-if="
                                                                    isInWishlist(
                                                                        trend
                                                                    )
                                                                "
                                                                key="inWishlist"
                                                            >
                                                                <i
                                                                    class="
                                                                                    fas
                                                                                    fa-heart
                                                                                "
                                                                ></i>
                                                            </nuxt-link>
                                                            <a
                                                                class="
                                                                                btn-product
                                                                            "
                                                                @click.prevent="
                                                                    addToWishlist(
                                                                        {
                                                                            product: trend
                                                                        }
                                                                    )
                                                                "
                                                                v-else
                                                                key="notInWishlist"
                                                            >
                                                                <i
                                                                    class="
                                                                                    far
                                                                                    fa-heart
                                                                                    fa-2x
                                                                                "
                                                                ></i>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <!-- <div class="col">
                                                    <div
                                                        class="
                                                                        img-holder
                                                                        share
                                                                    "
                                                    >
                                                        <nuxt-link to="/">
                                                            <i
                                                                class="
                                                                                fas
                                                                                fa-share
                                                                                fa-2x
                                                                            "
                                                            ></i>
                                                        </nuxt-link>
                                                    </div>
                                                </div> -->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="swiper-nav">
                                <div class="swiper-prev">
                                    <i class="icon-angle-left"></i>
                                </div>
                                <div class="swiper-next">
                                    <i class="icon-angle-right"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import axios from 'axios';
import {
    carouselSettingSingle,
    carouselSettingDefault
} from '~/utilities/carousel';
import Repository, { baseUrl } from '~/repositories/repository.js';

export default {
    data: function() {
        return {
            carouselSetting6: {
                ...carouselSettingDefault,
                slidesPerView: 4,
                spaceBetween: 30,
                navigation: {
                    nextEl: '.brands-slider .swiper-next',
                    prevEl: '.brands-slider .swiper-prev'
                },
                breakpoints: {
                    992: {
                        slidesPerView: 3
                    },
                    768: {
                        slidesPerView: 2,
                        navigation: true
                    },
                    576: {
                        slidesPerView: 1,
                        navigation: true
                    },
                    480: {
                        slidesPerView: 1,
                        navigation: true
                    }
                }
            },
            bestSelling: [],
            loading: false
        };
    },
    methods: {
        ...mapActions('cart', ['addToCart']),
        ...mapActions('wishlist', ['addToWishlist']),
        ...mapActions('compare', ['addToCompare']),
        getData: async function() {
            let bestSelling = `${baseUrl}/best/selling/products`;
            // best selling
            await Repository.post(bestSelling).then(resp => {
                // bestSelling ==> top trending
                if (resp.data.code == 200) {
                    this.loading = true;
                }
                this.bestSelling = resp.data.best_seller_products;
                console.log('bestSelling', resp);
            });
        }
    },
    computed: {
        ...mapGetters('cart', ['canAddToCart']),
        ...mapGetters('wishlist', ['isInWishlist']),
        ...mapGetters('compare', ['isInCompare']),
        fakeArray: function() {
            let temp = [];
            for (let i = 0; i < this.perPage; i++) {
                temp.push(i);
            }
            return temp;
        }
    },
    mounted() {
        this.getData();
    },
    props: ['product']
};
</script>

<style lang="scss">
hr{
    width: 50%;
    position: absolute;
    left: 19%;
    border-top: 1px solid #9a9999;
}
.col{
    padding: 0px 5px 0px 5px;
}
.text-primary{
    font-weight: bold;
}
.text-danger {
    // width: 50px;
    font-weight: bold;
}
.actions {
        margin-top: 15px;
            display: flex;
    justify-content: center;
    .img-holder {
        box-shadow: 0px 0px 6px 1px #ccc;
        width: 35px;
        padding: 10px 2px !important;
        border-radius: 50%;
        height: 35px;
        line-height: 20px;
        margin: auto;
        i {
                font-size: 22px;
                // line-height: 0;
            }
        
    }
}
.top-trending{
    .swiper-slide{
            padding: 10px;
    border-radius: 10px;
    border: 1px solid #f6f7f8;
    }
}
@media(max-width:768px){
    .top-trending{
        .row{
            margin-bottom: 10px !important;
        }
        h2{
            font-size: 30px;
        }
        .show-all{
            a{
                    padding: 10px !important;
            }
        }
        .brands-slider{
            margin-top: 0 !important;
            .actions .img-holder i{
                line-height: 0 !important;
            }
        }
    }
}
@media(max-width: 425px){
    hr{
        display: none;
    }

}
</style>
