<template>
    <div>
        <template v-if="!loading">
            <div class="col-9 m-auto skeleton-body skel-shop-products">
                <template>
                    <div
                        class="skel-pro skel-pro-list"
                        v-for="item in 1"
                        :key="item"
                    ></div>
                </template>
            </div>
        </template>
        <div class="" v-else>
            <!-- <section
                v-for="banner in banners.second"
                :key="banner.id"
                class="travel-sec py-5"
                v-lazy:background-image="banner.background_image"
            > -->
             <section
                v-for="banner in banners.second"
                :key="banner.id"
                class="travel-sec py-5"
               
            >
            <a :href="banner.button_link" v-if="$i18n.locale == 'en'" target="_blank">
                <img v-lazy="banner.background_image"/>
            </a>
            <a :href="banner.button_link" v-if="$i18n.locale == 'ar'" target="_blank">
                <img v-lazy="banner.background_image_ar"/>
            </a>
                <!-- <div class="container text-center">
                    <div class="row">
                        <div class="col-md-7 col-sm-12">
                            
                            <h2 class="h1" >
                                {{ banner.title }}
                            </h2>
                            <p
                                class="text-white"
                                
                            >
                                {{ banner.description }}
                            </p>
                            
                            <div class="my-5">
                            </div>
                        </div>
                        <div class="col-md-5 col-sm-12"></div>
                    </div>
                </div> -->
            </section>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import axios from 'axios';
import {
    carouselSettingSingle,
    carouselSettingDefault,
} from '~/utilities/carousel';
import Repository, { baseUrl } from '~/repositories/repository.js';

export default {
    data: function () {
        return {
            banners: {
                first: [],
            },
            loading: false,
        };
    },
    methods: {
        async getData() {
            let banners = `${baseUrl}/banners`;
            // banners
            await Repository.get(banners)
                .then((resp) => {
                    //banners
                    if (resp.data.code == 200) {
                        this.loading = true;
                    }
                    this.banners = resp.data.banners;
                    // console.log('banners', this.banners);
                })
                
        },
    },
    mounted() {
        this.getData();
    },
};
</script>

<style>
</style>