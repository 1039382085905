var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: _vm.wrap ? _vm.wrap : "product-countdown is-countdown" },
    [
      _vm.format !== "HMS" && _vm.labelsShort
        ? _c("span", { staticClass: "countdown-row countdown-show4" }, [
            _c("span", { staticClass: "countdown-section" }, [
              _c("span", { staticClass: "countdown-amount" }, [
                _vm._v(_vm._s(10 > _vm.days ? "0" + _vm.days : _vm.days))
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "countdown-period" }, [_vm._v("Days")])
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "countdown-section" }, [
              _c("span", { staticClass: "countdown-amount" }, [
                _vm._v(_vm._s(10 > _vm.hours ? "0" + _vm.hours : _vm.hours))
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "countdown-period" }, [_vm._v("Hours")])
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "countdown-section" }, [
              _c("span", { staticClass: "countdown-amount" }, [
                _vm._v(
                  _vm._s(10 > _vm.minutes ? "0" + _vm.minutes : _vm.minutes)
                )
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "countdown-period" }, [_vm._v("Mins")])
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "countdown-section" }, [
              _c("span", { staticClass: "countdown-amount" }, [
                _vm._v(
                  _vm._s(10 > _vm.seconds ? "0" + _vm.seconds : _vm.seconds)
                )
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "countdown-period" }, [_vm._v("Secs")])
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.format !== "HMS" && !_vm.labelsShort && !_vm.compact
        ? _c("span", { staticClass: "countdown-row countdown-show4" }, [
            _c("span", { staticClass: "countdown-section" }, [
              _c("span", { staticClass: "countdown-amount" }, [
                _vm._v(_vm._s(10 > _vm.days ? "0" + _vm.days : _vm.days))
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "countdown-period" }, [_vm._v("Days")])
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "countdown-section" }, [
              _c("span", { staticClass: "countdown-amount" }, [
                _vm._v(_vm._s(10 > _vm.hours ? "0" + _vm.hours : _vm.hours))
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "countdown-period" }, [_vm._v("Hours")])
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "countdown-section" }, [
              _c("span", { staticClass: "countdown-amount" }, [
                _vm._v(
                  _vm._s(10 > _vm.minutes ? "0" + _vm.minutes : _vm.minutes)
                )
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "countdown-period" }, [
                _vm._v("Minutes")
              ])
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "countdown-section" }, [
              _c("span", { staticClass: "countdown-amount" }, [
                _vm._v(
                  _vm._s(10 > _vm.seconds ? "0" + _vm.seconds : _vm.seconds)
                )
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "countdown-period" }, [
                _vm._v("Seconds")
              ])
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.format !== "HMS" && !_vm.labelsShort && _vm.compact
        ? _c("span", { staticClass: "countdown-row countdown-amount" }, [
            _vm._v(
              _vm._s(
                _vm.days +
                  " DAYS, " +
                  (10 > _vm.hours ? "0" : "") +
                  _vm.hours +
                  ": " +
                  (10 > _vm.minutes ? "0" : "") +
                  _vm.minutes +
                  ": " +
                  (10 > _vm.seconds ? "0" : "") +
                  _vm.seconds
              )
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.format === "HMS" && !_vm.labelsShort
        ? _c("span", { staticClass: "countdown-row countdown-show3" }, [
            _c("span", { staticClass: "countdown-section" }, [
              _c("span", { staticClass: "countdown-amount" }, [
                _vm._v(_vm._s(10 > _vm.hours ? "0" + _vm.hours : _vm.hours))
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "countdown-period" }, [_vm._v("Hours")])
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "countdown-section" }, [
              _c("span", { staticClass: "countdown-amount" }, [
                _vm._v(
                  _vm._s(10 > _vm.minutes ? "0" + _vm.minutes : _vm.minutes)
                )
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "countdown-period" }, [
                _vm._v("Minutes")
              ])
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "countdown-section" }, [
              _c("span", { staticClass: "countdown-amount" }, [
                _vm._v(
                  _vm._s(10 > _vm.seconds ? "0" + _vm.seconds : _vm.seconds)
                )
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "countdown-period" }, [
                _vm._v("Seconds")
              ])
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.format === "HMS" && _vm.labelsShort
        ? _c("span", { staticClass: "countdown-row countdown-show3" }, [
            _c("span", { staticClass: "countdown-section" }, [
              _c("span", { staticClass: "countdown-amount" }, [
                _vm._v(_vm._s(10 > _vm.hours ? "0" + _vm.hours : _vm.hours))
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "countdown-period" }, [_vm._v("hrs")])
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "countdown-section" }, [
              _c("span", { staticClass: "countdown-amount" }, [
                _vm._v(
                  _vm._s(10 > _vm.minutes ? "0" + _vm.minutes : _vm.minutes)
                )
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "countdown-period" }, [_vm._v("mins")])
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "countdown-section" }, [
              _c("span", { staticClass: "countdown-amount" }, [
                _vm._v(
                  _vm._s(10 > _vm.seconds ? "0" + _vm.seconds : _vm.seconds)
                )
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "countdown-period" }, [_vm._v("secs")])
            ])
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }