var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      !_vm.loading
        ? [
            _c(
              "div",
              { staticClass: "col-9 m-auto skeleton-body skel-shop-products" },
              [
                _vm._l(1, function(item) {
                  return _c("div", {
                    key: item,
                    staticClass: "skel-pro skel-pro-list"
                  })
                })
              ],
              2
            )
          ]
        : _c("div", {}, [
            _vm.hotDicounts.length > 0
              ? _c(
                  "section",
                  {
                    staticClass: "baby-producst third-carousel text-center mt-5"
                  },
                  [
                    _c("div", { staticClass: "wrapper-with-margin" }, [
                      _c("div", { staticClass: "container" }, [
                        _c("h2", { staticClass: "h1 text-gray my-3 Offers" }, [
                          _vm._v(
                            "\n                        " +
                              _vm._s(_vm.$t("discountOffer.title")) +
                              "\n                    "
                          )
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "swiper-carousel brands-slider swiper-2 mb-5 mt-5"
                          },
                          [
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "swiper",
                                    rawName: "v-swiper:swiper3",
                                    value: _vm.carouselSetting3,
                                    expression: "carouselSetting3",
                                    arg: "swiper3"
                                  }
                                ]
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "swiper-wrapper" },
                                  _vm._l(_vm.hotDicounts, function(hot) {
                                    return _c(
                                      "div",
                                      {
                                        key: hot.id,
                                        staticClass: "swiper-slide"
                                      },
                                      [
                                        _c(
                                          "nuxt-link",
                                          {
                                            staticClass: "brand",
                                            attrs: {
                                              to: _vm.localePath(
                                                `/product/default/${hot.id}`
                                              )
                                            }
                                          },
                                          [
                                            hot.product_main_image
                                              ? _c("img", {
                                                  directives: [
                                                    {
                                                      name: "lazy",
                                                      rawName: "v-lazy",
                                                      value:
                                                        hot.product_main_image,
                                                      expression:
                                                        "hot.product_main_image"
                                                    }
                                                  ],
                                                  staticClass: "bg-transparent",
                                                  attrs: {
                                                    alt: "Alf Logo",
                                                    height: "27",
                                                    width: "200"
                                                  }
                                                })
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _c(
                                              "p",
                                              { staticClass: "text-dark" },
                                              [
                                                _vm._v(
                                                  "\n                                            " +
                                                    _vm._s(hot.name) +
                                                    "\n                                        "
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            hot.productPrice_after_discount
                                              ? _c("div", [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "text-primary mr-5 text-bold"
                                                    },
                                                    [
                                                      _c("b", [
                                                        _vm._v(
                                                          "\n                                                    " +
                                                            _vm._s(
                                                              hot.productPrice_after_discount
                                                            ) +
                                                            "\n                                                "
                                                        )
                                                      ])
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass: "text-danger"
                                                    },
                                                    [
                                                      _c("b", [
                                                        _c("del", [
                                                          _vm._v(
                                                            _vm._s(hot.price)
                                                          )
                                                        ])
                                                      ])
                                                    ]
                                                  )
                                                ])
                                              : _vm._e()
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  }),
                                  0
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _vm._m(0)
                          ]
                        )
                      ])
                    ])
                  ]
                )
              : _vm._e()
          ])
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "swiper-nav" }, [
      _c("div", { staticClass: "swiper-prev" }, [
        _c("i", { staticClass: "icon-angle-left" })
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "swiper-next" }, [
        _c("i", { staticClass: "icon-angle-right" })
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }