var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "portfolio" }, [
    _c("figure", { staticClass: "portfolio-media" }, [
      _c("a", { attrs: { href: "#" } }, [
        _c("img", { attrs: { src: _vm.image, alt: "item" } })
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "portfolio-content" }, [
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "portfolio-tags" }, [
        _c("a", { attrs: { href: "#" } }, [_vm._v(_vm._s(_vm.category))])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("h3", { staticClass: "portfolio-title" }, [
      _c("a", { attrs: { href: "#" } }, [_vm._v("Vestibulum auctor dapibus")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }