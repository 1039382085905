export const portfolio1 = [{
        id: 1,
        class: 'accessories women',
        image: './images/portfolio/item-1.jpg',
        category: 'Accessories'
    },
    {
        id: 2,
        class: 'men',
        image: './images/portfolio/item-2.jpg',
        category: 'Men'
    },
    {
        id: 3,
        class: 'women accessories',
        image: './images/portfolio/item-3.jpg',
        category: 'Women'
    },
    {
        id: 4,
        class: 'men',
        image: './images/portfolio/item-4.jpg',
        category: 'Accessories'
    },
    {
        id: 5,
        class: 'men women',
        image: './images/portfolio/item-5.jpg',
        category: 'Women'
    },
    {
        id: 6,
        class: 'men accessories',
        image: './images/portfolio/item-6.jpg',
        category: 'Men'
    }
]

export const portfolio2 = [{
        class: "accessories women",
        image: "./images/portfolio/4cols/item-1.jpg",
        title: "Vestibulum auctor dapibus",
        category: "Accessories"
    },
    {
        class: "men",
        image: "./images/portfolio/4cols/item-2.jpg",
        title: "Vestibulum auctor dapibus",
        category: "Men"
    },
    {
        class: "women accessories",
        image: "./images/portfolio/4cols/item-3.jpg",
        title: "Vestibulum auctor dapibus",
        category: "Women"
    },
    {
        class: "men",
        image: "./images/portfolio/4cols/item-4.jpg",
        title: "Vestibulum auctor dapibus",
        category: "Accessories"
    },
    {
        class: "men women",
        image: "./images/portfolio/4cols/item-5.jpg",
        title: "Vestibulum auctor dapibus",
        category: "Women"
    },
    {
        class: "men accessories",
        image: "./images/portfolio/4cols/item-6.jpg",
        title: "Vestibulum auctor dapibus",
        category: "Men"
    },
    {
        class: "men",
        image: "./images/portfolio/4cols/item-7.jpg",
        title: "Vestibulum auctor dapibus",
        category: "Men"
    },
    {
        class: "women accessories",
        image: "./images/portfolio/4cols/item-8.jpg",
        title: "Vestibulum auctor dapibus",
        category: "Women"
    }
]

export const portfolio3 = [{
        class: "accessories women",
        image: "./images/portfolio/masonry-3cols/item-1.jpg",
        title: "Vestibulum auctor dapibus",
        category: "Accessories",
    },
    {
        class: "men",
        image: "./images/portfolio/masonry-3cols/item-2.jpg",
        title: "Vestibulum auctor dapibus",
        category: "Men",
    },
    {
        class: "women accessories",
        image: "./images/portfolio/masonry-3cols/item-3.jpg",
        title: "Vestibulum auctor dapibus",
        category: "Women",
    },
    {
        class: "men",
        image: "./images/portfolio/masonry-3cols/item-4.jpg",
        title: "Vestibulum auctor dapibus",
        category: "Accessories",
    },
    {
        class: "men women",
        image: "./images/portfolio/masonry-3cols/item-5.jpg",
        title: "Vestibulum auctor dapibus",
        category: "Women",
    },
    {
        class: "men accessories",
        image: "./images/portfolio/masonry-3cols/item-6.jpg",
        title: "Vestibulum auctor dapibus",
        category: "Men",
    }
]

export const portfolio4 = [{
        class: "accessories women",
        image: "./images/portfolio/masonry-4cols/item-1.jpg",
        title: "Vestibulum auctor dapibus",
        category: "Accessories",
    },
    {
        class: "men",
        image: "./images/portfolio/masonry-4cols/item-2.jpg",
        title: "Vestibulum auctor dapibus",
        category: "Men",
    },
    {
        class: "women accessories",
        image: "./images/portfolio/masonry-4cols/item-3.jpg",
        title: "Vestibulum auctor dapibus",
        category: "Women",
    },
    {
        class: "men",
        image: "./images/portfolio/masonry-4cols/item-4.jpg",
        title: "Vestibulum auctor dapibus",
        category: "Accessories",
    },
    {
        class: "men women",
        image: "./images/portfolio/masonry-4cols/item-5.jpg",
        title: "Vestibulum auctor dapibus",
        category: "Women",
    },
    {
        class: "men accessories",
        image: "./images/portfolio/masonry-4cols/item-6.jpg",
        title: "Vestibulum auctor dapibus",
        category: "Men",
    },
    {
        class: "men",
        image: "./images/portfolio/masonry-4cols/item-7.jpg",
        title: "Vestibulum auctor dapibus",
        category: "Men",
    },
    {
        class: "women accessories",
        image: "./images/portfolio/masonry-4cols/item-8.jpg",
        title: "Vestibulum auctor dapibus",
        category: "Women",
    }
]

export const portfolio5 = [{
        class: "accessories women",
        image: "./images/portfolio/fullwidth/item-1.jpg",
        title: "Vestibulum auctor dapibus",
        category: "Accessories"
    },
    {
        class: "accessories",
        image: "./images/portfolio/fullwidth/item-2.jpg",
        title: "Nunc dignissim risus",
        category: "Accessories"
    },
    {
        class: "men accessories",
        image: "./images/portfolio/fullwidth/item-3.jpg",
        title: "Cras ornare tristique",
        category: "Men"
    },
    {
        class: "men",
        image: "./images/portfolio/fullwidth/item-4.jpg",
        title: "Vivamus vestibulum ntulla",
        category: "Men"
    },
    {
        class: "men women",
        image: "./images/portfolio/fullwidth/item-5.jpg",
        title: "Vestibulum auctor dapibus",
        category: "Women"
    },
    {
        class: "men accessories",
        image: "./images/portfolio/fullwidth/item-6.jpg",
        title: "Nunc dignissim risus",
        category: "Accessories"
    },
    {
        class: "women accessories",
        image: "./images/portfolio/fullwidth/item-7.jpg",
        title: "Cras ornare tristique",
        category: "Women"
    },
    {
        class: "men accessories",
        image: "./images/portfolio/fullwidth/item-8.jpg",
        title: "Vivamus vestibulum ntulla",
        category: "Accessories"
    }
]

export const shopData = {
    "sizes": [{
            size: 'Extra Small',
            slug: 'XS'
        },
        {
            size: 'Small',
            slug: 'S'
        },
        {
            size: 'Medium',
            slug: 'M'
        },
        {
            size: 'Large',
            slug: 'L'
        },
        {
            size: 'Extra Large',
            slug: 'XL'
        }
    ],

    "colors": [{
            color: '#cc9966',
            color_name: 'Brown'
        },
        {
            color: '#3399cc',
            color_name: 'Blue'
        },
        {
            color: '#9966cc',
            color_name: 'Purple'
        },
        {
            color: '#333333',
            color_name: 'Black'
        },
        {
            color: '#669933',
            color_name: 'Green'
        },
        {
            color: '#cc3333',
            color_name: 'Red'
        },
        {
            color: '#999999',
            color_name: 'Grey'
        }
    ],

    "brands": [{
            brand: "Next",
            slug: "next"
        },
        {
            brand: "River Island",
            slug: "river-island"
        },
        {
            brand: "Geox",
            slug: "geox"
        },
        {
            brand: "New Balance",
            slug: "nex-balance"
        },
        {
            brand: "UGG",
            slug: "ugg"
        },
        {
            brand: "F&F",
            slug: "f-and-f"
        },
        {
            brand: "Nike",
            slug: "nike"
        }
    ],

    "categories": [{
            name: "Furniture",
            slug: "furniture",
            count: 8
        },
        {
            name: "Coffee & Tables",
            slug: "coffee-and-tables",
            count: 1
        },
        {
            name: "Lighting",
            slug: "lighting",
            count: 3
        },
        {
            name: "Decoration",
            slug: "decoration",
            count: 5
        },
        {
            name: "Electronics",
            slug: "electronics",
            count: 1
        },
        {
            name: "Beds",
            slug: "beds",
            count: 2
        },
        {
            name: "Armchairs & Chaises",
            slug: "armchairs-and-chaises",
            count: 3
        },
        {
            name: "Sofas & Sleeper Sofas",
            slug: "sofas-and-sleeper-sofas",
            count: 1
        }
    ],

    "prices": [{
            min: '0',
            max: '25',
            name: 'Under $25'
        },
        {
            min: '25',
            max: '50',
            name: '$25 to $50'
        },
        {
            min: '50',
            max: '100',
            name: '$50 to $100'
        },
        {
            min: '100',
            max: '200',
            name: '$100 to $200'
        },
        {
            min: '200',
            max: null,
            name: '$200 & Above'
        },
    ]
}

export const homeData = {
    brands: [{
            "name": "brand",
            "image": "./images/brands/1.png",
            "width": 100,
            "height": 23
        },
        {
            "name": "brand",
            "image": "./images/brands/2.png",
            "width": 101,
            "height": 34
        },
        {
            "name": "brand",
            "image": "./images/brands/3.png",
            "width": 100,
            "height": 30
        },
        {
            "name": "brand",
            "image": "./images/brands/4.png",
            "width": 101,
            "height": 39
        },
        {
            "name": "brand",
            "image": "./images/brands/5.png",
            "width": 100,
            "height": 48
        },
        {
            "name": "brand",
            "image": "./images/brands/6.png",
            "width": 100,
            "height": 23
        },
        {
            "name": "brand",
            "image": "./images/brands/7.png",
            "width": 63,
            "height": 32
        },
        {
            "name": "brand",
            "image": "./images/brands/8.png",
            "width": 80,
            "height": 72
        },
        {
            "name": "brand",
            "image": "./images/brands/9.png",
            "width": 100,
            "height": 25
        }
    ]
}

export const places = {
    "category": [
        {
            size: 'Extra Small',
            slug: 'XS'
        },
        {
            size: 'Small',
            slug: 'S'
        },
        {
            size: 'Medium',
            slug: 'M'
        },
        {
            size: 'Large',
            slug: 'L'
        },
        {
            size: 'Extra Large',
            slug: 'XL'
        }
    ],
    "Categories": [
        {
            category: "Hospitals",
            slug: "hospitals"
        },
        {
            category: "School",
            slug: "school"
        },
        {
            category: "Geox",
            slug: "geox"
        },
        {
            category: "Clinics",
            slug: "clinics"
        },
        {
            category: "More",
            slug: "more"
        },
    ],

    "categories": [{
            name: "Furniture",
            slug: "furniture",
            count: 8
        },
        {
            name: "Coffee & Tables",
            slug: "coffee-and-tables",
            count: 1
        },
        {
            name: "Lighting",
            slug: "lighting",
            count: 3
        },
        {
            name: "Decoration",
            slug: "decoration",
            count: 5
        },
        {
            name: "Electronics",
            slug: "electronics",
            count: 1
        },
        {
            name: "Beds",
            slug: "beds",
            count: 2
        },
        {
            name: "Armchairs & Chaises",
            slug: "armchairs-and-chaises",
            count: 3
        },
        {
            name: "Sofas & Sleeper Sofas",
            slug: "sofas-and-sleeper-sofas",
            count: 1
        }
    ],

    "prices": [{
            min: '0',
            max: '25',
            name: 'Under $25'
        },
        {
            min: '25',
            max: '50',
            name: '$25 to $50'
        },
        {
            min: '50',
            max: '100',
            name: '$50 to $100'
        },
        {
            min: '100',
            max: '200',
            name: '$100 to $200'
        },
        {
            min: '200',
            max: null,
            name: '$200 & Above'
        },
    ]
}

