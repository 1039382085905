<template>
    <div>
        <section class="choose-us text-center">
            <div class="container">
                
                <h2 class="h1">{{$t('choose.title1')}} <span>{{$t('choose.title2')}}</span></h2>
                <br />
                <br />
                <div class="row">
                    <div class="col-md-3 col-sm-12">
                        <div class="holder">
                            <img
                                v-lazy="'./images/Alf/alf/choose/Group 1.png'"
                                alt="image "
                                class="img-fluid bg-transparent m-auto"
                            />
                        </div>
                        <p>
                            {{$t('choose.secFourTitle')}}
                        </p>
                        <span class="text-dimied"
                            >{{$t('choose.secFourPara')}}</span
                        >
                    </div>
                    <div class="col-md-3 col-sm-12">
                        <div class="holder">
                            <img
                                v-lazy="'./images/Alf/alf/choose/Group 2.png'"
                                alt="image"
                                class="img-fluid bg-transparent m-auto"
                            />
                        </div>
                        <p>{{$t('choose.secThreeTitle')}}</p>
                        <span class="text-dimied"
                            >{{$t('choose.secThreePara')}}</span
                        >
                    </div>
                    <div class="col-md-3 col-sm-12">
                        <div class="holder">
                            <img
                                v-lazy="'./images/Alf/alf/choose/Group 33.png'"
                                alt="image"
                                class="img-fluid bg-transparent m-auto"
                            />
                        </div>
                        <p>{{$t('choose.secTwoTitle')}}</p>
                        <span class="text-dimied"
                            >{{$t('choose.secTwoPara')}}</span
                        >
                    </div>
                    <div class="col-md-3 col-sm-12">
                        <div class="holder">
                            <img
                                v-lazy="'./images/Alf/alf/choose/Group 4.png'"
                                alt="image"
                                class="img-fluid bg-transparent m-auto"
                            />
                        </div>
                        <p>{{$t('choose.secOneTitle')}}</p>
                        <span class="text-dimied"
                            >{{$t('choose.secOnePara')}}</span
                        >
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
// import Repository, { baseUrl } from '~/repositories/repository.js';

export default {};
</script>

<style>
</style>