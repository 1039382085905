<template>
    <div class="product-gallery product-gallery-vertical" v-if="product">
        <div class="row m-0 images-carousel" v-if="product[0].images.length > 0">
            <div class="swiper-carousel swiper-11" >
                <div v-swiper:swiper11="carouselSetting1">
                    <div class="swiper-wrapper">
                        <div
                            class="swiper-slide"
                            v-for="image in product[0].images"
                            :key="image.id"
                            
                        >
                          
                                
                                    
                                        <div
                                            class="
                                                image-carousel
                                            "
                                        >
                                            <img
                                                v-lazy="image"
                                                class="bg-transparent"
                                                alt="product side image"
                                                width="450"
                                                height="27"
                                            />
                                        </div>
                                   
                               
                            
                        </div>
                    </div>
                </div>
                <div class="swiper-nav nav-rounded">
                    <div class="swiper-prev">
                        <i class="icon-angle-left"></i>
                    </div>
                    <div class="swiper-next">
                        <i class="icon-angle-right"></i>
                    </div>
                </div>
                <div class="swiper-dots swiper-dots-inner"></div>
            </div>
        </div>
        <div v-else>
            <div class="swiper-carousel carousel-with-shadow">
                <div v-swiper:swiper2="carouselSetting">
                    <div class="swiper-wrapper">
                        <!-- <div
                            class="swiper-slide w-100 h-100"
                            v-for="item in [1]"
                            :key="item"
                        >
                            <div class="skel-pro"></div>
                        </div> -->
                        <img v-lazy="'/images/Alf/unnamed.png'" class="w-100" alt="image" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import LightBox from 'vue-image-lightbox';

import { VueLazyloadImage } from 'vue-lazyload';
import 'vue-image-lightbox/dist/vue-image-lightbox.min.css';
import { baseUrl } from '~/repositories/repository.js';
import { carouselSetting1 } from '~/utilities/carousel';
import {
    carouselSettingDefault,
    carouselSettingSingle,
} from '~/utilities/carousel';
export default {
    components: {
        LightBox,
    },
    props: {
        product: {
            type: Array,
            default: function () {
                return {
                    sm_pictures: [],
                    pictures: [],
                };
            },
        },
    },
    data: function () {
        return {
            baseUrl: baseUrl,
            currentIndex: 0,
            // carouselSetting: {
            //     ...carouselSetting1,
            //     navigation: {
            //         nextEl: '.swiper-1 .swiper-next',
            //         prevEl: '.swiper-1 .swiper-prev',
            //     },
            //     pagination: {
            //         el: '.swiper-1 .swiper-dots',
            //         clickable: true,
            //     },
            // },
            carouselSetting1: {
                ...carouselSettingSingle,
                 spaceBetween: 0,
                slidesPerView: 1,
                pagination: {
                    el: '.swiper-11 .swiper-dots',
                    dynamicBullets: true,
                    clickable: true,
                },
                navigation: {
                    nextEl: '.swiper-11 .swiper-next',
                    prevEl: '.swiper-11 .swiper-prev',
                },
                breakpoints: {
                    992: {
                        slidesPerView: 1,
                    },
                    768: {
                        slidesPerView: 1,
                    },
                    576: {
                        slidesPerView: 1,
                    },
                    480: {
                        slidesPerView: 1,
                    },
                },
            },
        };
    },
    computed: {
        lightBoxMedia: function () {
            return this.product[0].images.reduce((acc) => {
                return [
                    ...acc,
                    {
                        thumb: this.product[0].images[this.currentIndex],
                        src: this.product[0].images[this.currentIndex],
                        caption: this.product.name,
                    },
                ];
            }, []);
        },
    },
    methods: {
        changePicture: function (index) {
            this.currentIndex = index;
        },
        openLightBox: function () {
            this.$refs.lightbox.showImage(this.currentIndex);
        },
    },
};
</script>
<style lang="scss" scoped>
// .vue-lb-thumbnail-wrapper{
//     display: none !important;
// }
.intro-slider .swiper-nav .swiper-next {
    left: 91% !important;
    background: rgb(233, 132, 17);
    margin-left: 0 !important;
}
.intro-slider .swiper-nav .swiper-prev {
    left: 0% !important;
    background: rgb(233, 132, 17);
    margin-left: 0 !important;
}
</style>