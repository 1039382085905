var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-lg-6 deal-col" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "lazy",
                rawName: "v-lazy:background-image",
                value: "./images/home/deals/bg-1.jpg",
                expression: "'./images/home/deals/bg-1.jpg'",
                arg: "background-image"
              }
            ],
            staticClass: "deal"
          },
          [
            _vm._m(1),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "deal-content" },
              [
                _c(
                  "h3",
                  { staticClass: "product-title" },
                  [
                    _c(
                      "nuxt-link",
                      {
                        attrs: {
                          to:
                            "/product/default/home-smart-speaker-with-google-assistant"
                        }
                      },
                      [_vm._v("Home Smart Speaker with Google Assistant")]
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _vm._m(2),
                _vm._v(" "),
                _c(
                  "nuxt-link",
                  {
                    staticClass: "btn btn-link",
                    attrs: {
                      to:
                        "/product/default/home-smart-speaker-with-google-assistant"
                    }
                  },
                  [
                    _c("span", [_vm._v("Shop Now")]),
                    _vm._v(" "),
                    _c("i", { staticClass: "icon-long-arrow-right" })
                  ]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "deal-bottom" },
              [
                _c("count-down", {
                  attrs: {
                    wrap: "deal-countdown daily-deal-countdown",
                    until: "+10h",
                    format: "HMS"
                  }
                })
              ],
              1
            )
          ]
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-lg-6 deal-col" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "lazy",
                rawName: "v-lazy:background-image",
                value: "./images/home/deals/bg-2.jpg",
                expression: "'./images/home/deals/bg-2.jpg'",
                arg: "background-image"
              }
            ],
            staticClass: "deal"
          },
          [
            _vm._m(3),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "deal-content" },
              [
                _c(
                  "h3",
                  { staticClass: "product-title" },
                  [
                    _c(
                      "nuxt-link",
                      {
                        attrs: {
                          to:
                            "/product/default/microsoft-refurbish-xbox-one-s-500gb"
                        }
                      },
                      [_vm._v("Microsoft – Refurbish Xbox One S 500GB")]
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _vm._m(4),
                _vm._v(" "),
                _c(
                  "nuxt-link",
                  {
                    staticClass: "btn btn-link",
                    attrs: { to: "/pages/login" }
                  },
                  [
                    _c("span", [_vm._v("Sign In and Save money")]),
                    _vm._v(" "),
                    _c("i", { staticClass: "icon-long-arrow-right" })
                  ]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "deal-bottom" },
              [
                _c("count-down", {
                  attrs: {
                    wrap: "deal-countdown daily-deal-countdown",
                    until: "+11h",
                    format: "HMS"
                  }
                })
              ],
              1
            )
          ]
        )
      ])
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "more-container text-center mt-1 mb-5" },
      [
        _c(
          "nuxt-link",
          {
            staticClass: "btn btn-outline-dark-2 btn-round btn-more",
            attrs: { to: "/shop/sidebar/3cols" }
          },
          [
            _c("span", [_vm._v("Shop more Outlet deals")]),
            _vm._v(" "),
            _c("i", { staticClass: "icon-long-arrow-right" })
          ]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "heading text-center mb-3" }, [
      _c("h2", { staticClass: "title" }, [_vm._v("Deals & Outlet")]),
      _vm._v(" "),
      _c("p", { staticClass: "title-desc" }, [_vm._v("Today’s deal and more")])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "deal-top" }, [
      _c("h2", [_vm._v("Deal of the Day.")]),
      _vm._v(" "),
      _c("h4", [_vm._v("Limited quantities.")])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "product-price" }, [
      _c("span", { staticClass: "new-price" }, [_vm._v("$129.99")]),
      _vm._v(" "),
      _c("span", { staticClass: "old-price" }, [_vm._v("Was $159.99")])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "deal-top" }, [
      _c("h2", [_vm._v("Your Exclusive Offers.")]),
      _vm._v(" "),
      _c("h4", [_vm._v("Sign in to see amazing deals.")])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "product-price" }, [
      _c("span", { staticClass: "new-price" }, [_vm._v("$29.99")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }