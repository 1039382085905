var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "product-holder text-left" },
      [
        _c("places-list", {
          attrs: {
            places: _vm.places,
            "per-page": _vm.perPage,
            loaded: _vm.loaded
          }
        }),
        _vm._v(" "),
        _c("br")
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }