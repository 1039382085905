var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      !_vm.loading
        ? [
            _c(
              "div",
              { staticClass: "col-9 m-auto skeleton-body skel-shop-products" },
              [
                _vm._l(1, function(item) {
                  return _c("div", {
                    key: item,
                    staticClass: "skel-pro skel-pro-list"
                  })
                })
              ],
              2
            )
          ]
        : [
            _vm.firstListCategoriesLength > 0
              ? _c("section", { staticClass: "travel text-center py-5" }, [
                  _c("div", { staticClass: "container" }, [
                    _c("div", { staticClass: "row align-items-center mb-5" }, [
                      _vm._m(0),
                      _vm._v(" "),
                      _c("hr"),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-6 show-all" },
                        [
                          _c(
                            "nuxt-link",
                            {
                              staticClass: "btn btn-primary btn-round p-4",
                              attrs: {
                                to: _vm.localePath("/shop/sidebar/list")
                              }
                            },
                            [_c("span", [_vm._v("عرض الكل")])]
                          )
                        ],
                        1
                      )
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "row" },
                      _vm._l(_vm.firstListCategories, function(
                        listCategory,
                        index
                      ) {
                        return _c(
                          "div",
                          {
                            key: index,
                            staticClass: "col-lg-3 col-md-4 col-sm-12 mb-2"
                          },
                          [
                            _c("div", { staticClass: "card-holder" }, [
                              _c(
                                "div",
                                { staticClass: "card text-center" },
                                [
                                  listCategory.discount_int !== null
                                    ? _c(
                                        "div",
                                        { staticClass: "card-info text-white" },
                                        [
                                          _vm._v(
                                            "\n                                    Sale!\n                                "
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm._m(1, true),
                                  _vm._v(" "),
                                  _c(
                                    "nuxt-link",
                                    {
                                      attrs: {
                                        to: _vm.localePath(
                                          `/product/default/${listCategory.id}`
                                        )
                                      }
                                    },
                                    [
                                      listCategory.main_image
                                        ? _c(
                                            "div",
                                            { staticClass: "image-box" },
                                            [
                                              _c("img", {
                                                directives: [
                                                  {
                                                    name: "lazy",
                                                    rawName: "v-lazy",
                                                    value:
                                                      listCategory.main_image,
                                                    expression:
                                                      "listCategory.main_image"
                                                  }
                                                ],
                                                staticClass: "bg-transparent",
                                                attrs: {
                                                  alt: "Alf Logo",
                                                  width: "105",
                                                  height: "27"
                                                }
                                              })
                                            ]
                                          )
                                        : _c(
                                            "div",
                                            { staticClass: "image-box" },
                                            [
                                              _c("img", {
                                                attrs: {
                                                  src: require("../../../../static/images/Alf/unnamed.png"),
                                                  alt: "product",
                                                  width: _vm.category.width,
                                                  height: _vm.category.height
                                                }
                                              })
                                            ]
                                          )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("br"),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "card-content" }, [
                                    _c(
                                      "h2",
                                      { staticClass: "card-title" },
                                      [
                                        _c(
                                          "nuxt-link",
                                          {
                                            attrs: {
                                              to: _vm.localePath(
                                                `/product/default/${listCategory.id}`
                                              )
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                                            " +
                                                _vm._s(listCategory.name) +
                                                "\n                                        "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    listCategory.discount_int !== null
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "price-box text-center mb-1"
                                          },
                                          [
                                            _c("p", { staticClass: "price" }, [
                                              _c(
                                                "span",
                                                { staticClass: "text-primary" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      listCategory.price_after_discount
                                                    ) + " جنية"
                                                  )
                                                ]
                                              )
                                            ]),
                                            _vm._v(" "),
                                            _c(
                                              "p",
                                              { staticClass: "old-price" },
                                              [
                                                _c("del", [
                                                  _vm._v(
                                                    _vm._s(listCategory.price)
                                                  )
                                                ])
                                              ]
                                            )
                                          ]
                                        )
                                      : _c(
                                          "div",
                                          {
                                            staticClass:
                                              "price-box text-center mb-1"
                                          },
                                          [
                                            _c("p", { staticClass: "price" }, [
                                              _c(
                                                "span",
                                                { staticClass: "text-primary" },
                                                [
                                                  _vm._v(
                                                    _vm._s(listCategory.price) +
                                                      " "
                                                  )
                                                ]
                                              )
                                            ])
                                          ]
                                        ),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "actions" }, [
                                      _c("div", { staticClass: "row" }, [
                                        _c("div", { staticClass: "col" }, [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "img-holder",
                                              class: {
                                                "btn-disabled": !_vm.canAddToCart(
                                                  listCategory,
                                                  1
                                                )
                                              }
                                            },
                                            [
                                              _vm.isLoggedIn
                                                ? _c(
                                                    "button",
                                                    {
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          $event.preventDefault()
                                                          return _vm.addToCart({
                                                            product: listCategory
                                                          })
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c("img", {
                                                        directives: [
                                                          {
                                                            name: "lazy",
                                                            rawName: "v-lazy",
                                                            value:
                                                              "./images/Alf/alf/icon/alf/shopping-cart (3).png",
                                                            expression:
                                                              "\n                                                                './images/Alf/alf/icon/alf/shopping-cart (3).png'\n                                                            "
                                                          }
                                                        ],
                                                        staticClass:
                                                          "bg-transparent w-100",
                                                        attrs: {
                                                          alt: "Alf Logo",
                                                          height: "40"
                                                        }
                                                      })
                                                    ]
                                                  )
                                                : _c(
                                                    "button",
                                                    [
                                                      _c(
                                                        "nuxt-link",
                                                        {
                                                          attrs: {
                                                            to: _vm.localePath(
                                                              "/pages/login"
                                                            )
                                                          }
                                                        },
                                                        [
                                                          _c("img", {
                                                            directives: [
                                                              {
                                                                name: "lazy",
                                                                rawName:
                                                                  "v-lazy",
                                                                value:
                                                                  "./images/Alf/alf/icon/alf/shopping-cart (3).png",
                                                                expression:
                                                                  "\n                                                                    './images/Alf/alf/icon/alf/shopping-cart (3).png'\n                                                                "
                                                              }
                                                            ],
                                                            staticClass:
                                                              "bg-transparent w-100",
                                                            attrs: {
                                                              alt: "Alf Logo",
                                                              height: "40"
                                                            }
                                                          })
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                            ]
                                          )
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "col" },
                                          [
                                            _vm.isInWishlist(listCategory)
                                              ? _c(
                                                  "nuxt-link",
                                                  {
                                                    key: "inWishlist",
                                                    staticClass:
                                                      "btn-product img-holder",
                                                    attrs: {
                                                      to: _vm.localePath(
                                                        "/shop/wishlist"
                                                      )
                                                    }
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "fas fa-heart"
                                                    })
                                                  ]
                                                )
                                              : _c(
                                                  "div",
                                                  { staticClass: "img-holder" },
                                                  [
                                                    _c(
                                                      "button",
                                                      {
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            $event.preventDefault()
                                                            return _vm.addToWishlist(
                                                              {
                                                                product: listCategory
                                                              }
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _c("img", {
                                                          directives: [
                                                            {
                                                              name: "lazy",
                                                              rawName: "v-lazy",
                                                              value:
                                                                "./images/Alf/alf/icon/alf/heart.png",
                                                              expression:
                                                                "\n                                                                './images/Alf/alf/icon/alf/heart.png'\n                                                            "
                                                            }
                                                          ],
                                                          staticClass:
                                                            "bg-transparent",
                                                          attrs: {
                                                            alt: "Alf Logo",
                                                            width: "300",
                                                            height: "40"
                                                          }
                                                        })
                                                      ]
                                                    )
                                                  ]
                                                )
                                          ],
                                          1
                                        )
                                      ])
                                    ])
                                  ])
                                ],
                                1
                              )
                            ])
                          ]
                        )
                      }),
                      0
                    ),
                    _vm._v(" "),
                    _c("br")
                  ])
                ])
              : _vm._e()
          ]
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-6" }, [
      _c(
        "h1",
        { staticClass: "text-right text-gray m-3 mb-2 honey-products" },
        [
          _vm._v(
            "\n                            منتجات العسل\n                        "
          )
        ]
      )
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "rating card-info" }, [
      _c("i", { staticClass: "fas fa-star" }),
      _vm._v(" "),
      _c("span", [_vm._v("4.5")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }