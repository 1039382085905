var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "header-search header-search-extended mr-0 header-search-visible d-none d-lg-block"
    },
    [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "form",
        {
          attrs: { action: "#", method: "get" },
          on: {
            click: function($event) {
              $event.stopPropagation()
              return _vm.showSearchForm.apply(null, arguments)
            },
            submit: function($event) {
              $event.preventDefault()
              return _vm.submitSearchForm.apply(null, arguments)
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "header-search-wrapper search-wrapper-wide" },
            [
              _c("label", { staticClass: "sr-only", attrs: { for: "q" } }, [
                _vm._v(
                  "\n                    " +
                    _vm._s(_vm.$t("nav.search")) +
                    "\n                "
                )
              ]),
              _vm._v(" "),
              _vm._m(1),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.searchTerm,
                    expression: "searchTerm"
                  }
                ],
                staticClass: "form-control",
                attrs: {
                  type: "text",
                  placeholder: _vm.$t("nav.searchField"),
                  required: ""
                },
                domProps: { value: _vm.searchTerm },
                on: {
                  input: [
                    function($event) {
                      if ($event.target.composing) return
                      _vm.searchTerm = $event.target.value
                    },
                    _vm.searchProducts
                  ]
                }
              })
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "live-search-list" }, [
            _vm.suggestions.length > 0
              ? _c(
                  "div",
                  {
                    staticClass: "autocomplete-suggestions",
                    on: { click: _vm.goProductPage }
                  },
                  _vm._l(_vm.suggestions, function(product) {
                    return _c(
                      "nuxt-link",
                      {
                        key: product.id,
                        staticClass: "autocomplete-suggestion",
                        attrs: {
                          to: _vm.localePath(
                            `/product/default/` + product.slug
                          ),
                          "data-index": "0"
                        }
                      },
                      [
                        _c("img", {
                          staticClass: "product-image",
                          attrs: {
                            src: product.image,
                            alt: "\n\t\t\t\t\t\t\t\tProduct\n\t\t\t\t\t\t\t",
                            width: "40",
                            height: "40"
                          }
                        }),
                        _vm._v(" "),
                        _c("div", {
                          staticClass: "search-name",
                          domProps: {
                            innerHTML: _vm._s(_vm.matchEmphasize(product.name))
                          }
                        }),
                        _vm._v(" "),
                        _c(
                          "span",
                          { staticClass: "search-price" },
                          [
                            product.minPrice == product.maxPrice
                              ? _c(
                                  "div",
                                  { staticClass: "product-price mb-0" },
                                  [
                                    _vm._v(
                                      "\n                                $" +
                                        _vm._s(product.minPrice.toFixed(2)) +
                                        "\n                            "
                                    )
                                  ]
                                )
                              : [
                                  product.variants.length == 0
                                    ? _c(
                                        "div",
                                        { staticClass: "product-price mb-0" },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "new-price" },
                                            [
                                              _vm._v(
                                                "$" +
                                                  _vm._s(
                                                    product.minPrice.toFixed(2)
                                                  )
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            { staticClass: "old-price" },
                                            [
                                              _vm._v(
                                                "$" +
                                                  _vm._s(
                                                    product.maxPrice.toFixed(2)
                                                  )
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    : _c(
                                        "div",
                                        { staticClass: "product-price mb-0" },
                                        [
                                          _vm._v(
                                            "\n                                    $" +
                                              _vm._s(
                                                product.minPrice.toFixed(2)
                                              ) +
                                              " - $" +
                                              _vm._s(
                                                product.maxPrice.toFixed(2)
                                              ) +
                                              "\n                                "
                                          )
                                        ]
                                      )
                                ]
                          ],
                          2
                        )
                      ]
                    )
                  }),
                  1
                )
              : _vm._e()
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "a",
      { staticClass: "search-toggle", attrs: { role: "button" } },
      [_c("i", { staticClass: "icon-search" })]
    )
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("button", { staticClass: "btn", attrs: { type: "submit" } }, [
      _c("i", { staticClass: "icon-search" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }