<template>
    <div>
        <template v-if="!loading">
            <div class="col-9 m-auto skeleton-body skel-shop-products">
                <template>
                    <div
                        class="skel-pro skel-pro-list"
                        v-for="item in 1"
                        :key="item"
                    ></div>
                </template>
            </div>
        </template>
        <template v-else>
            <section
                class="travel text-center py-5"
                v-if="firstListCategoriesLength > 0"
            >
                <div class="container">
                    <div class="row align-items-center mb-5">
                        <div class="col-6">
                            <h1 class="text-right text-gray m-3 mb-2 honey-products">
                                منتجات العسل
                            </h1>
                        </div>
                        <hr>
                        <div class="col-6 show-all">
                            <nuxt-link
                                :to="localePath('/shop/sidebar/list')"
                                class="btn btn-primary btn-round p-4"
                            >
                                <span>عرض الكل</span>
                            </nuxt-link>
                        </div>
                    </div>

                    <div class="row">
                        <div
                            class="col-lg-3 col-md-4 col-sm-12 mb-2"
                            v-for="(listCategory, index) in firstListCategories"
                            :key="index"
                        >
                            <div class="card-holder">
                                <div class="card text-center">
                                    <div
                                        class="card-info text-white"
                                        v-if="
                                            listCategory.discount_int !== null
                                                
                                        "
                                    >
                                        Sale!
                                    </div>
                                    <div class="rating card-info">
                                        <i class="fas fa-star"></i>
                                        <span>4.5</span>
                                    </div>
                                    <nuxt-link
                                        :to="
                                            localePath(
                                                `/product/default/${listCategory.id}`
                                            )
                                        "
                                    >
                                        <div
                                            class="image-box"
                                            v-if="listCategory.main_image"
                                        >
                                            <img
                                                v-lazy="listCategory.main_image"
                                                class="bg-transparent"
                                                alt="Alf Logo"
                                                width="105"
                                                height="27"
                                            />
                                        </div>
                                        <div class="image-box" v-else>
                                            <img
                                                src="../../../../static/images/Alf/unnamed.png"
                                                alt="product"
                                                :width="category.width"
                                                :height="category.height"
                                            />
                                        </div>
                                    </nuxt-link>
                                    <br />
                                    <!-- <hr class="w-50 m-auto" /> -->
                                    <div class="card-content">
                                        <h2 class="card-title">
                                            <nuxt-link
                                                :to="
                                                    localePath(
                                                        `/product/default/${listCategory.id}`
                                                    )
                                                "
                                            >
                                                {{ listCategory.name }}
                                            </nuxt-link>
                                        </h2>

                                        <div class="price-box text-center mb-1"  v-if="
                                                    listCategory.discount_int !== null
                                                        
                                                ">
                                            <p class="price">
                                                <span class="text-primary">{{
                                                    listCategory.price_after_discount
                                                }} جنية</span>
                                               
                                            </p>
                                            <p
                                                class="old-price"
                                               
                                            >
                                                <del>{{
                                                    listCategory.price
                                                }}</del>
                                            </p>
                                        </div>
                                         <div class="price-box text-center mb-1"  v-else
                                                        
                                                >
                                            <p class="price">
                                                <span class="text-primary">{{
                                                    listCategory.price
                                                }} </span>
                                               
                                            </p>
                                          
                                        </div>
                                        <div class="actions">
                                            <div class="row">
                                                <div class="col">
                                                    <div
                                                        class="img-holder"
                                                        :class="{
                                                            'btn-disabled': !canAddToCart(
                                                                listCategory,
                                                                1
                                                            )
                                                        }"
                                                    >
                                                        <button
                                                            v-if="isLoggedIn"
                                                            @click.prevent="
                                                                addToCart({
                                                                    product: listCategory
                                                                })
                                                            "
                                                        >
                                                            <img
                                                                v-lazy="
                                                                    './images/Alf/alf/icon/alf/shopping-cart (3).png'
                                                                "
                                                                class="
                                                                    bg-transparent
                                                                    w-100
                                                                "
                                                                alt="Alf Logo"
                                                                height="40"
                                                            />
                                                        </button>
                                                        <button v-else>
                                                            <nuxt-link
                                                                :to="
                                                                    localePath(
                                                                        '/pages/login'
                                                                    )
                                                                "
                                                            >
                                                                <img
                                                                    v-lazy="
                                                                        './images/Alf/alf/icon/alf/shopping-cart (3).png'
                                                                    "
                                                                    class="
                                                                        bg-transparent
                                                                        w-100
                                                                    "
                                                                    alt="Alf Logo"
                                                                    height="40"
                                                                />
                                                            </nuxt-link>
                                                        </button>
                                                    </div>
                                                </div>
                                                <div class="col">
                                                     <nuxt-link
                                                                            :to="
                                                                                localePath(
                                                                                    '/shop/wishlist'
                                                                                )
                                                                            "
                                                                            class="
                                                                                btn-product img-holder
                                                                            "
                                                                            v-if="
                                                                                isInWishlist(
                                                                                    listCategory
                                                                                )
                                                                            "
                                                                            key="inWishlist"
                                                                        >
                                                                            <i
                                                                                class="
                                                                                    fas
                                                                                    fa-heart
                                                                                "
                                                                            ></i>
                                                                        </nuxt-link>
                                                    <div class="img-holder" v-else>
                                                        <button
                                                            @click.prevent="
                                                                addToWishlist({
                                                                    product: listCategory
                                                                })
                                                            "
                                                        >
                                                            <img
                                                                v-lazy="
                                                                    './images/Alf/alf/icon/alf/heart.png'
                                                                "
                                                                class="
                                                                    bg-transparent
                                                                "
                                                                alt="Alf Logo"
                                                                width="300"
                                                                height="40"
                                                            />
                                                        </button>
                                                    </div>
                                                </div>
                                                <!-- <div class="col">
                                                    <div
                                                        class="img-holder share"
                                                    >
                                                        <nuxt-link to="/">
                                                            <img
                                                                v-lazy="
                                                                    './images/Alf/alf/icon/alf/share.png'
                                                                "
                                                                class="
                                                                    bg-transparent
                                                                "
                                                                alt="Alf Logo"
                                                                width="300"
                                                                height="40"
                                                            />
                                                        </nuxt-link>
                                                    </div>
                                                </div> -->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br />
                </div>
            </section>
        </template>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import axios from 'axios';
import {
    carouselSettingSingle,
    carouselSettingDefault
} from '~/utilities/carousel';
import Repository, { baseUrl } from '~/repositories/repository.js';

export default {
    data: function() {
        return {
            firstListCategories: [],
            firstListCategoriesLength: 0,
            secondListCategories: [],
            secondListCategoriesLength: 0,
            loading: false,
            carouselSetting9: {
                ...carouselSettingDefault,
                slidesPerView: 4,
                spaceBetween: 30,
                navigation: {
                    nextEl: '.new-arrival .swiper-next',
                    prevEl: '.new-arrival .swiper-prev'
                },
                breakpoints: {
                    992: {
                        slidesPerView: 2
                    },
                    768: {
                        slidesPerView: 2,
                        navigation: true
                    },
                    576: {
                        slidesPerView: 1,
                        navigation: true
                    },
                    480: {
                        slidesPerView: 1,
                        navigation: true
                    }
                }
            }
        };
    },
    methods: {
        ...mapActions('cart', ['addToCart']),
        ...mapActions('wishlist', ['addToWishlist']),
        ...mapActions('compare', ['addToCompare']),
        getData: async function() {
            let listCategory = `${baseUrl}/list_categories`;

            //listCategory
            await Repository.get(listCategory).then(resp => {
                if (resp.data.code == 200) {
                    this.loading = true;
                }
                this.firstListCategories = resp.data.list_products.first.slice(
                    0,
                    8
                );
                console.log(
                    'this.firstListCategories',
                    this.firstListCategories
                );
                this.firstListCategoriesLength =
                    resp.data.list_products.first.length;
                this.secondListCategories = resp.data.list_products.second;
                this.secondListCategoriesLength =
                    resp.data.list_products.second.length;
                // console.log('listCategories', resp);
                // console.log(this.firstListCategories.length);
            });
        }
    },
    mounted() {
        this.getData();
    },
    computed: {
        ...mapGetters('cart', ['canAddToCart']),
        ...mapGetters('wishlist', ['isInWishlist']),
        ...mapGetters('compare', ['isInCompare']),
        isLoggedIn() {
            return this.$store.getters.isLoggedIn;
        }
    },
    props: ['product']
};
</script>

<style lang="scss" scoped>
hr{
    width: 50%;
    position: absolute;
    left: 19%;
    border-top: 1px solid #9a9999;
}
.card-title {
    a {
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 10px;
        color: #777;
        display: inline-block;
    }
}
.col {
    padding-right: 5px;
    padding-left: 5px;
}
h1,
a,
p,
span,
div {
    font-family: 'CairoRegular';
}
.rating.card-info {
    left: auto;
    right: 0;
    margin-right: 10px;
    background: transparent;
    border: 1px solid #ddd;
    padding: 0;
    width: 50px;
    border-radius: 5px;
    span {
        color: #818181;
    }
}
.show-all {
    text-align: left;
    a {
        background: transparent;
        border: 1px solid #bdbdbd;
        color: #676767;
        width: 100px !important;
        min-width: 100px;
        font-weight: bold;
    }
}
@media (max-width: 768px) {
    .holder {
        .travel {
            padding-top: 0 !important;
            .row {
                margin-bottom: 10px !important;
                h1 {
                    font-size: 30px;
                }
                a {
                    padding: 10px !important;
                }
            }
        }
    }
}
@media(max-width: 425px){
    hr{
        display: none;
    }

}
</style>
