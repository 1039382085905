<template>
    <div class="modal-body m-3">
        <button type="button" class="close" @click="$emit('close')">
            <span aria-hidden="true">
                <i class="icon-close"></i>
            </span>
        </button>

        <div class="form-box">
            <div class="form-tab">
                <tabs
                    class="nav-pills nav-fill"
                    :data="tabsData"
                    v-if="$i18n.locale == 'en'"
                ></tabs>
                <tabs
                    class="nav-pills nav-fill"
                    :data="tabsData2"
                    v-else
                ></tabs>
                <div
                    class="alert alert-danger text-center my-3"
                    v-if="message && fail"
                >
                    {{ message }}
                </div>
                <div
                    class="alert alert-success text-center my-3"
                    v-if="message && success"
                >
                    {{ message }}
                </div>
                <div class="tab-content">
                    <div class="tab-pane fade show active pt-5" id="sign_in">
                        <form @submit.prevent="loginHandleSubmit">
                            <div class="form-group">
                                <!-- <label for="singin-email-2"
                                    >{{$t('login.signInMessage')}} *</label
                                > -->
                                <input
                                    v-if="$i18n.locale == 'en'"
                                    type="text"
                                    v-model="user.Loginusername"
                                    id="Loginusername"
                                    name="Loginusername"
                                    placeholder="Email"
                                    class="form-control"
                                    :class="{
                                        'is-invalid':
                                            submitted &&
                                            $v.user.Loginusername.$error
                                    }"
                                />
                                <input
                                    v-else
                                    type="text"
                                    v-model="user.Loginusername"
                                    id="Loginusername"
                                    name="Loginusername"
                                    placeholder="البريد الالكترونى"
                                    class="form-control"
                                    :class="{
                                        'is-invalid':
                                            submitted &&
                                            $v.user.Loginusername.$error
                                    }"
                                />
                                <div
                                    v-if="
                                        submitted &&
                                            !$v.user.Loginusername.required &&
                                            $i18n.locale == 'en'
                                    "
                                    class="invalid-feedback"
                                >
                                    field is required
                                </div>
                                <div
                                    v-if="
                                        submitted &&
                                            !$v.user.Loginusername.required &&
                                            $i18n.locale == 'ar'
                                    "
                                    class="invalid-feedback"
                                >
                                    الحقل مطلوب
                                </div>
                            </div>

                            <div class="form-group">
                                <!-- <label for="singin-password-2"
                                    >{{$t('login.password')}} *</label
                                > -->
                                <div class="input-group">
                                    <input
                                        v-if="$i18n.locale == 'en'"
                                        autocomplete="off"
                                        v-model="user.password"
                                        id="password"
                                        name="password"
                                        placeholder="Password"
                                        class="form-control"
                                        :class="{
                                            'is-invalid':
                                                submitted &&
                                                $v.user.password.$error
                                        }"
                                        :type="[
                                            loginpassIcon ? 'text' : 'password'
                                        ]"
                                    />
                                    <input
                                        v-else
                                        autocomplete="off"
                                        v-model="user.password"
                                        id="password"
                                        name="password"
                                        placeholder="الرقم السرى *"
                                        class="form-control"
                                        :class="{
                                            'is-invalid':
                                                submitted &&
                                                $v.user.password.$error
                                        }"
                                        :type="[
                                            loginpassIcon ? 'text' : 'password'
                                        ]"
                                    />
                                    <div class="input-group-append">
                                        <span
                                            class="input-group-text eycon"
                                            @click="
                                                loginpassIcon = !loginpassIcon
                                            "
                                        >
                                            <i
                                                class="fa"
                                                :class="[
                                                    loginpassIcon
                                                        ? 'fa-eye fa-2x'
                                                        : 'fa-eye-slash fa-2x'
                                                ]"
                                                aria-hidden="true"
                                            ></i>
                                        </span>
                                    </div>
                                    <div
                                        v-if="
                                            submitted &&
                                                $v.user.password.$error &&
                                                $i18n.locale == 'en'
                                        "
                                        class="invalid-feedback"
                                    >
                                        <span v-if="!$v.user.password.required"
                                            >Password is required</span
                                        >
                                        <span v-if="!$v.user.password.minLength"
                                            >Password must be at least 8
                                            characters</span
                                        >
                                    </div>
                                    <div
                                        v-if="
                                            submitted &&
                                                $v.user.password.$error &&
                                                $i18n.locale == 'ar'
                                        "
                                        class="invalid-feedback"
                                    >
                                        <span v-if="!$v.user.password.required">
                                            حقل الرقم السرى مطلوب</span
                                        >
                                        <span v-if="!$v.user.password.minLength"
                                            >يجب الا يقل حقل الرقم السرى عن 8
                                            احرف
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div class="form-footer">
                                <!-- <div class="custom-control custom-checkbox">
                                    <input
                                        type="checkbox"
                                        class="custom-control-input"
                                        id="signin-remember-2"
                                        v-model="user.checkbox"
                                        :class="{
                                            'is-invalid':
                                                submitted &&
                                                $v.user.checkbox.$error,
                                        }"
                                    />
                                    <div
                                        v-if="
                                            submitted &&
                                            !$v.user.checkbox.required
                                        "
                                        class="invalid-feedback"
                                    >
                                        field is required
                                    </div>
                                    <label
                                        class="custom-control-label"
                                        for="signin-remember-2"
                                        >Remember Me</label
                                    >
                                </div> -->
                                <div
                                    class="submitButton d-flex justify-content-center"
                                    style="margin:auto"
                                >
                                    <button
                                        type="submit"
                                        class="btn btn-outline-primary-2"
                                    >
                                        <span>{{ $t('login.login') }}</span>
                                        <!-- <i class="icon-long-arrow-right"></i> -->
                                        <i class="fas fa-sign-in-alt fa-2x"></i>
                                    </button>
                                </div>
                            </div>

                            <button
                                @click.prevent="goForgetPage"
                                class="forgot-link"
                            >
                                {{ $t('login.passwordForget') }}
                            </button>
                        </form>
                        <!-- <div class="form-choice">
                                    <p class="text-center">or sign in with</p>
                                    <div class="row">
                                        <div class="col-sm-6">
                                            <a
                                                href="javascript:;"
                                                class="btn btn-login btn-g"
                                            >
                                                <i class="icon-google"></i>
                                                Login With Google
                                            </a>
                                        </div>

                                        <div class="col-sm-6">
                                            <a
                                                href="javascript:;"
                                                class="btn btn-login btn-f"
                                            >
                                                <i class="icon-facebook-f"></i>
                                                Login With Facebook
                                            </a>
                                        </div>
                                    </div>
                                </div> -->
                    </div>

                    <div class="tab-pane fade" id="register">
                        <form action="#" @submit.prevent="registerHandleSubmit">
                            <div class="form-group">
                                <!-- <label for="username text-dimied"
                                    >User Name</label
                                > -->
                                <input
                                    v-if="$i18n.locale == 'en'"
                                    type="text"
                                    class="form-control"
                                    id="registerUsername"
                                    name="registerUsername"
                                    v-model="user.registerUsername"
                                    placeholder="User Name"
                                    :class="{
                                        'is-invalid':
                                            submitted &&
                                            $v.user.registerUsername.$error
                                    }"
                                />
                                <input
                                    v-else
                                    type="text"
                                    class="form-control"
                                    id="registerUsername"
                                    name="registerUsername"
                                    v-model="user.registerUsername"
                                    placeholder="الاسم"
                                    :class="{
                                        'is-invalid':
                                            submitted &&
                                            $v.user.registerUsername.$error
                                    }"
                                />
                                <div
                                    v-if="
                                        submitted &&
                                            !$v.user.registerUsername
                                                .required &&
                                            $i18n.locale == 'en'
                                    "
                                    class="invalid-feedback"
                                >
                                    Username is required
                                </div>
                                <div
                                    v-if="
                                        submitted &&
                                            !$v.user.registerUsername
                                                .required &&
                                            $i18n.locale == 'ar'
                                    "
                                    class="invalid-feedback"
                                >
                                    حقل الاسم مطلوب
                                </div>
                            </div>
                            <div class="form-group">
                                <input
                                    v-if="$i18n.locale == 'en'"
                                    type="number"
                                    class="form-control"
                                    id="registerNumber"
                                    name="registerNumber"
                                    v-model="user.registerNumber"
                                    min="0"
                                    placeholder="Number"
                                    :class="{
                                        'is-invalid':
                                            submitted &&
                                            $v.user.registerNumber.$error
                                    }"
                                />
                                <input
                                    v-else
                                    type="number"
                                    class="form-control"
                                    id="registerNumber"
                                    name="registerNumber"
                                    v-model="user.registerNumber"
                                    min="0"
                                    placeholder="الرقم"
                                    :class="{
                                        'is-invalid':
                                            submitted &&
                                            $v.user.registerNumber.$error
                                    }"
                                />
                                <div
                                    v-if="
                                        submitted &&
                                            !$v.user.registerNumber.required &&
                                            $i18n.locale == 'en'
                                    "
                                    class="invalid-feedback"
                                >
                                    Number is required
                                </div>
                                <div
                                    v-if="
                                        submitted &&
                                            !$v.user.registerNumber.required &&
                                            $i18n.locale == 'ar'
                                    "
                                    class="invalid-feedback"
                                >
                                    حقل الرقم مطلوب
                                </div>
                            </div>
                            <div class="form-group">
                                <input
                                    v-if="$i18n.locale == 'en'"
                                    type="email"
                                    class="form-control"
                                    id="registerEmail"
                                    name="registerEmail"
                                    v-model="user.registerEmail"
                                    placeholder="Email"
                                    :class="{
                                        'is-invalid':
                                            submitted &&
                                            $v.user.registerEmail.$error
                                    }"
                                />
                                <input
                                    v-else
                                    type="email"
                                    class="form-control"
                                    id="registerEmail"
                                    name="registerEmail"
                                    v-model="user.registerEmail"
                                    placeholder="البريد الالكترونى"
                                    :class="{
                                        'is-invalid':
                                            submitted &&
                                            $v.user.registerEmail.$error
                                    }"
                                />
                                <div
                                    v-if="
                                        submitted &&
                                            $v.user.registerEmail.$error &&
                                            $i18n.locale == 'en'
                                    "
                                    class="invalid-feedback"
                                >
                                    <span v-if="!$v.user.registerEmail.required"
                                        >Email is required</span
                                    >
                                    <span
                                        v-if="
                                            !$v.user.registerEmail.registerEmail
                                        "
                                        >Email is invalid</span
                                    >
                                </div>
                                <div
                                    v-if="
                                        submitted &&
                                            $v.user.registerEmail.$error &&
                                            $i18n.locale == 'ar'
                                    "
                                    class="invalid-feedback"
                                >
                                    <span v-if="!$v.user.registerEmail.required"
                                        >حقل البريد الالكترونى مطلوب</span
                                    >
                                    <span
                                        v-if="
                                            !$v.user.registerEmail.registerEmail
                                        "
                                        >حقل البريد الالكترونى ليس صحيح</span
                                    >
                                </div>
                            </div>

                            <div class="form-group">
                                <!-- <label for="registerPassword">Password *</label> -->
                                <div class="input-group">
                                    <input
                                        v-if="$i18n.locale == 'en'"
                                        :type="[
                                            registerpassIcon
                                                ? 'text'
                                                : 'password'
                                        ]"
                                        class="form-control"
                                        id="registerPassword"
                                        name="registerPassword"
                                        placeholder="Password *"
                                        v-model="user.registerPassword"
                                        :class="{
                                            'is-invalid':
                                                submitted &&
                                                $v.user.registerPassword.$error
                                        }"
                                    />
                                    <input
                                        v-else
                                        :type="[
                                            registerpassIcon
                                                ? 'text'
                                                : 'password'
                                        ]"
                                        class="form-control"
                                        id="registerPassword"
                                        name="registerPassword"
                                        placeholder="الرقم السرى"
                                        v-model="user.registerPassword"
                                        :class="{
                                            'is-invalid':
                                                submitted &&
                                                $v.user.registerPassword.$error
                                        }"
                                    />
                                    <div class="input-group-append">
                                        <span
                                            class="input-group-text eycon"
                                            @click="
                                                registerpassIcon = !registerpassIcon
                                            "
                                        >
                                            <i
                                                class="fa"
                                                :class="[
                                                    registerpassIcon
                                                        ? 'fa-eye fa-2x'
                                                        : 'fa-eye-slash fa-2x'
                                                ]"
                                                aria-hidden="true"
                                            ></i>
                                        </span>
                                    </div>
                                    <div
                                        v-if="
                                            submitted &&
                                                $v.user.registerPassword
                                                    .$error &&
                                                $i18n.locale == 'en'
                                        "
                                        class="invalid-feedback"
                                    >
                                        <span
                                            v-if="
                                                !$v.user.registerPassword
                                                    .required
                                            "
                                            >Password is required</span
                                        >
                                        <span
                                            v-if="
                                                !$v.user.registerPassword
                                                    .minLength
                                            "
                                            >Password must be at least 6
                                            characters</span
                                        >
                                    </div>
                                    <div
                                        v-if="
                                            submitted &&
                                                $v.user.registerPassword
                                                    .$error &&
                                                $i18n.locale == 'ar'
                                        "
                                        class="invalid-feedback"
                                    >
                                        <span
                                            v-if="
                                                !$v.user.registerPassword
                                                    .required
                                            "
                                            >حقل الرقم السرى مطلوب</span
                                        >
                                        <span
                                            v-if="
                                                !$v.user.registerPassword
                                                    .minLength
                                            "
                                            >يجب الا يقل حقل الرقم السرى عن 6
                                            احرف</span
                                        >
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <!-- <label for="confirmRegisterPassword"
                                    >Confirm Password *</label
                                > -->
                                <div class="input-group">
                                    <input
                                        v-if="$i18n.locale == 'en'"
                                        :type="[
                                            confirmregisterpassIcon
                                                ? 'text'
                                                : 'password'
                                        ]"
                                        class="form-control"
                                        id="confirmRegisterPassword"
                                        name="confirmRegisterPassword"
                                        placeholder="Confirm Password *"
                                        v-model="user.confirmRegisterPassword"
                                        :class="{
                                            'is-invalid':
                                                submitted &&
                                                $v.user.confirmRegisterPassword
                                                    .$error
                                        }"
                                    />
                                    <input
                                        v-else
                                        :type="[
                                            confirmregisterpassIcon
                                                ? 'text'
                                                : 'password'
                                        ]"
                                        class="form-control"
                                        id="confirmRegisterPassword"
                                        name="confirmRegisterPassword"
                                        placeholder="تأكيد الرقم السرى *"
                                        v-model="user.confirmRegisterPassword"
                                        :class="{
                                            'is-invalid':
                                                submitted &&
                                                $v.user.confirmRegisterPassword
                                                    .$error
                                        }"
                                    />
                                    <div class="input-group-append">
                                        <span
                                            class="input-group-text eycon"
                                            @click="
                                                confirmregisterpassIcon = !confirmregisterpassIcon
                                            "
                                        >
                                            <i
                                                class="fa"
                                                :class="[
                                                    confirmregisterpassIcon
                                                        ? 'fa-eye fa-2x'
                                                        : 'fa-eye-slash fa-2x'
                                                ]"
                                                aria-hidden="true"
                                            ></i>
                                        </span>
                                    </div>
                                    <div
                                        v-if="
                                            submitted &&
                                                $v.user.confirmRegisterPassword
                                                    .$error &&
                                                $i18n.locale == 'en'
                                        "
                                        class="invalid-feedback"
                                    >
                                        <span
                                            v-if="
                                                !$v.user.confirmRegisterPassword
                                                    .required
                                            "
                                            >Confirm Password is required</span
                                        >
                                        <span
                                            v-else-if="
                                                !$v.user.confirmRegisterPassword
                                                    .sameAsPassword
                                            "
                                            >Passwords must match</span
                                        >
                                    </div>
                                    <div
                                        v-if="
                                            submitted &&
                                                $v.user.confirmRegisterPassword
                                                    .$error &&
                                                $i18n.locale == 'ar'
                                        "
                                        class="invalid-feedback"
                                    >
                                        <span
                                            v-if="
                                                !$v.user.confirmRegisterPassword
                                                    .required
                                            "
                                            >حقل تأكيد الرقم السرى</span
                                        >
                                        <span
                                            v-else-if="
                                                !$v.user.confirmRegisterPassword
                                                    .sameAsPassword
                                            "
                                            >يجب تطابق الرقم السرى</span
                                        >
                                    </div>
                                </div>
                            </div>

                            <div class="form-footer">
                                <!-- <div class="custom-control custom-checkbox">
                                    <input
                                        type="checkbox"
                                        class="custom-control-input"
                                        id="register-policy-2"
                                        v-model="user.registerCheck"
                                        :class="{
                                            'is-invalid':
                                                submitted &&
                                                $v.user.registerCheck.$error,
                                        }"
                                    />
                                    <div
                                        v-if="
                                            submitted &&
                                            !$v.user.registerCheck.required
                                        "
                                        class="invalid-feedback"
                                    >
                                        field is required
                                    </div>
                                    <label
                                        class="custom-control-label"
                                        for="register-policy-2"
                                    >
                                        I agree to the
                                        <a
                                            href="javascript:;"
                                            class="text-primary"
                                            >privacy policy</a
                                        >
                                        *
                                    </label>
                                </div> -->
                            </div>
                            <div
                                class="submitButton d-flex justify-content-end"
                            >
                                <button
                                    type="submit"
                                    class="btn btn-outline-primary-2"
                                   
                                >
                                    <span>{{ $t('login.registerBtn') }}</span>
                                    <!-- <i class="icon-long-arrow-right"></i> -->
                                    <i class="fas fa-sign-in-alt fa-2x"></i>
                                </button>
                            </div>
                        </form>
                        <!-- <div class="form-choice">
                                    <p class="text-center">or sign in with</p>
                                    <div class="row">
                                        <div class="col-sm-6">
                                            <a
                                                href="javascript:;"
                                                class="btn btn-login btn-g"
                                            >
                                                <i class="icon-google"></i>
                                                Login With Google
                                            </a>
                                        </div>

                                        <div class="col-sm-6">
                                            <a
                                                href="javascript:;"
                                                class="btn btn-login btn-f"
                                            >
                                                <i class="icon-facebook-f"></i>
                                                Login With Facebook
                                            </a>
                                        </div>
                                    </div>
                                </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { required, email, minLength, sameAs } from 'vuelidate/lib/validators';
import Vue from 'vue';
import Vuelidate from 'vuelidate';

Vue.use(Vuelidate);
import Tabs from '~/components/elements/Tabs';
export default {
    components: {
        Tabs
    },
    data: function() {
        return {
            tabsData: [
                {
                    id: 'sign_in',
                    title: 'Sign In',
                    active: true
                },
                {
                    id: 'register',
                    title: 'Sign Up'
                }
            ],
            tabsData2: [
                {
                    id: 'sign_in',
                    title: 'تسجيل الدخول',
                    active: true
                },
                {
                    id: 'register',
                    title: 'انشاء حساب'
                }
            ],
            user: {
                Loginusername: '',
                registerUsername: '',
                email: '',
                registerEmail: '',
                registerNumber: '',
                password: '',
                confirmPassword: '',
                // checkbox: '',
                registerCheck: '',
                registerPassword: '',
                confirmRegisterPassword: ''
            },
            submitted: false,
            message: false,
            fail: false,
            success: false,
            loginpassIcon: false,
            registerpassIcon: false,
            confirmregisterpassIcon: false
        };
    },
    validations: {
        user: {
            Loginusername: { required },
            registerUsername: { required },
            email: { required, email },
            registerEmail: { required, email },
            registerNumber: { required },
            // checkbox: { required },
            registerCheck: { required },
            password: { required, minLength: minLength(6) },
            registerPassword: { required, minLength: minLength(6) },
            confirmRegisterPassword: {
                required,
                sameAsPassword: sameAs('registerPassword')
            }
        }
    },

    methods: {
        loginHandleSubmit(e) {
            // stop here if form is invalid
            this.submitted = true;
            this.$v.$touch();
            this.login();
            if (this.$v.$invalid) {
                return;
            }

            alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.user));
        },
        registerHandleSubmit(e) {
            this.submitted = true;
            // stop here if form is invalid
            this.$v.$touch();
            this.register();
            if (this.$v.$invalid) {
                return;
            }

            alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.user));
        },
        login: function() {
            let email = this.user.Loginusername;
            let password = this.user.password;
            this.$store
                .dispatch('login', { email, password })
                .then(resp => {
                    console.log(resp);

                    console.log('message', resp.data.message);
                    this.message = resp.data.message;
                    if (resp.data.code === 200) {
                        this.success = true;
                        this.fail = false;
                    } else {
                        this.success = false;
                        this.fail = true;
                    }
                })
                .catch(err => {
                    this.loginFailed();
                    console.log(err);
                    this.message = resp.data.message;
                    this.success = false;
                    this.fail = true;
                });
        },
        loginFailed() {
            this.error = 'Login failed! incorrect email or password';
            delete sessionStorage.token;
        },
        register: function() {
            let data = {
                name: this.user.registerUsername,
                email: this.user.registerEmail,
                phone: this.user.registerNumber,
                password: this.user.registerPassword,
                password_confirmation: this.user.confirmRegisterPassword,
                checkbox: this.user.registerCheck
            };
            console.log(this.user.registerUsername);
            console.log(this.user.registerEmail);
            console.log(this.user.registerNumber);

            this.$store
                .dispatch('register', data)
                .then(resp => {
                    console.log(resp);
                    this.message = resp.data.message;
                    if (resp.data.code === 200) {
                        const user = resp.data.username;
                        console.log('usser', user);
                        this.success = true;
                        this.fail = false;
                        sessionStorage.setItem(
                            'username',
                            this.user.registerUsername
                        );
                        setTimeout(() => {
                            this.$router.push('/pages/activate');
                        }, 1000);
                    } else {
                        this.success = false;
                        this.fail = true;
                    }
                })
                .catch(err => {
                    console.log(err);
                    this.registerFailed();
                    this.message = resp.data.message;
                    this.success = false;
                    this.fail = true;
                });
        },
        registerFailed() {
            this.error = 'Register failed!';
            delete sessionStorage.token;
        },
        goForgetPage() {
            this.$router.push('/pages/forgetPassword');
            this.$emit('close');
        }
    }
};
</script>
<style lang="scss" scoped>
.form-control {
    margin-bottom: 0;
    padding: 25px 15px;
    box-shadow: 0px 0px 2px 0px #e3e1e1;
    border-radius: 5px;
    margin: 5px 0;
    &:focus {
        border-color: #fcb932;
        border-left: 4px solid #fcb932;
        border-radius: 0 5px 5px 0;
    }
}
.modal-body {
    height: auto;
}
.submitButton {
    button {
        border: 1px solid #fcb932;
        border-radius:8px ;
    }
}
</style>
