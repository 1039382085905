var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("First"),
      _vm._v(" "),
      _c("Second"),
      _vm._v(" "),
      _c("Third"),
      _vm._v(" "),
      _c("Fourth")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }