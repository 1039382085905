var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "products mb-3" },
    [
      _vm.products.length === 0 && _vm.loaded
        ? _c("p", { staticClass: "no-results" }, [
            _vm._v("\n        No products matching your selection.\n    ")
          ])
        : [
            _vm.type == "list"
              ? _c(
                  "div",
                  { key: "list" },
                  [
                    !_vm.loaded
                      ? _vm._l(_vm.fakeArray, function(item) {
                          return _c("div", {
                            key: item,
                            staticClass: "skel-pro skel-pro-list"
                          })
                        })
                      : [
                          _c(
                            "div",
                            { staticClass: "row" },
                            _vm._l(_vm.products, function(product, index) {
                              return _c("alf-products", {
                                key: index,
                                class: _vm.gridClass,
                                attrs: { product: product }
                              })
                            }),
                            1
                          )
                        ]
                  ],
                  2
                )
              : _vm._e()
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }