import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _14db9556 = () => interopDefault(import('../pages/elements/index.vue' /* webpackChunkName: "pages/elements/index" */))
const _1087df18 = () => interopDefault(import('../pages/ProductCard.vue' /* webpackChunkName: "pages/ProductCard" */))
const _68048bed = () => interopDefault(import('../pages/test.vue' /* webpackChunkName: "pages/test" */))
const _ceb6c9d6 = () => interopDefault(import('../pages/test2.vue' /* webpackChunkName: "pages/test2" */))
const _5f89dc3e = () => interopDefault(import('../pages/blog/blogResult.vue' /* webpackChunkName: "pages/blog/blogResult" */))
const _84bf9400 = () => interopDefault(import('../pages/blog/classic.vue' /* webpackChunkName: "pages/blog/classic" */))
const _0e8522d6 = () => interopDefault(import('../pages/blog/filteredAge.vue' /* webpackChunkName: "pages/blog/filteredAge" */))
const _f47cb452 = () => interopDefault(import('../pages/blog/grid-sidebar.vue' /* webpackChunkName: "pages/blog/grid-sidebar" */))
const _2d7d221c = () => interopDefault(import('../pages/blog/listing.vue' /* webpackChunkName: "pages/blog/listing" */))
const _11a42e42 = () => interopDefault(import('../pages/blog/masonry-sidebar.vue' /* webpackChunkName: "pages/blog/masonry-sidebar" */))
const _50e99138 = () => interopDefault(import('../pages/elements/accordions.vue' /* webpackChunkName: "pages/elements/accordions" */))
const _581e620a = () => interopDefault(import('../pages/elements/banners.vue' /* webpackChunkName: "pages/elements/banners" */))
const _99d0f716 = () => interopDefault(import('../pages/elements/blog-posts.vue' /* webpackChunkName: "pages/elements/blog-posts" */))
const _60e7cdc4 = () => interopDefault(import('../pages/elements/buttons.vue' /* webpackChunkName: "pages/elements/buttons" */))
const _236e92a9 = () => interopDefault(import('../pages/elements/categories.vue' /* webpackChunkName: "pages/elements/categories" */))
const _679b7e53 = () => interopDefault(import('../pages/elements/cta.vue' /* webpackChunkName: "pages/elements/cta" */))
const _62f61572 = () => interopDefault(import('../pages/elements/icon-boxes.vue' /* webpackChunkName: "pages/elements/icon-boxes" */))
const _c6890d6a = () => interopDefault(import('../pages/elements/portfolio.vue' /* webpackChunkName: "pages/elements/portfolio" */))
const _e3b0999e = () => interopDefault(import('../pages/elements/products.vue' /* webpackChunkName: "pages/elements/products" */))
const _ebdb4a6a = () => interopDefault(import('../pages/elements/tabs.vue' /* webpackChunkName: "pages/elements/tabs" */))
const _8a27f1fe = () => interopDefault(import('../pages/elements/testimonials.vue' /* webpackChunkName: "pages/elements/testimonials" */))
const _2a2420a8 = () => interopDefault(import('../pages/elements/titles.vue' /* webpackChunkName: "pages/elements/titles" */))
const _ff9bff88 = () => interopDefault(import('../pages/elements/typography.vue' /* webpackChunkName: "pages/elements/typography" */))
const _8dd0c910 = () => interopDefault(import('../pages/elements/video-banners.vue' /* webpackChunkName: "pages/elements/video-banners" */))
const _048b86bc = () => interopDefault(import('../pages/pages/404.vue' /* webpackChunkName: "pages/pages/404" */))
const _7c1b77f7 = () => interopDefault(import('../pages/pages/about.vue' /* webpackChunkName: "pages/pages/about" */))
const _44eb7148 = () => interopDefault(import('../pages/pages/about-2.vue' /* webpackChunkName: "pages/pages/about-2" */))
const _5ba8c8d4 = () => interopDefault(import('../pages/pages/aboutAlf.vue' /* webpackChunkName: "pages/pages/aboutAlf" */))
const _3ce9ec79 = () => interopDefault(import('../pages/pages/activate.vue' /* webpackChunkName: "pages/pages/activate" */))
const _82dfeb8c = () => interopDefault(import('../pages/pages/checkForgetPassword.vue' /* webpackChunkName: "pages/pages/checkForgetPassword" */))
const _626eaeb1 = () => interopDefault(import('../pages/pages/coming-soon.vue' /* webpackChunkName: "pages/pages/coming-soon" */))
const _34cbaaec = () => interopDefault(import('../pages/pages/contact.vue' /* webpackChunkName: "pages/pages/contact" */))
const _3bb2bda2 = () => interopDefault(import('../pages/pages/contact-2.vue' /* webpackChunkName: "pages/pages/contact-2" */))
const _acaa4100 = () => interopDefault(import('../pages/pages/faq.vue' /* webpackChunkName: "pages/pages/faq" */))
const _be3f23a4 = () => interopDefault(import('../pages/pages/forgetPassword.vue' /* webpackChunkName: "pages/pages/forgetPassword" */))
const _b4780592 = () => interopDefault(import('../pages/pages/info/index.vue' /* webpackChunkName: "pages/pages/info/index" */))
const _657af5d3 = () => interopDefault(import('../pages/pages/login.vue' /* webpackChunkName: "pages/pages/login" */))
const _5804a90a = () => interopDefault(import('../pages/pages/new.vue' /* webpackChunkName: "pages/pages/new" */))
const _3ef66fd5 = () => interopDefault(import('../pages/pages/places/index.vue' /* webpackChunkName: "pages/pages/places/index" */))
const _247cb8da = () => interopDefault(import('../pages/pages/profile.vue' /* webpackChunkName: "pages/pages/profile" */))
const _5bea58f4 = () => interopDefault(import('../pages/pages/resetPassword.vue' /* webpackChunkName: "pages/pages/resetPassword" */))
const _beda0e44 = () => interopDefault(import('../pages/shop/Card.vue' /* webpackChunkName: "pages/shop/Card" */))
const _c4cbe664 = () => interopDefault(import('../pages/shop/cart.vue' /* webpackChunkName: "pages/shop/cart" */))
const _164be0f4 = () => interopDefault(import('../pages/shop/checkout.vue' /* webpackChunkName: "pages/shop/checkout" */))
const _99703224 = () => interopDefault(import('../pages/shop/checkout2.vue' /* webpackChunkName: "pages/shop/checkout2" */))
const _b2a6d932 = () => interopDefault(import('../pages/shop/complete.vue' /* webpackChunkName: "pages/shop/complete" */))
const _065a7c54 = () => interopDefault(import('../pages/shop/dashboard.vue' /* webpackChunkName: "pages/shop/dashboard" */))
const _a8a26b2c = () => interopDefault(import('../pages/shop/market.vue' /* webpackChunkName: "pages/shop/market" */))
const _5ff6a2c8 = () => interopDefault(import('../pages/shop/payment.vue' /* webpackChunkName: "pages/shop/payment" */))
const _d0c974e0 = () => interopDefault(import('../pages/shop/products2.vue' /* webpackChunkName: "pages/shop/products2" */))
const _32c28f8e = () => interopDefault(import('../pages/shop/productSearch.vue' /* webpackChunkName: "pages/shop/productSearch" */))
const _a5041c1a = () => interopDefault(import('../pages/shop/wishlist.vue' /* webpackChunkName: "pages/shop/wishlist" */))
const _18645612 = () => interopDefault(import('../pages/blog/mask/grid.vue' /* webpackChunkName: "pages/blog/mask/grid" */))
const _769379a4 = () => interopDefault(import('../pages/blog/mask/masonry.vue' /* webpackChunkName: "pages/blog/mask/masonry" */))
const _64fc322c = () => interopDefault(import('../pages/brands/sidebar/advancedBrand.vue' /* webpackChunkName: "pages/brands/sidebar/advancedBrand" */))
const _6ac69d08 = () => interopDefault(import('../pages/pages/account/address.vue' /* webpackChunkName: "pages/pages/account/address" */))
const _61498b09 = () => interopDefault(import('../pages/pages/account/changePass.vue' /* webpackChunkName: "pages/pages/account/changePass" */))
const _15a17fe6 = () => interopDefault(import('../pages/pages/account/orders.vue' /* webpackChunkName: "pages/pages/account/orders" */))
const _274bbcb1 = () => interopDefault(import('../pages/pages/account/profile.vue' /* webpackChunkName: "pages/pages/account/profile" */))
const _9b891a2a = () => interopDefault(import('../pages/pages/account/trackOrder.vue' /* webpackChunkName: "pages/pages/account/trackOrder" */))
const _3e3585e0 = () => interopDefault(import('../pages/pages/info/location.vue' /* webpackChunkName: "pages/pages/info/location" */))
const _079ed03e = () => interopDefault(import('../pages/pages/info/socialProfile.vue' /* webpackChunkName: "pages/pages/info/socialProfile" */))
const _24532918 = () => interopDefault(import('../pages/pages/places/advancedSearchResult.vue' /* webpackChunkName: "pages/pages/places/advancedSearchResult" */))
const _6ed8da51 = () => interopDefault(import('../pages/pages/places/findPlace.vue' /* webpackChunkName: "pages/pages/places/findPlace" */))
const _9e2fa454 = () => interopDefault(import('../pages/pages/places/placesResult.vue' /* webpackChunkName: "pages/pages/places/placesResult" */))
const _608ef907 = () => interopDefault(import('../pages/shop/category/boxed.vue' /* webpackChunkName: "pages/shop/category/boxed" */))
const _1abb4e18 = () => interopDefault(import('../pages/shop/category/fullwidth.vue' /* webpackChunkName: "pages/shop/category/fullwidth" */))
const _3f9193b4 = () => interopDefault(import('../pages/shop/sidebar/advancedAttr.vue' /* webpackChunkName: "pages/shop/sidebar/advancedAttr" */))
const _61e43cd6 = () => interopDefault(import('../pages/shop/sidebar/advancedAttrAfterCategory.vue' /* webpackChunkName: "pages/shop/sidebar/advancedAttrAfterCategory" */))
const _f3dd1f58 = () => interopDefault(import('../pages/shop/sidebar/advancedBrand.vue' /* webpackChunkName: "pages/shop/sidebar/advancedBrand" */))
const _7a6ecc27 = () => interopDefault(import('../pages/shop/sidebar/advancedProducts.vue' /* webpackChunkName: "pages/shop/sidebar/advancedProducts" */))
const _a71bb62a = () => interopDefault(import('../pages/shop/sidebar/advancedSubCategory.vue' /* webpackChunkName: "pages/shop/sidebar/advancedSubCategory" */))
const _1bc7214f = () => interopDefault(import('../pages/shop/sidebar/shopFilter.vue' /* webpackChunkName: "pages/shop/sidebar/shopFilter" */))
const _9d3aa5f2 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _722c0cb7 = () => interopDefault(import('../pages/blog/single/default/_slug.vue' /* webpackChunkName: "pages/blog/single/default/_slug" */))
const _6845ba66 = () => interopDefault(import('../pages/blog/single/fullwidth/_slug.vue' /* webpackChunkName: "pages/blog/single/fullwidth/_slug" */))
const _5402dc12 = () => interopDefault(import('../pages/blog/single/sidebar/_slug.vue' /* webpackChunkName: "pages/blog/single/sidebar/_slug" */))
const _459f3b12 = () => interopDefault(import('../pages/blog/grid/_type.vue' /* webpackChunkName: "pages/blog/grid/_type" */))
const _5ecee23d = () => interopDefault(import('../pages/blog/masonry/_type.vue' /* webpackChunkName: "pages/blog/masonry/_type" */))
const _28a1297e = () => interopDefault(import('../pages/brands/sidebar/_type.vue' /* webpackChunkName: "pages/brands/sidebar/_type" */))
const _3d3fce8d = () => interopDefault(import('../pages/pages/places/_slug.vue' /* webpackChunkName: "pages/pages/places/_slug" */))
const _99650eac = () => interopDefault(import('../pages/product/centered/_slug.vue' /* webpackChunkName: "pages/product/centered/_slug" */))
const _70417b5e = () => interopDefault(import('../pages/product/default/_slug.vue' /* webpackChunkName: "pages/product/default/_slug" */))
const _243e6bef = () => interopDefault(import('../pages/product/extended/_slug.vue' /* webpackChunkName: "pages/product/extended/_slug" */))
const _fccd4c32 = () => interopDefault(import('../pages/product/fullwidth/_slug.vue' /* webpackChunkName: "pages/product/fullwidth/_slug" */))
const _31b28222 = () => interopDefault(import('../pages/product/gallery/_slug.vue' /* webpackChunkName: "pages/product/gallery/_slug" */))
const _da3b9556 = () => interopDefault(import('../pages/product/masonry/_slug.vue' /* webpackChunkName: "pages/product/masonry/_slug" */))
const _ac93dca8 = () => interopDefault(import('../pages/product/sidebar/_slug.vue' /* webpackChunkName: "pages/product/sidebar/_slug" */))
const _1700c15f = () => interopDefault(import('../pages/product/sticky/_slug.vue' /* webpackChunkName: "pages/product/sticky/_slug" */))
const _c21df272 = () => interopDefault(import('../pages/shop/nosidebar/_type.vue' /* webpackChunkName: "pages/shop/nosidebar/_type" */))
const _de4110c0 = () => interopDefault(import('../pages/shop/search/_type.vue' /* webpackChunkName: "pages/shop/search/_type" */))
const _6d1dbce8 = () => interopDefault(import('../pages/shop/sidebar/_type.vue' /* webpackChunkName: "pages/shop/sidebar/_type" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'link-active',
  linkExactActiveClass: 'active',
  scrollBehavior,

  routes: [{
    path: "/elements",
    component: _14db9556,
    meta: {},
    alias: ["/amp/elements"],
    name: "elements___ar"
  }, {
    path: "/ProductCard",
    component: _1087df18,
    meta: {},
    alias: ["/amp/ProductCard"],
    name: "ProductCard___ar"
  }, {
    path: "/test",
    component: _68048bed,
    meta: {},
    alias: ["/amp/test"],
    name: "test___ar"
  }, {
    path: "/test2",
    component: _ceb6c9d6,
    meta: {},
    alias: ["/amp/test2"],
    name: "test2___ar"
  }, {
    path: "/blog/blogResult",
    component: _5f89dc3e,
    meta: {},
    alias: ["/amp/blog/blogResult"],
    name: "blog-blogResult___ar"
  }, {
    path: "/blog/classic",
    component: _84bf9400,
    meta: {},
    alias: ["/amp/blog/classic"],
    name: "blog-classic___ar"
  }, {
    path: "/blog/filteredAge",
    component: _0e8522d6,
    meta: {},
    alias: ["/amp/blog/filteredAge"],
    name: "blog-filteredAge___ar"
  }, {
    path: "/blog/grid-sidebar",
    component: _f47cb452,
    meta: {},
    alias: ["/amp/blog/grid-sidebar"],
    name: "blog-grid-sidebar___ar"
  }, {
    path: "/blog/listing",
    component: _2d7d221c,
    meta: {},
    alias: ["/amp/blog/listing"],
    name: "blog-listing___ar"
  }, {
    path: "/blog/masonry-sidebar",
    component: _11a42e42,
    meta: {},
    alias: ["/amp/blog/masonry-sidebar"],
    name: "blog-masonry-sidebar___ar"
  }, {
    path: "/elements/accordions",
    component: _50e99138,
    meta: {},
    alias: ["/amp/elements/accordions"],
    name: "elements-accordions___ar"
  }, {
    path: "/elements/banners",
    component: _581e620a,
    meta: {},
    alias: ["/amp/elements/banners"],
    name: "elements-banners___ar"
  }, {
    path: "/elements/blog-posts",
    component: _99d0f716,
    meta: {},
    alias: ["/amp/elements/blog-posts"],
    name: "elements-blog-posts___ar"
  }, {
    path: "/elements/buttons",
    component: _60e7cdc4,
    meta: {},
    alias: ["/amp/elements/buttons"],
    name: "elements-buttons___ar"
  }, {
    path: "/elements/categories",
    component: _236e92a9,
    meta: {},
    alias: ["/amp/elements/categories"],
    name: "elements-categories___ar"
  }, {
    path: "/elements/cta",
    component: _679b7e53,
    meta: {},
    alias: ["/amp/elements/cta"],
    name: "elements-cta___ar"
  }, {
    path: "/elements/icon-boxes",
    component: _62f61572,
    meta: {},
    alias: ["/amp/elements/icon-boxes"],
    name: "elements-icon-boxes___ar"
  }, {
    path: "/elements/portfolio",
    component: _c6890d6a,
    meta: {},
    alias: ["/amp/elements/portfolio"],
    name: "elements-portfolio___ar"
  }, {
    path: "/elements/products",
    component: _e3b0999e,
    meta: {},
    alias: ["/amp/elements/products"],
    name: "elements-products___ar"
  }, {
    path: "/elements/tabs",
    component: _ebdb4a6a,
    meta: {},
    alias: ["/amp/elements/tabs"],
    name: "elements-tabs___ar"
  }, {
    path: "/elements/testimonials",
    component: _8a27f1fe,
    meta: {},
    alias: ["/amp/elements/testimonials"],
    name: "elements-testimonials___ar"
  }, {
    path: "/elements/titles",
    component: _2a2420a8,
    meta: {},
    alias: ["/amp/elements/titles"],
    name: "elements-titles___ar"
  }, {
    path: "/elements/typography",
    component: _ff9bff88,
    meta: {},
    alias: ["/amp/elements/typography"],
    name: "elements-typography___ar"
  }, {
    path: "/elements/video-banners",
    component: _8dd0c910,
    meta: {},
    alias: ["/amp/elements/video-banners"],
    name: "elements-video-banners___ar"
  }, {
    path: "/pages/404",
    component: _048b86bc,
    meta: {},
    alias: ["/amp/pages/404"],
    name: "pages-404___ar"
  }, {
    path: "/pages/about",
    component: _7c1b77f7,
    meta: {},
    alias: ["/amp/pages/about"],
    name: "pages-about___ar"
  }, {
    path: "/pages/about-2",
    component: _44eb7148,
    meta: {},
    alias: ["/amp/pages/about-2"],
    name: "pages-about-2___ar"
  }, {
    path: "/pages/aboutAlf",
    component: _5ba8c8d4,
    meta: {},
    alias: ["/amp/pages/aboutAlf"],
    name: "pages-aboutAlf___ar"
  }, {
    path: "/pages/activate",
    component: _3ce9ec79,
    meta: {},
    alias: ["/amp/pages/activate"],
    name: "pages-activate___ar"
  }, {
    path: "/pages/checkForgetPassword",
    component: _82dfeb8c,
    meta: {},
    alias: ["/amp/pages/checkForgetPassword"],
    name: "pages-checkForgetPassword___ar"
  }, {
    path: "/pages/coming-soon",
    component: _626eaeb1,
    meta: {},
    alias: ["/amp/pages/coming-soon"],
    name: "pages-coming-soon___ar"
  }, {
    path: "/pages/contact",
    component: _34cbaaec,
    meta: {},
    alias: ["/amp/pages/contact"],
    name: "pages-contact___ar"
  }, {
    path: "/pages/contact-2",
    component: _3bb2bda2,
    meta: {},
    alias: ["/amp/pages/contact-2"],
    name: "pages-contact-2___ar"
  }, {
    path: "/pages/faq",
    component: _acaa4100,
    meta: {},
    alias: ["/amp/pages/faq"],
    name: "pages-faq___ar"
  }, {
    path: "/pages/forgetPassword",
    component: _be3f23a4,
    meta: {},
    alias: ["/amp/pages/forgetPassword"],
    name: "pages-forgetPassword___ar"
  }, {
    path: "/pages/info",
    component: _b4780592,
    meta: {},
    alias: ["/amp/pages/info"],
    name: "pages-info___ar"
  }, {
    path: "/pages/login",
    component: _657af5d3,
    meta: {},
    alias: ["/amp/pages/login"],
    name: "pages-login___ar"
  }, {
    path: "/pages/new",
    component: _5804a90a,
    meta: {},
    alias: ["/amp/pages/new"],
    name: "pages-new___ar"
  }, {
    path: "/pages/places",
    component: _3ef66fd5,
    meta: {},
    alias: ["/amp/pages/places"],
    name: "pages-places___ar"
  }, {
    path: "/pages/profile",
    component: _247cb8da,
    meta: {},
    alias: ["/amp/pages/profile"],
    name: "pages-profile___ar"
  }, {
    path: "/pages/resetPassword",
    component: _5bea58f4,
    meta: {},
    alias: ["/amp/pages/resetPassword"],
    name: "pages-resetPassword___ar"
  }, {
    path: "/shop/Card",
    component: _beda0e44,
    meta: {},
    alias: ["/amp/shop/Card"],
    name: "shop-Card___ar"
  }, {
    path: "/shop/cart",
    component: _c4cbe664,
    meta: {},
    alias: ["/amp/shop/cart"],
    name: "shop-cart___ar"
  }, {
    path: "/shop/checkout",
    component: _164be0f4,
    meta: {},
    alias: ["/amp/shop/checkout"],
    name: "shop-checkout___ar"
  }, {
    path: "/shop/checkout2",
    component: _99703224,
    meta: {},
    alias: ["/amp/shop/checkout2"],
    name: "shop-checkout2___ar"
  }, {
    path: "/shop/complete",
    component: _b2a6d932,
    meta: {},
    alias: ["/amp/shop/complete"],
    name: "shop-complete___ar"
  }, {
    path: "/shop/dashboard",
    component: _065a7c54,
    meta: {},
    alias: ["/amp/shop/dashboard"],
    name: "shop-dashboard___ar"
  }, {
    path: "/shop/market",
    component: _a8a26b2c,
    meta: {},
    alias: ["/amp/shop/market"],
    name: "shop-market___ar"
  }, {
    path: "/shop/payment",
    component: _5ff6a2c8,
    meta: {},
    alias: ["/amp/shop/payment"],
    name: "shop-payment___ar"
  }, {
    path: "/shop/products2",
    component: _d0c974e0,
    meta: {},
    alias: ["/amp/shop/products2"],
    name: "shop-products2___ar"
  }, {
    path: "/shop/productSearch",
    component: _32c28f8e,
    meta: {},
    alias: ["/amp/shop/productSearch"],
    name: "shop-productSearch___ar"
  }, {
    path: "/shop/wishlist",
    component: _a5041c1a,
    meta: {},
    alias: ["/amp/shop/wishlist"],
    name: "shop-wishlist___ar"
  }, {
    path: "/blog/mask/grid",
    component: _18645612,
    meta: {},
    alias: ["/amp/blog/mask/grid"],
    name: "blog-mask-grid___ar"
  }, {
    path: "/blog/mask/masonry",
    component: _769379a4,
    meta: {},
    alias: ["/amp/blog/mask/masonry"],
    name: "blog-mask-masonry___ar"
  }, {
    path: "/brands/sidebar/advancedBrand",
    component: _64fc322c,
    meta: {},
    alias: ["/amp/brands/sidebar/advancedBrand"],
    name: "brands-sidebar-advancedBrand___ar"
  }, {
    path: "/pages/account/address",
    component: _6ac69d08,
    meta: {},
    alias: ["/amp/pages/account/address"],
    name: "pages-account-address___ar"
  }, {
    path: "/pages/account/changePass",
    component: _61498b09,
    meta: {},
    alias: ["/amp/pages/account/changePass"],
    name: "pages-account-changePass___ar"
  }, {
    path: "/pages/account/orders",
    component: _15a17fe6,
    meta: {},
    alias: ["/amp/pages/account/orders"],
    name: "pages-account-orders___ar"
  }, {
    path: "/pages/account/profile",
    component: _274bbcb1,
    meta: {},
    alias: ["/amp/pages/account/profile"],
    name: "pages-account-profile___ar"
  }, {
    path: "/pages/account/trackOrder",
    component: _9b891a2a,
    meta: {},
    alias: ["/amp/pages/account/trackOrder"],
    name: "pages-account-trackOrder___ar"
  }, {
    path: "/pages/info/location",
    component: _3e3585e0,
    meta: {},
    alias: ["/amp/pages/info/location"],
    name: "pages-info-location___ar"
  }, {
    path: "/pages/info/socialProfile",
    component: _079ed03e,
    meta: {},
    alias: ["/amp/pages/info/socialProfile"],
    name: "pages-info-socialProfile___ar"
  }, {
    path: "/pages/places/advancedSearchResult",
    component: _24532918,
    meta: {},
    alias: ["/amp/pages/places/advancedSearchResult"],
    name: "pages-places-advancedSearchResult___ar"
  }, {
    path: "/pages/places/findPlace",
    component: _6ed8da51,
    meta: {},
    alias: ["/amp/pages/places/findPlace"],
    name: "pages-places-findPlace___ar"
  }, {
    path: "/pages/places/placesResult",
    component: _9e2fa454,
    meta: {},
    alias: ["/amp/pages/places/placesResult"],
    name: "pages-places-placesResult___ar"
  }, {
    path: "/shop/category/boxed",
    component: _608ef907,
    meta: {},
    alias: ["/amp/shop/category/boxed"],
    name: "shop-category-boxed___ar"
  }, {
    path: "/shop/category/fullwidth",
    component: _1abb4e18,
    meta: {},
    alias: ["/amp/shop/category/fullwidth"],
    name: "shop-category-fullwidth___ar"
  }, {
    path: "/shop/sidebar/advancedAttr",
    component: _3f9193b4,
    meta: {},
    alias: ["/amp/shop/sidebar/advancedAttr"],
    name: "shop-sidebar-advancedAttr___ar"
  }, {
    path: "/shop/sidebar/advancedAttrAfterCategory",
    component: _61e43cd6,
    meta: {},
    alias: ["/amp/shop/sidebar/advancedAttrAfterCategory"],
    name: "shop-sidebar-advancedAttrAfterCategory___ar"
  }, {
    path: "/shop/sidebar/advancedBrand",
    component: _f3dd1f58,
    meta: {},
    alias: ["/amp/shop/sidebar/advancedBrand"],
    name: "shop-sidebar-advancedBrand___ar"
  }, {
    path: "/shop/sidebar/advancedProducts",
    component: _7a6ecc27,
    meta: {},
    alias: ["/amp/shop/sidebar/advancedProducts"],
    name: "shop-sidebar-advancedProducts___ar"
  }, {
    path: "/shop/sidebar/advancedSubCategory",
    component: _a71bb62a,
    meta: {},
    alias: ["/amp/shop/sidebar/advancedSubCategory"],
    name: "shop-sidebar-advancedSubCategory___ar"
  }, {
    path: "/shop/sidebar/shopFilter",
    component: _1bc7214f,
    meta: {},
    alias: ["/amp/shop/sidebar/shopFilter"],
    name: "shop-sidebar-shopFilter___ar"
  }, {
    path: "/",
    component: _9d3aa5f2,
    meta: {},
    alias: ["/amp/"],
    name: "index___ar"
  }, {
    path: "/blog/single/default/:slug?",
    component: _722c0cb7,
    meta: {},
    alias: ["/amp/blog/single/default/:slug?"],
    name: "blog-single-default-slug___ar"
  }, {
    path: "/blog/single/fullwidth/:slug?",
    component: _6845ba66,
    meta: {},
    alias: ["/amp/blog/single/fullwidth/:slug?"],
    name: "blog-single-fullwidth-slug___ar"
  }, {
    path: "/blog/single/sidebar/:slug?",
    component: _5402dc12,
    meta: {},
    alias: ["/amp/blog/single/sidebar/:slug?"],
    name: "blog-single-sidebar-slug___ar"
  }, {
    path: "/blog/grid/:type?",
    component: _459f3b12,
    meta: {},
    alias: ["/amp/blog/grid/:type?"],
    name: "blog-grid-type___ar"
  }, {
    path: "/blog/masonry/:type?",
    component: _5ecee23d,
    meta: {},
    alias: ["/amp/blog/masonry/:type?"],
    name: "blog-masonry-type___ar"
  }, {
    path: "/brands/sidebar/:type?",
    component: _28a1297e,
    meta: {},
    alias: ["/amp/brands/sidebar/:type?"],
    name: "brands-sidebar-type___ar"
  }, {
    path: "/pages/places/:slug",
    component: _3d3fce8d,
    meta: {},
    alias: ["/amp/pages/places/:slug"],
    name: "pages-places-slug___ar"
  }, {
    path: "/product/centered/:slug?",
    component: _99650eac,
    meta: {},
    alias: ["/amp/product/centered/:slug?"],
    name: "product-centered-slug___ar"
  }, {
    path: "/product/default/:slug?",
    component: _70417b5e,
    meta: {},
    alias: ["/amp/product/default/:slug?"],
    name: "product-default-slug___ar"
  }, {
    path: "/product/extended/:slug?",
    component: _243e6bef,
    meta: {},
    alias: ["/amp/product/extended/:slug?"],
    name: "product-extended-slug___ar"
  }, {
    path: "/product/fullwidth/:slug?",
    component: _fccd4c32,
    meta: {},
    alias: ["/amp/product/fullwidth/:slug?"],
    name: "product-fullwidth-slug___ar"
  }, {
    path: "/product/gallery/:slug?",
    component: _31b28222,
    meta: {},
    alias: ["/amp/product/gallery/:slug?"],
    name: "product-gallery-slug___ar"
  }, {
    path: "/product/masonry/:slug?",
    component: _da3b9556,
    meta: {},
    alias: ["/amp/product/masonry/:slug?"],
    name: "product-masonry-slug___ar"
  }, {
    path: "/product/sidebar/:slug?",
    component: _ac93dca8,
    meta: {},
    alias: ["/amp/product/sidebar/:slug?"],
    name: "product-sidebar-slug___ar"
  }, {
    path: "/product/sticky/:slug?",
    component: _1700c15f,
    meta: {},
    alias: ["/amp/product/sticky/:slug?"],
    name: "product-sticky-slug___ar"
  }, {
    path: "/shop/nosidebar/:type?",
    component: _c21df272,
    meta: {},
    alias: ["/amp/shop/nosidebar/:type?"],
    name: "shop-nosidebar-type___ar"
  }, {
    path: "/shop/search/:type?",
    component: _de4110c0,
    meta: {},
    alias: ["/amp/shop/search/:type?"],
    name: "shop-search-type___ar"
  }, {
    path: "/shop/sidebar/:type?",
    component: _6d1dbce8,
    meta: {},
    alias: ["/amp/shop/sidebar/:type?"],
    name: "shop-sidebar-type___ar"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
