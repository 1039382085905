<template>
    <div class="products mb-3">
        <p class="no-results" v-if="places.length === 0 && loaded">
            No products matching your selection.
        </p>

        <template v-else>
            <div >
                <template v-if="!loaded">
                    <div
                        class="skel-pro skel-pro-list"
                        v-for="item in fakeArray"
                        :key="item"
                    ></div>
                </template>
                <template v-else>
                    <div class="row">
                        <alf-places
                            class="col-md-6 col-sm-12"                        
                            :place="place"
                            v-for="(place, index) in places"
                            :key="index"
                        ></alf-places>
                    </div>
                </template>
            </div>
        </template>
    </div>
</template>
<script>
import AlfPlaces from '~/components/elements/places/AlfPlaces';
export default {
    components: {
        AlfPlaces,
    },
    props: ['places', 'perPage', 'loaded'],
    computed: {
        type: function () {
            return this.$route.params.type;
        },

        fakeArray: function () {
            let temp = [];
            for (let i = 0; i < this.perPage; i++) {
                temp.push(i);
            }
            return temp;
        },
    },
};
</script>
<style lang="scss" scoped>
.head .carousel-data{
    bottom: -50px;
}
.carousel-data .holder{
    padding: 10px 20px;
}
</style>